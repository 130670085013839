import React from 'react';
import { Row } from 'components';
import styled from 'styled-components';

import ChipAllActive from './chip-all-active';
import ChipNeedsReview from './chip-needs-review';
import ChipAlmostDue from './chip-almost-due';
import ChipOverdue from './chip-overdue';
import ChipArchived from './chip-archived';
import ChipAssignedToMe from './chip-assigned-to-me';

const QuickFilters = () => {
	return (
		<WrappingRow>
			<ChipAllActive />
			<ChipNeedsReview />
			<ChipAlmostDue />
			<ChipOverdue />
			<ChipAssignedToMe />
			<ChipArchived />
		</WrappingRow>
	);
};

const WrappingRow = styled(Row)`
	flex-wrap: wrap;
`;

export default QuickFilters;
