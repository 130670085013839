import { Property } from '@rentcheck/types';

export type State = Property | null;

const initialState = null;

type Action = {
	type: string;
	property?: Property;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'DELETE_PROPERTY':
			if (!state) return state;
			if (!action.property) return state;
			if (action.property.id === state.id) return null;
			return state;

		case 'FETCHED_PROPERTY_FB':
			if (!action.property) return state;
			return action.property;

		default:
			return state;
	}
};

export default reducer;
