import { Button } from '@mui/material';
import { IntercomAPI } from 'react-intercom';

const Inactive = () => {
	const handleLearnMore = () => {
		IntercomAPI('showNewMessage');
	};

	return <Button onClick={handleLearnMore}>Chat to upgrade</Button>;
};

export default Inactive;
