import { Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Feature, MaintenanceFlag, TemplateQuestion } from '@rentcheck/types';
import { Column, Row, Spacer } from 'components';
import { useFeatureDetailsModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { Dispatch } from 'types';

import Header from './header';
import Images from './images';
import Sidebar from './sidebar';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up(599));

	const dispatch: Dispatch = useDispatch();

	const modalData = useFeatureDetailsModalData();

	const [currentFeature, setCurrentFeature] =
		useState<FeatureWithMetadata | null>(null);
	const [hoveredImage, setHoveredImage] = useState<string>();
	const [hoveredQuestion, setHoveredQuestion] = useState<TemplateQuestion>();
	const [hoveredFlag, setHoveredFlag] = useState<MaintenanceFlag>();

	useEffect(() => {
		if (!modalData?.metadata?.initialFeature) {
			return;
		}

		setCurrentFeature(modalData.metadata.initialFeature);
	}, [modalData]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleFeatureChanged = (feature: Feature) => {
		setCurrentFeature(
			modalData.metadata?.features.find((f) => f.id === feature.id) ?? null
		);
	};

	return (
		<Dialog fullScreen open={open} onClose={handleClose}>
			{!!currentFeature && (
				<>
					<Header feature={currentFeature} setFeature={setCurrentFeature} />

					<DialogContent
						sx={{
							paddingLeft: 30,
							paddingRight: 30,
							overflowY: md ? 'hidden' : 'scroll',
						}}>
						{md && (
							<Row style={{ alignItems: 'flex-start', height: '100%' }}>
								<Sidebar
									feature={currentFeature}
									hoveredImage={hoveredImage}
									setHoveredQuestion={setHoveredQuestion}
									setHoveredFlag={setHoveredFlag}
									mode="all-images"
								/>
								<Spacer width={4} />
								<Images
									feature={currentFeature}
									setHoveredImage={setHoveredImage}
									hoveredQuestion={hoveredQuestion}
									hoveredFlag={hoveredFlag}
									onFeatureChanged={handleFeatureChanged}
								/>
							</Row>
						)}
						{!md && (
							<Column style={{ alignItems: 'flex-start' }}>
								<Sidebar
									feature={currentFeature}
									hoveredImage={hoveredImage}
									setHoveredQuestion={setHoveredQuestion}
									setHoveredFlag={setHoveredFlag}
									mode="all-images"
									fullWidth
								/>
								<Spacer height={4} />
								<Images
									feature={currentFeature}
									setHoveredImage={setHoveredImage}
									hoveredQuestion={hoveredQuestion}
									hoveredFlag={hoveredFlag}
									onFeatureChanged={handleFeatureChanged}
								/>
							</Column>
						)}
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
