import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Typography } from '@mui/material';
import { Row } from 'components';

interface Props {
	title: string;
	icon: IconDefinition;
	description: string;
}
export default ({ title, icon, description }: Props) => {
	return (
		<Paper elevation={0} sx={{ mt: 2, background: '#F6F5FF' }}>
			<div style={{ padding: 16 }}>
				<Row>
					<FontAwesomeIcon icon={icon} color="#00C14F" />
					<Typography variant="h6" ml={1.5}>
						{title}
					</Typography>
				</Row>
				<Typography variant="body2" color="secondary" mt={1}>
					{description}
				</Typography>
			</div>
		</Paper>
	);
};
