import { APIBuilding } from '@rentcheck/types';
import _ from 'lodash';

export interface State {
	data: APIBuilding[];
	total: number;
	loading: boolean;
}

const initialState: State = {
	data: [],
	total: 0,
	loading: false,
};

type Action = {
	type: string;
	value?: number;
	ids?: string[];
	loading?: boolean;
	property?: APIBuilding;
	properties?: APIBuilding[];
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'FETCHED_INITIAL_BUILDINGS':
			if (!action.properties) return state;
			return {
				...state,
				data: action.properties,
			};

		case 'FETCHED_BUILDINGS_PAGE':
			if (!action.properties) return state;
			return {
				...state,
				data: _.uniqBy([...state.data, ...action.properties], (e) => e.id),
			};

		case 'SET_TOTAL_BUILDINGS':
			if (!action.value) return state;
			return {
				...state,
				total: action.value,
			};

		case 'DELETE_BUILDINGS':
			if (!action.ids) return state;
			return {
				...state,
				data: state.data.filter((u) => !action.ids?.includes(u.id)),
			};

		case 'SET_LOADING_BUILDINGS':
			return {
				...state,
				loading: !!action.loading,
			};

		default:
			return state;
	}
};

export default reducer;
