import { PropertiesApi } from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';

import { useEffect, useState } from 'react';

export const useProperty = (propertyId: string) => {
	const [property, setProperty] = useState<APIProperty>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		PropertiesApi.getById(propertyId)
			.then(setProperty)
			.catch(() => {})
			.finally(() => setLoading(false));
	}, [propertyId]);

	return { property, loading };
};
