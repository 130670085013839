import 'react-phone-input-2/lib/style.css';

import { useInspectionTemplateModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import TemplateDialog from './dialog';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const modalData = useInspectionTemplateModalData();
	const template = modalData?.metadata?.template;

	const fullTemplate = useTypedSelector(
		(state) => state.inspectionTemplates.template
	);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!template) {
			return;
		}

		setLoading(true);

		dispatch(InspectionTemplatesActions.get(template.id))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, [template?.id]);

	useEffect(() => {
		if (open) return;

		setLoading(true);
	}, [open]);

	if (!fullTemplate && !loading && open) {
		dispatch(ModalFlowActions.closeCurrentModal());
		dispatch(SnackbarActions.showError('Template not found'));
		return null;
	}

	return <TemplateDialog open={open} loading={loading} />;
};
