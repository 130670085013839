/* @ts-ignore */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { match as Match } from 'react-router-dom';

import { Building } from '@rentcheck/types';

import { Dispatch } from 'types';
import {
	TopLink,
	ScreenContainer,
	ScreenContent,
	ScreenContentMain,
	ScreenTitleRowDivider,
	ScreenContentDivider,
} from 'components';
import { ConfirmationModal } from 'components/Common';
import Content from 'components/properties/details/content';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { PropertiesActions } from 'store/actions';
import { userHasPermissionsToViewObject } from 'utils/helpers';

import PropertyInformation from './property-information';
import PropertyDetailsHeader from './property-details-header';

interface MatchParams {
	id: string;
	reportId?: string;
}

interface Props {
	match: Match<MatchParams>;
}

const BuildingDetails = ({ match }: Props) => {
	const propertyId = match.params.id;

	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const profile = useTypedSelector((state) => state.activeProfile);
	const property = useTypedSelector((state) =>
		state.properties.property?.id === propertyId
			? state.properties.property
			: null
	);

	const [fetchError, setFetchError] = useState<string | undefined>(undefined);

	const genericPropertyError =
		"This property was removed or you don't have the necessary permissions to view it.";

	useEffect(() => {
		dispatch(PropertiesActions.get(propertyId))
			.catch(() => setFetchError(genericPropertyError))
			.catch(() => setFetchError(genericPropertyError));
	}, []);

	useEffect(() => {
		if (!profile) return;
		if (!property) return;
		if (userHasPermissionsToViewObject(profile, property)) return;

		setFetchError(genericPropertyError);
	}, [property, profile]);

	if (!property) {
		if (fetchError) {
			return (
				<ConfirmationModal
					title="There was a problem"
					body={fetchError}
					confirmTitle="Back to all buildings"
					confirmPressed={() => history.push('/properties/buildings')}
				/>
			);
		}

		return null;
	}

	return (
		<div>
			<TopLink linkTo="/properties/buildings" label="Back to all buildings" />
			<ScreenContainer>
				<PropertyDetailsHeader property={property as Building} />
				<ScreenTitleRowDivider />
				<ScreenContent hasTitleRow>
					<PropertyInformation property={property as Building} />
					<ScreenContentDivider hasTitleRow />
					<ScreenContentMain hasTitleRow>
						<Content property={property} />
					</ScreenContentMain>
				</ScreenContent>
			</ScreenContainer>
		</div>
	);
};

export default BuildingDetails;
