import { createTheme as muiCreateTheme, ThemeOptions } from '@mui/material';
import typographyComponent from './typography';

const orange = '#E5740B';
const red = '#E63030';
const lightGray = '#EBEFF3';

export const colors = {
	alertBackground: 'rgba(255, 149, 75, 0.2)',
	alertBorder: orange,
	background: lightGray,
	black: '#000',
	contentBackground: '#FAFAFA',
	contentBorder: '#D9D7D7',
	danger: red,
	darkGray: '#232E3A',
	fadeBlue: '#7EB8FF',
	fadeDark: '#15212B',
	gray: '#9AA6B6',
	lightGray,
	primaryLight: '#D3D4FF',
	primary: '#3D50FF',
	primaryDark: '#515D89',
	secondary: '#4d4d4d',
	transparent: 'transparent',
	valid: '#4ADEA9',
	white: '#FFF',

	lightBlue3: '#CFD7FF',
	darkBlue1: '#454BD9',
	grayBlue: '#4E5D8D',
	warning: orange,
	error: red,
	success: '#2da656',
	shadow: '#979797',

	darkBlue2: '#061ACA',
	darkGreen: '#1B823E',
	steel: '#798AA0',
	darkSteel: '#445567',
};

export const radius = {
	small: '4px',
	large: '8px',
};

const BASE_SPACING = 4;
export const spacing = (multiplier?: number) =>
	multiplier ? BASE_SPACING * multiplier : 0;

export const spacingPx = (multiplier?: number) => spacing(multiplier) + 'px';

export const reset = {
	border: 0,
	margin: 0,
	padding: 0,
	background: 'transparent',
	height: 'auto',
	width: 'auto',
	transition: 'none',
};

export const dimensions = {
	maxWidth: '1152px',
};

export const mediaSize = {
	mobile: '(max-width: 900px)',
	desktop: '(min-width: 901px)',
};

export const muiTheme: ThemeOptions = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 615,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	palette: {
		primary: { main: colors.primary },
		secondary: { main: '#707070' },
		success: { main: '#36b45f' },
	},
	typography: {
		...typographyComponent.MuiTypography.styleOverrides.body1,
		fontSize: 14,
	},
	components: {
		...typographyComponent,
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: colors.white,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: 12,
				},
				popper: {
					zIndex: 9999999999,
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				title: {
					textAlign: 'left',
					...typographyComponent.MuiTypography.styleOverrides.h6,
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					'&.Mui-expanded': {
						margin: 0,
					},
					'&:before': {
						display: 'none',
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					'&.Mui-focusVisible': {
						backgroundColor: '#fff',
					},
					'&.Mui-expanded': {
						margin: 0,
					},
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiIconButton: {
			styleOverrides: {
				colorSecondary: {
					color: '#445567',
				},
			},
		},
		MuiLoadingButton: {
			defaultProps: {
				variant: 'contained',
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					...typographyComponent.MuiTypography.styleOverrides.subtitle2,
					color: `${colors.secondary} !important`,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 500,
					textTransform: 'none',
					fontFamily: 'Poppins',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
			},
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 500,
					textTransform: 'none',
					fontFamily: 'Poppins',
					letterSpacing: '-0.02rem',
				},
				outlinedPrimary: {
					borderColor: '#0000001F',
				},
				outlinedSecondary: {
					color: '#707070',
					borderColor: '#0000001F',
					':hover': {
						backgroundColor: '#F0F0F0',
					},
				},
				sizeSmall: {
					borderRadius: spacingPx(10),
					borderColor: colors.black,
					minWidth: 'auto',
				},
				startIcon: {
					fontSize: 14,
					'svg:not(:root).svg-inline--fa': {
						fontSize: 14,
					},
				},
				endIcon: {
					fontSize: 14,
					'svg:not(:root).svg-inline--fa': {
						fontSize: 14,
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					color: '#2D3CE6',
					fontSize: 16,
					fontWeight: 500,
					textTransform: 'none',

					selected: {
						color: '#2D3CE6',
						backgroundColor: '#2D3CE614',
					},

					':disabled': {
						border: 'none',
					},

					'&.Mui-selected': {
						color: '#2D3CE6',
						backgroundColor: '#2D3CE614',

						':hover': {
							backgroundColor: '#2D3CE624',
						},
					},

					':hover': {
						backgroundColor: '#0000000A',
					},
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: `${spacingPx(7)} ${spacingPx(8)}`,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				icon: {
					marginLeft: 12,
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				scroll: 'paper',
				maxWidth: 'md',
				fullWidth: true,
			},
			styleOverrides: {},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: 'inherit',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					paddingLeft: spacingPx(7),
					paddingRight: spacingPx(7),

					'@media (max-width: 1239px)': {
						paddingLeft: spacingPx(4),
						paddingRight: spacingPx(4),
					},
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					borderTop: '1px solid #cccccc',
					justifyContent: 'space-between',
					padding: `${spacingPx(5)} ${spacingPx(7)}`,

					'@media (max-width: 1239px)': {
						padding: `${spacingPx(4)}`,
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontSize: 32,
					fontWeight: 600,
					padding: `${spacingPx(5)} ${spacingPx(7)}`,

					'@media (max-width: 1239px)': {
						padding: `${spacingPx(4)}`,
					},
				},
			},
		},

		MuiMenu: {
			styleOverrides: {
				paper: {
					marginTop: 4,
				},
			},
			defaultProps: {
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'center',
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: 'Poppins',
					letterSpacing: '0rem',
					fontWeight: 500,
					fontSize: 12,
					height: 40,
				},
			},
		},
		MuiPopover: {
			defaultProps: {
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				transformOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: '#989898',
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					'.MuiInputAdornment-positionStart': {
						marginLeft: '4px !important',
					},
				},
				inputRoot: {
					'.MuiAutocomplete-input': {
						padding: 0,
					},
				},
				listbox: {
					li: {
						fontFamily: 'Poppins',
						letterSpacing: '0rem',
						fontWeight: 500,
						fontSize: 12,
					},
				},
				popper: {
					zIndex: 9999999999,
				},
			},
		},
		MuiSnackbar: {
			defaultProps: {
				autoHideDuration: 3000,
			},
			styleOverrides: {
				root: {
					zIndex: 9999999999,
				},
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					backgroundColor: colors.white,
					color: colors.darkGray,
					fontWeight: 500,
				},
				action: {
					button: {
						color: colors.primaryDark,
						fontWeight: 500,
					},
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				standardSuccess: {
					backgroundColor: '#354351',
					color: colors.white,
					fontWeight: 500,
				},
				standardError: {
					backgroundColor: colors.error,
					color: colors.white,
					fontWeight: 500,
				},
				standardInfo: {
					fontWeight: 500,
				},
			},
		},

		MuiTable: {
			styleOverrides: {
				root: { th: { backgroundColor: '#0000000A' } },
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: '#0000000A',
					th: {
						...typographyComponent.MuiTypography.styleOverrides.overline,
						color: '#101010',
					},
				},
			},
		},
		MuiTableSortLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					color: '#101010',
					':hover': {
						color: '#000',
					},
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					fontSize: 13,
					'.MuiTableRow-root': {
						':hover': {
							backgroundColor: '#eff1f480',
							transition: 'background-color 0.3s',
						},
					},
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: colors.primary,
				},
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				colorSecondary: {
					color: '#61768F',
				},
				//@ts-ignore
				colorInfo: {
					color: '#fff',
				},
				//@ts-ignore
				colorSuccess: {
					color: '#51C978',
				},
			},
		},
	},
};

export const createTheme = () => {
	return muiCreateTheme(muiTheme);
};
