import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TemplateFeature, TemplateFeatureLogic } from '@rentcheck/types';

import { DialogTitle, SpacedRow, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';

import EmptyState from './empty-state';
import LogicCard from '../logic-card/single';

interface Props {
	feature: TemplateFeature;
	features: TemplateFeature[];
	onUpdate: (feaure: TemplateFeature) => void;
	open: boolean;
	onClose: () => void;
}

export default ({ feature, features, onUpdate, open, onClose }: Props) => {
	const dispatch = useDispatch();

	const [logicConditions, setLogicConditions] = useState(feature.logic);

	useEffect(() => {
		const logicConditions = [...feature.logic];

		if (!logicConditions.length) {
			logicConditions.push({
				id: uuid(),
				trigger_id: '',
				trigger_type: 'question',
				condition: 'is-any-of',
				action: 'show',
				condition_value: [],
			});
		}

		setLogicConditions(logicConditions);
	}, [feature.logic, open]);

	const handleSetLogic = (newLogic: TemplateFeatureLogic) => {
		const newLogicConditions = logicConditions.map((logic) =>
			logic.id === newLogic.id ? newLogic : logic
		);

		setLogicConditions(newLogicConditions);
	};

	const handleDeleteLogic = (logic: TemplateFeatureLogic) => {
		const newLogicConditions = logicConditions.filter((l) => l.id !== logic.id);

		setLogicConditions(newLogicConditions);
	};

	const handleSave = () => {
		const updatedFeature = { ...feature, logic: logicConditions };

		onUpdate(updatedFeature);
		onClose();

		dispatch(SnackbarActions.showSuccess('Success! Logic saved.'));
	};

	const handleAddLogic = () => {
		setLogicConditions([
			...logicConditions,
			{
				id: uuid(),
				trigger_id: '',
				trigger_type: 'question',
				condition: 'is-any-of',
				action: 'show',
				condition_value: [],
			},
		]);
	};

	const hasInvalidLogic = logicConditions.some((logic) => {
		return !logic.trigger_id || logic.condition_value.length === 0;
	});

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				onClose={onClose}
				title={`Feature Logic: ${feature.name}`}
				icon={regular('split')}
			/>
			<DialogContent sx={{ minHeight: '60vh', backgroundColor: '#F6F5FF' }}>
				{logicConditions.length === 0 && (
					<EmptyState onAddLogic={handleAddLogic} />
				)}
				{logicConditions.map((logic) => (
					<LogicCard
						key={logic.id}
						logic={logic}
						logics={logicConditions}
						onUpdate={handleSetLogic}
						onDelete={handleDeleteLogic}
						feature={feature}
						features={features}
					/>
				))}
				{logicConditions.length > 0 && (
					<SpacedRow>
						<Spacer width={1} />
						<Button
							variant="text"
							onClick={handleAddLogic}
							startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
							Add Logic
						</Button>
					</SpacedRow>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={handleSave} disabled={hasInvalidLogic}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
