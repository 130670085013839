import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Building, Property } from '@rentcheck/types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import Modal from 'components/Common/Modal';

import { Dialog, DialogContent } from '@mui/material';
import firebase from 'firebase/app';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import * as BuildingActions from 'store/actions/buildingsActions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { DialogTitle } from '../..';
import { RoomNames } from '../../../constants';
import { Confirmation, Footer, SelectRooms } from '../CreateUnit/Components';
import { Address, Floors, Team } from './Components';

interface Props {
	setModalVisible: (visible: boolean) => void;
	creationCallback: (property: Property) => void;
}

export type CreateBuildingStep =
	| 'Building Address'
	| 'Floors'
	| 'Building Areas'
	| 'Select Team';

const CreateBuildingModal = ({ setModalVisible, creationCallback }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const accountSettings = useTypedSelector((state) => state.accountSettings);
	const profile = useTypedSelector((state) => state.activeProfile);

	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [floors, setFloors] = useState('1');
	const [roomNames, setRoomNames] = useState<string[]>([]);
	const [organizationId, setOrganizationId] = useState('');
	const [additionalOrganizationIds, setAdditionalOrganizationIds] = useState<
		string[]
	>([]);

	const [loading, setLoading] = useState(false);

	const [exitModalVisible, setExitModalVisible] = useState(false);

	const [property, setProperty] = useState<Building | undefined>(undefined);
	const [stepHistory, setStepHistory] = useState<CreateBuildingStep[]>([
		'Building Address',
	]);

	useEffect(() => {}, [organizationId]);

	const pushStep = (newStep: CreateBuildingStep) => {
		setStepHistory([...stepHistory, newStep]);
	};

	const backStep = () => {
		if (stepHistory.length <= 1) {
			return;
		}

		setStepHistory(stepHistory.slice(0, stepHistory.length - 1));
	};

	const handleCreateBuilding = () => {
		setLoading(true);

		const payload: Omit<Building, 'id'> = {
			name: name,
			address: address,
			address_lowercase: address.toLowerCase(),
			authorized_user_ids: [organizationId, ...additionalOrganizationIds],
			city: city,
			region: '',
			zipcode: zipcode,
			created_date: firebase.firestore.Timestamp.fromDate(new Date()),
			creatorID: profile.id,
			landlordID: profile.id,
			ownerID: profile.id,
			property_type: 'Building',
			room_names: roomNames,
			rooms: roomNames,
			organization_id: organizationId,
			bedrooms: 0,
			full_bathrooms: 0,
			half_bathrooms: 0,
			floors: parseInt(floors),
		};

		dispatch(BuildingActions.create(payload))
			.then((createdBuilding: Building) => {
				Analytics.trackEvent('Created building', {
					address,
					city,
					zipcode,
					propertyID: createdBuilding.id,
				});

				setProperty(createdBuilding);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const renderContent = () => {
		const currentStep = _.last(stepHistory);

		const customRooms = accountSettings?.rooms.building ?? [];

		switch (currentStep) {
			case 'Building Address':
				return (
					<>
						<Address
							name={name}
							address={address}
							city={city}
							zipcode={zipcode}
							setName={setName}
							setAddress={setAddress}
							setCity={setCity}
							setZipcode={setZipcode}
						/>
						<Footer
							leftClick={() => setExitModalVisible(true)}
							rightClick={() => pushStep('Floors')}
							leftLabel={'Cancel'}
							rightLabel={'Next: Floors'}
							disabled={!name || !address || !city || !zipcode}
						/>
					</>
				);
			case 'Floors':
				return (
					<>
						<Floors floors={floors} setFloors={setFloors} />
						<Footer
							leftClick={backStep}
							rightClick={() => pushStep('Building Areas')}
							leftLabel={'Back'}
							rightLabel={'Next: Building Areas'}
							disabled={isNaN(parseInt(floors)) || parseInt(floors) < 1}
						/>
					</>
				);
			case 'Building Areas':
				return (
					<>
						<SelectRooms
							roomNames={roomNames}
							options={[
								...RoomNames.building,
								...customRooms.map((r) => r.name),
							]}
							setValue={setRoomNames}
							title={
								'Select all amenities and common areas that apply to this Building'
							}
						/>
						<Footer
							leftClick={backStep}
							rightClick={() => pushStep('Select Team')}
							leftLabel={'Back'}
							rightLabel={'Next: Select Team'}
							disabled={false}
						/>
					</>
				);
			case 'Select Team':
				return (
					<>
						<Team
							organizationId={organizationId}
							setOrganizationId={setOrganizationId}
							additionalOrganizationIds={additionalOrganizationIds}
							setAdditionalOrganizationIds={setAdditionalOrganizationIds}
						/>
						<Footer
							leftClick={backStep}
							rightClick={handleCreateBuilding}
							rightClickDisabled={() =>
								dispatch(
									SnackbarActions.showError(
										'At least one team must be selected.'
									)
								)
							}
							leftLabel={'Back'}
							rightLabel={'Complete Building'}
							disabled={!organizationId}
							loading={loading}
						/>
					</>
				);
		}
	};

	const currentStep = _.last(stepHistory);

	if (property) {
		const extraText =
			`${property.name} was successfully created. You can add units to the building ` +
			`at any time by selecting one or more units from the Units tab and selecting to Add to Building.`;

		return (
			<Modal>
				<Confirmation
					address={address}
					dismissModal={() => {
						setModalVisible(false);
						history.push('/properties/buildings');
					}}
					title="Building Added!"
					extraText={extraText}
					showInspectionModal={() => creationCallback(property)}
				/>
			</Modal>
		);
	}

	return (
		<Dialog open onClose={() => setExitModalVisible(true)}>
			{!property && (
				<DialogTitle
					title="Add Building"
					icon={solid('building')}
					onClose={() => setExitModalVisible(true)}
					bottomSubtitle={
						currentStep !== 'Building Address'
							? `${name} - ${address}, ${city} ${zipcode}`
							: 'Fill out the details below to add a building.'
					}
				/>
			)}

			<DialogContent>{renderContent()}</DialogContent>

			{exitModalVisible && (
				<ConfirmationModal
					title="Are you sure?"
					body="Do you want to exit and lose all your progress in creating this new building?"
					cancelTitle="Go Back"
					confirmTitle="Exit & Close"
					destructive
					cancelPressed={() => setExitModalVisible(false)}
					confirmPressed={() => setModalVisible(false)}
				/>
			)}
		</Dialog>
	);
};

export { Address, Team };
export default CreateBuildingModal;
