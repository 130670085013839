import { LoadingButton } from '@mui/lab';
import {
	Card,
	CardContent,
	Divider,
	TextField,
	Typography,
} from '@mui/material';
import { Profile } from '@rentcheck/types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { UsersApi } from 'api';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import BuildingsUploader from './buildings';
import ResidentsUploader from './residents';
import UnitsUploader from './units';

export default () => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const [user, setUser] = useState<Profile | undefined>(undefined);

	const dispatch: Dispatch = useDispatch();

	const search = async (email: string) => {
		setUser(undefined);
		setLoading(true);

		UsersApi.getByEmail(email)
			.then((user) => {
				if (!user) {
					dispatch(SnackbarActions.showError('User not found'));
					return;
				}

				setUser(user);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Uploaders</Typography>
				<Spacer height={2} />
				<Typography>Search by user email:</Typography>
				<Spacer height={4} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						search(email);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Spacer width={6} />
					<LoadingButton
						type="submit"
						variant="contained"
						onClick={() => search(email)}
						loading={loading}>
						Search
					</LoadingButton>
				</FormRow>

				{!!user && (
					<SpacedRow style={{ alignItems: 'flex-start', paddingTop: 32 }}>
						<Column style={{ width: '50%' }}>
							<Card>
								<CardContent>
									<Typography variant="h6">{user.user_name}</Typography>
									<Typography>{user.user_type}</Typography>
									<Typography>{user.email}</Typography>

									<Divider sx={{ marginTop: 1, marginBottom: 2 }} />

									<Row>
										<Typography fontWeight={600}>Organizations:</Typography>
										<Spacer width={1} />
										<Typography>
											{user.organizations.map((o) => o.id).join(', ')}
										</Typography>
									</Row>
								</CardContent>
							</Card>
						</Column>

						<Column style={{ width: '48%', alignItems: 'flex-start' }}>
							<UnitsUploader user={user} />
							<Spacer height={4} />
							<BuildingsUploader user={user} />
							<Spacer height={4} />
							<ResidentsUploader user={user} />
						</Column>
					</SpacedRow>
				)}
			</CardContent>
		</Card>
	);
};

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	width: 50%;
`;
