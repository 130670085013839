import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Screens } from 'components';
import { useEffect, useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import {
	ModalFlowActions,
	PropertiesActions,
	SessionOptionsActions,
	SubscriptionActions,
} from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import Skeleton from './skeleton';
import Subscribed from './subscribed';
import { handleUpdatePaymentMethod } from './subscribed/common';
import Unsubscribed from './unsubscribed';

const ActionButton = () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const handleUpdateSubscription = () => {
		IntercomAPI('showNewMessage');
		return;
	};

	const handleAddPaymentDetails = () => {
		dispatch(SessionOptionsActions.setBillingSetupDismissed(false));
	};

	const handleUpgrade = () => {
		Analytics.trackEvent('selected_button_to_upgrade_plan');
		dispatch(ModalFlowActions.showUpgradeSubscriptionModal());
	};

	const handleUpgradeFromTrial = () => {
		handleUpdatePaymentMethod(dispatch, profile, subscription);
	};

	if (subscription) {
		if (subscription.status === 'in_trial' && !subscription.payment_method) {
			return (
				<Button
					variant="outlined"
					onClick={handleUpgradeFromTrial}
					startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}>
					Upgrade from Trial
				</Button>
			);
		}

		return (
			<Button
				variant="outlined"
				onClick={handleUpdateSubscription}
				startIcon={<FontAwesomeIcon icon={solid('message-lines')} />}>
				Chat to update subscription
			</Button>
		);
	}

	if (profile.prospect_data?.payment_info_provided === false) {
		return (
			<Button
				variant="outlined"
				onClick={handleAddPaymentDetails}
				startIcon={<FontAwesomeIcon icon={solid('credit-card')} />}>
				Add payment details
			</Button>
		);
	}

	return (
		<Button
			variant="outlined"
			onClick={handleUpgrade}
			startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}>
			Upgrade subscription
		</Button>
	);
};

const Billing = () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const units = useTypedSelector((state) => state.properties.units);

	const [loading, setLoading] = useState(!subscription || !units.total);

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (subscription && units.total) {
			setLoading(false);
			return;
		}

		setLoading(true);

		const promises: Promise<unknown>[] = [
			dispatch(SubscriptionActions.getSubscription()),
		];

		/**
		 * If units total is 0 it means we either don't have units yet or the account
		 * actually has 0 units, just in case we will fetch units to get the total.
		 */
		if (units.total === 0) {
			promises.push(dispatch(PropertiesActions.units.getAll()));
		}

		Promise.allSettled(promises).finally(() => setLoading(false));
	}, [profile]);

	if (!profile || loading) {
		return <Skeleton />;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Billing"
				showBreadCrumbs
				subtitle="View and manage subscription details and billing information. Download RentCheck invoices."
				actions={[<ActionButton />]}
			/>
			<Subscribed />
			<Unsubscribed />
		</Screens.Container>
	);
};

export default Billing;
