import { Dispatch } from 'types';
import { BuildingsApi, PropertiesApi } from 'api';
import { Building } from '@rentcheck/types';

export type BuildingsActionType = 'FETCHED_BUILDINGS' | 'FETCHED_BUILDING';

export const actionTypes: { [index: string]: BuildingsActionType } = {
	FETCHED_BUILDINGS: 'FETCHED_BUILDINGS',
	FETCHED_BUILDING: 'FETCHED_BUILDING',
};

export const create =
	(payload: Omit<Building, 'id'>) =>
	async (dispatch: Dispatch): Promise<Building | undefined> => {
		const building = await BuildingsApi.create(payload);
		dispatch({ type: actionTypes.FETCHED_BUILDING, building });
		return building;
	};

export const batchCreate =
	(payload: Omit<Building, 'id'>[]) =>
	async (dispatch: Dispatch): Promise<Building[]> => {
		const buildings = await BuildingsApi.batchCreate(payload);
		dispatch({ type: actionTypes.FETCHED_BUILDINGS, buildings });
		return buildings;
	};

export const update =
	(id: string, payload: Partial<Building>) =>
	async (dispatch: Dispatch): Promise<void> =>
		BuildingsApi.update(id, payload).then((building) =>
			dispatch({
				type: 'FETCHED_PROPERTY_FB',
				property: building,
			})
		);

export const updateOrganizations =
	(
		id: string,
		adminOrganizationId: string,
		additionalOrganizationIds: string[]
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		await PropertiesApi.updateOrganization([id], adminOrganizationId);
		const building = await BuildingsApi.update(id, {
			authorized_user_ids: [adminOrganizationId, ...additionalOrganizationIds],
		});
		dispatch({
			type: 'FETCHED_PROPERTY_FB',
			property: building,
		});
	};
