import { inspectionStatusDisplayEnumArray } from '@rentcheck/types';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';

const title: FilterType = 'Inspection Status';

const FilterInspectionStatusTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const inspectionStatusFilter = useTypedSelector(
		(state) => state.inspections.filters.inspectionStatus
	);

	return (
		<FilterTitle
			label={title}
			isActive={inspectionStatusFilter.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const FilterInspectionStatusDetails = ({
	selectedFilter,
}: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const inspectionStatusFilter = useTypedSelector(
		(state) => state.inspections.filters.inspectionStatus
	);

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (inspectionStatus: string) => {
		dispatch({
			type: 'TOGGLE_INSPECTION_STATUS_FILTER',
			value: inspectionStatus,
		});
	};

	return (
		<>
			{inspectionStatusDisplayEnumArray.map((is) => (
				<FilterTitleOption
					key={is}
					label={is}
					isSelected={inspectionStatusFilter.includes(is)}
					onClick={() => handleClick(is)}
				/>
			))}
		</>
	);
};

export { FilterInspectionStatusTitle, FilterInspectionStatusDetails };
