import { Building, Profile } from '@rentcheck/types';
import firebase from 'config/fbConfig';
import _ from 'lodash';

import {
	objectFromDocumentSnapshot,
	objectsFromQuerySnapshot,
} from './helpers';

const COLLECTION = 'properties';

export const create = async (payload: Omit<Building, 'id'>) => {
	const ref = firebase.firestore().collection(COLLECTION).doc();
	return ref.set({ ...payload, id: ref.id }).then(() => getById(ref.id));
};

export const batchCreate = async (payload: Omit<Building, 'id'>[]) => {
	const payloadChunks = _.chunk(payload, 500);
	const ids: string[] = [];

	for (const chunk of payloadChunks) {
		const batch = firebase.firestore().batch();

		chunk.forEach((p) => {
			const ref = firebase.firestore().collection('properties').doc();
			batch.set(ref, { ...p, id: ref.id });
			batch.update(ref, { latchel_integration: {} });
			ids.push(ref.id);
		});

		await batch.commit();
	}

	return getByIds(ids);
};

export const hasBuildings = async (profile: Profile): Promise<boolean> => {
	const fetchIds = [profile.id];

	if (profile.organizations && profile.organizations.length) {
		profile.organizations.forEach((o) => fetchIds.push(o.id));
	}

	const chunks = _.chunk(fetchIds, 10);

	return Promise.all(
		chunks.map((chunk) =>
			firebase
				.firestore()
				.collection('properties')
				.where('authorized_user_ids', 'array-contains-any', chunk)
				.where('property_type', '==', 'Building')
				.limit(1)
				.get()
				.then((snapshot) => objectsFromQuerySnapshot<Building>(snapshot))
				.catch(() => [] as Building[])
		)
	).then((results) => _.flatten(results).length > 0);
};

export const getAll = async (profile: Profile): Promise<Building[]> => {
	const fetchIds = [profile.id];

	if (profile.organizations && profile.organizations.length) {
		profile.organizations.forEach((o) => fetchIds.push(o.id));
	}

	const chunks = _.chunk(fetchIds, 10);

	return Promise.all(
		chunks.map((chunk) =>
			firebase
				.firestore()
				.collection('properties')
				.where('authorized_user_ids', 'array-contains-any', chunk)
				.where('property_type', '==', 'Building')
				.get()
				.then((snapshot) => objectsFromQuerySnapshot<Building>(snapshot))
				.catch(() => [] as Building[])
		)
	).then((results) => _.flatten(results));
};

export const getById = async (id: string) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.doc(id)
		.get()
		.then((doc) => objectFromDocumentSnapshot<Building>(doc))
		.catch(() => undefined);
};

export const getByIds = async (ids: string[]): Promise<Building[]> => {
	const buildings: Building[] = [];

	const chunks = _.chunk(ids, 10);
	for (const ids_chunk of chunks) {
		await firebase
			.firestore()
			.collection('properties')
			.where('id', 'in', ids_chunk)
			.get()
			.then((snapshot) =>
				buildings.push(...objectsFromQuerySnapshot<Building>(snapshot))
			)
			.catch(() => undefined);
	}

	return buildings;
};

export const getByOrganizationIds = async (orgIds: string[]) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.where('organization_id', 'in', orgIds)
		.where('property_type', '==', 'Building')
		.get()
		.then((querySnapshot) => objectsFromQuerySnapshot<Building>(querySnapshot))
		.catch(() => []);
};

export const getByCreatorId = async (creatorId: string) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.where('creatorID', '==', creatorId)
		.where('property_type', '==', 'Building')
		.get()
		.then((querySnapshot) => objectsFromQuerySnapshot<Building>(querySnapshot))
		.catch(() => []);
};

export const getByAddressAndOwner = async (
	lowercaseAddress: string,
	zipcode: string,
	ownerId: string,
	organizationId?: string
) => {
	const authorizedUserIds = [ownerId];
	if (organizationId) authorizedUserIds.push(organizationId);

	return firebase
		.firestore()
		.collection(COLLECTION)
		.where('address_lowercase', '==', lowercaseAddress)
		.where('zipcode', '==', zipcode)
		.where('authorized_user_ids', 'array-contains-any', authorizedUserIds)
		.where('property_type', '==', 'Building')
		.get()
		.then((querySnapshot) =>
			_.first(objectsFromQuerySnapshot<Building>(querySnapshot))
		);
};

export const update = async (id: string, payload: Partial<Building>) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.doc(id)
		.update(payload)
		.then(() => getById(id));
};
