import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	DialogActions,
	DialogContent,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import {
	APIProperty,
	ApiInspectionTemplate,
	ApiInspectionTemplateDigest,
	TemplateSection,
} from '@rentcheck/types';

import { Column, Spacer } from 'components';

import { Utils } from '@rentcheck/biz';
import { useInspectionTemplate } from 'hooks/templates';
import { noCardinalRoomName, numberAndPluralizeWord } from 'utils/helpers';
import { FeatureInfo } from '../../index';
import CheckboxRow from './checkbox-row';
import Skeleton from './skeleton';

interface Props {
	properties: APIProperty[];
	templateDigest?: ApiInspectionTemplateDigest;
	checkedFeaturesInfo: FeatureInfo[];
	setCheckedFeaturesInfo: (value: FeatureInfo[]) => void;
	setSelectFeaturesInfo: (features: FeatureInfo[]) => void;
	handleClose: () => void;
}

const filterAvailableSections = (
	template: ApiInspectionTemplate,
	properties: APIProperty[]
) => {
	const propertyRooms = _.uniq(properties.map((p) => p.rooms).flat());

	return template.sections.filter((s) => {
		if (s.type === 'section') return true;

		if (template.property_type === 'building' && s.name === 'Floors') {
			return true;
		}

		return propertyRooms.includes(
			Utils.InspectionFeatures.parseTemplateRoomName(s.name)
		);
	});
};

const expandRepeatableSections = (
	template: ApiInspectionTemplate,
	sections: TemplateSection[],
	properties: APIProperty[]
) => {
	/**
	 * Neither buildings nor communities contain any repeatable sections
	 * that we're interested in so we just return what we have.
	 * FYI - buildings do have repeatable floors, but they don't get expanded
	 * here, we handle them in the api, as any feature selected for "Floors" is actually
	 * selected for every floor in the building.
	 */
	if (
		template.property_type === 'building' ||
		template.property_type === 'community'
	) {
		return sections;
	}

	/**
	 * We need to expand the repeatable sections for units. By creating an array of all
	 * the room names we get a set that contains the maximum number of bedrooms, full bathrooms
	 * and half bathrooms that can exist on any property.
	 */
	const propertyRoomNames = _.uniq(properties.map((p) => p.room_names).flat());
	const expandedSections: TemplateSection[] = [];

	sections.forEach((section) => {
		if (!Utils.InspectionFeatures.sectionCanExistMultipleTimes(section.name)) {
			expandedSections.push(section);
			return;
		}

		const singularSectionName = Utils.InspectionFeatures.parseTemplateRoomName(
			section.name
		);

		const roomNamesForSection = propertyRoomNames.filter(
			(name) => noCardinalRoomName(name) === singularSectionName
		);

		roomNamesForSection.forEach((roomName) => {
			expandedSections.push({
				...section,
				name: roomName,
			});
		});
	});

	return expandedSections;
};

export default ({
	properties,
	templateDigest,
	checkedFeaturesInfo,
	setCheckedFeaturesInfo,
	setSelectFeaturesInfo,
	handleClose,
}: Props) => {
	const { template, loading: templateLoading } = useInspectionTemplate(
		templateDigest?.id
	);

	const [search, setSearch] = useState('');
	const [availableFeatures, setAvailableFeatures] = useState<FeatureInfo[]>([]);
	const [filteredFeatures, setFilteredFeatures] = useState<FeatureInfo[]>([]);

	useEffect(() => {
		if (!template) return;

		const availableSections = filterAvailableSections(template, properties);
		const expandedAvailableSections = expandRepeatableSections(
			template,
			availableSections,
			properties
		);

		const availableFeatures = expandedAvailableSections
			.map((s) => {
				return s.features.map((f) => {
					return { feature: f.name, room: s.name };
				});
			})
			.flat();

		setAvailableFeatures(availableFeatures);
	}, [template, properties]);

	useEffect(() => {
		if (!search.trim().length) {
			setFilteredFeatures(availableFeatures);
			return;
		}

		setFilteredFeatures(
			availableFeatures.filter((f) => {
				const stringToSearchIn = `${f.feature} ${f.room}`.toLowerCase();
				return stringToSearchIn.includes(search.toLowerCase());
			})
		);
	}, [availableFeatures, search]);

	const handleClear = () => {
		setCheckedFeaturesInfo([]);
		setSearch('');
	};

	const handleSubmit = () => {
		setSelectFeaturesInfo(checkedFeaturesInfo);
		handleClose();
	};

	const featureInfoIsEqual = (f1: FeatureInfo, f2: FeatureInfo) => {
		return f1.feature === f2.feature && f1.room === f2.room;
	};

	const checkFeatureInfo = (feature: FeatureInfo) => {
		const foundFeature = checkedFeaturesInfo.find((f) =>
			featureInfoIsEqual(f, feature)
		);

		if (foundFeature) {
			setCheckedFeaturesInfo(
				checkedFeaturesInfo.filter((f) => {
					if (featureInfoIsEqual(f, feature)) return false;
					return true;
				})
			);
		} else {
			setCheckedFeaturesInfo([...checkedFeaturesInfo, feature]);
		}
	};

	const handleSelectAll = () => {
		const allSelected = checkedFeaturesInfo.length === availableFeatures.length;

		if (!allSelected) {
			setCheckedFeaturesInfo(availableFeatures);
		} else {
			setCheckedFeaturesInfo([]);
		}
	};

	return (
		<>
			<DialogContent style={{ minHeight: '65vh' }}>
				<Column>
					<TextField
						fullWidth
						placeholder="Search Features"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<FontAwesomeIcon icon={regular('search')} />
								</InputAdornment>
							),
						}}
					/>

					{properties.length > 1 && (
						<>
							<Spacer height={5} />
							<Typography>
								Selected features will be included in inspections only if they
								apply to existing rooms/areas on the property.
							</Typography>
						</>
					)}

					{!search && (
						<CheckboxRow
							title="Select all"
							subtitle={numberAndPluralizeWord(
								availableFeatures.length,
								'Feature'
							)}
							checked={checkedFeaturesInfo.length === availableFeatures.length}
							onClick={handleSelectAll}
						/>
					)}

					{templateLoading && <Skeleton />}

					{filteredFeatures.map((f) => (
						<CheckboxRow
							title={f.feature}
							subtitle={f.room}
							checked={
								!!checkedFeaturesInfo.find((feature) =>
									featureInfoIsEqual(f, feature)
								)
							}
							onClick={() => checkFeatureInfo(f)}
						/>
					))}
				</Column>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClear}>
					Clear
				</Button>

				<Button
					variant="contained"
					disabled={!checkedFeaturesInfo.length}
					onClick={handleSubmit}>
					{checkedFeaturesInfo.length === 0
						? 'Add Features'
						: `Add ${numberAndPluralizeWord(checkedFeaturesInfo.length, 'Feature')}`}
				</Button>
			</DialogActions>
		</>
	);
};
