"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.webhookEvent = void 0;
exports.webhookEvent = [
    'INSPECTION_CREATED',
    'INVITE_ACCEPTED',
    'INSPECTION_COMPLETE_SELF',
    'INSPECTION_APPROVED',
    'INSPECTION_REVISION_SUBMITTED',
    'INSPECTION_READY_FOR_REVIEW',
    'SIGNATURE_REQUEST_FULFILLED',
    'MAINTENANCE_REPORT_CREATED',
    'MAINTENANCE_FLAG_CREATED',
];
