import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Screens } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import _ from 'lodash';
import {
	AccountSettingsActions,
	ModalFlowActions,
	SnackbarActions,
	SubscriptionActions,
	TeamsActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import { Permissions } from '@rentcheck/biz';
import { Prompt } from 'react-router-dom';
import Features from './features';
import MaintenanceFlags from './maintenance-flags';
import Skeleton from './skeleton';
import Summary from './summary';

const ActionButton = ({
	isEditing,
	setIsEditing,
	onSave,
}: {
	isEditing: boolean;
	setIsEditing: (value: boolean) => void;
	onSave: () => Promise<void>;
}) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [loading, setLoading] = useState(false);

	const handleSave = () => {
		setLoading(true);

		onSave()
			.then(() => {
				dispatch(
					SnackbarActions.showSuccess('Inspection report settings saved')
				);
				setIsEditing(false);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	const handleEdit = () => {
		if (!canEdit) {
			dispatch(
				ModalFlowActions.showConfirmationModal({
					icon: solid('lock'),
					title: 'Oops!',
					body1: [
						"It seems you don't have the necessary permission to edit your inspection report settings. Please contact your administrator for assistance.",
					],
					buttons: [
						{
							title: 'Done',
							onClick: () =>
								dispatch(ModalFlowActions.closeConfirmationModal()),
						},
					],
				})
			);

			return;
		}

		setIsEditing(true);
	};

	const canEdit = Permissions.AccountSettings.InspectionReports.canEdit(
		profile,
		subscription
	);

	if (isEditing) {
		return (
			<LoadingButton
				loading={loading}
				variant="contained"
				onClick={handleSave}
				startIcon={<FontAwesomeIcon icon={solid('save')} />}>
				Save
			</LoadingButton>
		);
	}

	return (
		<Button
			variant="outlined"
			onClick={handleEdit}
			startIcon={
				<FontAwesomeIcon icon={canEdit ? solid('edit') : solid('lock')} />
			}>
			Edit
		</Button>
	);
};

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const reportSettings = useTypedSelector(
		(state) => state.accountSettings?.inspection_report_templates.default
	);

	const [loading, setLoading] = useState(!subscription);
	const [isEditing, setIsEditing] = useState(false);
	const [payload, setPayload] = useState(_.cloneDeep(reportSettings));

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (subscription) {
			setLoading(false);
			return;
		}

		setLoading(true);

		const promises: Promise<unknown>[] = [
			dispatch(SubscriptionActions.getSubscription()),
			dispatch(TeamsActions.getAll()),
		];

		Promise.allSettled(promises).finally(() => setLoading(false));
	}, [profile]);

	useEffect(() => {
		setPayload(_.cloneDeep(reportSettings));
	}, [reportSettings]);

	const handleSave = async () => {
		if (!payload) {
			return;
		}

		return dispatch(
			AccountSettingsActions.updateInspectionReportTemplates({
				default: payload,
			})
		);
	};

	if (!profile || loading || !payload) {
		return <Skeleton />;
	}

	const hasUnsavedChanges = !_.isEqual(payload, reportSettings);

	return (
		<Screens.Container>
			<Screens.Header
				title="Inspection Report Settings"
				showBreadCrumbs
				subtitle="Customize the content of your RentCheck inspection reports."
				actions={[
					<ActionButton
						isEditing={isEditing}
						setIsEditing={setIsEditing}
						onSave={handleSave}
					/>,
				]}
			/>
			<Summary
				isEditing={isEditing}
				payload={payload}
				setPayload={setPayload}
			/>
			<Features
				isEditing={isEditing}
				payload={payload}
				setPayload={setPayload}
			/>
			<MaintenanceFlags
				isEditing={isEditing}
				payload={payload}
				setPayload={setPayload}
			/>
			<Prompt
				when={isEditing && hasUnsavedChanges}
				message="You've made changes to your inspection report settings. Leaving now will discard these changes."
			/>
		</Screens.Container>
	);
};
