import { Typography } from '@mui/material';
import { Column } from 'components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTypedSelector } from 'store/reducers/rootReducer';

import CreateTeamModal from 'components/teams/create';
import { useTeams } from 'hooks/teams';
import MenuItem from './menu-item';
import Skeleton from './skeleton';

const List = () => {
	const profile = useTypedSelector((state) => state.activeProfile);
	const { teams, loading } = useTeams();

	const [createModalVisible, setCreateModalVisible] = useState(false);

	useEffect(() => {
		if (!profile) {
			return;
		}
	}, [profile]);

	if (loading && teams.length === 0) {
		return (
			<Content>
				<Skeleton />
			</Content>
		);
	}

	if (!loading && teams.length === 0) {
		return null;
	}

	return (
		<>
			<Content>
				{teams.map((o) => {
					return <MenuItem team={o} />;
				})}
				<Typography
					variant="subtitle2"
					fontWeight={600}
					color="#3d50ff"
					sx={{ marginTop: 1.5, marginBottom: 1.5, cursor: 'pointer' }}
					style={{ marginLeft: 45 }}
					onClick={() => setCreateModalVisible(true)}>
					+ Add Team
				</Typography>
			</Content>

			{createModalVisible && (
				<CreateTeamModal setModalVisible={setCreateModalVisible} />
			)}
		</>
	);
};

const Content = styled(Column)`
	padding-top: 5px;
	padding-bottom: 5px;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	align-self: stretch;
	width: 250px;
`;

export default List;
