import { GetAllResult } from '@rentcheck/types';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Api<F, S, D> {
	getAll: (
		pageNumber: number,
		pageSize: number,
		filters: F,
		sorting?: S
	) => Promise<GetAllResult<D>>;
}

const useApiModel = <F, S, D extends { id: string }>(
	api: Api<F, S, D>,
	initialFilters: F,
	defaultSort: S,
	pageSize: number = 40
) => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<D[]>([]);
	const [totalCount, setTotalCount] = useState(0);

	const [filters, setFilters] = useState<F>(initialFilters);
	const [sort, setSort] = useState<S>(defaultSort);

	const fetchInitialData = (filters: F, sort: S) => {
		if (!profile) return;

		setLoading(true);

		api
			.getAll(0, pageSize, filters, sort)
			.then((result) => {
				setData(result.data);
				setTotalCount(result.total_results);
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const debouncedFetchInitialData = useCallback(
		_.debounce(fetchInitialData, 300),
		[]
	);

	const loadMore = (): Promise<GetAllResult<D>> =>
		api
			.getAll(data.length / pageSize, pageSize, filters, sort)
			.then((result) => {
				setData([...data, ...result.data]);
				return result;
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));

				return {
					data: [],
					total_results: 0,
				};
			})
			.finally(() => {
				setLoading(false);
			});

	const onDelete = (objects: Pick<D, 'id'>[]) => {
		setData(data.filter((d) => !objects.some((o) => d.id === o.id)));
		setTotalCount(totalCount - objects.length);
	};

	const resetFilters = () => {
		setFilters(initialFilters);
	};

	useEffect(() => {
		fetchInitialData(filters, sort);
	}, [profile?.id]);

	useEffect(() => {
		debouncedFetchInitialData(filters, sort);
	}, [filters]);

	useEffect(() => {
		fetchInitialData(filters, sort);
	}, [sort]);

	return {
		loading,
		data,
		totalCount,
		loadMore,
		filters,
		setFilters,
		resetFilters,
		sort,
		setSort,
		onDelete,
	};
};

export default useApiModel;
