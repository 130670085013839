"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recurrenceType = void 0;
exports.recurrenceType = [
    'None',
    'Daily',
    'Weekly',
    'Monthly',
    'Quarterly',
    'Semi-Annually',
    'Annually',
];
