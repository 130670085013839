import { Dispatch } from 'types';
import { CommunitiesApi } from 'api';
import { Community } from '@rentcheck/types';

export type CommunitiesActionType =
	| 'FETCHED_COMMUNITIES'
	| 'FETCHED_COMMUNITY'
	| 'DELETED_COMMUNITY';

export const actionTypes: { [index: string]: CommunitiesActionType } = {
	FETCHED_COMMUNITIES: 'FETCHED_COMMUNITIES',
	FETCHED_COMMUNITY: 'FETCHED_COMMUNITY',
	DELETED_COMMUNITY: 'DELETED_COMMUNITY',
};

export const getByCreatorIdAndOrganizationIds =
	(creatorId: string, organizationIds: string[]) =>
	async (dispatch: Dispatch): Promise<void> =>
		CommunitiesApi.getByCreatorIdAndOrganizationIds(
			creatorId,
			organizationIds
		).then((communities) =>
			dispatch({
				type: actionTypes.FETCHED_COMMUNITIES,
				communities,
			})
		);

export const create =
	(payload: Omit<Community, 'id'>) =>
	async (dispatch: Dispatch): Promise<Community | undefined> => {
		const community = await CommunitiesApi.create(payload);
		dispatch({ type: actionTypes.FETCHED_COMMUNITY, community });
		return community;
	};

export const setCommunity =
	(community: Community) =>
	async (dispatch: Dispatch): Promise<void> =>
		dispatch({ type: actionTypes.FETCHED_COMMUNITY, community });
