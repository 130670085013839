import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import { LatchelApi } from '@rentcheck/api-frontend';
import { CenteredRow, Column, SpacedRow, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { colors } from 'theme';

import { useTeams } from 'hooks/teams';
import { useDispatch } from 'react-redux';
import { SnackbarActions, TeamsActions } from 'store/actions';
import AlreadyLinkedNotice from './already-linked-notice';
import HeaderIcons from './header-icons';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const ConnectLatchelModal = ({ open, handleClose }: Props) => {
	const dispatch = useDispatch();

	const { teams } = useTeams();

	const [apiKey, setApiKey] = useState('');
	const [teamIds, setTeamIds] = useState<string[]>([]);

	const [apiKeyError, setApiKeyError] = useState('');
	const [teamIdsError, setTeamIdsError] = useState('');

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setApiKey('');
		setTeamIds([]);

		setApiKeyError('');
		setTeamIdsError('');

		setLoading(false);
	}, [open]);

	useEffect(() => {
		setApiKeyError('');
	}, [apiKey]);

	useEffect(() => {
		setTeamIdsError('');
	}, [teamIds]);

	const validateForm = () => {
		let formIsValid = true;

		if (!apiKey) {
			setApiKeyError('API Key is required.');
			formIsValid = false;
		}

		if (teamIds.length === 0) {
			setTeamIdsError('At least one team needs to be selected');
			formIsValid = false;
		}

		return formIsValid;
	};

	const handleConnect = () => {
		if (!validateForm()) {
			return;
		}

		setLoading(true);

		LatchelApi.connect(apiKey, teamIds)
			.then(() => dispatch(TeamsActions.getAll()))
			.then(() =>
				dispatch(SnackbarActions.showSuccess('Latchel integration successful'))
			)
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleChangeTeams = (e: SelectChangeEvent<string[]>) => {
		const ids = e.target.value;
		const value = (typeof ids === 'string' ? [ids] : ids).filter(
			(id) => id !== 'placeholder'
		);

		setTeamIds(value);
	};

	const handleSelectAllTeams = () => {
		setTeamIds(teams.map((o) => o.id));
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>
				<CenteredRow>
					<FontAwesomeIcon
						icon={regular('link')}
						color={colors.primary}
						size="xs"
					/>
					<Spacer width={3} />
					<Typography variant="h4" noWrap fontWeight={500}>
						Connect Latchel to RentCheck
					</Typography>
				</CenteredRow>
			</DialogTitle>

			<DialogContent>
				<Column>
					<HeaderIcons />
					<Spacer height={16} />

					<Typography variant="h6">Latchel API Key:</Typography>
					<Spacer height={1} />
					<TextField
						id="textinput-api_key"
						variant="filled"
						value={apiKey}
						onChange={(e) => setApiKey(e.target.value)}
						error={!!apiKeyError}
						helperText={apiKeyError}
					/>
					<Spacer height={8} />

					<SpacedRow>
						<Typography variant="h6">Select Teams:</Typography>
						<Button
							disabled={teamIds.length === teams.length}
							onClick={handleSelectAllTeams}
							variant="text"
							sx={{ height: 30 }}>
							Select All
						</Button>
					</SpacedRow>
					<Spacer height={1} />
					<Select
						multiple
						id="select-teams"
						variant="filled"
						value={teamIds.length ? teamIds : ['placeholder']}
						onChange={handleChangeTeams}
						error={!!teamIdsError}
						inputProps={{ helperText: teamIdsError, id: 'textinput-teams' }}>
						<MenuItem value="placeholder" sx={{ display: 'none' }}>
							Select one or more
						</MenuItem>
						{teams.map((o) => (
							<MenuItem key={o.id} value={o.id} id={`select-team-${o.id}`}>
								{o.internal_label}
							</MenuItem>
						))}
					</Select>
					<AlreadyLinkedNotice teamIds={teamIds} apiKey={apiKey} />
					<Spacer height={8} />
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					id="latchel-modal-connect"
					variant="contained"
					onClick={handleConnect}
					loading={loading}>
					Connect
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ConnectLatchelModal;
