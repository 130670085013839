import { ISettings } from '@flatfile/adapter';

const buildings: ISettings = {
	type: 'Buildings',
	fields: [
		{
			label: 'Team ID',
			key: 'team_id',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Building name',
			key: 'name',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Address',
			key: 'address',
			validators: [{ validate: 'required' }, { validate: 'unique' }],
		},
		{
			label: 'City',
			key: 'city',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'State',
			key: 'state',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Postal Code',
			key: 'postal_code',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Floors',
			key: 'floors',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^\\d+$',
					error: 'Only integer values are allowed',
				},
			],
		},
		{
			label: 'Courtyard(s)',
			key: 'courtyard',
			type: 'checkbox',
		},
		{
			label: 'Patio(s)',
			key: 'patio',
			type: 'checkbox',
		},
		{
			label: 'Playground Area(s)',
			key: 'playground_area',
			type: 'checkbox',
		},
		{
			label: 'Shared Laundry Room(s)',
			key: 'laundry_room',
			type: 'checkbox',
		},
		{
			label: 'Lobby',
			key: 'lobby',
			type: 'checkbox',
		},
		{
			label: 'Exercise Room(s)',
			key: 'exercise_room',
			type: 'checkbox',
		},
		{
			label: 'Roof Deck',
			key: 'roof_deck',
			type: 'checkbox',
		},
		{
			label: 'Pool(s)',
			key: 'pool',
			type: 'checkbox',
		},
		{
			label: 'Mail / Package / Storage Area',
			key: 'mail_room',
			type: 'checkbox',
		},
	],
};

const residents: ISettings = {
	type: 'Residents',
	fields: [
		{
			label: 'Street 1',
			key: 'street1',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Street 2',
			key: 'street2',
		},
		{
			label: 'State',
			key: 'state',
		},
		{
			label: 'Postal Code',
			key: 'postal_code',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'First Name',
			key: 'first_name',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Last Name',
			key: 'last_name',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Email',
			key: 'email',
			validators: [{ validate: 'required' }, { validate: 'unique' }],
		},
		{
			label: 'Phone',
			key: 'phone',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^(?:\\+\\d{1,3}|0\\d{1,3}|00\\d{1,2})?(?:\\s?\\(\\d+\\))?(?:[-\\/\\s.]|\\d)+$`,
				},
			],
		},
		{
			label: 'Start Date',
			key: 'start_date',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^(0[1-9]|1[012]|[1-9])[/](0[1-9]|[12][0-9]|3[01]|[1-9])[/](19|20)\\d\\d$`,
					error:
						'Invalid date format, valid formats are MM/DD/YYYY or M/D/YYYY',
				},
			],
		},
		{
			label: 'End Date',
			key: 'end_date',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^(0[1-9]|1[012]|[1-9])[/](0[1-9]|[12][0-9]|3[01]|[1-9])[/](19|20)\\d\\d$`,
					error:
						'Invalid date format, valid formats are MM/DD/YYYY or M/D/YYYY',
				},
			],
		},
		{
			label: 'Base Rent',
			key: 'rent',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^\\d+(\\.\\d+)?$`,
					error: 'Only numbers are allowed',
				},
			],
		},
		{
			label: 'Security',
			key: 'security_deposit',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^\\d+(\\.\\d+)?$`,
					error: 'Only numbers are allowed',
				},
			],
		},
		{
			label: 'Move In Date',
			key: 'move_in_date',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^(0[1-9]|1[012]|[1-9])[/](0[1-9]|[12][0-9]|3[01]|[1-9])[/](19|20)\\d\\d$`,
					error:
						'Invalid date format, valid formats are MM/DD/YYYY or M/D/YYYY',
				},
			],
		},
		{
			label: 'Move Out Date',
			key: 'move_out_date',
			validators: [
				{
					validate: 'regex_matches',
					regex: `^(0[1-9]|1[012]|[1-9])[/](0[1-9]|[12][0-9]|3[01]|[1-9])[/](19|20)\\d\\d$`,
					error:
						'Invalid date format, valid formats are MM/DD/YYYY or M/D/YYYY',
				},
			],
		},
		{
			label: 'Notice',
			key: 'notice',
			type: 'checkbox',
		},
		{
			label: 'Evict',
			key: 'evict',
			type: 'checkbox',
		},
	],
};

const units: ISettings = {
	type: 'Properties',
	fields: [
		{
			label: 'Team ID',
			key: 'team_id',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Address',
			key: 'address',
			validators: [{ validate: 'required' }, { validate: 'unique' }],
		},
		{
			label: 'City',
			key: 'city',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'State',
			key: 'state',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Postal Code',
			key: 'postal_code',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Unit Type',
			key: 'unit_type',
			validators: [
				{ validate: 'required' },
				{
					validate: 'regex_matches',
					regex:
						'^(Apartment or Condo|Single family|Multi family|apartment or condo|single family|multi family|Apartment or condo|Single Family|Multi Family)$',
					error:
						'Only Apartment or Condo, Single family or Multi family are allowed',
				},
			],
		},
		{
			label: 'Bedrooms',
			key: 'bedrooms',
			validators: [
				{ validate: 'required' },
				{
					validate: 'regex_matches',
					regex: '^\\d$',
					error: 'Only integer values are allowed',
				},
			],
		},
		{
			label: 'Bathrooms',
			key: 'bathrooms',
			validators: [
				{ validate: 'required' },
				{
					validate: 'regex_matches',
					regex: '^\\d(\\.[05])?$',
					error: 'Only numbers in .5 increments are allowed',
				},
			],
		},
		{
			label: 'Kitchen',
			key: 'kitchen',
			type: 'checkbox',
		},
		{
			label: 'Living Room',
			key: 'living_room',
			type: 'checkbox',
		},
		{
			label: 'Dining Room',
			key: 'dining_room',
			type: 'checkbox',
		},
		{
			label: 'Family Room',
			key: 'family_room',
			type: 'checkbox',
		},
		{
			label: 'Laundry Room',
			key: 'laundry_room',
			type: 'checkbox',
		},
		{
			label: 'Hallway',
			key: 'hallway',
			type: 'checkbox',
		},
		{
			label: 'Stairway',
			key: 'stairway',
			type: 'checkbox',
		},
		{
			label: 'Garage',
			key: 'garage',
			type: 'checkbox',
		},
		{
			label: 'Carport',
			key: 'carport',
			type: 'checkbox',
		},
		{
			label: 'Basement',
			key: 'basement',
			type: 'checkbox',
		},
		{
			label: 'Patio',
			key: 'patio',
			type: 'checkbox',
		},
		{
			label: 'Balcony',
			key: 'balcony',
			type: 'checkbox',
		},
		{
			label: 'Den',
			key: 'den',
			type: 'checkbox',
		},
		{
			label: 'Attic',
			key: 'attic',
			type: 'checkbox',
		},
		{
			label: 'Office',
			key: 'office',
			type: 'checkbox',
		},
		{
			label: 'Storage Room',
			key: 'storage_room',
			type: 'checkbox',
		},
		{
			label: 'Loft',
			key: 'loft',
			type: 'checkbox',
		},
		{
			label: 'Exterior',
			key: 'exterior',
			type: 'checkbox',
		},
		{
			label: 'Pool',
			key: 'pool',
			type: 'checkbox',
		},
		{
			label: 'Rent',
			key: 'rent',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^-?\\d+\\.?\\d*$',
					error: 'Only a number is allowed',
				},
			],
		},
		{
			label: 'Security Deposit',
			key: 'security_deposit',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^-?\\d+\\.?\\d*$',
					error: 'Only a number is allowed',
				},
			],
		},
	],
};

export default { units, buildings, residents };
