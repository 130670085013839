import { TeamsApi } from '@rentcheck/api-frontend';
import { Dispatch } from 'types';

export type TeamsActionType =
	| 'FETCHED_TEAMS'
	| 'IMPERSONATE'
	| 'SIGNOUT_SUCCESS';

export const actionTypes: { [index: string]: TeamsActionType } = {
	FETCHED_TEAMS: 'FETCHED_TEAMS',
	IMPERSONATE: 'IMPERSONATE',
	SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
};

export const getAll =
	() =>
	async (dispatch: Dispatch): Promise<void> =>
		TeamsApi.getAll().then((teams) =>
			dispatch({
				type: actionTypes.FETCHED_TEAMS,
				teams,
			})
		);
