import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Divider, Paper, Typography } from '@mui/material';

import { Utils } from '@rentcheck/biz';

import { colors } from 'theme';
import { PropertiesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);
	const units = useTypedSelector((state) => state.properties.units);

	useEffect(() => {
		dispatch(PropertiesActions.units.getAll());
	}, [subscription]);

	if (!subscription) {
		return null;
	}

	const activeUnits =
		subscription.plan.pricing_model === 'per_unit'
			? subscription.plan.plan_qty
			: units.total;

	return (
		<Paper
			elevation={0}
			sx={{ border: '1px solid #0000001F', padding: '8px 0' }}>
			<Typography variant="overline" sx={{ margin: '0 16px 16px 16px' }}>
				Summary
			</Typography>
			<Divider sx={{ marginTop: 1, marginBottom: 2 }} />

			{Utils.Subscriptions.isCancelled(subscription) && (
				<Chip
					label="Cancelled"
					variant="outlined"
					color="error"
					sx={{ margin: '0 16px 8px' }}
					icon={<FontAwesomeIcon icon={solid('triangle-exclamation')} />}
				/>
			)}

			<Typography variant="subtitle1" sx={{ margin: '8px 16px' }}>
				Active units: {activeUnits}
			</Typography>
			<Typography
				variant="body2"
				sx={{ margin: '4px 16px' }}
				color={colors.secondary}>
				When making changes to your account or subscription please allow up to
				24 hours for the active units listed to be reflected here.
			</Typography>
		</Paper>
	);
};
