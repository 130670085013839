import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions, TeamActions, TeamsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useTeams = () => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const teams = useTypedSelector((state) => state.teams);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!profile) return;

		setLoading(true);

		dispatch(TeamsActions.getAll())
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [profile]);

	return {
		loading,
		teams,
	};
};

export const useTeam = (teamId?: string) => {
	const dispatch: Dispatch = useDispatch();

	const team = useTypedSelector((state) => state.team);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!teamId) return;

		setLoading(true);

		dispatch(TeamActions.getById(teamId))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [teamId]);

	if (!teamId) return { loading: false, team: undefined };

	if (teamId !== team?.id) {
		return { loading, team: undefined };
	}

	return {
		loading,
		team,
	};
};
