import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules, Utils } from '@rentcheck/biz';
import { ApiUserDigest, Profile } from '@rentcheck/types';
import _ from 'lodash';
import {
	ModalFlowActions,
	SnackbarActions,
	TeammatesActions,
} from 'store/actions';
import { Dispatch } from 'types';

export const handleDeactivateUsers = (
	profile: Profile,
	users: ApiUserDigest[],
	dispatch: Dispatch
) => {
	dispatch(
		ModalFlowActions.showConfirmationModal({
			icon: solid('user-xmark'),
			title: 'Deactivate Users',
			body1: [
				'Are you sure you want to deactivate these users and revoke their access?',
			],
			buttons: [
				{
					title: 'Cancel',
					onClick: () => handleCloseConfirmationModal(dispatch),
				},
				{
					title: 'Deactivate',
					onClick: () =>
						handleDeactivateUsersConfirmed(profile, users, dispatch),
				},
			],
		})
	);
};

export const handleEditUsersPermissionGroups = (
	users: ApiUserDigest[],
	dispatch: Dispatch
) => {
	dispatch(
		ModalFlowActions.showEditUsersPermissionGroupsModal({
			user_ids: users.map((user) => user.id),
		})
	);
};

const handleCloseConfirmationModal = (dispatch: Dispatch) => {
	dispatch(ModalFlowActions.closeConfirmationModal());
};

const handleDeactivateUsersConfirmed = async (
	profile: Profile,
	users: ApiUserDigest[],
	dispatch: Dispatch
) => {
	dispatch(ModalFlowActions.setConfirmationModalLoading(true));

	const deletedIds: string[] = [];

	await Promise.all(
		users
			.filter((u) => Rules.Teammates.canBeDeactivated(u, profile).result)
			.map((u) =>
				dispatch(TeammatesActions.Users.deactivate(u.id)).then(() =>
					deletedIds.push(u.id)
				)
			)
	);

	if (deletedIds.length > 0) {
		const message = `${Utils.Helpers.numberAndPluralizedWord(deletedIds.length, 'User')} deactivated successfully`;
		dispatch(SnackbarActions.showSuccess(message));
	}

	if (deletedIds.length !== users.length) {
		const filterReasons = _.uniq(
			users
				.map((u) => Rules.Teammates.canBeDeactivated(u, profile).reason)
				.filter((r) => !!r)
		);

		filterReasons.map((reason) =>
			dispatch(SnackbarActions.showSnackbar(reason))
		);
	}

	handleCloseConfirmationModal(dispatch);
};
