"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDigest = void 0;
const buildDigest = (data) => {
    return {
        id: data.id,
        name: data.name,
        user_count: data.users.length,
    };
};
exports.buildDigest = buildDigest;
