import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { createSx } from './create-sx';

const ChipArchived = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
		dispatch({ type: 'SET_INSPECTION_ARCHIVED_FILTER', value: true });
	};

	const selected = filters.archived;

	return (
		<Chip
			variant="outlined"
			icon={<FontAwesomeIcon icon={solid('archive')} />}
			label="Archived"
			onClick={handleClick}
			sx={createSx(selected, '#101010', '#DCDCDC', '#C9C9C9')}
		/>
	);
};

export default ChipArchived;
