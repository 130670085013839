import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { ApplicationSettings } from '@rentcheck/types';
import { ChargebeeApi } from 'api';
import { CenteredColumn, Column, DialogTitle, Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';
import { useRemoteSettings } from 'utils/hooks';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const { settings } = useRemoteSettings();

	const [success, setSuccess] = useState(false);
	const [checkoutClosed, setCheckoutClosed] = useState(false);
	const [profileUpdateError, setProfileUpdateError] = useState(false);

	useEffect(() => {
		if (!profile) return;

		setCheckoutClosed(false);
		setSuccess(false);

		ChargebeeApi.initChargebeeModal(
			profile,
			() => setCheckoutClosed(true),
			() => setSuccess(true)
		);
	}, [profile, open]);

	useEffect(() => {
		if (!success || !checkoutClosed) return;

		dispatch(SubscriptionActions.waitForSubscriptionWebhook()).catch(() =>
			setProfileUpdateError(true)
		);
	}, [success, checkoutClosed]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handlePlan = (plan: ApplicationSettings['subscription_plans'][0]) => {
		if (plan.action_type === 'link') {
			window.open(plan.link);
		}
	};

	const handleContactSupport = () => {
		IntercomAPI('showNewMessage', 'Problem with subscription');
	};

	if (success && checkoutClosed) {
		if (subscription) {
			return (
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle
						title="Thanks for subscribing!"
						icon={solid('sparkles')}
						onClose={handleClose}
					/>
					<DialogContent>
						<Typography align="center" mt={6} mb={6}>
							Upgrading to a RentCheck subscription plan supercharges your
							RentCheck experience, giving you access to all inspection types in
							addition to other premium features. As always, let us know if you
							have questions or feedback at any time via our in-app chat box
							located at the bottom right of your screen.
						</Typography>
					</DialogContent>
					<DialogActions>
						<div />
						<Button onClick={handleClose}>Close</Button>
					</DialogActions>
				</Dialog>
			);
		}

		if (profileUpdateError) {
			return (
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle
						title="Thanks for subscribing!"
						icon={solid('sparkles')}
						onClose={handleClose}
					/>
					<DialogContent>
						<Typography align="center" mt={6} mb={6}>
							It's taking a bit longer than ususal to update your user profile.
							<br />
							Please wait a a few minutes and refresh this page.
							<br />
							If the problem persists, please contact Customer Support.
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} variant="text" color="secondary">
							Close
						</Button>
						<Button onClick={handleContactSupport}>Contact Support</Button>
					</DialogActions>
				</Dialog>
			);
		}

		return (
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle
					title="Thanks for subscribing!"
					icon={solid('sparkles')}
					onClose={handleClose}
				/>
				<DialogContent>
					<CenteredColumn>
						<Typography align="center" mt={6} mb={6}>
							Please hold on while we update your account.
							<br />
							This might take a moment.
						</Typography>
						<CircularProgress />
						<Spacer height={10} />
					</CenteredColumn>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Pricing that grows with your business"
				icon={solid('sparkles')}
				onClose={handleClose}
				bottomSubtitle="Month-to-month plans. No setup fees. No long-term contracts."
			/>

			<DialogContent>
				<Row style={{ justifyContent: 'space-around' }}>
					{settings.subscription_plans.map((sp) => {
						const containerProps: Record<string, string> = {};

						if (sp.action_type === 'chargebee' && sp.chargebee_product) {
							containerProps['data-cb-type'] = 'checkout';
							containerProps['data-cb-plan-id'] = sp.chargebee_product;
						}

						return (
							<PlanContainer
								key={
									sp.action_type === 'chargebee'
										? sp.chargebee_product
										: sp.link
								}
								{...containerProps}>
								<Typography variant="h5">{sp.name}</Typography>
								<Typography color="#9a9a9a" fontWeight={600} align="center">
									{sp.label}
								</Typography>

								<Spacer height={3} />

								<Typography variant="h6">{sp.price}</Typography>
								<Typography color="#9a9a9a" fontWeight={600}>
									{sp.price_recurrence}
								</Typography>
								<Spacer height={6} />
								<Button onClick={() => handlePlan(sp)}>{sp.cta}</Button>
							</PlanContainer>
						);
					})}
				</Row>
			</DialogContent>
		</Dialog>
	);
};

const PlanContainer = styled(Column)`
	align-items: center;
	justify-content: space-around;
	padding: 20px;
	border: 1px solid ${colors.lightGray};
	border-radius: ${spacingPx(2)};
	height: ${spacingPx(70)};
	width: ${spacingPx(50)};

	transition: 0.3s background-color ease-in-out;

	&:hover {
		cursor: pointer;
		background-color: #fafafa;
	}
`;
