import { ApiTeam } from '@rentcheck/types';
import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { useTeams } from 'hooks/teams';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'Team';

const FilterTeamTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	return (
		<FilterTitle
			label={title}
			isActive={filters.teamIds.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const FilterTeamDetails = ({ selectedFilter }: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const { teams } = useTeams();

	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	if (teams.length === 0) {
		return null;
	}

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (team: ApiTeam) => {
		const newTeamIds = _.xor(filters.teamIds, [team.id]);
		dispatch(TeammatesActions.Users.setFilters({ teamIds: newTeamIds }));
	};

	return (
		<>
			{teams.map((o) => (
				<FilterTitleOption
					label={o.internal_label}
					isSelected={filters.teamIds.includes(o.id) ?? false}
					onClick={() => handleClick(o)}
				/>
			))}
		</>
	);
};

export { FilterTeamDetails, FilterTeamTitle };
