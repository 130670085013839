import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { TopSpacer, SpacedRow, Spacer } from 'components';
import { colors, spacingPx } from 'theme';
import { stringStartsWith } from 'utils/helpers';

import TabBar from './tab-bar';
import Reports from './reports';
import WorkOrders from './work-orders';

const MaintenanceList = () => {
	const { pathname } = useLocation();

	const isPathnameWorkOrders = (pathname: string) => {
		return stringStartsWith(pathname, '/maintenance/work-orders');
	};

	if (isPathnameWorkOrders(pathname)) return <WorkOrders />;

	return <Reports />;
};

const Maintenance = () => {
	return (
		<div>
			<TopSpacer />
			<ScreenContainer>
				<SpacedRow>
					<TabBar />
				</SpacedRow>
				<Spacer height={6} />
				<MaintenanceList />
			</ScreenContainer>
		</div>
	);
};

const ScreenContainer = styled.div`
	width: 80vw;
	background-color: ${colors.contentBackground};
	border: 1px solid ${colors.contentBorder};
	border-radius: 3px;
	-webkit-box-shadow: 0 0 1px ${colors.shadow};
	box-shadow: 0 0 1px ${colors.shadow};
	position: relative;
	padding: ${spacingPx(10)};
	margin-bottom: ${spacingPx(10)};
`;

export default Maintenance;
