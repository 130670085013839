import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import {
	BuildingsApi,
	CommunitiesApi,
	UnitsApi,
} from '@rentcheck/api-frontend';
import { Property } from '@rentcheck/types';
import { PopoverItemProps } from 'components/PopoverMenu';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { CreateInspectionFlowType } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import PopoverMenu from '../PopoverMenu';

interface Props {
	property?: Property;
}

const NewInspectionButton = ({ property }: Props) => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [hasBuildings, setHasBuildings] = useState(false);
	const [hasCommunities, setHasCommunities] = useState(false);
	const [loadingApiProperty, setLoadingApiProperty] = useState(false);

	useEffect(() => {
		if (!profile) return;

		const filters = {
			search: '',
			teamId: [],
		};
		const sorting = {
			sortBy: 'name',
			sortType: 'asc' as 'asc' | 'desc',
		};

		BuildingsApi.getAll(0, 20, filters, sorting).then((data) =>
			setHasBuildings(data.totalResults > 0)
		);

		CommunitiesApi.getAll(0, 20, filters, sorting).then((data) =>
			setHasCommunities(data.totalResults > 0)
		);
	}, [profile]);

	const showCreateInspectionModal = async (type: CreateInspectionFlowType) => {
		if (!property) {
			dispatch(
				ModalFlowActions.showCreateInspectionModal({
					properties: [],
					property_type: type,
				})
			);
			return;
		}

		const apis = {
			unit: UnitsApi,
			building: BuildingsApi,
			community: CommunitiesApi,
		};

		setLoadingApiProperty(true);

		const propertyFromApi = await apis[type]
			.getById(property.id)
			.catch(() => undefined);

		setLoadingApiProperty(false);

		if (!propertyFromApi) {
			return;
		}

		dispatch(
			ModalFlowActions.showCreateInspectionModal({
				properties: [propertyFromApi as any],
				property_type: type,
			})
		);
	};

	if (property) {
		const flowTypesMap: { [key: string]: CreateInspectionFlowType } = {
			Building: 'building',
			Community: 'community',
		};

		const flowType: CreateInspectionFlowType =
			flowTypesMap[property.property_type] ?? 'unit';

		return (
			<LoadingButton
				loading={loadingApiProperty}
				variant="contained"
				startIcon={<FontAwesomeIcon icon={solid('plus')} />}
				onClick={() => showCreateInspectionModal(flowType)}>
				Add Inspection
			</LoadingButton>
		);
	}

	if (!hasBuildings && !hasCommunities) {
		return (
			<Button
				sx={{ ml: 2 }}
				startIcon={<FontAwesomeIcon icon={solid('plus')} />}
				onClick={() => showCreateInspectionModal('unit')}>
				Create
			</Button>
		);
	}

	const popoverItems: PopoverItemProps[] = [
		{
			title: 'Add unit inspection',
			onClick: () => showCreateInspectionModal('unit'),
		},
	];

	if (hasBuildings) {
		popoverItems.push({
			title: 'Add building inspection',
			onClick: () => showCreateInspectionModal('building'),
		});
	}

	if (hasCommunities) {
		popoverItems.push({
			title: 'Add community inspection',
			onClick: () => showCreateInspectionModal('community'),
		});
	}

	return (
		<PopoverMenu
			anchorPosition="bottom-right"
			options={popoverItems}
			triggerNode={
				<Button
					sx={{ ml: 2 }}
					startIcon={<FontAwesomeIcon icon={solid('plus')} />}>
					Create
				</Button>
			}
		/>
	);
};

export default NewInspectionButton;
