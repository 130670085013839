import { AssignMethod } from '@rentcheck/types';

export const assignMethodData: Record<
	AssignMethod,
	{
		title: string;
		description: string;
		comingSoon?: boolean;
		isNew?: boolean;
		caption?: string;
	}
> = {
	residents: {
		title: 'Residents',
		description:
			'Residents on the current lease will be invited to perform the inspection.',
		caption: 'Dynamic audience',
		isNew: true,
	},
	'self-perform': {
		title: 'Entire Team',
		description: 'Everyone on the team will be able to perform the inspection.',
	},
	emails: {
		title: 'Manually assign',
		description: 'Assign to selected email addresses.',
	},
	myself: {
		title: 'Myself',
		description: 'Assign to myself.',
	},
	teammates: {
		title: 'Teammates',
		description: 'Assign to specific teammates.',
	},
};
