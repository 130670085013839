import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Divider,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';

import Spacer from 'components/Spacer';
import IconButton from 'components/icon-button';
import { Column } from 'components/layout/Column';
import { SpacedRow } from 'components/layout/Row';

import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';

const signatureTypes = ['Type', 'Draw'] as const;
type SignatureType = (typeof signatureTypes)[number];

export type TextSignature = { type: 'Type'; text: string };
export type DrawnSignature = { type: 'Draw'; data: string };
export type Signature = TextSignature | DrawnSignature | undefined;

export interface SignatureRef {
	clear: () => void;
	getSignature: () => Signature;
}

export default forwardRef<SignatureRef, {}>((props, ref) => {
	const signatureCanvasRef = useRef<SignatureCanvas>(null);
	const canvasContainerRef = useRef<HTMLDivElement>(null);

	const profile = useTypedSelector((state) => state.authenticatedUser);

	const [signatureType, setSignatureType] = useState<SignatureType>('Type');
	const [signatureText, setSignatureText] = useState<string>(profile.user_name);

	useImperativeHandle(ref, () => ({
		clear: handleClear,
		getSignature: handleGetSignature,
	}));

	const handleClear = () => {
		setSignatureType('Type');
		signatureCanvasRef.current?.clear();
	};

	const handleGetSignature = (): Signature => {
		if (signatureType === 'Type' && signatureText.length > 0) {
			return { type: 'Type', text: signatureText };
		}

		if (
			signatureType === 'Draw' &&
			!signatureCanvasRef.current?.isEmpty() &&
			signatureCanvasRef.current?.toDataURL('image/png')
		) {
			return {
				type: 'Draw',
				data: signatureCanvasRef.current?.toDataURL('image/png'),
			};
		}

		return undefined;
	};

	const handleChangeSignatureType = (
		event: SelectChangeEvent<SignatureType>
	) => {
		setSignatureType(event.target.value as SignatureType);
	};

	return (
		<Column>
			<SpacedRow>
				<Typography variant="overline">Signature</Typography>
				<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
					<Select value={signatureType} onChange={handleChangeSignatureType}>
						{signatureTypes.map((type) => (
							<MenuItem key={type} value={type}>
								{type}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</SpacedRow>
			<Spacer height={2} />
			<CanvasContainer ref={canvasContainerRef}>
				{signatureType === 'Type' && (
					<>
						<FreeTypeInput
							type="text"
							value={signatureText}
							onChange={(e) => setSignatureText(e.target.value)}
						/>
					</>
				)}
				{signatureType === 'Draw' && (
					<>
						<div style={{ position: 'absolute', top: 5, right: 5 }}>
							<IconButton
								icon={regular('times')}
								onClick={() => signatureCanvasRef.current?.clear()}
							/>
						</div>
						<SignatureCanvas
							clearOnResize
							ref={signatureCanvasRef}
							// @ts-ignore
							style={{ marginTop: -32 }}
							canvasProps={{
								width: canvasContainerRef.current?.clientWidth,
								height: canvasContainerRef.current?.clientHeight,
							}}
						/>
					</>
				)}
			</CanvasContainer>
			<Spacer height={5} />
			<Divider />
		</Column>
	);
});

const CanvasContainer = styled.div`
	height: 112px;
	border: 1px dashed #0000001f;
	border-radius: 8px;
	background-color: #0000000a;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const FreeTypeInput = styled.input`
	width: 100%;
	color: #000;
	text-align: center;
	font-family: Montecarlo;
	font-size: 48px !important;
	transition: width 0.2s ease-in-out !important;
	border: 0 !important;
	margin: 16px !important;
	padding: 0 !important;
	background: transparent !important;
	transition: none !important;
	box-sizing: border-box !important;
	box-shadow: none !important;

	&:focus {
		box-shadow: none !important;
		outline: none !important;
	}
`;
