"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inspectionTemplateAddonIds = exports.inspectionTemplateFreeTrialAddonId = exports.inspectionTemplatePerUnitAddonId = exports.rentManagerWorkOrderAddonIds = exports.rentManagerAddonIds = exports.jenarkWorkOrderAddonIds = exports.latchelWorkOrderAddonIds = exports.appFolioWorkOrderAddonIds = exports.appFolioAddonIds = void 0;
/**
 * Base AppFolio addons here plus we add support for 2 more
 * in order to support Atlas' billing setup
 */
exports.appFolioAddonIds = [
    'appfolio-integration---two-way-data-sync',
    'appfolio-data-sync-plus-workorders',
    'atlas-appfolio-integration---two-way-data-sync',
    'atlas-appfolio-data-sync-plus-workorders',
];
exports.appFolioWorkOrderAddonIds = [
    'appfolio-data-sync-plus-workorders',
    'atlas-appfolio-data-sync-plus-workorders',
];
/**
 * Latchel Addon Ids
 */
exports.latchelWorkOrderAddonIds = ['latchel-integration'];
/**
 * Jenark (365) Addon Ids
 */
exports.jenarkWorkOrderAddonIds = ['jenark-integration'];
/**
 * Rent Manager Addon Ids
 */
exports.rentManagerAddonIds = [
    'rm-integration---two-way-data-sync',
    'rentmanager-integration---two-way-data-sync',
];
exports.rentManagerWorkOrderAddonIds = [
    'rm-integration---work-orders',
    'rentmanager-integration---work-orders',
];
/**
 * Inspection Template Addon Ids
 */
exports.inspectionTemplatePerUnitAddonId = 'inspection-template-customization-2';
exports.inspectionTemplateFreeTrialAddonId = 'inspection-template-customization-free-trial';
exports.inspectionTemplateAddonIds = [
    'inspection-template-customization',
    exports.inspectionTemplateFreeTrialAddonId,
    exports.inspectionTemplatePerUnitAddonId,
];
