import {
	TemplateFeature,
	TemplateQuestion,
	TemplateQuestionAnswer,
	TemplateSection,
} from '@rentcheck/types';

export const areAnswersIncomplete = (
	answers: TemplateQuestionAnswer[]
): boolean => answers.some((answer) => !answer.title.trim());

export const areQuestionsIncomplete = (
	questions: TemplateQuestion[]
): boolean =>
	questions.some(
		(question) =>
			!question.title.trim() || areAnswersIncomplete(question.answers)
	);

export const isFeatureIncomplete = (feature: TemplateFeature): boolean =>
	!feature.name.trim() ||
	!feature.description.trim() ||
	areQuestionsIncomplete(feature.questions);

export const containsIncompleteFeatures = (
	object: TemplateSection | TemplateSection[]
): boolean => {
	if (Array.isArray(object)) {
		return object.some((section) => containsIncompleteFeatures(section));
	}

	return object.features.some(isFeatureIncomplete);
};
