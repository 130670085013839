import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Checkbox,
	Divider,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { APIProperty, APIPropertyTeam } from '@rentcheck/types';

import { Column, SpacedRow, Spacer } from 'components';
import { AssignedTeammates } from 'screens/modal-flows-controller/create-inspection/common/assigned-teammates';

import { TeamsApi } from '@rentcheck/api-frontend';
import { ApiTeamDetails } from '@rentcheck/types';
import SelectTeammates from './select-teammates';
import Skeleton from './skeleton';

interface Props {
	properties: APIProperty[];
	notifyChecked: boolean;
	setNotifyChecked: (checked: boolean) => void;
	notifyNote: string;
	setNotifyNote: (note: string) => void;
	assignedTeammates: AssignedTeammates;
	setAssignedTeammates: (teammates: AssignedTeammates) => void;
	showNotify?: boolean;
}

export default ({
	properties,
	notifyChecked,
	setNotifyChecked,
	notifyNote,
	setNotifyNote,
	assignedTeammates,
	setAssignedTeammates,
	showNotify = true,
}: Props) => {
	const [loading, setLoading] = useState(true);
	const [teams, setTeams] = useState<ApiTeamDetails[]>([]);

	useEffect(() => {
		if (!properties.length) return;

		const propertyTeams = _.uniqBy(
			properties.filter((p) => p.team),
			'team.id'
		).map((p) => p.team as APIPropertyTeam);

		Promise.all(propertyTeams.map((p) => TeamsApi.getById(p.id)))
			.then(setTeams)
			.finally(() => setLoading(false));
	}, [properties]);

	const notifyMessage = () => {
		if (!teams.length) return '';

		let teamsToPerform = '';

		if (teams.length === 1) {
			teamsToPerform = teams[0].internal_label;
		} else {
			teamsToPerform = teams.map((t) => t.internal_label).join(' or ');
		}

		return `Residents will be notified by email that an inspection has been
		scheduled to be performed by ${teamsToPerform} on the due date
		selected.`;
	};

	const isUnitInspection =
		!properties.find((p) =>
			['Building', 'Community'].includes(p.property_type)
		) && showNotify;

	if (loading) {
		return <Skeleton />;
	}

	return (
		<Column>
			<Spacer height={4} />

			{teams.map((t) => {
				if (!t) return null;

				return (
					<>
						<Typography variant="subtitle1">{t.internal_label} team</Typography>

						<SelectTeammates
							team={t}
							assignedTeammates={assignedTeammates}
							setAssignedTeammates={setAssignedTeammates}
						/>

						<Divider />
						<Spacer height={4} />
					</>
				);
			})}

			{isUnitInspection && (
				<>
					<Column>
						<SpacedRow>
							<Typography variant="overline">Notify Residents?</Typography>

							<Checkbox
								checked={notifyChecked}
								onChange={() => setNotifyChecked(!notifyChecked)}
							/>
						</SpacedRow>
						{notifyChecked && (
							<Typography color="#00000099">{notifyMessage()}</Typography>
						)}
					</Column>

					<Spacer height={2} />

					{notifyChecked && (
						<TextField
							value={notifyNote}
							onChange={(e) => setNotifyNote(e.target.value)}
							placeholder="Add note"
							helperText="Optional"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FontAwesomeIcon icon={regular('message-lines')} />
									</InputAdornment>
								),
							}}
						/>
					)}
				</>
			)}
		</Column>
	);
};
