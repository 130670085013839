import { AccountSettings } from '@rentcheck/types';
import _ from 'lodash';

export const defaultRooms = {
	units: {
		bedsAndBaths: ['Bedrooms', 'Full Bathrooms', 'Half Bathrooms'],
		interior: _.sortBy([
			'Front Door',
			'Back Door',
			'Kitchen',
			'Dining Room',
			'Living Room',
			'Family Room',
			'Den',
			'Office',
			'Laundry Room',
			'Basement',
			'Loft',
			'Attic',
			'Storage Room',
			'Hallway',
			'Stairway',
		]),
		exterior: _.sortBy([
			'Garage',
			'Carport',
			'Patio',
			'Balcony',
			'Exterior',
			'Pool',
		]),
	},
	building: {
		common: _.sortBy([
			'Courtyard(s)',
			'Patio(s)',
			'Playground Area(s)',
			'Shared Laundry Room(s)',
			'Lobby',
			'Exercise Room(s)',
			'Roof Deck',
			'Pool(s)',
			'Mail / Package / Storage Area',
			'Maintenance Shop',
		]),
	},
};

export const defaultRoomsList: Record<
	keyof AccountSettings['rooms'],
	string[]
> = {
	unit: [
		...defaultRooms.units.bedsAndBaths,
		...defaultRooms.units.interior,
		...defaultRooms.units.exterior,
	],
	building: [...defaultRooms.building.common],
	community: [],
};
