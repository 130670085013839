import { CommunitiesApi } from '@rentcheck/api-frontend';
import * as Sentry from '@sentry/react';
import { State as FilterState } from 'store/reducers/properties/filters/communities';
import { ReduxFn } from 'types';

import * as ModalFlowActions from '../modal-flows';
import * as SnackbarActions from '../snackbar-actions';

export const pageSize = 20;

export const getAll = (
	offset: number = 0,
	filterState: FilterState
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const pageNumber = Math.floor(offset / pageSize);

		const filters = {
			search: filterState.search,
			teamId: filterState.team,
			dateRange: filterState.dateRange?.start
				? filterState.dateRange
				: undefined,
		};

		const sorting = {
			sortBy: filterState.sortBy,
			sortType: filterState.sortType,
		};

		dispatch({ type: 'SET_LOADING_COMMUNITIES', loading: true });

		const { data, totalResults } = await CommunitiesApi.getAll(
			pageNumber,
			pageSize,
			filters,
			sorting
		);

		const dispatchType =
			offset === 0 ? 'FETCHED_INITIAL_COMMUNITIES' : 'FETCHED_COMMUNITIES_PAGE';

		dispatch({ type: dispatchType, properties: data as any[] });
		dispatch({ type: 'SET_TOTAL_COMMUNITIES', value: totalResults });
		dispatch({ type: 'SET_LOADING_COMMUNITIES', loading: false });

		return data;
	};

	return fn;
};

export const deleteObjects = (ids: string[]): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const numberOfCommunities = `${ids.length} communit${
				ids.length === 1 ? 'y' : 'ies'
			}`;

			dispatch(ModalFlowActions.closeConfirmationModal());

			const progressSnackbar = await dispatch(
				SnackbarActions.showProgress(`Deleting ${numberOfCommunities}`)
			);

			await CommunitiesApi.deleteObjects(ids);
			dispatch({ type: 'DELETE_COMMUNITIES', ids });

			dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));

			dispatch(
				SnackbarActions.showSuccess(
					`Deleted ${numberOfCommunities} successfully!`
				)
			);
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	};

	return fn;
};
