import styled from 'styled-components';
import { mediaSize } from 'theme';

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const CenteredRow = styled(Row)`
	justify-content: center;
`;

export const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

export const StartRow = styled(Row)`
	align-items: flex-start;
`;

export const ResponsiveSpacedRow = styled(SpacedRow)<{
	variant: 'start' | 'end';
}>`
	flex: 1;

	@media ${mediaSize.mobile} {
		flex-direction: column;
		align-items: ${({ variant }) =>
			variant === 'start' ? 'flex-start' : 'flex-end'};

		div:last-child {
			margin-top: 8px;
		}
	}
`;
