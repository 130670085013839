"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLinkedQuestionsForMedia = exports.getFlagsForMedia = void 0;
__exportStar(require("./sorting"), exports);
const getFlagsForMedia = (feature, image) => {
    return feature.maintenance_flags.filter((flag) => { var _a, _b; return ((_a = flag.image_urls) === null || _a === void 0 ? void 0 : _a.includes(image)) || ((_b = flag.video_urls) === null || _b === void 0 ? void 0 : _b.includes(image)); });
};
exports.getFlagsForMedia = getFlagsForMedia;
const getLinkedQuestionsForMedia = (feature, image) => {
    var _a, _b, _c, _d;
    const imageLinks = (_b = (_a = feature.image_links) === null || _a === void 0 ? void 0 : _a.filter((il) => il.image_uri === image)) !== null && _b !== void 0 ? _b : [];
    const videoLinks = (_d = (_c = feature.video_links) === null || _c === void 0 ? void 0 : _c.filter((vl) => vl.video_uri === image)) !== null && _d !== void 0 ? _d : [];
    const mediaLinks = [...imageLinks, ...videoLinks];
    return feature.questions
        .map((question, index) => {
        const hasLinkedMedia = mediaLinks.some((ml) => ml.linked_object === question.title);
        if (hasLinkedMedia) {
            return { question, answer: feature.responses[index] };
        }
        return undefined;
    })
        .filter(Boolean);
};
exports.getLinkedQuestionsForMedia = getLinkedQuestionsForMedia;
