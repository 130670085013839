import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	APIProperty,
	ApiInspectionTemplateDigest,
	AssignMethod,
	CreateInspectionsResult,
} from '@rentcheck/types';

import { DialogTitle } from 'components';
import { RecurringOption } from 'components/inspections/create-steps/recurrence';
import { useCreateInspectionModalData } from 'hooks/modals';
import {
	InspectionActions,
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import {
	getFullTemplateName,
	isEmbeddedInMobileApp,
	postMessageToNativeApp,
} from 'utils/helpers';

import { Recipient } from 'components/recipients-list';
import moment from 'moment';
import Assign from './assign';
import {
	AssignedTeammates,
	buildPropertyTeamAssignments,
} from './common/assigned-teammates';
import DueDate from './due-date';
import ErrorModal from './error-modal';
import FastTrack from './fast-track';
import Template from './inspection-template';
import MoreOptions from './more-options';
import SelectProperty from './select-property';

export interface FeatureInfo {
	feature: string;
	room: string;
}

interface Props {
	open: boolean;
}

const CreateInspection = ({ open }: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const profile = useTypedSelector((state) => state.activeProfile);

	const dispatch: Dispatch = useDispatch();

	const modalData = useCreateInspectionModalData();
	const { properties: initialProperties, property_type: propertyType } =
		modalData?.metadata ?? {};

	const oneWeekFromToday = moment(new Date()).add(1, 'week').toDate();

	const [properties, setProperties] = useState<APIProperty[]>([]);
	const [template, setTemplate] = useState<ApiInspectionTemplateDigest>();
	const [fastTrack, setFastTrack] = useState(false);
	const [selectedFeaturesInfo, setSelectFeaturesInfo] = useState<FeatureInfo[]>(
		[]
	);
	const [dueDate, setDueDate] = useState<Date | undefined>(oneWeekFromToday);
	const [recurrence, setRecurrence] = useState<RecurringOption>();
	const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date>();
	const [assignMethod, setAssignMethod] = useState<AssignMethod>();
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [inviteDate, setInviteDate] = useState<Date>();
	const [inspectionLabel, setInspectionLabel] = useState<string>('');
	const [assignedTeammates, setAssignedTeammates] = useState<AssignedTeammates>(
		{}
	);
	const [notifyChecked, setNotifyChecked] = useState(false);
	const [notifyNote, setNotifyNote] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [creationResult, setCreationResult] =
		useState<CreateInspectionsResult>();

	useEffect(() => {
		if (!profile) return;

		dispatch(InspectionTemplatesActions.getAll())
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [profile?.id]);

	useEffect(() => {
		if (!assignMethod) return;

		setRecipients([]);
		setAssignedTeammates({});
		setNotifyChecked(false);
		setNotifyNote('');
	}, [assignMethod]);

	useEffect(() => {
		setProperties(initialProperties ?? []);
		setTemplate(undefined);
		setDueDate(oneWeekFromToday);
		setAssignMethod(undefined);
		setRecipients([]);
		setAssignedTeammates({});
		setNotifyChecked(false);
		setNotifyNote('');
	}, [initialProperties]);

	useEffect(() => {
		if (!open) {
			setTemplate(undefined);
			setFastTrack(false);
			setSelectFeaturesInfo([]);
			setInspectionLabel('');
			setAssignedTeammates({});
			setNotifyChecked(false);
			setNotifyNote('');
			setRecurrence(undefined);
		}
	}, [open]);

	const handleClose = () => {
		if (isEmbeddedInMobileApp()) {
			postMessageToNativeApp({
				type: 'closed-modal',
				modal: 'create-inspection',
			});
		}

		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleConfirmCreate = () => {
		if (!profile || !template || !dueDate || !assignMethod) return;

		setLoading(true);

		dispatch(ModalFlowActions.setConfirmationModalLoading(true));

		dispatch(
			InspectionActions.bulkCreate(
				profile.id,
				properties,
				template,
				fastTrack,
				selectedFeaturesInfo,
				dueDate,
				recurrence ?? 'Does not repeat',
				assignMethod,
				recipients,
				inspectionLabel,
				recurrenceEndDate,
				inviteDate,
				buildPropertyTeamAssignments(properties, assignedTeammates),
				notifyChecked,
				notifyNote
			)
		)
			.then((result: CreateInspectionsResult) => {
				if (result.errors?.length > 0) {
					setCreationResult(result);
					setErrorModal(true);
					return; //return without dispatch success message
				}

				if (result.warnings?.length > 0) {
					setCreationResult(result);
					setErrorModal(true);
				}

				if (result.success.length === 1) {
					dispatch(
						SnackbarActions.showSuccess('Inspection created successfully.')
					);
				}

				if (result.success.length > 1) {
					dispatch(
						SnackbarActions.showSuccess(
							`${result.success.length} ${getFullTemplateName(template)} inspections created succesfully.`
						)
					);
				}

				handleClose();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
				dispatch(ModalFlowActions.setConfirmationModalLoading(false));
				dispatch(ModalFlowActions.closeConfirmationModal());
			});
	};

	const handleCreate = () => {
		if (!profile || !template || !dueDate || !assignMethod) return;

		if (
			template &&
			template.template_type === 'specific-features' &&
			!selectedFeaturesInfo.length
		) {
			dispatch(
				SnackbarActions.showError(
					`Please select features for this ${getFullTemplateName(template)} Inspection.`
				)
			);
			return;
		}

		if (assignMethod === 'emails' && !recipients.length) {
			dispatch(
				SnackbarActions.showError(
					'Please enter recipients to perform this assigned inspection.'
				)
			);
			return;
		}
		if (
			assignMethod === 'teammates' &&
			!Object.values(assignedTeammates).length
		) {
			dispatch(
				SnackbarActions.showError(
					'Please enter teammates to perform this assigned inspection.'
				)
			);
			return;
		}

		if (properties.length < 50) {
			handleConfirmCreate();
			return;
		}

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Create Inspections',
				body1: [
					`Are you sure you want to create ${properties.length} inspections?`,
					'This may take several minutes. After confirming, please do not close the window until complete.',
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: handleConfirmCreate,
					},
				],
			})
		);
	};

	const extraOptionsDisabled = !properties.length || !template;
	const createdDisabled = !template || !dueDate || !assignMethod;

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen={mobileScreen || isEmbeddedInMobileApp()}>
				<DialogTitle
					title={`${_.capitalize(propertyType)} Inspection`}
					icon={solid('sparkles')}
					onClose={handleClose}
				/>
				<DialogContent>
					<SelectProperty value={properties} setValue={setProperties} />
					<Template
						properties={properties}
						disabled={!properties.length}
						template={template}
						setTemplate={setTemplate}
						selectedFeaturesInfo={selectedFeaturesInfo}
						setSelectFeaturesInfo={setSelectFeaturesInfo}
					/>
					<FastTrack
						fastTrack={fastTrack}
						setFastTrack={setFastTrack}
						template={template}
					/>
					<DueDate
						value={dueDate}
						setValue={setDueDate}
						recurrenceValue={recurrence}
						setRecurrenceValue={setRecurrence}
						recurrenceEndDateValue={recurrenceEndDate}
						setRecurrenceEndDateValue={setRecurrenceEndDate}
						disabled={extraOptionsDisabled}
						template={template}
					/>
					<Assign
						template={template}
						disabled={extraOptionsDisabled}
						properties={properties}
						assignMethod={assignMethod}
						setAssignMethod={setAssignMethod}
						recipients={recipients}
						setRecipients={setRecipients}
						inviteDate={inviteDate}
						setInviteDate={setInviteDate}
						dueDate={dueDate}
						recurrence={recurrence}
						notifyChecked={notifyChecked}
						setNotifyChecked={setNotifyChecked}
						notifyNote={notifyNote}
						setNotifyNote={setNotifyNote}
						assignedTeammates={assignedTeammates}
						setAssignedTeammates={setAssignedTeammates}
					/>
					<MoreOptions
						inspectionLabel={inspectionLabel}
						setInspectionLabel={setInspectionLabel}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<LoadingButton
						id="button-create-inspection"
						variant="contained"
						onClick={handleCreate}
						loading={loading}
						disabled={createdDisabled}>
						Create
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<ErrorModal
				setErrorModal={setErrorModal}
				errorModal={errorModal}
				creationResult={creationResult}
			/>
		</>
	);
};

export default CreateInspection;
