import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Utils } from '@rentcheck/biz';
import { Property } from '@rentcheck/types';

import IconButton from 'components/icon-button';
import { simplePropertyType } from 'utils/helpers';

import Dialog from './dialog';

interface Props {
	property: Property;
}
export default ({ property }: Props) => {
	const [dialogOpen, setDialogOpen] = useState(false);

	if (Utils.Properties.propertyIsStillInSync(property)) {
		return null;
	}

	return (
		<>
			<IconButton
				icon={solid('merge')}
				buttonSx={{ mr: 1 }}
				tooltip={`Merge ${simplePropertyType(property)}`}
				onClick={() => setDialogOpen(true)}
			/>
			<Dialog
				open={dialogOpen}
				property={property}
				onClose={() => setDialogOpen(false)}
			/>
		</>
	);
};
