import { useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Button, MenuItem, Paper, Typography } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TemplateFeature, TemplateSection } from '@rentcheck/types';

import { useInspectionTemplateModalData } from 'hooks/modals';
import { Column, SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { numberAndPluralizeWord } from 'utils/helpers';
import assets from 'assets';

import CopyToSectionDialog from './copy-to-section-dialog';
import FeatureCard from './feature-card';
import FeatureDetailsCard from './feature-details-card';
import { containsIncompleteFeatures } from './helpers';
import FeatureLogicModal from './feature-logic/bulk-modal';

interface Props {
	editing: boolean;
	isSmallScreen: boolean;
	isMediumScreen: boolean;

	section: TemplateSection;
	setSection: (section?: TemplateSection) => void;

	sections: TemplateSection[];
	setSections: (sections: TemplateSection[]) => void;

	currentFeature?: TemplateFeature;
	setCurrentFeature: (feature?: TemplateFeature) => void;
}

export default ({
	editing,
	isSmallScreen,
	isMediumScreen,
	section,
	setSection,
	sections,
	setSections,
	currentFeature,
	setCurrentFeature,
}: Props) => {
	const modalData = useInspectionTemplateModalData();
	const template = modalData?.metadata?.template;

	const [copyToSectionOpen, setCopyToSectionOpen] = useState(false);
	const [featureLogicModalOpen, setFeatureLogicModalOpen] = useState(false);

	useEffect(() => {
		if (!currentFeature && !isMediumScreen) {
			setCurrentFeature(section.features[0]);
		}

		if (
			currentFeature &&
			!section.features.find((f) => f.id === currentFeature.id)
		) {
			setCurrentFeature(section.features[0]);
		}
	}, [section]);

	useEffect(() => {
		scrollFeaturesToTop();
	}, [section.id]);

	const scrollFeaturesToTop = () => {
		document.getElementById('features-container')?.scrollTo(0, 0);
	};

	const handleDragEnd = (result: DropResult) => {
		/**
		 * feature was dropped outside the list, don't do anything
		 */
		if (!result.destination) {
			return;
		}

		/**
		 * Remove feature from array then re-insert at the
		 * correct location
		 */
		const reordered = Array.from(section.features);
		const [removed] = reordered.splice(result.source.index, 1);
		reordered.splice(result.destination.index, 0, removed);

		handleUpdateSection({ ...section, features: reordered });
	};

	const handleUpdateSection = (section: TemplateSection) => {
		/**
		 * Even though the parent component updates the section whenever the
		 * sections array is updated we still need to update the individual section
		 * we're editing manually to prevent delays and glitches caused by the
		 * useEffect hook taking a bit of time to update the state
		 */
		setSection(section);

		const updatedSections = sections.map((s) =>
			s.id === section.id ? section : s
		);

		/**
		 * When we update a section we need to look into all other sections that have logic
		 * enabled to make sure that the logic is still valid (i.e. the trigger question still exists)
		 */
		const validSections = updatedSections.map((s) => {
			if (s.id === section.id) {
				return section;
			}

			/**
			 * If the section doesn't have any logic we don't need to check anything
			 */
			if (!s.logic.length) {
				return s;
			}

			/**
			 * If the section has logic and it was using a deleted question as a trigger
			 * we need to remove that logic
			 */
			const allQuestionIds = updatedSections.flatMap((s) =>
				s.features.flatMap((f) => f.questions.map((q) => q.id))
			);

			return {
				...s,
				logic: s.logic.filter((l) => allQuestionIds.includes(l.trigger_id)),
			};
		});

		setSections(validSections);
	};

	const insertFeature = (feature: TemplateFeature, after?: TemplateFeature) => {
		const indexToInsert = after
			? section.features.findIndex((f) => f.id === after.id) + 1
			: 0;

		/**
		 * Need this auxiliary variable because splice returns the
		 * deleted elements, not the updated array
		 */
		const updatedFeatures = [...section.features];
		updatedFeatures.splice(indexToInsert, 0, feature);

		handleUpdateSection({
			...section,
			features: updatedFeatures,
		});
	};

	const handleAddFeature = (insertAfter?: TemplateFeature) => {
		const newFeature: TemplateFeature = {
			id: uuid(),
			name: '',
			description: '',
			is_rating_required: true,
			number_of_photos_required: 1,
			reference_photos: [],
			questions: [],
			is_video_enabled: false,
			logic: [],
		};

		insertFeature(newFeature, insertAfter);
		setCurrentFeature(newFeature);
	};

	const handleCopyFeature = (feature: TemplateFeature) => {
		const newFeature: TemplateFeature = { ...feature, id: uuid() };

		return insertFeature(newFeature, feature);
	};

	const handleCopyToSection = () => {
		setCopyToSectionOpen(true);
	};

	const handleDeleteFeature = (feature: TemplateFeature) => {
		handleUpdateSection({
			...section,
			features: section.features.filter((f) => f.id !== feature.id),
		});
	};

	const handleUpdateFeature = (feature: TemplateFeature) => {
		handleUpdateSection({
			...section,
			features: section.features.map((f) =>
				f.id === feature.id ? feature : f
			),
		});
	};

	const handleBackToSections = () => {
		if (isSmallScreen) {
			setCurrentFeature(undefined);
			setSection(undefined);
		} else {
			setCurrentFeature(undefined);
		}
	};

	const handleBackToFeatures = () => {
		setCurrentFeature(undefined);
	};

	const disabled = containsIncompleteFeatures(sections);

	const handleSelectFeature = (feature: TemplateFeature) => {
		if (disabled) {
			return;
		}

		setCurrentFeature(feature);
	};

	const handleUpdateSingleFeature = (feature: TemplateFeature) => {
		handleUpdateSection({
			...section,
			features: section.features.map((f) =>
				f.id === feature.id ? feature : f
			),
		});
	};

	const handleUpdateFeatures = (features: TemplateFeature[]) => {
		handleUpdateSection({ ...section, features });
	};

	if (!template) {
		return null;
	}

	return (
		<>
			<FeaturesContainer
				featureSelected={!!currentFeature}
				id="features-container">
				<InnerFeaturesContainer>
					{((!!currentFeature && isMediumScreen) || isSmallScreen) && (
						<div>
							<Button
								variant="text"
								onClick={handleBackToSections}
								disabled={disabled}
								startIcon={<FontAwesomeIcon icon={regular('arrow-left')} />}>
								Back to Sections
							</Button>
						</div>
					)}
					<SpacedRow
						style={{ padding: '8px 16px 24px 32px', alignItems: 'center' }}>
						<Column>
							<Typography variant="h6">Features</Typography>
							<Typography variant="caption">
								{numberAndPluralizeWord(section.features.length, 'Feature')}
							</Typography>
						</Column>

						{editing && (
							<IconButton
								icon={regular('ellipsis-vertical')}
								disabled={disabled}>
								<MenuItem onClick={() => handleAddFeature()}>
									Add Feature
								</MenuItem>
								<MenuItem onClick={handleCopyToSection}>Copy Features</MenuItem>
							</IconButton>
						)}
					</SpacedRow>

					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{section.features.map((feature, index) => (
										<FeatureCard
											index={index}
											key={feature.id}
											feature={feature}
											editing={editing}
											onSelect={handleSelectFeature}
											selected={feature.id === currentFeature?.id}
											handleUpdateSingleFeature={handleUpdateSingleFeature}
											features={section.features}
										/>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					{!section.features.length && (
						<Paper
							elevation={0}
							sx={{
								margin: '0px 0px 8px',
								padding: '16px 32px',
								backgroundColor: '#DFFFE9',
							}}>
							<Typography variant="h6">Add Features</Typography>
							<Typography variant="subtitle2" mt={1}>
								Sections with 0 features will not be visible on inspections. Add
								at least one feature if you want {section.name} to appear in
								inspections.
							</Typography>
						</Paper>
					)}
					{editing && (
						<Button
							onClick={() => handleAddFeature()}
							disabled={disabled}
							startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
							Add Feature
						</Button>
					)}
				</InnerFeaturesContainer>
			</FeaturesContainer>
			{!!currentFeature && (
				<FeatureContainer featureSelected={!!currentFeature}>
					<InnerFeaturesContainer>
						{currentFeature && isSmallScreen && (
							<div>
								<Button
									variant="text"
									sx={{ mb: 2 }}
									disabled={disabled}
									onClick={handleBackToFeatures}
									startIcon={<FontAwesomeIcon icon={regular('arrow-left')} />}>
									Back to Features
								</Button>
							</div>
						)}
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{section.features.map((feature, index) => (
											<FeatureDetailsCard
												index={index}
												key={feature.id}
												feature={feature}
												editing={editing}
												sections={sections}
												onSelect={setCurrentFeature}
												onDelete={handleDeleteFeature}
												onUpdate={handleUpdateFeature}
												onUpdateSection={handleUpdateSection}
												onAdd={handleAddFeature}
												onCopy={handleCopyFeature}
												selected={feature.id === currentFeature?.id}
											/>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</InnerFeaturesContainer>
					<FeatureLogicModal
						features={section.features}
						onUpdate={handleUpdateFeatures}
						open={featureLogicModalOpen}
						onClose={() => setFeatureLogicModalOpen(false)}
					/>
				</FeatureContainer>
			)}
			{!section.features.length && (
				<EmptyFeaturesContainer>
					<img
						src={assets.inspectionTemplates.dogPainting}
						alt="Empty State"
						style={{ maxHeight: '60vh', maxWidth: '80%' }}
					/>
				</EmptyFeaturesContainer>
			)}

			<CopyToSectionDialog
				open={copyToSectionOpen}
				setOpen={setCopyToSectionOpen}
				section={section}
				template={template}
				sections={sections}
				setSections={setSections}
			/>
		</>
	);
};

const FeaturesContainer = styled(Column)<{
	featureSelected: boolean;
}>`
	width: 20%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: clip;
	border-right: 1px solid #0000001f;

	transition: width 0.2s ease-in-out;

	@media (max-width: 1199px) {
		width: ${({ featureSelected }) => (featureSelected ? '40%' : '50%')};
	}

	@media (max-width: 599px) {
		width: ${({ featureSelected }) => (featureSelected ? '0%' : '100%')};
	}
`;

const FeatureContainer = styled(Column)<{
	featureSelected: boolean;
}>`
	width: 60%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: clip;
	border-right: 1px solid #0000001f;

	transition: width 0.2s ease-in-out;

	@media (max-width: 1199px) {
		width: ${({ featureSelected }) => (featureSelected ? '60%' : '0%')};
	}

	@media (max-width: 599px) {
		width: ${({ featureSelected }) => (featureSelected ? '100%' : '0%')};
	}
`;

const InnerFeaturesContainer = styled(Column)`
	margin: 12px;
	margin-bottom: 4px;
`;

const EmptyFeaturesContainer = styled(Column)`
	width: 60%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: clip;

	align-items: center;
	justify-content: center;

	@media (max-width: 1199px) {
		width: 0px;
	}
`;
