"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordinalPrefixes = void 0;
exports.ordinalPrefixes = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Ninth',
];
