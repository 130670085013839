import { Accordion, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { Spacer } from 'components';

import { Permissions } from '@rentcheck/biz';
import { IntercomAPI } from 'react-intercom';
import { useTypedSelector } from 'store/reducers/rootReducer';
import Content from '../common/content';
import Header from '../common/header';
import IntegrationPassword from '../common/integration-password';

const headerProps = {
	icon: integrations.zapier,
	title: 'Zapier',
	body: 'Connect RentCheck to 4,000+ apps via Zapier.',
	route: '/account/integrations/zapier',
	learnMore:
		'https://help.getrentcheck.com/en/articles/6150234-connect-rentcheck-to-zapier',
};

const Zapier = () => {
	const location = useLocation();
	const subscription = useTypedSelector((state) => state.subscription);

	const expanded = location.pathname.startsWith(headerProps.route);

	const handleLearnMore = () => {
		window.open(headerProps.learnMore, '_blank');
	};

	const handleChatToUpgrade = () => {
		IntercomAPI('showNewMessage');
	};

	const canAccessIntegration =
		Permissions.Integrations.Zapier.canAccess(subscription);

	return (
		<Accordion expanded={expanded}>
			<Header {...headerProps} active={false} />
			<Content>
				{canAccessIntegration && (
					<>
						<IntegrationPassword />
						<Spacer height={8} />
						<Button onClick={handleLearnMore}>Learn More</Button>
					</>
				)}
				{!canAccessIntegration && (
					<Button onClick={handleChatToUpgrade}>Chat to upgrade</Button>
				)}
			</Content>
		</Accordion>
	);
};

export default Zapier;
