import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Property } from '@rentcheck/types';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Row, Spacer } from '../../..';

interface Props {
	property?: Property;
	roomNames?: string[];
	title?: string;
	options: string[];
	setValue: (rooms: string[]) => void;
}
const SelectRooms = ({
	property,
	roomNames,
	options,
	title,
	setValue,
}: Props) => {
	const setDefaultState = () => {
		if (property) return property.room_names;
		if (roomNames) return roomNames;
		return [];
	};

	const [selectedRooms, setSelectedRooms] = useState(setDefaultState());

	return (
		<div>
			<Typography variant="h6">
				{title ?? 'Select all features that apply to this Unit'}
			</Typography>
			<Spacer height={7} />

			{options.map((r) => {
				const selected = selectedRooms.includes(r);
				return (
					<RoomRow
						key={r}
						id={`${r}-row`}
						selected={selected}
						onClick={() => {
							let newSelectedRooms: string[] = [];

							if (!selected) newSelectedRooms = [...selectedRooms, r];
							else newSelectedRooms = selectedRooms.filter((sr) => sr !== r);

							setSelectedRooms(newSelectedRooms);
							setValue(newSelectedRooms);
						}}>
						<FontAwesomeIcon
							icon={selected ? solid('check-square') : solid('square')}
							color={selected ? colors.primary : colors.lightGray}
						/>
						<Spacer width={2} />
						<Typography variant="subtitle1">{r}</Typography>
					</RoomRow>
				);
			})}
		</div>
	);
};

const RoomRow = styled(Row)<{ selected?: boolean }>`
	height: ${spacingPx(11)};
	padding-left: ${spacingPx(3)};
	border-radius: ${spacingPx(1)};
	border: 2px solid ${colors.lightGray};
	background-color: ${({ selected }) =>
		selected ? colors.lightGray : colors.white};
	margin-bottom: ${spacingPx(2)};
	cursor: pointer;
`;

export default SelectRooms;
