import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { useState } from 'react';
import { FeatureWithMetadata } from 'store/reducers/features';
import FlaggedAndCollapse from '../flagged-and-collapse';
import FeatureAccordion from './feature-accordion';

interface Props {
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;
}

export default ({ features, inspection }: Props) => {
	const [expandedFeatureIds, setExpandedFeatureIds] = useState<string[]>([]);

	return (
		<>
			<FlaggedAndCollapse
				inspection={inspection}
				features={features}
				expandedFeatureIds={expandedFeatureIds}
				setExpandedFeatureIds={setExpandedFeatureIds}
			/>
			{features.map((f) => (
				<FeatureAccordion
					key={f.id}
					feature={f}
					features={features}
					inspection={inspection}
					expandedFeatureIds={expandedFeatureIds}
					setExpandedFeatureIds={setExpandedFeatureIds}
				/>
			))}
		</>
	);
};
