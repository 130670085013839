import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Card, CardContent, Chip, Typography } from '@mui/material';
import { Row, SpacedRow, Spacer } from 'components';
import { colors } from 'theme';

interface Props {
	title: string;
	icon: IconProp;
	body: string;
	isBeta?: boolean;
	onClick: () => void;
}

export default ({ title, icon, body, onClick, isBeta }: Props) => {
	return (
		<ButtonBase
			sx={{ height: '100%', width: '100%', textAlign: 'left' }}
			onClick={onClick}>
			<Card
				elevation={0}
				sx={{
					border: '1px solid #0000001F',
					flexGrow: 1,
					height: '100%',
					transition: 'background-color 0.2s ease-in-out',
					':hover': {
						backgroundColor: '#F6F5FF',
					},
				}}>
				<CardContent>
					<SpacedRow>
						<Row>
							<FontAwesomeIcon icon={icon} size="lg" />
							<Spacer width={3} />
							<Typography variant="h6">{title}</Typography>
						</Row>
						{isBeta && (
							<Chip
								label="Beta"
								sx={{
									backgroundColor: '#2D3CE614',
									'.MuiChip-icon': {
										color: colors.primary,
									},
								}}
								icon={<FontAwesomeIcon icon={regular('message-smile')} />}
							/>
						)}
					</SpacedRow>
					<Typography mt={2}>{body}</Typography>
				</CardContent>
			</Card>
		</ButtonBase>
	);
};
