import firebase from 'firebase/app';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogTitle, Spacer } from 'components';

import { LoadingButton } from '@mui/lab';
import {
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { SnackbarActions } from 'store/actions';
import * as AuthActions from 'store/actions/auth-actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

interface Props {
	open: boolean;
}

const UserNameModal = ({ open }: Props) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');

	const handleSave = () => {
		setLoading(true);

		dispatch(
			AuthActions.updateUser({
				user_name: `${firstName} ${lastName}`,
				temporary_password: firebase.firestore.FieldValue.delete(),
			})
		)
			.then(() => Analytics.trackEvent('onboarding set name and password'))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={() => {}} fullScreen={fullScreen}>
			<DialogTitle
				title="Welcome to RentCheck!"
				bottomSubtitle="To get started using RentCheck, please enter your name and create a password."
			/>
			<DialogContent>
				<Typography>First Name</Typography>
				<Spacer height={1} />
				<TextField
					id="first-name"
					fullWidth
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>

				<Spacer height={5} />
				<Typography>Last Name</Typography>
				<Spacer height={1} />
				<TextField
					id="last-name"
					fullWidth
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>

				<Spacer height={8} />
			</DialogContent>
			<DialogActions>
				<div />
				<LoadingButton
					variant="contained"
					onClick={handleSave}
					disabled={!firstName || !lastName}
					loading={loading}>
					Save & Continue
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default UserNameModal;
