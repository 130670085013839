import { Typography } from '@mui/material';
import { Property } from '@rentcheck/types';
import { useState } from 'react';
import { spacing } from 'theme';
import { Row, Spacer, TextInput } from '../../..';

interface Props {
	property?: Property;
	address?: string;
	city?: string;
	zipcode?: string;
	setAddress: (address: string) => void;
	setCity: (city: string) => void;
	setZipcode: (zipcode: string) => void;
}

const Address = ({
	property,
	address,
	city,
	zipcode,
	setCity,
	setZipcode,
	setAddress,
}: Props) => {
	const [selectedAddress, setSelectedAddress] = useState(
		property?.address ?? address
	);
	const [selectedCity, setSelectedCity] = useState(property?.city ?? city);
	const [selectedZipcode, setSelectedZipcode] = useState(
		property?.zipcode ?? zipcode
	);

	return (
		<div>
			<Typography variant="h6">Where is the rental unit located?</Typography>
			<Spacer height={7} />
			<TextInput
				label="Address"
				width={spacing(98)}
				onChange={(v) => {
					setSelectedAddress(v);
					setAddress(v);
				}}
				value={selectedAddress}
			/>
			<Spacer height={7} />
			<Row>
				<TextInput
					label="City"
					onChange={(v) => {
						setSelectedCity(v);
						setCity(v);
					}}
					value={selectedCity}
				/>
				<Spacer width={4} />
				<TextInput
					label="Zip Code"
					onChange={(v) => {
						setSelectedZipcode(v);
						setZipcode(v);
					}}
					value={selectedZipcode}
				/>
				<Spacer width={36} />
			</Row>
		</div>
	);
};

export default Address;
