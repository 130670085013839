import { SearchBar } from 'components';
import { useDispatch } from 'react-redux';
import { RootState, useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	stateExtractor: (state: RootState) => string;
	type:
		| 'inspection'
		| 'communities'
		| 'buildings'
		| 'units'
		| 'work orders'
		| 'reports';
}

export default ({ stateExtractor, type }: Props) => {
	const dispatch = useDispatch();

	const searchFilter = useTypedSelector(stateExtractor);

	const handleChange = (v: string) => {
		const dispatchType = `SET_${type
			.toUpperCase()
			.replaceAll(' ', '_')}_SEARCH_FILTER`;
		dispatch({ type: dispatchType, value: v });
	};

	return <SearchBar value={searchFilter} onChange={handleChange} />;
};
