import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { UsersApi } from 'api';
import { Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { isRenter } from 'utils/helpers';

const IntegrationPassword = () => {
	const dispatch = useDispatch();
	const profile = useTypedSelector((state) => state.activeProfile);

	const [token, setToken] = useState('Loading...');

	useEffect(() => {
		if (!profile) return;

		UsersApi.getIntegrationToken(profile.id)
			.then((data) => setToken(data))
			.catch(() => setToken('Error getting integration token'));
	}, [profile]);

	if (isRenter(profile)) return null;

	return (
		<>
			<Typography variant="h6">RentCheck Integration Password</Typography>
			<Spacer height={3} />

			<Typography>
				Your integration password is the password you will use for RentCheck
				third-party integrations such as Zapier. <br />
				Enter this password during integration setup to authenticate and connect
				your RentCheck account.
			</Typography>

			<Spacer height={3} />
			<Row>
				<TokenContainer>
					{token}
					<Spacer width={3} />
					<Clickable
						onClick={() => {
							if (!navigator.clipboard) return;

							navigator.clipboard.writeText(token);
							dispatch(
								SnackbarActions.showSuccess(
									'The integration password was copied to your clipboard'
								)
							);
						}}>
						<Typography variant="body2" sx={{ fontWeight: 600 }}>
							Copy
						</Typography>
						<Spacer width={1} />
						<FontAwesomeIcon icon={solid('copy')} />
					</Clickable>
				</TokenContainer>
			</Row>
		</>
	);
};

const TokenContainer = styled(Row)`
	background-color: ${colors.lightGray};
	border: 1px solid ${colors.gray};
	border-radius: ${spacingPx(1)};
	padding: ${spacingPx(2)} ${spacingPx(2)};
	justify-content: space-between;
	min-width: 318px;
`;

const Clickable = styled(Row)`
	cursor: pointer;
	padding: ${spacingPx(1)} ${spacingPx(1)};
	border-radius: ${spacingPx(1)};
	color: ${colors.gray};

	-webkit-transition: background-color 0.25s ease;
	transition: background-color 0.25s ease;

	&:hover {
		background-color: #dadada;
	}
`;

export default IntegrationPassword;
