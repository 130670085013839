import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Building } from '@rentcheck/types';
import { SpacedRow, Spacer } from 'components';
import { Modal } from 'components/Common';
import { Address } from 'components/properties/CreateBuilding';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as BuildingActions from 'store/actions/buildingsActions';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';

interface Props {
	section: EditBuildingSection;
	property: Building;
	setHidden: () => void;
}

export type EditBuildingSection =
	| 'Property Address'
	| 'Amenities & Common Areas';

const EditProperty = ({ section, property, setHidden }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const [name, setName] = useState(property.name);
	const [address, setAddress] = useState(property.address);
	const [city, setCity] = useState(property.city);
	const [zipcode, setZipcode] = useState(property.zipcode);

	const saveAndClose = () => {
		setLoading(true);
		const updatePayload: Partial<Building> = {};

		if (section === 'Property Address' && address && city && zipcode) {
			updatePayload.name = name;
			updatePayload.address = address;
			updatePayload.address_lowercase = address.toLowerCase();
			updatePayload.city = city;
			updatePayload.zipcode = zipcode;
		}

		dispatch(BuildingActions.update(property.id, updatePayload))
			.then(() => setHidden())
			.catch((e) => setError(e.message))
			.finally(() => setLoading(false));
	};

	const renderContent = () => {
		switch (section) {
			case 'Property Address':
				return (
					<Address
						name={name}
						address={address}
						city={city}
						zipcode={zipcode}
						setName={setName}
						setAddress={setAddress}
						setCity={setCity}
						setZipcode={setZipcode}
						title="Where is the Community located?"
						firstLabel="Community Name"
						secondLabel="Community Address"
					/>
				);
		}
	};

	return (
		<Modal targetId="edit-property" setHidden={setHidden}>
			<Typography variant="h6">Update {section}</Typography>
			<Typography>
				{property.address}, {property.city} {property.zipcode}
			</Typography>
			<Spacer height={7} />
			{renderContent()}
			<Spacer height={7} />
			<Divider />
			<Spacer height={5} />
			{!!error && (
				<div>
					<Typography color={colors.danger}>{error}</Typography>
					<Spacer height={5} />
				</div>
			)}
			<SpacedRow>
				<ButtonContainer left>
					<Button variant="text" color="secondary" onClick={setHidden}>
						Cancel
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton loading={loading} onClick={saveAndClose}>
						Save & Close
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Modal>
	);
};

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

export default EditProperty;
