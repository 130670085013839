import { Typography } from '@mui/material';
import { Property } from '@rentcheck/types';
import { emptyStates } from 'assets';
import styled from 'styled-components';
import { spacingPx } from 'theme';
import { CenteredColumn, Spacer } from '../..';

interface Props {
	property: Property;
}

const EmptyState = ({ property }: Props) => {
	const isBuildingOrCommunity = ['Building', 'Community'].includes(
		property.property_type
	);

	return (
		<Container>
			<Spacer height={5} />
			<EmptyStateImage src={emptyStates.inspections} alt="empty-inspections" />
			<Typography variant="h4" align="center">
				No Inspections Yet
			</Typography>
			<Spacer height={1} />
			<Typography align="center">
				{isBuildingOrCommunity
					? 'Get started setting up your first inspection.'
					: `Set up an inspection and invite residents to complete it. \
          We'll walk them through an easy-to-follow guided inspection \
          and you can rest easy knowing the condition of your unit is \
          thoroughly documented. You can also perform inspections yourself.`}
			</Typography>
		</Container>
	);
};

const EmptyStateImage = styled.img`
	height: 35vh;
	resize-mode: contain;
`;

const Container = styled(CenteredColumn)`
	padding: 0 ${spacingPx(40)};
`;

export default EmptyState;
