import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { PropertiesApi } from 'api';
import { DiffResult } from 'api/Properties';
import { DialogTitle, Spacer } from 'components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import { useIsMobileBreakpoint } from 'utils/hooks';
import ActionChip from './action-chip';

interface Props {
	open: boolean;
	handleClose: () => void;
	diffResults: DiffResult[];
	inspection: ApiInspection;
}

const Modal = ({ open, handleClose, inspection, diffResults }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const [loading, setLoading] = useState(false);
	const [selectedRooms, setSelectedRooms] = useState(diffResults);

	useEffect(() => {
		setSelectedRooms(diffResults);
	}, [diffResults]);

	const handleCheckRoom = (df: DiffResult) => {
		setSelectedRooms(_.xor(selectedRooms, [df]));
	};

	const handleUpdate = () => {
		setLoading(true);

		PropertiesApi.updateRooms(inspection.property.id, selectedRooms)
			.then(handleClose)
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog
			id="inspection-edit-unit-modal"
			open={open}
			onClose={handleClose}
			fullScreen={isMobile}>
			<DialogTitle
				title="Unit Updates"
				onClose={handleClose}
				icon={regular('house')}
			/>
			<DialogContent>
				<Typography>
					These rooms or areas were updated by the user performing this
					inspection. Review and update the rooms to ensure consistent and
					accurate inspections in the future. Rooms marked as Not Applicable
					will be removed and rooms added will be added to the unit.{' '}
				</Typography>

				<Spacer height={4} />

				<TableContainer component={Paper} sx={{ maxHeight: 350 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell style={{ backgroundColor: '#e6e6e6' }}></TableCell>
								<TableCell style={{ backgroundColor: '#e6e6e6' }}>
									<TableSortLabel hideSortIcon>Area/Room</TableSortLabel>
								</TableCell>
								<TableCell align="right" style={{ backgroundColor: '#e6e6e6' }}>
									<TableSortLabel hideSortIcon sx={{ marginRight: 3.5 }}>
										Inspection Edits
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{diffResults.map((df) => (
								<TableRow>
									<TableCell>
										<Checkbox
											size="small"
											checked={selectedRooms.includes(df)}
											onChange={() => handleCheckRoom(df)}
										/>
									</TableCell>
									<TableCell>{df.roomName}</TableCell>
									<TableCell align="right">
										<ActionChip diffResult={df} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					id="button-update-unit-rooms"
					variant="contained"
					onClick={handleUpdate}
					loading={loading}>
					Update
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default Modal;
