"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.propertyHasIntegrationSync = void 0;
const propertyHasIntegrationSync = (property, { appFolioIntegration, rentManagerIntegrations, }) => {
    var _a, _b, _c;
    /**
     * There's only one customer using jenark, so in this case we're letting allowing
     * them do whatever they want.
     */
    if ((_a = property === null || property === void 0 ? void 0 : property.jenark_sync) === null || _a === void 0 ? void 0 : _a.id)
        return false;
    const isUnitSynced = !!(((_b = property.appfolio_sync) === null || _b === void 0 ? void 0 : _b.id) || ((_c = property.rentmanager_sync) === null || _c === void 0 ? void 0 : _c.id));
    const isIntegrationActive = !!appFolioIntegration ||
        !!(rentManagerIntegrations === null || rentManagerIntegrations === void 0 ? void 0 : rentManagerIntegrations.find((rmi) => rmi.active));
    return isUnitSynced && isIntegrationActive;
};
exports.propertyHasIntegrationSync = propertyHasIntegrationSync;
