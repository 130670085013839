/* eslint-disable @typescript-eslint/no-explicit-any */
import { InspectionEventsApi } from '@rentcheck/api-frontend';
import {
	FirebaseUpdatePayload,
	Inspection,
	InspectionEdited,
	Profile,
} from '@rentcheck/types';
import firebase from 'config/fbConfig';
import _ from 'lodash';

export const getById = async (id: string): Promise<Inspection> =>
	firebase
		.firestore()
		.collection('inspections')
		.doc(id)
		.get()
		.then((result) => {
			if (result.exists) return result.data() as Inspection;
			throw new Error('Inspection not found');
		});

export const getByIds = async (ids: string[]): Promise<Inspection[]> => {
	const inspections: Inspection[] = [];

	const chunks = _.chunk(ids, 10);
	for (const ids_chunk of chunks) {
		await firebase
			.firestore()
			.collection('inspections')
			.where('id', 'in', ids_chunk)
			.get()
			.then((snapshot) =>
				snapshot.forEach((doc) => inspections.push(doc.data() as Inspection))
			)
			.catch(() => undefined);
	}

	return inspections;
};

export const update = async (
	id: string,
	payload: FirebaseUpdatePayload<Inspection>
): Promise<Inspection | undefined> =>
	firebase
		.firestore()
		.collection('inspections')
		.doc(id)
		.update(payload)
		.then(() => getById(id))
		.catch(() => undefined);

export const edited = async (
	id: string,
	user: Profile,
	changeset: InspectionEdited
) => {
	InspectionEventsApi.create(id, {
		type: 'inspection_edited',
		metadata: {
			edited_by: user.user_name,
		},
	});

	return getById(id).then((inspection) =>
		update(id, { edits: [...(inspection.edits ?? []), changeset] })
	);
};

export const getByPropertyId = async (propertyId: string) => {
	const inspections: Inspection[] = [];
	await firebase
		.firestore()
		.collection('inspections')
		.where('propertyID', '==', propertyId)
		.get()
		.then((snapshot) =>
			snapshot.forEach((doc) => inspections.push(doc.data() as Inspection))
		)
		.catch(() => undefined);

	return inspections.sort(
		(a, b) => b.inspection_date.seconds - a.inspection_date.seconds
	);
};
