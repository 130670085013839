import { LoadingButton } from '@mui/lab';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { Inspection } from '@rentcheck/types';
import { InspectionsApi, UsersApi } from 'api';
import { SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: Inspection;
	refreshInspection: () => void;
}

const TakeOver = ({ inspection, refreshInspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [newValue, setNewValue] = useState('');
	const [loading, setLoading] = useState(false);

	const handleChangeStatus = async () => {
		if (!inspection) {
			return;
		}

		if (!newValue) {
			return;
		}

		const newRenter = await UsersApi.getById(newValue);

		if (!newRenter) {
			dispatch(SnackbarActions.showError("Couldn't find a user with that ID"));
			return;
		}

		setLoading(true);

		await InspectionsApi.update(inspection.id, {
			renterID: newRenter.id,
			renter_name: newRenter.user_name,
		})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));

		refreshInspection();
		dispatch(SnackbarActions.showSuccess('Inspection Updated'));
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Take Over</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="New Renter ID"
						value={newValue}
						onChange={(e) => setNewValue(e.target.value)}
					/>

					<Spacer width={4} />

					<LoadingButton loading={loading} onClick={handleChangeStatus}>
						Confirm
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default TakeOver;
