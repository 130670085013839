import { ApiInspection, Property } from '@rentcheck/types';
import { PropertiesApi } from 'api';
import { useEffect, useState } from 'react';

import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import AppFolioWorkOrderIntegration from './appfolio';
import JenarkWorkOrderIntegration from './jenark';
import RentManagerWorkOrderIntegration from './rentmanager';

interface Props {
	inspection: ApiInspection;
	value?: CreateWorkOrderMetadata['provider'];
	setValue: (value?: CreateWorkOrderMetadata['provider']) => void;
}

const IntegrationWorkOrder = ({ inspection, value, setValue }: Props) => {
	const [property, setProperty] = useState<Property | undefined>(undefined);

	useEffect(() => {
		PropertiesApi.getById(inspection.property.id)
			.then(setProperty)
			.catch(() => {});
	}, [inspection]);

	if (!property) {
		return null;
	}

	return (
		<>
			<AppFolioWorkOrderIntegration
				inspection={inspection}
				property={property}
				value={value}
				setValue={setValue}
			/>
			<RentManagerWorkOrderIntegration
				inspection={inspection}
				property={property}
				value={value}
				setValue={setValue}
			/>
			<JenarkWorkOrderIntegration
				inspection={inspection}
				property={property}
				value={value}
				setValue={setValue}
			/>
		</>
	);
};

export default IntegrationWorkOrder;
