import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { SpacedRow, Spacer, TopSpacer } from 'components';
import { colors, spacingPx } from 'theme';
import { stringStartsWith } from 'utils/helpers';

import PropertyUploadButton from '../components/PropertyUploadButton';

import Buildings from './buildings';
import Communities from './communities';
import TabBar from './tab-bar';
import Units from './units';

const PropertiesList = () => {
	const { pathname } = useLocation();

	const isPathnameCommunities = (pathname: string) => {
		return stringStartsWith(pathname, '/properties/communities');
	};

	const isPathnameBuildings = (pathname: string) => {
		return stringStartsWith(pathname, '/properties/buildings');
	};

	if (isPathnameBuildings(pathname)) {
		return <Buildings />;
	}

	if (isPathnameCommunities(pathname)) {
		return <Communities />;
	}

	return <Units />;
};

const Properties = () => {
	return (
		<div>
			<TopSpacer />
			<ScreenContainer>
				<SpacedRow>
					<TabBar />
					<PropertyUploadButton />
				</SpacedRow>
				<Spacer height={6} />
				<PropertiesList />
			</ScreenContainer>
		</div>
	);
};

const ScreenContainer = styled.div`
	width: 80vw;
	background-color: ${colors.contentBackground};
	border: 1px solid ${colors.contentBorder};
	border-radius: 3px;
	-webkit-box-shadow: 0 0 1px ${colors.shadow};
	box-shadow: 0 0 1px ${colors.shadow};
	position: relative;
	padding: ${spacingPx(10)};
	margin-bottom: ${spacingPx(10)};
`;

export default Properties;
