import { ApiInspection, ApiInspectionWithTemplate } from '@rentcheck/types';

import { Rules } from '@rentcheck/biz';
import { useTenants } from 'hooks/residents';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AssigneeTooltip from './assignee-tooltip';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const { tenants, loading } = useTenants(20, {
		property_id: inspection.property.id,
	});

	if (!Rules.Inspections.canShowAssignee(inspection)) {
		return null;
	}

	/**
	 * Both of these cases are easy, we just need to show static
	 * text
	 */
	if (inspection.assigned_recipients.type === 'myself') {
		return <span>Myself</span>;
	}

	if (inspection.assigned_recipients.type === 'self-perform') {
		return <span>Entire Team</span>;
	}

	/**
	 * For the next one, if the inspection is scheduled and the
	 * recipients are residents, we have 2 options:
	 * 1. If the invite date is more than 7 days in the future, we
	 * 	show "Residents" as static text
	 * 2. If the invite date is less than 7 days in the future, we
	 * 	show the actual current residents
	 */
	if (
		inspection.inspection_status === 'Scheduled' &&
		inspection.assigned_recipients.type === 'residents'
	) {
		const inviteDateIsMoreThan7DaysInTheFuture =
			moment().diff(inspection.invite_date, 'days') < -7;

		if (inviteDateIsMoreThan7DaysInTheFuture) {
			return <span>Residents</span>;
		}

		if (loading) {
			return <span>Loading...</span>;
		}

		if (tenants.length) {
			const mappedTenants = tenants.map((t) => ({
				id: '',
				email: t.emails[0],
				reference_code: '',
			}));

			return (
				<AssigneeTooltip
					title="Planned Recipients"
					recipients={mappedTenants}
				/>
			);
		}

		return (
			<AssigneeTooltip title="Planned Recipients" bodyTitle="Residents">
				<div>No current residents.</div>
				<div>
					<Link
						to="/residents"
						style={{ color: '#fff', textDecoration: 'underline' }}>
						Add or update residents
					</Link>{' '}
					for this unit.
				</div>
			</AssigneeTooltip>
		);
	}

	/**
	 * For the next one, we fetch the recipients from the inspection
	 * based on wether it's scheduled or not.
	 * 1. If the inspection is scheduled, we fetch the emails from the
	 * 	assigned_recipients object
	 * 2. If the inspection is not scheduled, we fetch the invites
	 * 	that have already been sent
	 */
	const recipients: ApiInspection['invites'] =
		inspection.inspection_status === 'Scheduled'
			? inspection.assigned_recipients.emails?.map((e) => ({
					email: e,
					reference_code: '',
					id: '',
				})) ?? []
			: inspection.invites;

	/**
	 * If we have no recipients just show static text
	 */
	if (recipients.length === 0) {
		return <span>No Recipients Assigned</span>;
	}

	/**
	 * If we have recipients, we show the tooltip
	 * with the recipients, emails, residents, teammates behave the same way
	 * here becaues they rely on sending invites
	 */
	if (
		inspection.assigned_recipients.type === 'emails' ||
		inspection.assigned_recipients.type === 'residents' ||
		inspection.assigned_recipients.type === 'teammates'
	) {
		return <AssigneeTooltip recipients={recipients} />;
	}

	return <span>Failed to fetch recipients</span>;
};
