import { LatchelApi } from '@rentcheck/api-frontend';
import { UsersApi } from 'api';
import { Dispatch, GetState } from 'types';

export const disconnect = (organizationIds: string[], apiKey: string) => {
	return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		return LatchelApi.disconnect(organizationIds, apiKey)
			.then(() => UsersApi.getById(getState().activeProfile.id))
			.then((profile) => {
				if (!profile) {
					throw new Error(
						"Couldn't update your profile. Please refresh the page."
					);
				}

				dispatch({
					type: 'UPDATE_USER',
					profile,
				});
			});
	};
};
