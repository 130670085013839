import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { Profile } from '@rentcheck/types';
import { Column, Spacer } from 'components';

interface Props {
	note: string;
	setNote: (value: string) => void;
	requester?: Profile;
}

export default ({ note, setNote, requester }: Props) => {
	return (
		<Column>
			<Spacer height={5} />
			<Typography variant="overline">Note</Typography>
			<Spacer height={3} />

			<TextField
				label="Note"
				helperText="Optional"
				placeholder={`Add a note ${
					requester ? `for ${requester.user_name}` : ''
				}`}
				variant="filled"
				value={note}
				onChange={(e) => setNote(e.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<FontAwesomeIcon icon={regular('message-lines')} />
						</InputAdornment>
					),
				}}
			/>
			<Spacer height={4} />
		</Column>
	);
};
