import { Accordion, AccordionSummary } from '@mui/material';

import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';

import { Spacer } from 'components';
import Details from './details';
import Media from './media';
import Notes from './notes';
import ReviewNotes from './review-notes';
import Summary from './summary';

interface Props {
	feature: FeatureWithMetadata;
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;
	expandedFeatureIds: string[];
	setExpandedFeatureIds: (ids: string[]) => void;
}

export default ({
	feature,
	features,
	inspection,
	expandedFeatureIds,
	setExpandedFeatureIds,
}: Props) => {
	const expanded = expandedFeatureIds.includes(feature.id);

	const setExpanded = (expanded: boolean) => {
		if (expanded) {
			setExpandedFeatureIds([...expandedFeatureIds, feature.id]);
		} else {
			setExpandedFeatureIds(
				expandedFeatureIds.filter((id) => id !== feature.id)
			);
		}
	};

	const handleChange = () => {
		if (feature.not_applicable) {
			return;
		}

		setExpanded(!expanded);
	};

	return (
		<div>
			<Summary
				feature={feature}
				features={features}
				inspection={inspection}
				expanded={expanded}
				setExpanded={setExpanded}
			/>
			<Accordion
				id={`feature-accordion-${feature.id}`}
				elevation={0}
				expanded={expanded}
				onChange={handleChange}>
				{/* 
					We add this dummy Accordion Summary so that the expand/collapse functionality
					still works on the details
				*/}
				<AccordionSummary sx={{ display: 'none' }} />
				<Details feature={feature} inspection={inspection} />
			</Accordion>
			<Notes feature={feature} />
			<ReviewNotes feature={feature} />
			<Media feature={feature} features={features} inspection={inspection} />
			<Spacer height={8} />
		</div>
	);
};
