import reactotronApiSauce from 'reactotron-apisauce';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

const reactotron = Reactotron.configure({ name: 'RentCheck Web App' })
	.use(reactotronRedux())
	.use(reactotronApiSauce());

if (process.env.firebase_projectId === 'web-console-nonprod') {
	reactotron.connect();
	reactotron.clear();

	console.tron = reactotron;
	console.log = console.tron.log;
} else {
	console.tron = console;
}

export default reactotron;
