import { Typography } from '@mui/material';
import styled from 'styled-components';

import { emptyStates } from 'assets';
import { CenteredColumn, Spacer } from 'components';
import { spacingPx } from 'theme';

const EmptyState = () => {
	return (
		<Container>
			<EmptyStateImage src={emptyStates.inspections} alt="empty-inspections" />

			<Typography variant="h4" align="center">
				No Inspections Yet
			</Typography>
			<Spacer height={4} />

			<Typography sx={{ maxWidth: 600 }}>
				Set up an inspection and invite residents to complete it. We'll walk
				them through an easy-to-follow guided inspection and you can rest easy
				knowing the condition of your unit is thoroughly documented. You can
				also perform inspections yourself.
			</Typography>
		</Container>
	);
};

const EmptyStateImage = styled.img`
	height: 35vh;
	resize-mode: contain;
`;

const Container = styled(CenteredColumn)`
	padding: 0 ${spacingPx(40)};
`;

export default EmptyState;
