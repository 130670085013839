import { ApiSubscription } from '@rentcheck/types';
import * as IntegrationsApi from 'api/integrations';

/**
 * Base AppFolio addons here plus we add support for 2 more
 * in order to support Atlas' billing setup
 */
export const appFolioAddonIds = [
	'appfolio-integration---two-way-data-sync',
	'appfolio-data-sync-plus-workorders',
	'atlas-appfolio-integration---two-way-data-sync',
	'atlas-appfolio-data-sync-plus-workorders',
];

export const appFolioWorkOrderAddonIds = [
	'appfolio-data-sync-plus-workorders',
	'atlas-appfolio-data-sync-plus-workorders',
];

export const latchelWorkOrderAddonIds = ['latchel-integration'];

export const yardiWorkOrderAddonIds = ['yardi-integration'];

export const jenarkWorkOrderAddonIds = ['jenark-integration'];

export const appFolioAddons = {
	twoWaySync: 'appfolio-integration---two-way-data-sync',
	workOrders: 'appfolio-data-sync-plus-workorders',
};

export const rentManagerAddonIds = [
	'rm-integration---two-way-data-sync',
	'rentmanager-integration---two-way-data-sync',
];

export const rentManagerWorkOrderAddonIds = [
	'rm-integration---work-orders',
	'rentmanager-integration---work-orders',
];

export const inspectionTemplatePerUnitAddonId =
	'inspection-template-customization-2';

export const inspectionTemplateFreeTrialAddonId =
	'inspection-template-customization-free-trial';

export const inspectionTemplateAddonIds = [
	'inspection-template-customization',
	inspectionTemplateFreeTrialAddonId,
	inspectionTemplatePerUnitAddonId,
];

const subscriptionHasAddons = (
	subscription: ApiSubscription | null,
	addonIds?: string[]
) => {
	if (!subscription) {
		return false;
	}

	if (!addonIds) {
		return false;
	}

	return !!subscription.addons.find((a) => addonIds.includes(a.id));
};

export const hasAppfolioAddons = (subscription: ApiSubscription | null) => {
	return subscriptionHasAddons(subscription, appFolioAddonIds);
};

export const hasAppfolioWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, appFolioWorkOrderAddonIds);
};

export const hasRentManagerAddons = (subscription: ApiSubscription | null) => {
	return subscriptionHasAddons(subscription, rentManagerAddonIds);
};

export const hasRentManagerWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, rentManagerWorkOrderAddonIds);
};

export const hasYardiWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, yardiWorkOrderAddonIds);
};

export const hasLatchelWorkOrdersAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, latchelWorkOrderAddonIds);
};

export const hasJenarkWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, jenarkWorkOrderAddonIds);
};

export const hasWorkOrderAddons = (subscription: ApiSubscription | null) => {
	const addonIds = [
		...latchelWorkOrderAddonIds,
		...appFolioWorkOrderAddonIds,
		...yardiWorkOrderAddonIds,
		...jenarkWorkOrderAddonIds,
		...rentManagerWorkOrderAddonIds,
	];

	return subscriptionHasAddons(subscription, addonIds);
};

export const hasInspectionTemplateAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, inspectionTemplateAddonIds);
};

export const hasActiveIntegration = async (subscriptionId?: string) => {
	if (!subscriptionId) return '';

	const [appfolioIntegration, rentmanagerIntegration] = await Promise.all([
		IntegrationsApi.getAppFolioIntegrationForSubscriptionId(subscriptionId),
		IntegrationsApi.getRentmanagerIntegrationBySubscriptionId(subscriptionId),
	]);

	if (appfolioIntegration) {
		return 'AppFolio';
	}

	if (rentmanagerIntegration) {
		return 'Rent Manager';
	}

	return '';
};
