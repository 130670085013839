import { UserSettings } from '@rentcheck/types';
import { UsersApi } from 'api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const defaultSettings: UserSettings = {
	inspection_view_mode: 'list',
};

export const useUserSettings = () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const userSettings = useTypedSelector((state) => state.userSettings);

	useEffect(() => {
		if (!profile) return;

		UsersApi.getById(profile.id).then((data) => {
			if (!data) return;
			dispatch({ type: 'SET_USER_SETTINGS', value: data.settings });
		});
	}, [profile]);

	const handleUpdateSettings = (payload: Partial<UserSettings>) => {
		const effectiveSettings = { ...defaultSettings, ...payload };

		dispatch({ type: 'SET_USER_SETTINGS', value: effectiveSettings });
		UsersApi.update(profile.id, { settings: effectiveSettings });
	};

	return {
		userSettings,
		updateUserSettings: handleUpdateSettings,
	};
};
