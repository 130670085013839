import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { colors, spacingPx } from 'theme';

import { Column } from 'components/layout/Column';
import { SpacedRow } from 'components/layout/Row';
import styled from 'styled-components';
import HeaderBreadcrumbs from './header-breadcrumbs';

export const Container = (props: PropsWithChildren<{}>) => {
	/**
	 * This is needed in order to support the old screen container,
	 * we're setting the body background color here and when we get removed
	 * the dom it will be reset to the default color
	 */
	useEffect(() => {
		document.body.style.backgroundColor = '#fff';

		return () => {
			document.body.style.backgroundColor = '#ebeff3';
		};
	}, []);

	return (
		<Paper elevation={0} sx={{ width: '100%' }}>
			<Paper
				elevation={0}
				sx={{
					flex: 1,
					margin: {
						xs: `${spacingPx(3)} ${spacingPx(5)}`,
						sm: `${spacingPx(3)} ${spacingPx(8)}`,
						xl: `${spacingPx(4)} ${spacingPx(50)}`,
					},
				}}>
				{props.children}
			</Paper>
		</Paper>
	);
};

export const Header = (props: {
	overline?: string | ReactNode;
	title: string | ReactNode;
	subtitle?: string | ReactNode;
	titleCompanion?: ReactNode;
	actions?: ReactNode[];
	secondaryActions?: ReactNode[];
	tertiaryActions?: ReactNode[];
	showBreadCrumbs?: boolean;
	breadcrumbReplacements?: Record<string, string>;
}) => {
	return (
		<Box sx={{ mb: { xs: 2, sm: 4 } }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: { xs: 'center', sm: 'flex-start' },
				}}>
				<Column>
					<Box>
						{/* BreadCrumbs */}
						{props.showBreadCrumbs && (
							<HeaderBreadcrumbs
								breadcrumbReplacements={props.breadcrumbReplacements}
							/>
						)}

						{/* Overline and Title */}
						{!!props.overline && props.overline}
						<Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
							{props.title}
						</Typography>
					</Box>

					{/* Subtitle */}
					<Typography
						variant="body1"
						color={colors.secondary}
						sx={{ wordBreak: 'break-word' }}>
						{props.subtitle}
					</Typography>
				</Column>
				<Column>
					{/* Primary actions and title companion (small and larger screens only) */}
					<ActionsContainer
						sx={{
							display: { xs: 'none', sm: 'flex' },
							justifyContent: 'flex-end',
						}}>
						{!!props.actions && props.actions}
						{props.titleCompanion}
					</ActionsContainer>

					{/* Secondary actions and Tertiary Actions (medium screen only)*/}
					{!!props.secondaryActions?.length && (
						<SecondaryActionsContainer
							sx={{
								display: { xs: 'none', sm: 'flex' },
								justifyContent: 'flex-end',
							}}>
							{!!props.secondaryActions && props.secondaryActions}
							{!!props.tertiaryActions && (
								<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
									{props.tertiaryActions}
								</Box>
							)}
						</SecondaryActionsContainer>
					)}

					{/* Tertiary Actions (small screen only)*/}
					{!!props.tertiaryActions && (
						<TertiaryActionsContainer
							sx={{
								display: { xs: 'none', sm: 'flex', md: 'none' },
								justifyContent: 'flex-end',
							}}>
							{props.tertiaryActions}
						</TertiaryActionsContainer>
					)}
				</Column>

				{/* Title companion (extra small screens only) */}
				<Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
					{props.titleCompanion}
				</Box>
			</Box>

			{/* Small screen layout */}
			{/* Secondary Actions */}
			{!!props.secondaryActions?.length && (
				<SecondaryActionsContainer sx={{ display: { xs: 'flex', sm: 'none' } }}>
					{!!props.secondaryActions && props.secondaryActions}
				</SecondaryActionsContainer>
			)}

			{/* Primary Actions */}
			<ActionsContainer sx={{ mt: 2, display: { xs: 'flex', sm: 'none' } }}>
				{!!props.actions && props.actions}
			</ActionsContainer>

			{/* Tertiary Actions (extra small screen only)*/}
			{!!props.tertiaryActions && (
				<TertiaryActionsContainer
					sx={{
						display: { xs: 'flex', sm: 'none' },
					}}>
					{props.tertiaryActions}
				</TertiaryActionsContainer>
			)}
		</Box>
	);
};

export const HeaderSkeleton = (props: {
	subtitle?: boolean;
	showBreadCrumbs?: boolean;
}) => {
	return (
		<Box sx={{ mb: { xs: 2, sm: 4 } }}>
			<SpacedRow>
				<Box>
					{props.showBreadCrumbs && (
						<Skeleton variant="text" width={60} sx={{ marginBottom: 1.5 }} />
					)}
					<Skeleton variant="text" width={200} height={60} />
				</Box>
			</SpacedRow>

			{props.subtitle && (
				<Skeleton variant="text" width={400} sx={{ marginBottom: 1.5 }} />
			)}
		</Box>
	);
};

const ActionsContainer = styled(Box)`
	align-items: center;
	gap: 8px;

	button {
		margin-left: 0;
	}

	div {
		margin-left: 0;
	}

	span:first-child {
		margin-left: 0;
	}

	button:first-child {
		margin-left: 0;
	}

	button:first-child {
		margin-left: 0;
	}

	div:first-child {
		margin-left: 0;
	}
`;

const SecondaryActionsContainer = styled(ActionsContainer)`
	margin-top: 16px;
`;

const TertiaryActionsContainer = styled(ActionsContainer)`
	margin-top: 8px;
`;
