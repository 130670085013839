import { InspectionTemplatesApi } from '@rentcheck/api-frontend';
import {
	ApiInspectionTemplate,
	ApiInspectionTemplateDigest,
	ApiTeam,
} from '@rentcheck/types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { InspectionTemplatesActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

type UseInspectionTemplatesParams = {
	teams?: ApiTeam[];
	propertyTypes?: ApiInspectionTemplateDigest['property_type'][];
	search?: string;
	onlyPublished?: boolean;
	includeUnavailable?: boolean;
};

export const useInspectionTemplates = ({
	propertyTypes = ['building', 'community', 'unit'],
	teams = [],
	includeUnavailable,
	onlyPublished,
	search,
}: UseInspectionTemplatesParams) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const templates = useTypedSelector(
		(state) => state.inspectionTemplates.templates
	);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!profile) return;

		dispatch(InspectionTemplatesActions.getAll(includeUnavailable))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [profile?.id, includeUnavailable]);

	const filteredTemplates = templates.filter((t) => {
		/**
		 * Only show published templates
		 * When checking a RC template we need to look for the subscription
		 * in the unpublished_sub_ids array, this works different from regular
		 * templates, where we just need to check the is_published flag
		 */
		if (onlyPublished && !t.is_published) {
			return false;
		}

		/**
		 * If a team is selected, only show templates that belong to that team
		 * or RentCheck Templates
		 */
		if (teams.length && !t.is_rc_template) {
			if (!_.intersection(t.team_ids, _.map(teams, 'id')).length) {
				return false;
			}
		}

		/**
		 * If a property type is selected, only show templates that match that property type
		 */
		if (propertyTypes.length && !propertyTypes.includes(t.property_type)) {
			return false;
		}

		/**
		 * If search is defined and the template name or the internal label don't match the
		 * search term return false
		 */
		const lowercasedSearch = search?.toLowerCase().trim() ?? '';
		if (
			search &&
			!t.name.toLowerCase().trim().includes(lowercasedSearch) &&
			!t.internal_label?.toLowerCase().trim().includes(lowercasedSearch)
		) {
			return false;
		}

		return true;
	});

	return {
		loading,
		templates: filteredTemplates,
	};
};

export const useInspectionTemplate = (id?: string) => {
	const [template, setTemplate] = useState<ApiInspectionTemplate>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!id) {
			setLoading(false);
			setTemplate(undefined);

			return;
		}

		setLoading(true);

		InspectionTemplatesApi.get(id)
			.then(setTemplate)
			.catch(() => {})
			.finally(() => setLoading(false));
	}, [id]);

	return { template, loading };
};

export const useStoreInspectionTemplate = (id?: string) => {
	const template = useTypedSelector(
		(state) => state.inspectionTemplates.template
	);

	if (!id) {
		return null;
	}

	if (!template || template.id !== id) {
		return null;
	}

	return template;
};
