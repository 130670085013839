"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canPerformInspection = exports.isRenter = void 0;
function isRenter(userOrType) {
    if (!userOrType) {
        return false;
    }
    if (typeof userOrType === 'string') {
        return userOrType === 'Renter';
    }
    return userOrType.user_type === 'Renter';
}
exports.isRenter = isRenter;
const canPerformInspection = (inspection) => {
    return ['self-perform', 'invitee-write'].includes(inspection.role);
};
exports.canPerformInspection = canPerformInspection;
