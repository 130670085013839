import { Card, CardContent, Chip, Typography } from '@mui/material';
import { Column, Row } from 'components';
import { ReactNode } from 'react';
import typographyStyles from 'theme/typography';

interface Props {
	title: string;
	subtitle?: string;
	templateType: string;
	description: string;
	backgroundColor: string;
	hoverBackgroundColor: string;
	opacity: number;
	icon: ReactNode;
	iconBackgroundColor: string;
	onClick: () => void;
	actions?: ReactNode;
	statusChipText?: ReactNode;
}

interface IconProps {
	children: ReactNode;
	backgroundColor: string;
}

const Icon = ({ backgroundColor, children }: IconProps) => {
	const containerStyle = {
		height: 38,
		width: 38,
		minWidth: 38,
		borderRadius: 19,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	return (
		<div
			style={{
				...containerStyle,
				backgroundColor,
			}}>
			{children}
		</div>
	);
};

export default ({
	title,
	subtitle,
	templateType,
	description,
	backgroundColor,
	hoverBackgroundColor,
	opacity,
	icon,
	iconBackgroundColor,
	onClick,
	actions,
	statusChipText,
}: Props) => {
	const handleClick = () => {
		onClick();
	};

	const showSubtitle = subtitle?.length && subtitle !== title;

	return (
		<Card
			elevation={0}
			onClick={handleClick}
			sx={{
				display: 'flex',
				flexGrow: 1,
				mt: 1,
				border: '1px solid #0000001F',
				backgroundColor,
				cursor: 'pointer',
				transition: 'all 0.2s ease',
				opacity,
				':hover': {
					backgroundColor: hoverBackgroundColor,
				},
			}}>
			<CardContent
				sx={{
					paddingTop: 2,
					paddingBottom: 2,
					paddingLeft: 3,
					paddingRight: 3,

					display: 'flex',
					flex: 1,

					flexDirection: 'column',
					justifyContent: 'space-between',
				}}>
				<Column>
					<Row>
						<Icon backgroundColor={iconBackgroundColor}>{icon}</Icon>
						<Column
							style={{
								marginLeft: 16,
							}}>
							<Typography variant="h6">{title}</Typography>
							{showSubtitle && (
								<Typography variant="body2" mb={0.25}>
									{subtitle}
								</Typography>
							)}
							<Typography variant="body2">{templateType}</Typography>
						</Column>
						{!!statusChipText && (
							<Chip
								label={statusChipText}
								sx={{
									ml: 'auto',
									mt: -1,
									mb: -1,
									backgroundColor: '#ACFFC6',
									...typographyStyles.MuiTypography.styleOverrides.body2,
								}}
							/>
						)}
					</Row>

					<Typography mt={2} color="#00000099">
						{description}
					</Typography>
				</Column>
				{!!actions && (
					<Row style={{ alignSelf: 'flex-end', marginTop: 16 }}>{actions}</Row>
				)}
			</CardContent>
		</Card>
	);
};
