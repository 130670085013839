import {
	ApiInspection,
	FirebaseCreatePayload,
	Profile,
	Request,
	Signature,
	Timestamp,
} from '@rentcheck/types';
import * as Sentry from '@sentry/react';
import firebase, { firestore } from 'firebase/app';

import { InspectionEventsApi } from '@rentcheck/api-frontend';
import { objectsFromQuerySnapshot } from './helpers';

const collection = 'user_requests';

export const getPendingSignatureRequestsAndAssignToUser = (
	inspectionId: string,
	email: string,
	id: string
) => {
	return firestore()
		.collection(collection)
		.where('inspection_id', '==', inspectionId)
		.where('type', '==', 'ADDITIONAL_SIGNATURE')
		.where('recipient_email', '==', email)
		.where('status', 'in', ['REQUEST_SENT', 'REQUEST_ACCEPTED'])
		.get()
		.then((res) => {
			const requests = objectsFromQuerySnapshot<Request>(res);
			const hasRequests = requests.length > 0;

			if (hasRequests) {
				requests.forEach((request) =>
					assignRequestInspectionToUser(request, id)
				);
			}
			return requests;
		})
		.catch(() => [] as Request[]);
};

export const getUserRequest = async (id: string): Promise<any> =>
	firebase
		.firestore()
		.collection('user_requests')
		.doc(id)
		.get()
		.then((doc) => doc.data());

export const assignRequestInspectionToUser = async (
	request: any,
	id: string
) => {
	await firebase
		.firestore()
		.collection('inspections')
		.doc(request.inspection_id)
		.get()
		.then((doc) => {
			const inspection = doc.data();
			if (!inspection || inspection.authorized_user_ids.includes(id)) {
				return;
			}
			const payload = {
				authorized_user_ids: [...inspection.authorized_user_ids, id],
			};
			return firebase
				.firestore()
				.collection('inspections')
				.doc(request.inspection_id)
				.update(payload);
		});

	return request;
};

export const reassignAndAcceptAllUnacceptedUserRequestsForEmail = async (
	request: any,
	email: string,
	authorizedId: string
) => {
	const requests = await firebase
		.firestore()
		.collection('user_requests')
		.where('recipient_email', '==', request.recipient_email)
		.where('status', '==', 'REQUEST_SENT')
		.get()
		.then((snapshot) => snapshot.docs.map((doc) => doc.data()));

	for (const request of requests) {
		const inspectionRef = firebase
			.firestore()
			.collection('inspections')
			.doc(request.inspection_id);
		const inspection = await inspectionRef.get().then((doc) => doc.data());

		if (!inspection || inspection.authorized_user_ids.includes(authorizedId)) {
			continue;
		}

		await inspectionRef.update({
			authorized_user_ids: [...inspection.authorized_user_ids, authorizedId],
		});
	}

	const batch = firebase.firestore().batch();

	for (const request of requests) {
		const requestRef = firebase
			.firestore()
			.collection('user_requests')
			.doc(request.id);
		batch.update(requestRef, {
			status: 'REQUEST_ACCEPTED',
			recipient_email: email,
		});
	}

	await batch.commit();
};

export const acceptUserRequest = (request: any) =>
	firebase
		.firestore()
		.collection('inspections')
		.doc(request.inspection_id)
		.update({ status: 'REQUEST_ACCEPTED' });

export const getPendingUserRequestsByEmailInspectionID = async (
	email: string,
	id: string
): Promise<any> =>
	firebase
		.firestore()
		.collection('user_requests')
		.where('inspection_id', '==', id)
		.where('recipient_email', '==', email)
		.get()
		.then((snapshot) =>
			snapshot.docs
				.map((doc) => doc.data())
				.filter((r) => ['REQUEST_SENT', 'REQUEST_ACCEPTED'].includes(r.status))
		)
		.catch((e) => {
			Sentry.captureException(e);
		});

export const updateUserRequest = async (
	id: string,
	payload: Object
): Promise<any> =>
	firebase.firestore().collection('user_requests').doc(id).update(payload);

export const respondToRequest = async (
	inspection: ApiInspection,
	signer: Profile,
	userRequests: Request[],
	note?: string,
	signatureImage?: string,
	signatureText?: string
) => {
	InspectionEventsApi.create(inspection.id, {
		type: 'inspection_signature_request_fulfilled',
		metadata: { signed_by: signer.user_name, note },
		shows_in_timeline: true,
	});

	const payloadUpdate = {
		status: 'REQUEST_FULFILLED',
	};

	for (const request of userRequests) {
		await updateUserRequest(request.id, payloadUpdate);
	}

	const signaturePayload: FirebaseCreatePayload<Signature> = {
		date: new Date() as unknown as Timestamp,
		inspection_id: inspection.id,
		platform: 'WEB',
		property_id: inspection.property.id,
		request_id: userRequests[0].id,
		signer_user_id: signer.id,
		type: 'ADDITIONAL_REQUEST',
		note,
	};

	if (signatureText) signaturePayload.signature_text = signatureText;

	await createSignature(signaturePayload, signatureImage);
};

const createSignature = async (
	payload: FirebaseCreatePayload<Signature>,
	signatureImage?: string
) => {
	const ref = await firebase.firestore().collection('signatures').doc();

	if (!signatureImage) {
		return ref.set({ ...payload, id: ref.id });
	}

	const signatureDataRef = firebase
		.storage()
		.ref()
		.child(`signatures/signature-${ref.id}.png`);

	await signatureDataRef.putString(signatureImage, `data_url`);
	const signatureDataURL = await signatureDataRef.getDownloadURL();

	await ref.set({ ...payload, storage_path: signatureDataURL, id: ref.id });
};
