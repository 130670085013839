import {
	FirebaseUpdatePayload,
	Profile,
	Property,
	Unit,
} from '@rentcheck/types';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import _ from 'lodash';
import { noCardinalRoomName, sortRooms } from 'utils/helpers';
import { objectsFromQuerySnapshot } from './helpers';

export interface DiffResult {
	roomName: string;
	operation: 'add' | 'remove';
}

const filterOutBuildingsAndCommunities = (unit: Unit) => {
	return !['Building', 'Community'].includes(unit.property_type);
};

export const getAll = async (profile: Profile): Promise<Unit[]> => {
	const fetchIds = [profile.id];

	if (profile.organizations && profile.organizations.length) {
		profile.organizations.forEach((o) => fetchIds.push(o.id));
	}

	const chunks = _.chunk(fetchIds, 10);

	return Promise.all(
		chunks.map((chunk) =>
			firebase
				.firestore()
				.collection('properties')
				.where('authorized_user_ids', 'array-contains-any', chunk)
				.get()
				.then((snapshot) =>
					objectsFromQuerySnapshot<Unit>(snapshot).filter(
						filterOutBuildingsAndCommunities
					)
				)
				.catch(() => [] as Unit[])
		)
	).then((results) => _.flatten(results));
};

export const getById = async (id: string): Promise<Property | undefined> => {
	return firebase
		.firestore()
		.collection('properties')
		.doc(id)
		.get()
		.then((doc: any) => doc.data())
		.catch(() => undefined);
};

export const getByIds = async (ids: string[]): Promise<Property[]> => {
	const properties: Property[] = [];

	const chunks = _.chunk(ids, 10);
	for (const ids_chunk of chunks) {
		await firebase
			.firestore()
			.collection('properties')
			.where('id', 'in', ids_chunk)
			.get()
			.then((snapshot) =>
				snapshot.forEach((doc) => properties.push(doc.data() as Property))
			)
			.catch(() => undefined);
	}

	return properties;
};

export const updateOrganization = async (
	propertyIds: string[],
	organizationId: string
) => {
	const fn = firebase
		.functions()
		.httpsCallable('modelCallableUpdateOrgForProperties');

	return fn({
		property_ids: propertyIds,
		organization_id: organizationId,
	}).then(() => getByIds(propertyIds));
};

export const assignUnitsToBuilding = async (
	unitIds: string[],
	buildingId: string
) => {
	const fn = firebase
		.functions()
		.httpsCallable('modelCallableAssignUnitsToBuilding');

	return fn({
		unit_ids: unitIds,
		building_id: buildingId,
	}).then(() => getByIds(unitIds));
};

export const updateRooms = async (
	propertyId: string,
	roomsDiff: DiffResult[]
) => {
	try {
		const property = await getById(propertyId);

		if (!property) {
			throw new Error('Property not found');
		}

		let updatedRooms = [...property.room_names];

		roomsDiff.forEach((roomDiff) => {
			if (roomDiff.operation === 'add') {
				if (updatedRooms.includes(roomDiff.roomName)) {
					return;
				}

				updatedRooms = [...updatedRooms, roomDiff.roomName];

				return;
			}

			if (roomDiff.operation === 'remove') {
				updatedRooms = updatedRooms.filter((r) => r !== roomDiff.roomName);
				return;
			}
		});

		updatedRooms = sortRooms(updatedRooms);

		await firebase
			.firestore()
			.collection('properties')
			.doc(propertyId)
			.update({
				room_names: updatedRooms,
				rooms: updatedRooms.map(noCardinalRoomName),
			});

		const updatedProperty = await getById(propertyId);

		if (!updatedProperty) {
			throw new Error('Property not found');
		}

		return updatedProperty;
	} catch (e: any) {
		Sentry.captureException(e);
		throw e;
	}
};

export const update = async (
	id: string,
	payload: FirebaseUpdatePayload<Property>
): Promise<Property | undefined> =>
	firebase
		.firestore()
		.collection('properties')
		.doc(id)
		.update(payload)
		.then(() => getById(id))
		.catch(() => undefined);
