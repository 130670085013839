import { LoadingButton } from '@mui/lab';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { Property } from '@rentcheck/types';
import { PropertiesApi } from 'api';
import { Column, SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	property: Property;
	refreshProperty: () => void;
}

const RestoreProperty = ({ property, refreshProperty }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [teamId, setTeamId] = useState('');

	const handleTransfer = () => {
		if (!property) {
			return;
		}

		setLoading(true);

		PropertiesApi.update(property.id, {
			authorized_user_ids: [teamId],
			organization_id: teamId,
		})
			.then(() => {
				refreshProperty();
				dispatch(SnackbarActions.showSuccess('Property Restored'));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card sx={{ mt: 2, width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Transfer Property</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<Column style={{ flex: 1 }}>
						<Typography>Transfer a property to a different team</Typography>

						<TextField
							value={teamId}
							variant="filled"
							label="Team ID"
							fullWidth
							sx={{ mt: 1 }}
							onChange={(e) => setTeamId(e.target.value)}
						/>
					</Column>

					<Spacer width={4} />

					<LoadingButton
						loading={loading}
						onClick={handleTransfer}
						sx={{ minWidth: 90, maxWidth: 90 }}>
						Transfer
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default RestoreProperty;
