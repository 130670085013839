import { Divider, Menu, MenuItem as MuiMenuItem } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signOut } from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	anchorEl: HTMLElement | null;
	setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

interface MenuItemProps {
	title: string;
	route: string;
	setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
	hidden?: boolean;
}

const MenuItem = ({ title, route, setAnchorEl, hidden }: MenuItemProps) => {
	const history = useHistory();

	const handleClick = () => {
		setAnchorEl(null);
		history.push(route);
	};

	if (hidden) {
		return null;
	}

	return <MuiMenuItem onClick={handleClick}>{title}</MuiMenuItem>;
};

export default ({ anchorEl, setAnchorEl }: Props) => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		handleClose();
		dispatch(signOut());
	};

	if (!authenticatedUser) {
		return null;
	}

	const userIsRenter = profile.user_type === 'Renter';

	return (
		<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
			<MenuItem
				title="Dashboard"
				route="/dashboard"
				setAnchorEl={setAnchorEl}
				hidden={userIsRenter}
			/>
			<MenuItem
				title="Properties"
				route="/properties"
				setAnchorEl={setAnchorEl}
			/>
			<MenuItem
				title="Inspections"
				route="/inspections"
				setAnchorEl={setAnchorEl}
			/>
			<MenuItem
				title="Maintenance"
				route="/maintenance"
				setAnchorEl={setAnchorEl}
			/>
			<MenuItem
				title="Residents"
				route="/residents"
				setAnchorEl={setAnchorEl}
				hidden={userIsRenter}
			/>
			<MenuItem
				title="Organizations"
				route="/organizations"
				setAnchorEl={setAnchorEl}
				hidden={!authenticatedUser.org_admin}
			/>

			<Divider sx={{ width: 200 }} />

			<MenuItem title="Account" route="/account" setAnchorEl={setAnchorEl} />
			<MenuItem
				title="Teams"
				route="/account/teams"
				setAnchorEl={setAnchorEl}
				hidden={userIsRenter}
			/>
			<MenuItem
				title="Teammates"
				route="/account/teammates"
				setAnchorEl={setAnchorEl}
				hidden={!Rules.Teammates.canAccess(profile, subscription)}
			/>
			<MenuItem
				title="Integrations"
				route="/account/integrations"
				setAnchorEl={setAnchorEl}
				hidden={userIsRenter}
			/>
			<MenuItem
				title="Sales"
				route="/account/sales"
				setAnchorEl={setAnchorEl}
				hidden={!authenticatedUser.internal_sales}
			/>
			<MenuItem
				title="Admin"
				route="/account/admin"
				setAnchorEl={setAnchorEl}
				hidden={!authenticatedUser.internal_admin}
			/>
			<MuiMenuItem onClick={handleLogout}>Logout</MuiMenuItem>
		</Menu>
	);
};
