import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortConfig, UnitsApi } from '@rentcheck/api-frontend';
import { Property } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';

import { Button, Typography } from '@mui/material';
import Modal from 'components/Common/Modal';
import Spacer from 'components/Spacer';
import IconButton from 'components/icon-button';
import { Column } from '../layout/Column';

interface Props {
	title?: string;
	subtitle?: string;
	handlePropSelected?: (property: Property | null) => void;
	closeOnSelection?: boolean;
	showAddNewProperty?: boolean;
	handleAddNewPropertyClicked?: () => void;
	showCancelButton?: boolean;
	handleCancelClicked?: () => void;
	showContinueButton?: boolean;
	handleContinueClicked?: (property: Property | null) => void;
	continueButtonLabel?: string;
	filterFunction?: (properties: Property[]) => Property[];
}

const SelectProperty = ({
	title,
	subtitle,
	handlePropSelected,
	closeOnSelection,
	showAddNewProperty,
	handleAddNewPropertyClicked,
	showCancelButton,
	handleCancelClicked,
	showContinueButton,
	handleContinueClicked,
	continueButtonLabel,
	filterFunction,
}: Props) => {
	const [searchValue, setSearchValue] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState<Property | null>(
		null
	);
	const [units, setUnits] = useState<Property[]>([]);

	useEffect(() => {
		const filters = {
			search: searchValue,
			teamId: [],
			dateRange: undefined,
			buildingId: [],
			inspectionType: [],
		};

		const sorting: SortConfig = {
			sortBy: 'address',
			sortType: 'asc',
		};

		UnitsApi.getAll(0, 2000, filters, sorting).then((result) =>
			setUnits(result.data as unknown as Property[])
		);
	}, [searchValue]);

	const lowerCaseSearch = searchValue.toLowerCase();

	const filteredProperties = filterFunction ? filterFunction(units) : units;

	const searchedProperties = filteredProperties
		? filteredProperties.filter((p) =>
				p.address.toLowerCase().includes(lowerCaseSearch)
			)
		: new Array<Property>();

	return (
		<Modal>
			{title && (
				<>
					<SpacedRow>
						<Column>
							<Typography variant="h5">{title}</Typography>
							<Spacer height={2} />
							<Typography>{subtitle}</Typography>
						</Column>
						<IconButton
							icon={regular('times')}
							onClick={() => handleCancelClicked?.()}
						/>
					</SpacedRow>

					<Spacer height={10} />
				</>
			)}

			<Wrapper
				id="select-property-wrapper"
				onClick={(e: any) => {
					if (e.target.id === 'select-property-wrapper') {
						setShowDropdown(false);
					}
				}}>
				{!showDropdown && (
					<SearchBarContainer
						onClick={() => {
							setSearchValue('');
							setShowDropdown(true);
						}}>
						<Typography color={selectedProperty ? colors.black : colors.gray}>
							{selectedProperty ? selectedProperty.address : 'Select Unit'}
						</Typography>
						<FontAwesomeIcon icon={solid('search')} color={colors.gray} />
					</SearchBarContainer>
				)}

				{showDropdown && (
					<DropdownAnchor>
						<DropdownContent>
							<AutocompleteRow key="text-input">
								<AddressInput
									autoFocus
									className="browser-default"
									type="text"
									placeholder="Select Unit"
									value={searchValue}
									onChange={(e) => setSearchValue(e.target.value)}
								/>
								<FontAwesomeIcon icon={solid('search')} color={colors.gray} />
							</AutocompleteRow>

							<Divider />
							{showAddNewProperty && (
								<AutocompleteRow
									key="add-property"
									onClick={() => {
										handleAddNewPropertyClicked &&
											handleAddNewPropertyClicked();
									}}>
									<Typography color={colors.primary}>
										+ Add New Property
									</Typography>
								</AutocompleteRow>
							)}
							<Scroll>
								{searchedProperties.map((p: Property) => (
									<AutocompleteRow
										key={p.id}
										onClick={() => {
											setSelectedProperty(p);
											setSearchValue(p.address);
											setShowDropdown(false);

											handlePropSelected && handlePropSelected(p);

											if (closeOnSelection && handleCancelClicked) {
												handleCancelClicked();
											}
										}}>
										<AutocompleteContent>
											<Typography>{p.address}</Typography>
										</AutocompleteContent>
									</AutocompleteRow>
								))}
							</Scroll>
						</DropdownContent>
					</DropdownAnchor>
				)}

				{(showCancelButton || showContinueButton) && (
					<>
						<Spacer height={15} />
						<SpacedRow>
							{showCancelButton && (
								<ButtonContainer left>
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => {
											handleCancelClicked && handleCancelClicked();
										}}>
										Cancel
									</Button>
								</ButtonContainer>
							)}
							{showContinueButton && (
								<ButtonContainer right>
									<Button
										disabled={!selectedProperty}
										onClick={() => {
											if (!selectedProperty) return;

											handleContinueClicked &&
												handleContinueClicked(selectedProperty);
										}}>
										{continueButtonLabel ?? 'Continue'}
									</Button>
								</ButtonContainer>
							)}
						</SpacedRow>
					</>
				)}
			</Wrapper>
		</Modal>
	);
};

const Wrapper = styled.div``;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;

	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const SearchBarContainer = styled(SpacedRow)`
	height: ${spacingPx(14)};
	padding: 0 ${spacingPx(6)} 0 ${spacingPx(6)};
	align-items: center;
	border: 1px solid #e6eef0;
	cursor: text;
`;

const DropdownAnchor = styled(Column)`
	height: ${spacingPx(14)};
	flex: 1;
`;

const AutocompleteRow = styled(Row)`
	height: ${spacingPx(14)};
	padding: 0 ${spacingPx(6)} 0 ${spacingPx(6)};
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`;

const AutocompleteContent = styled(Row)`
	flex: 1;
	height: 100%;
	border-top: 1px solid ${colors.contentBorder};
	align-items: center;
`;

const DropdownContent = styled(Column)`
	background-color: white;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	flex: 1;
	z-index: 1;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.contentBorder};
`;

const Scroll = styled.div`
	overflow: auto;
	height: ${spacingPx(56)};
`;

const AddressInput = styled.input`
	font-family: 'Poppins';
	font-size: 14px;
	height: ${spacingPx(10)};
	outline: none;
	font-weight: 500;
	line-height: 1.45;
	border: 0;
	flex: 1;
`;

export default SelectProperty;
