"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rentCheckSkills = void 0;
exports.rentCheckSkills = [
    {
        title: 'Access control systems',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Add new plumbing',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Apply pest control treatment',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Asbestos abatement',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Backflow testing',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Basement waterproofing',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Bathtub leak',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Broken electronic lock',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Broken smart lock',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Cabinet repairs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Cable',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'CCTV systems',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Change HVAC filter',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean appliances',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean carpet',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean coils',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean dryer vent',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean rain gutters',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean septic tank',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean solid flooring',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean tile and grout',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean unit (general)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean unit ventilation',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clean up crime scene',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clear drain',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Clear sewage backup',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Columns or structural beams',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Connect dryer vent',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Connect water supply',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Countertop repair/installation',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Deck installation',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Deck repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Demolition',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Door adjustments',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Door frame repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Door repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Drywall/sheetrock repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Electric',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Electrical inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exhaust fan repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterior siding',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate ant',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate bed bugs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate cockroaches',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate fleas',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate miscellaneous insects',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate rodents (mice/rats)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Exterminate spiders',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Extract water/sewage',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Fire alarm',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Fire alarm system (wired)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Fire extinguisher',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Fire sprinklers',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Foundation repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Fountains/cosmetic water fixtures',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Full frame replacement',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Gas',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Gas pressure',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General appliance work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General awning work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General cleaning work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General concrete/driveway/masonry work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General electrical work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General elevator work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General fence/gate work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General fireplace/chimney work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General flooring cleaning work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General flooring work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General garage door work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General glass/windows work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General home inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General HVAC',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General irrigation/landscaping work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General locksmith work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General maintenance',
        vendor: 'RentCheck',
        default: true,
        popular: false,
    },
    {
        title: 'General painting work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General pest control work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General plumbing work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General pool work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General remodeling',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General removal/hauling work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General roofing work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General snow work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'General solar panel',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Gutter repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Hang doors',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Hang mirrors/pictures',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Home photography',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'HVAC maintenance/inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Hydraulic door mechanism repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Identify/correct mold claims',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Insall mini split/ductless heat pump',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Inspect for bed bugs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install appliance',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install new ceiling fan',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install new GFI/GFCI outlet/s',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install new light fixtures (exterior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install new light fixtures (Interior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install new outlet/s',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install new switch/plugs/s',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install plumbing fixture',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install solar panels',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install/repair carpet',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install/repair linoleum flooring',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install/repair tile flooring',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install/repair wood flooring',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install/replace hardware',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Install/replace insulation',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Investigate cosmetic issues',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Jacuzzi / hot tub repairs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Keycard/keyfob access',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Leak inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Leak restoration',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Lock box removal',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Maintain garage doors',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Mold removal',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Move Furniture/other heavy items (same location)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Mow/cut grass',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Open access door (locked out)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Over the range microwave repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Paint (exterior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Paint (interior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Parking lot striping',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Pond maintenance',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Pour concrete',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Prepare unit for new residents (heavy remodel)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Prepare unit for new residents (simple work)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Pressure wash',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Prevention inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Provide access',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Re-key door locks',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remediation services',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove fallen tree limbs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove junk',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove snow (ground)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove Snow (roof/structure)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove snow large buildings (multi-family or business)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove trash (exterior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove wasps/bees',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Remove wildlife',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair A/C',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair baseboard heater',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair basement leaking',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair boiler',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair broken pipe',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair coin-operated dryer (clothing)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair coin-operated washer (clothing)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair dishwasher',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair dryer (clothing)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair duct work',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair external leaking',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair fire damage',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair fixed awning',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair furnace',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair garage door',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair garage door chains',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair garage door motor',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair garage door tracks',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair heater',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair hot water heater',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair internal leaking',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair mini split/ductless heat pump',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair oil lines',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair or replace countertop/standalone microwave',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair or replace spigot',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair radiator',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair refrigerator',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair retractable awning',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair roof (major)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair roof (minor)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair sink faucet',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair solar panels',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair standalone freezer',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair stovetop/oven',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair toilet',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair washing machine (clothing)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair window, evaporator, or portable A/C',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Install chain link fence/gate',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Install stone fence/gate',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Install wood fence/gate',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Install wrought iron fence/gate',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace bathroom exhaust fan',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace blinds or shades',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace bricks',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace capacitor',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace caulking/grout',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace ceiling fan',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace compressor',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace condenser coils',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace doorbell',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace evaporator coils',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace fan motor',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace garage door opener',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace garage door springs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace garbage disposal',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace Garbage Disposal',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace GFI/GFCI plugs or plug/switch',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace irrigation system',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace light fixtures (exterior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace light fixtures (Interior)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace outlet/s',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace photo-eye',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace range vent hood',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace shower head',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace shower rod',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace shower valve',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace smoke detector',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace sump pump',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace switch/plug/s',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/Replace weather stripping',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace window',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace window (2nd story+)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace window screens',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace/install mailbox (cluster)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace/Install mailbox (single)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Repair/replace/install retaining wall',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace breaker panel',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace burglar bars',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace dishwasher',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace double pane window glass',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace freon charge',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace garage door',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace garage door motor',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace garage door tracks',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace hot water heater',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace large tempered glass (doors)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace lightbulbs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace locks',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace roof',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace window glass',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace/program remotes',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Replace/repair breakers/fuses',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Restore power (entire residence)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Restore power (Partial)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Restore water (no water)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Roof leak inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Routine lawn maintenance',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Salt driveway/sidewalk',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Sauna repairs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Screen door repair/replacement',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Service fireplace (gas)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Service fireplace (wood)',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Service the pool',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Sewer',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Sheetrock replacement',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Shower door repair/replacement',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Shower/tub door repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Signage Repair/Removal',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Simple toilet repairs',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Sliding door repair/replacement',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Sliding door track repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Soffit repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Stain deck or other wood',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Stair railing repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Stair tread repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Striker adjustment',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Termite Inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Thermostat repair',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Tile',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Touch-up painting',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Towing',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Trash out',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Trash service',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Trim hedge',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Trim larger trees',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Tub resurfacing',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Water',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Water extraction',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Water pressure issues',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
    {
        title: 'Water softener inspection',
        vendor: 'RentCheck',
        default: false,
        popular: false,
    },
];
