import { Inspection } from '@rentcheck/types';
import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'Inspection Template';

export const FilterInspectionTemplateTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const inspectionTypesFilter = useTypedSelector(
		(state) => state.inspections.filters.inspectionTemplate
	);

	return (
		<FilterTitle
			label={title}
			isActive={inspectionTypesFilter.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

export const FilterInspectionTemplateDetails = ({
	selectedFilter,
}: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const inspectionTemplatesFilter = useTypedSelector(
		(state) => state.inspections.filters.inspectionTemplate
	);

	const templates = useTypedSelector(
		(state) => state.inspectionTemplates.templates
	);

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (
		inspectionTemplateId: Inspection['inspection_template']['id']
	) => {
		dispatch({
			type: 'TOGGLE_INSPECTION_TEMPLATE_FILTER',
			value: inspectionTemplateId,
		});
	};

	return (
		<>
			{templates.map((template) => (
				<FilterTitleOption
					key={template.id}
					label={template.name}
					isSelected={inspectionTemplatesFilter.includes(template.id)}
					onClick={() => handleClick(template.id)}
				/>
			))}
		</>
	);
};
