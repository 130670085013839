import { Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate, Feature } from '@rentcheck/types';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { ChipFilterType } from './types';

import { useUserSettings } from 'hooks/user-settings';
import List from './list';
import Review from './review';
import Skeleton from './skeleton';

interface Props {
	loading: boolean;
	inspection: ApiInspectionWithTemplate;
	chipFilter: ChipFilterType[];
	searchFilter: string;
}

const filterFeatures = (
	feature: Feature,
	chipFilters: ChipFilterType[],
	search: string
) => {
	const matchesSearch =
		`${feature.section.name_with_ordinal} ${feature.name} ${feature.notes}`
			.toLowerCase()
			.includes(search.toLowerCase());

	if (!matchesSearch) {
		return false;
	}

	if (chipFilters.length === 0) {
		return true;
	}

	const filterFunctions: Record<ChipFilterType, (f: Feature) => boolean> = {
		Good: (f) => f.rating === 'Good',
		Fair: (f) => f.rating === 'Fair',
		Poor: (f) => f.rating === 'Poor',
		NO: (f) => f.responses.includes('No'),
		'N/A': (f) => !!f.not_applicable,
		Notes: (f) => !!f.notes,
		'Media Required': (f) =>
			f.video_links?.length > 0 || f.image_links?.length > 0,
		'Edited by Reviewer': (f) => !!f.edited_by_reviewer,
		'': () => false,
	};

	for (const chip of chipFilters) {
		if (filterFunctions[chip](feature)) {
			return true;
		}
	}

	return false;
};

export default ({ loading, inspection, chipFilter, searchFilter }: Props) => {
	const { userSettings } = useUserSettings();

	const features = useTypedSelector((state) =>
		Utils.InspectionFeatures.sortFeaturesBySection(
			_.values(state.features[inspection.id] ?? {}),
			inspection.inspection_template
		)
	);

	const visibleFeatures = useMemo(
		() =>
			features.filter((f) =>
				Utils.InspectionTemplates.sectionShouldBeVisible(
					f.section,
					features,
					inspection.inspection_template
				)
			),
		[features, inspection.inspection_template]
	);

	if (loading) {
		return <Skeleton />;
	}

	const filteredFeatures = visibleFeatures.filter((f) =>
		filterFeatures(f, chipFilter, searchFilter)
	);

	if (!filteredFeatures.length) {
		return null;
	}

	if (userSettings.inspection_view_mode === 'list') {
		return <List inspection={inspection} features={filteredFeatures} />;
	}

	if (userSettings.inspection_view_mode === 'review') {
		return <Review inspection={inspection} features={filteredFeatures} />;
	}

	return null;
};
