import * as Sentry from '@sentry/react';
import fbConfig from 'config/fbConfig';
import Reactotron from 'config/reactotronConfig';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { getFirebase, reactReduxFirebase } from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import thunk from 'redux-thunk';
import rootReducer from 'store/reducers/rootReducer';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({
	dsn: 'https://63be41e081e5415587b303717c4e11dc@o398956.ingest.sentry.io/5379070',
	ignoreErrors: [
		'ResizeObserver loop completed with undelivered notifications.',
	],
});

var abbrs = {
	EST: 'Eastern Time',
	EDT: 'Eastern Time',
	CST: 'Central Time',
	CDT: 'Central Time',
	MST: 'Mountain Time',
	MDT: 'Mountain Time',
	PST: 'Pacific Time',
	PDT: 'Pacific Time',
};

moment.fn.zoneName = function () {
	var abbr = this.zoneAbbr();
	return abbrs[abbr] || abbr;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	composeEnhancers(
		applyMiddleware(
			thunk.withExtraArgument({ getFirebase, getFirestore })
			// logger
		),
		Reactotron.createEnhancer(),
		reduxFirestore(fbConfig),
		reactReduxFirebase(fbConfig, {
			useFirestoreForProfile: true,
			userProfile: 'users',
			attachAuthIsReady: true,
		})
	)
);

store.firebaseAuthIsReady.then(() => {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
});
