import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PropertiesApi } from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';
import { DialogTitle } from 'components';
import RecipientsList, { Recipient } from 'components/recipients-list';
import { useEditRecipientsModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import {
	InspectionActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';
import { useIsMobileBreakpoint } from 'utils/hooks';
import Skeleton from './skeleton';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const modalData = useEditRecipientsModalData();
	const inspection = modalData?.metadata?.inspection;
	const onSuccess = modalData?.metadata?.onSuccess;

	const [properties, setProperties] = useState<APIProperty[]>([]);
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setRecipients([]);
		setProperties([]);
	}, [open]);

	useEffect(() => {
		if (!inspection) return;

		setLoading(true);

		PropertiesApi.getById(inspection.property.id)
			.then((data) => setProperties([data]))
			.finally(() => setLoading(false));

		setRecipients(inspection.assigned_recipients.emails ?? []);
	}, [inspection]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleUpdate = async () => {
		handleClose();

		const progress = await dispatch(
			SnackbarActions.showProgress('Updating inspection')
		);

		dispatch(
			InspectionActions.updateInspectionRecipients(
				inspection,
				inspection.assigned_recipients.type,
				recipients
			)
		)
			.then(() => {
				dispatch(
					SnackbarActions.showSuccess(
						'Inspection recipients updated successfully'
					)
				);

				onSuccess?.();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => dispatch(SnackbarActions.hideSnackbar(progress.id)));
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Edit Recipients"
				icon={solid('users')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Typography>
					Any recipients that have been invited to complete the inspection are
					listed below. Click the X to remove the email from this inspection, or
					use the form below to add more recipients. Add multiple email
					addresses by hitting Enter or adding a comma after each email address.
				</Typography>

				{(!inspection || loading) && <Skeleton />}

				{!!inspection && !loading && (
					<RecipientsList
						properties={properties}
						value={recipients}
						setValue={setRecipients}
					/>
				)}
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<Button id="button-confirm" variant="contained" onClick={handleUpdate}>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
};
