import { Accordion } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { requestProperties } from 'store/actions/unitsAction';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import Content from '../common/content';
import Header from '../common/header';

import { Permissions } from '@rentcheck/biz';
import ActiveIntegration from './active';
import InactiveIntegration from './inactive';

const headerProps = {
	icon: integrations.rentManager,
	title: 'Rent Manager',
	body: 'Import and sync property and resident data from Rent Manager to RentCheck. Send completed inspection reports back to Rent Manager.',
	body2: 'Available as an to a RentCheck subscription.',
	route: '/account/integrations/rentmanager',
};

const RentManager = () => {
	const dispatch: Dispatch = useDispatch();
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);

	const expanded = location.pathname.startsWith(headerProps.route);

	const active = Permissions.Integrations.RentManager.canInstall(subscription);

	useEffect(() => {
		if (!expanded) {
			return;
		}

		dispatch(requestProperties());
	}, [expanded]);

	return (
		<>
			<Accordion expanded={expanded}>
				<Header {...headerProps} active={active} />
				<Content>
					{active && <ActiveIntegration />}
					{!active && <InactiveIntegration />}
				</Content>
			</Accordion>
		</>
	);
};

export default RentManager;
