import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import _ from 'lodash';
import { Dialog, DialogContent } from '@mui/material';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { TemplateFeature, TemplateSection } from '@rentcheck/types';

import assets from 'assets';
import { Dispatch } from 'types';
import { Column, Row } from 'components';
import { useStoreInspectionTemplate } from 'hooks/templates';
import { useInspectionTemplateModalData } from 'hooks/modals';
import {
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';

import { useTypedSelector } from 'store/reducers/rootReducer';
import { useIsMobileBreakpoint } from 'utils/hooks';

import Header from './header';
import Sections from './sections';
import Features from './features';
import {
	InitializedTemplateSection,
	initializeSections,
	sectionsHaveChanged,
} from './helpers/sections';

interface Props {
	open: boolean;
	loading: boolean;
}

export default ({ open, loading }: Props) => {
	const history = useHistory();
	const dispatch: Dispatch = useDispatch();

	const isSmallScreen = useIsMobileBreakpoint('sm');
	const isMediumScreen = useIsMobileBreakpoint('lg');

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const modalData = useInspectionTemplateModalData();
	const editMode = modalData?.metadata?.editMode;
	const templateDigest = modalData?.metadata?.template;

	const template = useStoreInspectionTemplate(templateDigest?.id);

	const [editing, setEditing] = useState(false);
	const [feature, setFeature] = useState<TemplateFeature>();
	const [section, setSection] = useState<TemplateSection>();
	const [sections, setSections] = useState<InitializedTemplateSection[]>([]);

	useEffect(() => {
		if (open) {
			return;
		}

		setEditing(false);
		setSections([]);
		setSection(undefined);
		setFeature(undefined);
	}, [open]);

	useEffect(() => {
		if (!template) {
			return;
		}

		const sections = initializeSections(template, accountSettings);

		setSections(sections);

		if (!isSmallScreen) setSection(sections[0]);

		setEditing(editMode ?? false);
	}, [template?.id, open]);

	useEffect(() => {
		if (!section) {
			return;
		}

		setSection(sections.find((s) => s.id === section.id));
	}, [sections]);

	const validateSections = () => {
		if (sections.some((s) => !s.name && s.features.length > 0)) {
			dispatch(
				SnackbarActions.showError(
					'The template contains invalid sections, please fix the errors and try again.'
				)
			);

			return false;
		}

		if (_.uniqBy(sections, 'name').length !== sections.length) {
			dispatch(
				SnackbarActions.showError(
					'The template contains invalid sections, please fix the errors and try again.'
				)
			);

			return false;
		}

		return true;
	};

	const handleSave = async () => {
		if (!template) {
			return false;
		}

		if (!validateSections()) {
			return false;
		}

		const parsedSections = sections.map((s) => {
			delete s.isNewSection;
			return s;
		});

		await dispatch(
			InspectionTemplatesActions.updateSections(template.id, {
				sections: parsedSections,
			})
		);

		return true;
	};

	const showUnsavedChangesModal = (onConfirm: () => void) => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Unsaved Changes',
				icon: solid('save'),
				body1: [
					'You have unsaved changes. Do you want to leave without saving?',
				],
				buttons: [
					{
						title: 'Exit Without Saving',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
							onConfirm();
						},
					},
					{
						title: 'Go Back',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
				],
			})
		);
	};

	const handleClose = () => {
		if (!editing || !sectionsHaveChanged(sections, template?.sections ?? [])) {
			history.replace('/account/inspection-templates');
			dispatch(ModalFlowActions.closeCurrentModal());

			return;
		}

		showUnsavedChangesModal(() => {
			history.replace('/account/inspection-templates');

			return dispatch(ModalFlowActions.closeCurrentModal());
		});
	};

	const handleConfirmCopy = () => {
		if (!template) {
			return;
		}

		dispatch(ModalFlowActions.closeCurrentModal());
		dispatch(
			ModalFlowActions.showCreateInspectionTemplateModal({
				mode: template.property_type,
				baseTemplate: template,
			})
		);
	};

	const handleCopy = () => {
		if (!editing || !sectionsHaveChanged(sections, template?.sections ?? [])) {
			handleConfirmCopy();
			return;
		}

		showUnsavedChangesModal(handleConfirmCopy);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen>
			<Header
				template={template}
				sections={sections}
				editing={editing}
				setEditing={setEditing}
				onSave={handleSave}
				onClose={handleClose}
				onCopy={handleCopy}
			/>
			{!loading && (
				<DialogContent sx={{ padding: '0px !important' }}>
					<Row style={{ height: '100%' }}>
						<Sections
							currentSection={section}
							setCurrentSection={setSection}
							currentFeature={feature}
							sections={sections}
							setSections={setSections}
							editing={editing}
						/>
						{section && (
							<Features
								isSmallScreen={isSmallScreen}
								isMediumScreen={isMediumScreen}
								section={section}
								setSection={setSection}
								currentFeature={feature}
								setCurrentFeature={setFeature}
								sections={sections}
								setSections={setSections}
								editing={editing}
							/>
						)}

						{!sections.length && (
							<EmptySectionsContainer>
								<img
									src={assets.inspectionTemplates.dogPainting}
									alt="Empty State"
									style={{ maxHeight: '60vh', maxWidth: '80%' }}
								/>
							</EmptySectionsContainer>
						)}
					</Row>
				</DialogContent>
			)}
		</Dialog>
	);
};

const EmptySectionsContainer = styled(Column)`
	width: 80%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: clip;

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 1199px) {
		width: 50%;
	}

	@media (max-width: 599px) {
		width: 0px;
	}
`;
