"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultAccountSettings = exports.DefaultInspectionReportTemplate = void 0;
exports.DefaultInspectionReportTemplate = {
    name: 'default',
    summary_section: {
        section_active: true,
        resident_info: false,
        summary_page: true,
        table_of_contents: true,
        inspection_label: true,
        summary_note: false,
    },
    inspection_features_section: {
        section_active: true,
        questions_answers: true,
        images: true,
        notes: true,
        ratings: true,
    },
    maintenance_flags_section: {
        section_active: true,
        owner_flags: {
            section_active: false,
            cost: false,
            notes: false,
            internal_notes: false,
        },
        uncategorized_flags: {
            section_active: false,
            cost: false,
            notes: false,
            internal_notes: false,
        },
        resident_flags: {
            section_active: false,
            cost: false,
            notes: false,
            internal_notes: false,
        },
    },
};
exports.defaultAccountSettings = {
    rooms: {
        unit: [],
        building: [],
        community: [],
    },
    is_foxy_ai_enabled: false,
    is_maintenance_flag_default_photos_enabled: false,
    is_resident_reported_maintenance_enabled: false,
    inspection_report_templates: { default: exports.DefaultInspectionReportTemplate },
    inspection_contact_info: {},
};
