import { UnitsApi } from '@rentcheck/api-frontend';
import * as Sentry from '@sentry/react';
import {
	State as FilterState,
	initialState,
} from 'store/reducers/properties/filters/units';
import { ReduxFn } from 'types';

import * as ModalFlowActions from '../modal-flows';
import * as SnackbarActions from '../snackbar-actions';

export const pageSize = 20;

export const getAll = (
	offset: number = 0,
	filterState: FilterState = initialState
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const pageNumber = Math.floor(offset / pageSize);

		const filters = {
			search: filterState.search,
			teamId: filterState.team,
			buildingId: filterState.building,
			inspectionType: filterState.inspectionTemplate,
			dateRange: filterState.dateRange?.start
				? filterState.dateRange
				: undefined,
		};

		const sorting = {
			sortBy: filterState.sortBy,
			sortType: filterState.sortType,
		};

		dispatch({ type: 'SET_LOADING_UNITS', loading: true });

		const { data, totalResults } = await UnitsApi.getAll(
			pageNumber,
			pageSize,
			filters,
			sorting
		);

		const dispatchType =
			offset === 0 ? 'FETCHED_INITIAL_UNITS' : 'FETCHED_UNITS_PAGE';

		dispatch({ type: dispatchType, properties: data as any[] });
		dispatch({ type: 'SET_TOTAL_UNITS', value: totalResults });
		dispatch({ type: 'SET_LOADING_UNITS', loading: false });

		return data;
	};

	return fn;
};

export const deleteObjects = (ids: string[]): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const numberofUnits = `${ids.length} unit${ids.length === 1 ? '' : 's'}`;

			dispatch(ModalFlowActions.closeConfirmationModal());

			const progressSnackbar = await dispatch(
				SnackbarActions.showProgress(`Deleting ${numberofUnits}`)
			);

			await UnitsApi.deleteObjects(ids);
			dispatch({ type: 'DELETE_UNITS', ids });

			dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));

			dispatch(
				SnackbarActions.showSuccess(`Deleted ${numberofUnits} successfully!`)
			);
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	};

	return fn;
};
