import { ApiInspection, Property } from '@rentcheck/types';
import React, { useState } from 'react';
import Modal from '../../../Common/Modal';
import InspectionsToCompare from './InspectionsToCompare';
import SelectInspection from './SelectInspection';

interface Props {
	property: Property;
	inspections: ApiInspection[];
	inspection?: ApiInspection;
	setModalVisible: (modalVisible: boolean) => void;
}

const CreateInspectionComparisonModal = ({
	property,
	inspections,
	inspection,
	setModalVisible,
}: Props) => {
	const [firstInspection, setFirstInspection] = useState(inspection);
	const [secondInspection, setSecondInspection] = useState<ApiInspection>();

	const [currentStep, setCurrentStep] = React.useState(
		'inspections_to_compare'
	);

	const renderContent = () => {
		if (currentStep === 'inspections_to_compare') {
			return (
				<InspectionsToCompare
					property={property}
					firstInspection={firstInspection}
					secondInspection={secondInspection}
					setCurrentStepOne={() => setCurrentStep('select_inspection_1')}
					setCurrentStepTwo={() => setCurrentStep('select_inspection_2')}
					setModalVisible={setModalVisible}
				/>
			);
		}
		if (currentStep === 'select_inspection_1') {
			return (
				<SelectInspection
					inspections={inspections}
					setConfirmedInspection={setFirstInspection}
					firstInspection={firstInspection}
					secondInspection={secondInspection}
					currentStep={'select_inspection_1'}
					setCurrentStep={() => setCurrentStep('inspections_to_compare')}
				/>
			);
		}
		if (currentStep === 'select_inspection_2') {
			return (
				<SelectInspection
					inspections={inspections}
					setConfirmedInspection={setSecondInspection}
					firstInspection={firstInspection}
					secondInspection={secondInspection}
					currentStep={'select_inspection_2'}
					setCurrentStep={() => setCurrentStep('inspections_to_compare')}
				/>
			);
		}
	};

	return <Modal>{renderContent()}</Modal>;
};

export default CreateInspectionComparisonModal;
