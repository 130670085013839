"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sectionShouldBeVisible = exports.getQuestionIdsAttachedToLogic = void 0;
const types_1 = require("@rentcheck/types");
const lodash_1 = __importStar(require("lodash"));
const common_1 = require("./common");
const getQuestionIdsAttachedToLogic = (inspectionTemplate) => inspectionTemplate.sections
    .map((section) => section.logic)
    .flat()
    .filter((logic) => logic.trigger_type === 'question')
    .map((l) => l.trigger_id);
exports.getQuestionIdsAttachedToLogic = getQuestionIdsAttachedToLogic;
const sectionShouldBeVisible = (section, otherFeatures, template) => {
    if (section.name === 'Set Up Section') {
        return true;
    }
    const templateSection = template.sections.find((s) => ((0, common_1.parseTemplateRoomName)(s.name) === section.name ||
        s.name === section.name) &&
        s.type === section.type);
    if (!templateSection) {
        return true;
    }
    if (!templateSection.logic.length) {
        return true;
    }
    /**
     * If section has logic we need to find at least one trigger
     * that allows it to show
     */
    return templateSection.logic.some((condition) => {
        if (condition.trigger_type !== 'question') {
            return false;
        }
        const featureWithQuestion = otherFeatures.find((f) => f.questions.some((q) => q.id === condition.trigger_id));
        if (!featureWithQuestion) {
            return false;
        }
        const questionIndex = featureWithQuestion === null || featureWithQuestion === void 0 ? void 0 : featureWithQuestion.questions.findIndex((q) => q.id === condition.trigger_id);
        /**
         * Split first if it's multiple choice
         * We lowercase because yes/no questions have been used for a long
         * time and we're not sure if the case is enforced there
         */
        const conditionValue = condition.condition_value.map(lodash_1.lowerCase);
        const response = featureWithQuestion.responses[questionIndex]
            .split(types_1.MULTIPLE_CHOICE_RESPONSE_SEPARATOR)
            .map(lodash_1.lowerCase);
        return lodash_1.default.intersection(conditionValue, response).length > 0;
    });
};
exports.sectionShouldBeVisible = sectionShouldBeVisible;
