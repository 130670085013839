import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FileRequestApi } from '@rentcheck/api-frontend';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

type Variant =
	| 'inspections'
	| 'units'
	| 'buildings'
	| 'communities'
	| 'maintenance reports';

interface Props {
	variant: Variant;
}

const FilterButton = ({ variant }: Props) => {
	const dispatch = useDispatch();

	const ref = useRef<IconButtonRef>(null);

	const [loading, setLoading] = useState(false);

	const inspectionFilters = useTypedSelector(
		(state) => state.inspections.filters
	);

	const unitsFilters = useTypedSelector(
		(state) => state.properties.filters.units
	);

	const buildingsFilters = useTypedSelector(
		(state) => state.properties.filters.buildings
	);

	const communitiesFilters = useTypedSelector(
		(state) => state.properties.filters.communities
	);

	const maintenanceReportsFilters = useTypedSelector(
		(state) => state.maintenance.filters.reports
	);

	const exportData: Record<Variant, any> = {
		inspections: {
			exportType: 'INSPECTION_LIST_EXPORT',
			filters: inspectionFilters,
		},
		units: {
			exportType: 'UNITS_LIST_EXPORT',
			filters: unitsFilters,
		},
		buildings: {
			exportType: 'BUILDINGS_LIST_EXPORT',
			filters: buildingsFilters,
		},
		communities: {
			exportType: 'COMMUNITIES_LIST_EXPORT',
			filters: communitiesFilters,
		},
		'maintenance reports': {
			exportType: 'MAINTENANCE_REPORTS_LIST_EXPORT',
			filters: maintenanceReportsFilters,
		},
	};

	const handleDownloadCSV = (detailed: boolean) => {
		ref.current?.close();

		setLoading(true);

		const successMessage =
			'You should receive an email with a link to download the report shortly.';

		FileRequestApi.create(exportData[variant].exportType, '', 'CSV', {
			...exportData[variant].filters,
			detailed,
		})
			.then(() => dispatch(SnackbarActions.showSuccess(successMessage)))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleAll = () => {
		handleDownloadCSV(true);
	};

	return (
		<IconButton
			ref={ref}
			loading={loading}
			tooltip="Export to CSV"
			badgeCount={0}
			icon={regular('arrow-to-bottom')}
			onClick={handleAll}
		/>
	);
};

export default FilterButton;
