import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { createSx } from './create-sx';

const ChipArchived = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);
	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
		dispatch({
			type: 'SET_INSPECTION_ASSIGNED_TO_FILTER',
			value: [authenticatedUser.id],
		});
	};

	const selected = !!filters.assignedTo?.length;

	return (
		<Chip
			variant="outlined"
			icon={<FontAwesomeIcon icon={solid('user-circle')} />}
			label="Assigned to me"
			onClick={handleClick}
			sx={createSx(selected, '#757CF0', '#F6F5FF', '#E9E8FD')}
		/>
	);
};

export default ChipArchived;
