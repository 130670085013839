import { Column, Row } from 'components';
import { FilterType } from 'components/lists/filters';
import FilterButton from 'components/table/filter-button';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appliedFiltersCount } from 'store/reducers/inspections/filters';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import * as theme from 'theme';

import { InspectionTemplatesActions } from 'store/actions';
import {
	FilterCompletedDateDetails,
	FilterCompletedDateTitle,
} from './filter-completed-date';
import { FilterDueDateDetails, FilterDueDateTitle } from './filter-due-date';
import { FilterRatingDetails, FilterRatingTitle } from './filter-has-ratings';
import {
	FilterInspectionStatusDetails,
	FilterInspectionStatusTitle,
} from './filter-inspection-status';
import {
	FilterInspectionTemplateDetails,
	FilterInspectionTemplateTitle,
} from './filter-inspection-template';
import { FilterTeamDetails, FilterTeamTitle } from './filter-team';

export default () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const filters = useTypedSelector((state) => state.inspections.filters);

	const [selectedFilter, setSelectedFilter] = useState<FilterType | undefined>(
		'Due Date'
	);

	useEffect(() => {
		if (!profile) return;

		dispatch(InspectionTemplatesActions.getAll(true));
	}, [profile]);

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
	};

	return (
		<FilterButton
			resetFilters={handleResetFilters}
			badgeCount={appliedFiltersCount(filters)}>
			<Row style={{ alignItems: 'flex-start' }}>
				<FilterTitles>
					<FilterDueDateTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterCompletedDateTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterInspectionStatusTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterRatingTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterInspectionTemplateTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterTeamTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
				</FilterTitles>

				<FilterDetails>
					<FilterDueDateDetails selectedFilter={selectedFilter} />
					<FilterCompletedDateDetails selectedFilter={selectedFilter} />
					<FilterInspectionStatusDetails selectedFilter={selectedFilter} />
					<FilterRatingDetails selectedFilter={selectedFilter} />
					<FilterInspectionTemplateDetails selectedFilter={selectedFilter} />
					<FilterTeamDetails selectedFilter={selectedFilter} />
				</FilterDetails>
			</Row>
		</FilterButton>
	);
};

const FilterTitles = styled(Column)`
	flex: 1;
	padding: ${theme.spacingPx(2)} 0;
	border-right: 1px solid ${theme.colors.lightGray};
`;

const FilterDetails = styled(Column)`
	flex: 1;
	margin-left: -1px;
	border-left: 1px solid ${theme.colors.lightGray};
	padding: ${theme.spacingPx(2)} 0;
`;
