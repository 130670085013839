import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { ApiInspection, Profile, Request } from '@rentcheck/types';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import { UserRequestsApi, UsersApi } from 'api';
import { DialogTitle } from 'components';
import Signature, {
	DrawnSignature,
	SignatureRef,
	TextSignature,
} from 'components/signature';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import Note from './note';

interface Props {
	inspection: ApiInspection;
}

const Sign = ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const signatureRef = useRef<SignatureRef>(null);

	const [signatureRequests, setSignatureRequests] = useState<Request[]>([]);
	const [requester, setRequester] = useState<Profile>();
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [note, setNote] = useState('');

	useEffect(() => {
		if (!profile) {
			return;
		}

		UserRequestsApi.getPendingSignatureRequestsAndAssignToUser(
			inspection.id,
			profile.email,
			profile.id
		).then(setSignatureRequests);
	}, [inspection, profile]);

	useEffect(() => {
		if (!signatureRequests.length) return;

		UsersApi.getById(signatureRequests[0].sender_user_id).then(setRequester);
	});

	const handleClose = () => {
		setModalOpen(false);
	};

	const handleSign = () => {
		setLoading(true);

		const signature = signatureRef.current?.getSignature();

		if (!signature) {
			return dispatch(
				SnackbarActions.showError(
					'Please sign the inspection before approving it'
				)
			);
		}

		dispatch(
			InspectionActions.signInspection(
				inspection,
				profile,
				signatureRequests,
				note,
				(signature as DrawnSignature).data,
				(signature as TextSignature).text
			)
		)
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (!signatureRequests.length) return null;

	return (
		<>
			<Button sx={{ marginLeft: 2 }} onClick={() => setModalOpen(true)}>
				Sign
			</Button>

			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle
					title="Sign Inspection"
					icon={solid('pen-field')}
					onClose={handleClose}
				/>

				<DialogContent>
					<Signature ref={signatureRef} />
					<Note note={note} setNote={setNote} requester={requester} />
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} variant="text" color="secondary">
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						onClick={handleSign}
						loading={loading}>
						Sign
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Sign;
