import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { TemplateFeature, TemplateFeatureLogic } from '@rentcheck/types';

import { DialogTitle } from 'components';
import { SnackbarActions } from 'store/actions';

import LogicCard from '../logic-card/bulk';

interface Props {
	features: TemplateFeature[];
	onUpdate: (features: TemplateFeature[]) => void;
	open: boolean;
	onClose: () => void;
}

export interface BulkTemplateFeatureLogic extends TemplateFeatureLogic {
	features: TemplateFeature[];
}

export default ({ features, onUpdate, open, onClose }: Props) => {
	const dispatch = useDispatch();

	const newLogicCondition: BulkTemplateFeatureLogic = {
		id: uuid(),
		trigger_id: '',
		trigger_type: 'question',
		condition: 'is-any-of',
		action: 'show',
		condition_value: [],
		features: [],
	};

	const [logicCondition, setLogicCondition] =
		useState<BulkTemplateFeatureLogic>(newLogicCondition);

	useEffect(() => {
		setLogicCondition(newLogicCondition);
	}, [open]);

	const handleSave = () => {
		onClose();

		const logicWithoutExtraKeys: TemplateFeatureLogic = _.omit(
			logicCondition,
			'features'
		);

		const updatedFeatures = features.map((f) => {
			/**
			 * If bulk update doesn't affect this feature return as is
			 */
			if (!logicCondition.features.some((lcf) => lcf.id === f.id)) {
				return f;
			}

			/**
			 * If bulk update affects this feature, add the new logic condition
			 * to the feature and set the id to a new uuid to ensure uniqueness
			 */
			return {
				...f,
				logic: [...f.logic, { ...logicWithoutExtraKeys, id: uuid() }],
			};
		});

		onUpdate(updatedFeatures);
		dispatch(SnackbarActions.showSuccess('Success! Logic saved.'));
	};

	const hasInvalidLogic =
		!logicCondition.trigger_id ||
		!logicCondition.condition_value.length ||
		!logicCondition.features.length;

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				onClose={onClose}
				title="Feature Logic"
				bottomSubtitle="Add logic to multiple features at once"
				icon={regular('split')}
			/>
			<DialogContent sx={{ minHeight: '60vh', backgroundColor: '#F6F5FF' }}>
				<LogicCard
					logic={logicCondition}
					onUpdate={setLogicCondition}
					features={features}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={handleSave} disabled={hasInvalidLogic}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
