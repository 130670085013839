import { UsersApi as UsersApiFrontend } from '@rentcheck/api-frontend';
import { UsersApi } from 'api';
import { isEmbeddedInMobileApp, postMessageToNativeApp } from 'utils/helpers';

import { EmailSuggestion } from '@rentcheck/api-frontend/dist/users/post';
import { FirebaseUpdatePayload, Profile } from '@rentcheck/types';
import firebase from 'firebase';
import { ReduxFn } from 'types';
import {
	RENTCHECK_INSPECTION_FILTERS,
	RENTCHECK_INSPECTION_GLOBAL_FILTERS,
	RENTCHECK_PROPERTY_FILTERS,
	RENTCHECK_PROPERTY_GLOBAL_FILTERS,
} from '../../constants';
import { Analytics } from '../../utils';
import * as SnackbarActions from './snackbar-actions';
import { showError, showSuccess } from './snackbar-actions';
import * as TeaminvitesActions from './team-invites';

const DOWNLOAD_RENTCHECK_URL =
	'https://www.getrentcheck.com/download-rentcheck';

// Redirect users not allowed on the web page to the download app page.
const signOutAndRedirect = () => {
	const fn: ReduxFn = async (dispatch) => {
		return dispatch(signOut(DOWNLOAD_RENTCHECK_URL));
	};

	return fn;
};

export const reAuthenticateUser = (email: string, password: string) => {
	const fn: ReduxFn = async () => {
		const user = firebase.auth().currentUser;

		if (!user) {
			throw new Error('Current user not found');
		}

		const cred = firebase.auth.EmailAuthProvider.credential(email, password);
		return user?.reauthenticateWithCredential(cred).then(() => user);
	};

	return fn;
};

export const updateEmail = (email: string) => {
	const fn: ReduxFn = async (dispatch) => {
		const user = firebase.auth().currentUser;

		if (!user) {
			throw new Error('Current user not found');
		}

		await user.updateEmail(email);

		await firebase
			.firestore()
			.collection('users')
			.doc(user.uid)
			.update({ email });

		const profile = await UsersApi.getById(user.uid);

		dispatch({ type: 'EMAIL_UPDATED', email });
		dispatch({ type: 'UPDATE_USER', profile });
	};

	return fn;
};

export const resetPassword = (email: string) => {
	const fn: ReduxFn = async (dispatch) => {
		firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then(function () {
				// Email sent.
				dispatch({ type: 'PASSWORD_RESET_SENT', email });
				dispatch(showSuccess('Reset email sent. Please check your email.'));
			})
			.catch((e) => {
				dispatch({ type: 'PASSWORD_RESET_FAILED', e });
				dispatch(showError(`Reset password failed: ${e.message}`));
			});
	};

	return fn;
};

const needsToSignOutRenter = (profile?: Profile) => {
	return profile?.user_type === 'Renter' && !isEmbeddedInMobileApp();
};

const updateProfileAfterSignIn = (userId?: string, password?: string) => {
	const fn: ReduxFn = async (dispatch) => {
		if (!userId) {
			throw new Error('Current user not found');
		}

		const profile = await UsersApi.getById(userId).then((user) => {
			// If the user doesn't have a temporary password or if the
			// temporary password is the same as the one the user input
			// then return the user.
			if (!user?.temporary_password) {
				return user;
			}

			if (user.temporary_password === password) {
				return user;
			}

			// If the temporary password is different than the one the user input
			// delete the temporary password and return the updated user
			return UsersApi.update(userId, {
				temporary_password: firebase.firestore.FieldValue.delete(),
			});
		});

		if (!profile) {
			throw new Error('User not found');
		}

		if (isEmbeddedInMobileApp()) {
			postMessageToNativeApp({ type: 'signed-in' });
		}

		if (needsToSignOutRenter(profile)) {
			return dispatch(signOutAndRedirect());
		}

		dispatch(TeaminvitesActions.acceptTeamInvites());

		dispatch({ type: 'LOGIN_SUCCESS', profile });
	};

	return fn;
};

export const signInWithToken = (token: string) => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const userId = await firebase
				.auth()
				.signInWithCustomToken(token)
				.then((res) => res.user?.uid);

			dispatch(updateProfileAfterSignIn(userId));
		} catch (e) {
			dispatch(showError(`Login failed: ${(e as Error).message}`));
		}
	};

	return fn;
};

export const signIn = (email: string, password: string) => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const userId = await firebase
				.auth()
				.signInWithEmailAndPassword(email, password)
				.then((res) => res.user?.uid);

			dispatch(updateProfileAfterSignIn(userId, password));
		} catch (e) {
			dispatch(showError(`Login failed: ${(e as Error).message}`));
		}
	};

	return fn;
};

export const signOut = (redirectUrl?: string) => {
	const fn: ReduxFn = async (dispatch) => {
		localStorage.removeItem(RENTCHECK_INSPECTION_FILTERS);
		localStorage.removeItem(RENTCHECK_INSPECTION_GLOBAL_FILTERS);

		localStorage.removeItem(RENTCHECK_PROPERTY_FILTERS);
		localStorage.removeItem(RENTCHECK_PROPERTY_GLOBAL_FILTERS);

		dispatch({ type: 'SIGNOUT' });
		dispatch({ type: 'SIGNOUT_SUCCESS' });

		try {
			await firebase
				.auth()
				.signOut()
				.then(() => {
					if (redirectUrl) {
						window.location.href = redirectUrl;
					}
				});
		} catch (e) {
			dispatch(SnackbarActions.showError(e as Error));
		}
	};

	return fn;
};

export type AdditionalScreenErrors =
	| 'email'
	| 'length'
	| 'invalid'
	| 'first'
	| 'last'
	| '';

export const signUp = (params: {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	userType: Profile['user_type'];
	totalProperties: number;
	company: string;
	phoneNumber: string;
	bypassEmailValidation: boolean;
	setEmailSuggestion: (suggestion: string) => void;
	setBypassEmailValidation: (bypass: boolean) => void;
	setInitialScreenError: (error: AdditionalScreenErrors) => void;
}) => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const lowerCasedEmail = params.email.toLowerCase();
			const first_name = params.firstName.trim();
			const last_name = params.lastName.trim();
			const user_name = `${first_name} ${last_name}`;

			const signUpResponse = await UsersApiFrontend.signUp({
				email: lowerCasedEmail,
				password: params.password,
				first_name,
				last_name,
				user_type: params.userType,
				total_properties: params.totalProperties,
				bypass_email_validation: params.bypassEmailValidation,
			});

			const emailSuggestion = signUpResponse as EmailSuggestion;

			if (emailSuggestion.message === 'email_suggestion') {
				params.setEmailSuggestion(emailSuggestion.suggestion);
				params.setBypassEmailValidation(true);

				return;
			}

			Analytics.trackEvent('user sign up', {
				email: lowerCasedEmail,
				name: user_name,
				total_properties: params.totalProperties,
				user_type: params.userType,
				company: params.company,
				phone_number: params.phoneNumber,
			});

			const profile = signUpResponse as Profile;

			if (needsToSignOutRenter(profile)) {
				return dispatch(signOutAndRedirect());
			}

			dispatch(signIn(lowerCasedEmail, params.password));
		} catch (error) {
			dispatch(SnackbarActions.showError(error as Error));

			/**
			 * this will redirect back to the initial screen, since we don't have a button for that
			 * otherwise users won't be able to change the data that might be causing the issue
			 */

			if ((error as any)?.response?.data?.code === 'auth/invalid-email') {
				params.setInitialScreenError('email');
			}
		}
	};

	return fn;
};

export const checkIfUserExists = () => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const state = getState();

		try {
			const profile = await UsersApi.getById(state.firebase.auth.uid);

			if (!profile) {
				throw new Error('Profile not found');
			}

			dispatch({ type: 'LOGIN_DETECTED', profile });

			if (needsToSignOutRenter(profile)) {
				return dispatch(signOutAndRedirect());
			}

			return dispatch(TeaminvitesActions.acceptTeamInvites());
		} catch (e) {
			firebase
				.auth()
				.signOut()
				.then(() => {
					dispatch({ type: 'SIGNOUT_SUCCESS' });
				});

			return dispatch({ type: 'SIGNOUT' });
		}
	};

	return fn;
};

export const updateUser = (
	data: FirebaseUpdatePayload<Profile>,
	password?: string,
	email?: string,
	currentPassword?: string
) => {
	const fn: ReduxFn = async (dispatch) => {
		const user = await firebase.auth().currentUser;

		if (!user) {
			throw new Error('Current user not found');
		}

		if (password && email && currentPassword) {
			await firebase.auth().signInWithEmailAndPassword(email, currentPassword);
			await user.updatePassword(password);
		}

		await firebase.firestore().collection('users').doc(user.uid).update(data);

		const profile = await UsersApi.getById(user.uid);

		return dispatch({ type: 'UPDATE_USER', profile });
	};

	return fn;
};
