import { InspectionTemplatesApi } from '@rentcheck/api-frontend';
import { ApiInspectionTemplateDigest, ListOptions } from '@rentcheck/types';
import _ from 'lodash';
import useApiModel from './common/api-hook';

const defaultSort: ListOptions['sort'] = {
	sort_by: 'created_date',
	sort_type: 'ASC',
};

const useInspectionTemplates = (
	pageSize: number = 20,
	includeUnavailable = true
) => {
	const hookData = useApiModel(
		InspectionTemplatesApi,
		{
			include_unavailable: includeUnavailable,
		},
		defaultSort,
		pageSize
	);

	return {
		templates: hookData.data as ApiInspectionTemplateDigest[],
		..._.omit(hookData, 'data'),
	};
};

export default useInspectionTemplates;
