import { Masonry } from '@mui/lab';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Feature, MaintenanceFlag, TemplateQuestion } from '@rentcheck/types';
import { Column } from 'components';
import { useFeatureDetailsModalData } from 'hooks/modals';
import _ from 'lodash';
import { FeatureWithMetadata } from 'store/reducers/features';
import { getFeatureMedia } from 'utils/helpers';

import { Image, ImageBadges, MediaTypeIcon } from 'components/features';

interface Props {
	feature: FeatureWithMetadata;
	setHoveredImage: (imageUri?: string) => void;
	hoveredQuestion?: TemplateQuestion;
	hoveredFlag?: MaintenanceFlag;
	onFeatureChanged: (feature: Feature) => void;
}

export default ({
	feature,
	setHoveredImage,
	hoveredQuestion,
	hoveredFlag,
	onFeatureChanged,
}: Props) => {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up(905));
	const lg = useMediaQuery(theme.breakpoints.up(1380));

	const modalData = useFeatureDetailsModalData();
	const inspection = modalData?.metadata?.inspection;
	const features = modalData?.metadata?.features;

	const media = getFeatureMedia(feature);

	const columns = Math.min(lg ? 3 : md ? 2 : 1, media.length);
	const spacing = !lg && !md ? 0 : 2;
	const marginBottom = !lg && !md ? 16 : 0;
	const imageSize = _.floor(3000 / columns);

	if (!inspection) return null;

	if (feature.not_applicable) {
		return (
			<Masonry columns={1} spacing={spacing}>
				<Column
					style={{
						border: '1px solid #0000001F',
						borderRadius: 4,
						padding: 14,
					}}>
					<Typography color="#00000099">
						This feature was marked 'Not Applicable' by{' '}
						{inspection.current_performer?.name ?? 'the performer'}
					</Typography>
				</Column>
			</Masonry>
		);
	}

	return (
		<div style={{ width: '100%', maxHeight: '100%', overflowY: 'scroll' }}>
			<Masonry columns={columns} spacing={spacing}>
				{media.map((item, index) => (
					<div
						key={item + index}
						style={{ position: 'relative', display: 'flex' }}>
						<Image
							feature={feature}
							features={features}
							inspection={inspection}
							imageUri={item}
							imageSize={imageSize}
							mb={marginBottom}
							setHoveredImage={setHoveredImage}
							hoveredQuestion={hoveredQuestion}
							hoveredFlag={hoveredFlag}
							onFeatureChanged={onFeatureChanged}
						/>
						<ImageBadges
							inspection={inspection}
							feature={feature}
							image={item}
						/>
						<MediaTypeIcon image={item} />
					</div>
				))}
			</Masonry>
		</div>
	);
};
