import { Inspection, InspectionStatusDisplay } from '@rentcheck/types';
import _ from 'lodash';

export interface DateRange {
	start: number;
	end: number;
}

export interface State {
	inspectionStatus: InspectionStatusDisplay[];
	inspectionTemplate: Inspection['inspection_template']['id'][];
	team: string[];
	dueDateRange?: DateRange;
	completedDateRange?: DateRange;
	search: string;
	archived: boolean;
	assignedTo: string[];
	ratings: string[];

	sortBy: string;
	sortType: 'asc' | 'desc';
}

const initialState: State = {
	sortBy: 'due_date',
	sortType: 'desc',
	inspectionStatus: [],
	inspectionTemplate: [],
	team: [],
	dueDateRange: undefined,
	completedDateRange: undefined,
	search: '',
	archived: false,
	assignedTo: [],
	ratings: [],
};

const resetState: Partial<State> = {
	inspectionStatus: [],
	inspectionTemplate: [],
	team: [],
	dueDateRange: undefined,
	completedDateRange: undefined,
	archived: false,
	assignedTo: [],
	ratings: [],
};

type Action = {
	type: string;
	value?: string;
	dateRange: DateRange;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'TOGGLE_INSPECTION_STATUS_FILTER':
			if (!action.value) return state;
			return {
				...state,
				inspectionStatus: _.xor(state.inspectionStatus, [action.value]),
			};

		case 'TOGGLE_INSPECTION_TEMPLATE_FILTER':
			if (!action.value) return state;
			return {
				...state,
				inspectionTemplate: _.xor(state.inspectionTemplate, [action.value]),
			};

		case 'TOGGLE_INSPECTION_TEAMS_FILTER':
			if (!action.value) return state;
			return { ...state, team: _.xor(state.team, [action.value]) };

		case 'TOGGLE_INSPECTION_RATINGS_FILTER':
			if (!action.value) return state;
			return { ...state, ratings: _.xor(state.ratings, [action.value]) };

		case 'SET_INSPECTION_DUE_DATE_RANGE_FILTER':
			return { ...state, dueDateRange: action.dateRange };

		case 'SET_INSPECTION_COMPLETED_DATE_RANGE_FILTER':
			return { ...state, completedDateRange: action.dateRange };

		case 'TOGGLE_INSPECTION_SORT_TYPE':
			return { ...state, sortType: state.sortType === 'asc' ? 'desc' : 'asc' };

		case 'SET_INSPECTION_SORT_BY':
			if (!action.value) return state;
			return { ...state, sortBy: action.value, sortType: 'asc' };

		case 'SET_INSPECTION_SEARCH_FILTER':
			return { ...state, search: action.value ?? '' };

		case 'SET_INSPECTION_ARCHIVED_FILTER':
			return { ...state, archived: action.value ?? false };

		case 'SET_INSPECTION_ASSIGNED_TO_FILTER':
			return { ...state, assignedTo: action.value ?? [] };

		case 'RESET_INSPECTION_FILTERS':
			return { ...state, ...resetState };

		default:
			return state;
	}
};

export const appliedFiltersCount = (state: State) => {
	return [
		!!state.dueDateRange,
		!!state.completedDateRange,
		state.team.length > 0,
		state.inspectionTemplate.length,
		state.inspectionStatus.length,
		state.archived,
		state.assignedTo.length,
		state.ratings.length,
	].filter((f) => f).length;
};

export const hasAppliedFilters = (state: State) => {
	return appliedFiltersCount(state) > 0 || state.search.length > 0;
};

export default reducer;
