"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inspectionMessageEvent = exports.inspectionStatusDisplayEnumArray = exports.inspectionStatusEnumArray = void 0;
exports.inspectionStatusEnumArray = [
    'Inspection Scheduled',
    'Inspection Request Sent',
    'Inspection Request Accepted',
    'Start Inspection',
    'Send Request or Start Inspection',
    'Continue Inspection',
    'Review Inspection',
    'Inspection Complete',
];
exports.inspectionStatusDisplayEnumArray = [
    'Scheduled',
    'Not Started',
    'Started',
    'In Review',
    'Completed',
    'Awaiting Review',
    'Revision Requested',
    'Revision Review',
    'Approved',
];
exports.inspectionMessageEvent = [
    'INSPECTION_CREATED',
    'INVITE_ACCEPTED',
    'INSPECTION_COMPLETE_SELF',
    'INSPECTION_APPROVED',
    'INSPECTION_REVISION_SUBMITTED',
    'INSPECTION_READY_FOR_REVIEW',
    'SIGNATURE_REQUEST_FULFILLED',
    'MAINTENANCE_REPORT_CREATED',
];
