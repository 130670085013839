import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { createSx } from './create-sx';

const ChipAllActive = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
	};

	const selected =
		filters.inspectionStatus.length === 0 &&
		filters.inspectionTemplate.length === 0 &&
		filters.team.length === 0 &&
		filters.assignedTo.length === 0 &&
		!filters.dueDateRange &&
		!filters.completedDateRange &&
		!filters.archived;

	return (
		<Chip
			variant="outlined"
			icon={<FontAwesomeIcon icon={solid('inbox')} />}
			label="All"
			onClick={handleClick}
			sx={createSx(selected, colors.primary, '#e7eafd', '#d8dBfc')}
		/>
	);
};

export default ChipAllActive;
