import { FirebaseUpdatePayload, Profile } from '@rentcheck/types';
import firebase from 'config/fbConfig';
import _ from 'lodash';
import { objectsFromQuerySnapshot } from './helpers';

export const getByEmail = async (email: string): Promise<Profile | undefined> =>
	firebase
		.firestore()
		.collection('users')
		.where('email', '==', email)
		.get()
		.then((querySnapshot) =>
			_.first(objectsFromQuerySnapshot<Profile>(querySnapshot))
		);

export const getById = async (id: string): Promise<Profile | undefined> =>
	firebase
		.firestore()
		.collection('users')
		.doc(id)
		.get()
		.then((result) => {
			if (result.exists) return result.data() as Profile;
			return undefined;
		})
		.catch(() => undefined);

export const getByIds = async (ids: string[]): Promise<Profile[]> => {
	const profiles: Profile[] = [];

	const chunks = _.chunk(ids, 10);
	for (const chunk of chunks) {
		await firebase
			.firestore()
			.collection('users')
			.where('id', 'in', chunk)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const profile = doc.data() as Profile;
					if (!profile) return;
					profiles.push(profile);
				});
			});
	}

	return profiles;
};

export const update = async (
	id: string,
	payload: FirebaseUpdatePayload<Profile>
): Promise<Profile | undefined> => {
	return firebase
		.firestore()
		.collection('users')
		.doc(id)
		.update(payload)
		.then(() => getById(id));
};

export const getIntegrationToken = async (userId: string): Promise<string> => {
	const getIntegrationToken = firebase
		.functions()
		.httpsCallable('modelCallableGetIntegrationToken');

	const { data } = await getIntegrationToken({ user_id: userId });

	return data;
};

export const deleteAccount = async (id: string) => {
	const fn = firebase.functions().httpsCallable('modelCallableDeleteAccount');
	const { data } = await fn({ user_id: id });

	return data;
};

export const enableAccount = async (id: string) => {
	const fn = firebase.functions().httpsCallable('modelCallableEnableAccount');
	const { data } = await fn({ user_id: id });

	return data;
};

export const getAuthUser = async (id: string) => {
	const fn = firebase.functions().httpsCallable('modelCallableGetAuthUser');
	const { data } = await fn({ user_id: id });

	return data;
};
