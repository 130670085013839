import { Row, SpacedRow } from 'components';
import SearchBar from 'components/table/search';
import { RootState } from 'store/reducers/rootReducer';

import styled from 'styled-components';
import FiltersButton from './menu';
import QuickFilters from './quick-filters';

const List = () => {
	const searchExtractor = (state: RootState) =>
		state.inspections.filters.search;

	return (
		<Container>
			<QuickFilters />

			<RightContainer>
				<SearchBar stateExtractor={searchExtractor} type="inspection" />
				<FiltersButton />
			</RightContainer>
		</Container>
	);
};

const Container = styled(SpacedRow)`
	align-items: flex-end;
	border: 1px solid #0000001f;
	border-radius: 4px;
	padding: 8px 16px 8px 8px;

	@media (max-width: 1023px) {
		align-items: flex-start;
		flex-direction: column;
	}
`;

const RightContainer = styled(Row)`
	margin-left: auto;

	@media (max-width: 1023px) {
		margin-top: 4px;
	}
`;

export default List;
