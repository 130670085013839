import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ReminderChannel, ReminderRecipient } from '@rentcheck/types';
import { useSendReminderModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import {
	InspectionActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DialogTitle } from 'components';
import { useIsMobileBreakpoint } from 'utils/hooks';
import { Settings as SettingsT } from './common';
import InspectionData from './inspections';
import Message from './message';
import Recipients from './recipients';
import Settings from './settings';

interface Props {
	open: boolean;
}

const FlagFeature = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const modalData = useSendReminderModalData();
	const inspections = modalData?.metadata?.inspections ?? [];

	const [recipients, setRecipients] = useState<ReminderRecipient[]>([]);
	const [message, setMessage] = useState('');
	const [settings, setSettings] = useState<SettingsT>('Email and SMS');

	useEffect(() => {
		if (!inspections.length || !open) {
			return;
		}

		setRecipients([]);
		setMessage('');
		setSettings('Email and SMS');
	}, [open]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSendReminder = async () => {
		const channels: ReminderChannel[] =
			settings === 'Email and SMS' ? ['email', 'sms'] : ['email'];

		const sendTo = recipients.filter((r) => r.selected);

		dispatch(ModalFlowActions.closeCurrentModal());

		const progress = await dispatch(
			SnackbarActions.showProgress('Sending inspection reminders')
		);

		dispatch(InspectionActions.sendReminder(sendTo, message, channels))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => dispatch(SnackbarActions.hideSnackbar(progress.id)));
	};

	const enabledRecipients = recipients.filter((r) => r.selected);

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Send a reminder"
				onClose={handleClose}
				icon={solid('envelope-dot')}
			/>

			<DialogContent>
				<InspectionData inspections={inspections} />

				<Recipients
					inspections={inspections}
					recipients={recipients}
					setRecipients={setRecipients}
				/>

				<Message
					inspections={inspections}
					message={message}
					setMessage={setMessage}
				/>

				<Settings
					inspections={inspections}
					settings={settings}
					setSettings={setSettings}
				/>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<Button
					id="button-confirm"
					variant="contained"
					disabled={enabledRecipients.length === 0}
					onClick={handleSendReminder}>
					Send Reminder
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FlagFeature;
