import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
} from '@mui/material';

import { PropertiesApi } from '@rentcheck/api-frontend';
import { APIProperty, Property } from '@rentcheck/types';

import DialogTitle from 'components/dialog-title';
import { SnackbarActions } from 'store/actions';
import { formattedAddress, simplePropertyType } from 'utils/helpers';

import SelectProperty from './select-property';

interface Props {
	property: Property;
	open: boolean;
	onClose: () => void;
}

export default ({ open, onClose, property }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState<APIProperty>();
	const [overrideSelected, setOverrideSelected] = useState(false);

	const handleMerge = () => {
		if (!selectedProperty) return;

		setLoading(true);

		PropertiesApi.merge(property.id, selectedProperty.id, {
			transfer_rooms: overrideSelected,
		})
			.then(() => {
				history.push(`/properties/${selectedProperty.id}`);
				const message = `Success! ${_.capitalize(simplePropertyType(property))}s have been merged successfully.`;
				dispatch(SnackbarActions.showSuccess(message));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.then(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				title={`Merge ${simplePropertyType(property)}`}
				bottomSubtitle={formattedAddress(property)}
				icon={solid('merge')}
				onClose={onClose}
			/>
			<DialogContent>
				<SelectProperty
					propertyType={simplePropertyType(property)}
					property={selectedProperty}
					setProperty={setSelectedProperty}
					filterProperty={(p) =>
						p.id !== property.id && (!!p.appfolio_sync || !!p.rentmanager_sync)
					}
				/>
				<FormControlLabel
					label="Override target room configuration?"
					control={
						<Checkbox
							checked={overrideSelected}
							onChange={() => setOverrideSelected(!overrideSelected)}
						/>
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="text" color="secondary">
					Cancel
				</Button>
				<LoadingButton
					loading={loading}
					onClick={handleMerge}
					disabled={!selectedProperty}>
					Merge
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
