import { Button, Typography } from '@mui/material';
import { Property, UnitPropertyType } from '@rentcheck/types';
import { useState } from 'react';
import { SpacedRow, Spacer } from '../../..';

interface Props {
	property?: Property;
	propertyType?: string;
	setValue: (propertyType: UnitPropertyType) => void;
}

const PropertyType = ({ property, propertyType, setValue }: Props) => {
	const propertyTypes: UnitPropertyType[] = [
		'Apartment or Condo',
		'Single-Family',
		'Multi-Family',
	];

	const [selectedType, setSelectedType] = useState(
		property?.property_type ?? propertyType
	);

	return (
		<div>
			<Typography variant="h6">What type of unit are you renting?</Typography>
			<Spacer height={7} />
			<SpacedRow>
				{propertyTypes.map((pt) => {
					const selected = pt === selectedType;
					return (
						<Button
							key={pt}
							variant={selected ? 'contained' : 'outlined'}
							color={selected ? 'primary' : 'secondary'}
							onClick={() => {
								setSelectedType(pt);
								setValue(pt);
							}}>
							{pt}
						</Button>
					);
				})}
			</SpacedRow>
		</div>
	);
};

export default PropertyType;
