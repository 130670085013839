"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTemplateFeatures = void 0;
const common_1 = require("./common");
const createResponsesForFeature = (feature) => feature.questions.map((q) => {
    if (q.type === 'yes/no') {
        return 'Yes';
    }
    return '';
});
const createEmptyFeature = (inspection, basicFeatureProps, section, sectionName, sectionNameWithOrdinal) => (Object.assign(Object.assign({}, basicFeatureProps), { id: '', inspection_id: inspection.id, property_id: inspection.propertyID, section: {
        name: sectionName,
        name_with_ordinal: sectionNameWithOrdinal !== null && sectionNameWithOrdinal !== void 0 ? sectionNameWithOrdinal : sectionName,
        type: section.type,
    }, images: [], videos: [], image_links: [], video_links: [], rating: undefined, incomplete: true, not_applicable: false, notes: '', responses: createResponsesForFeature(basicFeatureProps), uploaded: false, revision_requested: false, revision_requested_update: false, edited_by_reviewer: false, review_notes: '', reviewed_by: undefined, reviewed_at: undefined, maintenance_flags: [], created_date: new Date(), updated_date: new Date() }));
const createFeaturesFromSection = (inspection, section, sectionName, sectionNameWithOrdinal) => {
    if (!section.features.length) {
        return [];
    }
    /**
     * If fast track is enabled, we only create one feature for the section
     * with the name of the section as feature name and a predefined description.
     */
    if (inspection.fast_track) {
        const basicFeatureProps = {
            name: sectionName,
            description: '',
            is_rating_required: true,
            reference_photos: [],
            number_of_photos_required: 1,
            questions: [],
            is_video_enabled: true,
            logic: [],
        };
        if (section.type === 'room') {
            basicFeatureProps.description =
                `Stand back from each corner to ` +
                    `ensure you get photos of the ${sectionName} in its entirety.`;
        }
        else if (section.type === 'section') {
            basicFeatureProps.description =
                `Take any additional ${sectionName}` +
                    ` photos that you would like to document.`;
        }
        return [
            createEmptyFeature(inspection, basicFeatureProps, section, sectionName, sectionNameWithOrdinal),
        ];
    }
    /**
     * If fast track is not enabled, we create features based on the template
     * features for the section.
     */
    return section.features.map((tf) => createEmptyFeature(inspection, tf, section, sectionName, sectionNameWithOrdinal));
};
const createFeaturesFromRepeatableSection = (sectionName, section, inspection, property) => {
    const sectionCount = sectionName === 'Floor'
        ? property.floors
        : inspection.rooms.filter((r) => r === sectionName).length;
    return new Array(sectionCount)
        .fill(null)
        .map((_, i) => createFeaturesFromSection(inspection, section, sectionName, (0, common_1.addOrdinalToSectionName)(sectionName, i)))
        .flat();
};
const createFeaturesForSpecificFeaturesTemplate = (inspection, inspectionTemplate) => {
    var _a;
    const features = [];
    const selectedFeatures = Array.from((_a = inspection.selected_features) !== null && _a !== void 0 ? _a : []);
    for (const selectedFeature of selectedFeatures) {
        const sectionName = (0, common_1.parseSelectedSectionName)(selectedFeature.section);
        const section = inspectionTemplate.sections.find((s) => s.name === sectionName);
        if (!section) {
            continue;
        }
        const selectedFeaturesInSection = section.features.filter((f) => selectedFeature.feature === f.name);
        const limitedSection = Object.assign(Object.assign({}, section), { features: selectedFeaturesInSection });
        /**
         * For selected features we create sections in the template regardless of
         * property rooms. We assume all that logic already happened in the backend
         * at the time of inspection creation
         */
        features.push(...createFeaturesFromSection(inspection, limitedSection, section.name, selectedFeature.room_name));
    }
    return features;
};
const createFeaturesForAllRoomsTemplate = (inspection, inspectionTemplate, property) => {
    const features = [];
    for (const section of inspectionTemplate.sections) {
        /**
         * We create sections in the template regardless of
         * property rooms.
         */
        if (section.type === 'section') {
            features.push(...createFeaturesFromSection(inspection, section, section.name));
        }
        /**
         * For room type sections we first check if the room exists on
         * the property/inspection. If it does we create the features for that room.
         */
        if (section.type === 'room') {
            /**
             * Some section names use plural nomenclature, while the property rooms
             * are all singular, that's why we first need to change
             */
            const singularName = (0, common_1.parseTemplateRoomName)(section.name);
            if (!inspection.rooms.includes(singularName)) {
                continue;
            }
            /**
             * If this is a section that can be repeated (Bedroom, Bathroom, etc.)
             * we need to create features for each room of that type.
             */
            if ((0, common_1.sectionCanExistMultipleTimes)(section.name)) {
                features.push(...createFeaturesFromRepeatableSection(singularName, section, inspection, property));
            }
            else {
                features.push(...createFeaturesFromSection(inspection, section, singularName));
            }
        }
    }
    return features;
};
const buildTemplateFeatures = ({ inspection, property, inspectionTemplate, }) => __awaiter(void 0, void 0, void 0, function* () {
    const features = [];
    if (!property) {
        return [];
    }
    if (inspectionTemplate.template_type === 'all-rooms') {
        features.push(...createFeaturesForAllRoomsTemplate(inspection, inspectionTemplate, property));
    }
    if (inspectionTemplate.template_type === 'specific-features') {
        features.push(...createFeaturesForSpecificFeaturesTemplate(inspection, inspectionTemplate));
    }
    return features;
});
exports.buildTemplateFeatures = buildTemplateFeatures;
