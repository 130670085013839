import { Button } from '@mui/material';
import { IntercomAPI } from 'react-intercom';

const Latchel = () => {
	const handleChatToInstall = () => {
		IntercomAPI('showNewMessage');
	};

	return <Button onClick={handleChatToInstall}>Chat to upgrade</Button>;
};

export default Latchel;
