import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Badge,
	BadgeTypeMap,
	CircularProgress,
	IconButtonTypeMap,
	IconButton as MuiIconButton,
	Popover,
	SxProps,
	Theme,
	Tooltip,
} from '@mui/material';
import {
	MouseEvent,
	ReactNode,
	ReactNodeArray,
	forwardRef,
	useImperativeHandle,
	useState,
} from 'react';
import styled from 'styled-components';

import * as theme from 'theme';

interface Props {
	icon: IconProp;
	loading?: boolean;
	fullSize?: boolean;
	tooltip?: string;
	disabled?: boolean;
	badgeCount?: number;
	badgeColor?: BadgeTypeMap['props']['color'];
	color?: IconButtonTypeMap['props']['color'];
	width?: number;
	children?: ReactNode | ReactNodeArray;
	onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
	buttonSx?: SxProps<Theme>;
	tabIndex?: number;
	dismissMenuOnClick?: boolean;
}

export interface IconButtonRef {
	close: () => void;
	click: () => void;
}

const backgroundStyle = (active: boolean) => {
	if (!active) return {};

	return { backgroundColor: '#EFEFEF', border: '1px solid #AEBBC9' };
};

const IconButton = forwardRef<IconButtonRef, Props>((props, ref) => {
	const {
		disabled,
		tooltip = '',
		color,
		badgeCount,
		badgeColor = 'error',
		icon,
		children,
		onClick,
		width,
		loading,
		fullSize,
		tabIndex,
		buttonSx = {},
		dismissMenuOnClick = true,
	} = props;

	const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

	const handleClick = (e?: MouseEvent<HTMLButtonElement>) => {
		onClick?.(e);
		children && e && setMenuAnchor(e.currentTarget);
	};

	const handleClose = () => {
		setMenuAnchor(null);
	};

	useImperativeHandle(ref, () => ({
		click: handleClick,
		close: handleClose,
	}));

	return (
		<>
			<Tooltip title={tooltip}>
				<Badge
					color={badgeColor}
					overlap="circular"
					badgeContent={badgeCount}
					sx={{
						'.MuiBadge-badge': {
							height: 16,
							minWidth: 16,
							padding: '0 1px',
							fontSize: 10,
						},
					}}>
					<MuiIconButton
						disabled={disabled}
						id={`icon-button-${tooltip}`}
						size="small"
						color={color}
						tabIndex={tabIndex}
						sx={{
							height: 32,
							width: 32,
							marginLeft: 1,
							...backgroundStyle(!!menuAnchor),
							...buttonSx,
						}}
						onClick={handleClick}>
						{!loading && (
							<FontAwesomeIcon icon={icon} size={fullSize ? 'lg' : 'sm'} />
						)}
						{loading && <CircularProgress size={14} color="secondary" />}
					</MuiIconButton>
				</Badge>
			</Tooltip>
			{!!children && (
				<Popover
					sx={{ marginTop: 1 }}
					open={!!menuAnchor}
					anchorEl={menuAnchor}
					onClose={() => setMenuAnchor(null)}
					onClick={dismissMenuOnClick ? handleClose : undefined}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					<MenuWrapper width={width}>{children}</MenuWrapper>
				</Popover>
			)}
		</>
	);
});

const MenuWrapper = styled.div<{ width?: number }>`
	background-color: ${theme.colors.white};
	border-radius: ${theme.radius.small};
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
	${({ width }) => (width ? `${width}px` : '')};
`;

export default IconButton;
