"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberAndPluralizedWord = void 0;
/**
 * Returns a string built using the count provided and appending
 * the singular or plural form of the original word. For words
 * with simple plural forms (e.g.: bean/beans) this function will
 * handle adding the 's' on its own, for words with more complex
 * plural forms (e.g.: community/communities) the correct plural
 * word needs to be provided
 */
const numberAndPluralizedWord = (count, baseWord, pluralWord) => {
    if (Array.isArray(count)) {
        count = count.length;
    }
    if (count === 1) {
        return `${count} ${baseWord}`;
    }
    if (pluralWord) {
        return `${count} ${pluralWord}`;
    }
    return `${count} ${baseWord}s`;
};
exports.numberAndPluralizedWord = numberAndPluralizedWord;
