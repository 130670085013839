import { Utils } from '@rentcheck/biz';
import {
	ApiInspectionWithTemplate,
	Feature,
	MaintenanceFlag,
	TemplateQuestion,
} from '@rentcheck/types';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { colors } from 'theme';
import { useHover } from 'usehooks-ts';
import {
	getFeatureMedia,
	imageUrlFromGCPUri,
	videoUrlFromGCPUri,
} from 'utils/helpers';

interface Props {
	feature: FeatureWithMetadata;
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;

	imageUri: string;
	imageSize: number;
	mb: number;
	setHoveredImage?: (imageUri?: string) => void;
	hoveredQuestion?: TemplateQuestion;
	hoveredFlag?: MaintenanceFlag;
	onFeatureChanged?: (feature: Feature) => void;
}

export default ({
	feature,
	features,
	inspection,
	imageUri,
	imageSize,
	mb,
	setHoveredImage,
	hoveredQuestion,
	hoveredFlag,
	onFeatureChanged,
}: Props) => {
	const dispatch = useDispatch();

	const hoverRef = useRef(null);
	const isHovering = useHover(hoverRef);

	useEffect(() => {
		if (!setHoveredImage) {
			return;
		}

		if (isHovering) {
			setHoveredImage(imageUri);
		} else {
			setHoveredImage(undefined);
		}
	}, [isHovering]);

	const handleClickImage = () => {
		const media = getFeatureMedia(feature);
		const index = media.findIndex((item) => item === imageUri);

		dispatch(
			ModalFlowActions.showFeatureImage({
				initialFeature: feature,
				initialIndex: index,
				onFeatureChanged,
				features,
				inspection,
			})
		);
	};

	const needsToDim = () => {
		if (hoveredQuestion) {
			const questionLinks =
				feature.image_links?.filter(
					(link) =>
						link.linked_object === hoveredQuestion.title &&
						link.type === 'no_answer'
				) ?? [];

			return !questionLinks.find((link) => link.image_uri === imageUri);
		}

		if (hoveredFlag) {
			return (
				!hoveredFlag.image_urls?.includes(imageUri) &&
				!hoveredFlag.video_urls?.includes(imageUri)
			);
		}

		return false;
	};

	const fullUrl = Utils.Media.isVideo(imageUri)
		? videoUrlFromGCPUri(imageUri, true)
		: imageUrlFromGCPUri(imageUri, imageSize, imageSize);

	return (
		<img
			ref={hoverRef}
			src={fullUrl}
			onClick={handleClickImage}
			style={{
				width: '100%',
				minHeight: 150,
				display: 'block',
				cursor: 'pointer',
				objectFit: 'cover',
				backgroundColor: colors.lightGray,
				marginBottom: mb,
				opacity: needsToDim() ? 0.5 : 1,
				transition: 'opacity 0.3s',
			}}
		/>
	);
};
