import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { ApiUserDigest } from '@rentcheck/types';
import InfiniteScrollV2 from 'components/infinite-scroll-v2';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import Header from './header';
import Row from './row';
import Skeleton from './skeleton';

export default () => {
	const dispatch: Dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.teammates.usersFilters);
	const data = useTypedSelector((state) => state.teammates.users.data);

	const [loading, setLoading] = useState(true);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	useEffect(() => {
		setLoading(true);

		dispatch(TeammatesActions.Users.getAll(0, filters)).finally(() =>
			setLoading(false)
		);
	}, [filters]);

	if (data.length === 0 && loading) {
		return <Skeleton />;
	}

	const handleSelectAllChanged = () => {
		if (allSelected) {
			return setSelectedIds([]);
		}

		setSelectedIds(_.map(data, 'id'));
	};

	const handleSelectOneChanged = (user: ApiUserDigest) => {
		setSelectedIds(_.xor(selectedIds, [user.id]));
	};

	const handleLoadMore = async () => {
		return dispatch(TeammatesActions.Users.getAll(data.length, filters));
	};

	const allSelected =
		selectedIds.length > 0 &&
		_.difference(_.map(data, 'id'), selectedIds).length === 0;

	const indeterminate = !allSelected && selectedIds.length > 0;

	return (
		<>
			<TableContainer component={Paper} sx={{ mt: 2 }}>
				<Table>
					<Header
						selected={allSelected}
						indeterminate={indeterminate}
						selectedIds={selectedIds}
						data={data}
						onSelect={handleSelectAllChanged}
					/>
					<TableBody>
						{data.map((user) => (
							<Row
								key={user.id}
								user={user}
								selected={selectedIds.includes(user.id)}
								onSelect={handleSelectOneChanged}
							/>
						))}
					</TableBody>
				</Table>
				<InfiniteScrollV2
					onLoadMore={handleLoadMore}
					filterState={filters}
					data={data}
					pageSize={20}
				/>
			</TableContainer>
		</>
	);
};
