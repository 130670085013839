import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Column, Row, Screens, Spacer, TextInput } from 'components';
import * as authActions from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { isInvalidPassword } from 'utils/helpers';

const UpdatePassword = () => {
	const history = useHistory();
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(false);

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [repeatNewPassword, setRepeatNewPassword] = useState('');

	const [submissionError, setSubmissionError] = useState('');
	const [currentPasswordError, setCurrentPasswordError] = useState('');
	const [newPasswordError, setNewPasswordError] = useState('');
	const [repeatNewPasswordError, setRepeatNewPasswordError] = useState('');

	useEffect(() => {
		setCurrentPasswordError('');
	}, [currentPassword]);

	useEffect(() => {
		setNewPasswordError('');
	}, [newPassword]);

	useEffect(() => {
		setRepeatNewPasswordError('');
	}, [repeatNewPassword]);

	const validateForm = () => {
		const currentPasswordError =
			currentPassword.length < 6 ? 'Please enter your current password' : '';

		const newPasswordError =
			newPassword.length < 8
				? 'Your new password must be at least 8 characters long'
				: isInvalidPassword(newPassword)
					? "Make sure your new password doesn't consist entirely of repeated or sequential characters"
					: '';

		const repeatNewPasswordError =
			repeatNewPassword !== newPassword
				? "New password confirmation doesn't match"
				: '';

		setCurrentPasswordError(currentPasswordError);
		setNewPasswordError(newPasswordError);
		setRepeatNewPasswordError(repeatNewPasswordError);

		return !(
			currentPasswordError ||
			newPasswordError ||
			repeatNewPasswordError
		);
	};

	const handleSave = async () => {
		if (!validateForm()) return;

		setLoading(true);
		setSubmissionError('');

		dispatch(
			authActions.updateUser({}, newPassword, profile.email, currentPassword)
		)
			.then(() => history.push('/account/profile'))
			.catch((e) => {
				if (e.code === 'auth/wrong-password') {
					setCurrentPasswordError(
						'The current password is invalid or the user does not have a password'
					);

					return;
				}

				setSubmissionError(e.message);
			})
			.finally(() => setLoading(false));
	};

	if (!profile) {
		return null;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Update Password"
				subtitle="Manage your personal profile email and password."
				showBreadCrumbs
			/>
			<MainColumn>
				<TextInput
					type="password"
					label="Current Password"
					value={currentPassword}
					onChange={(v) => setCurrentPassword(v)}
					error={currentPasswordError}
				/>
				<Spacer height={6} />
				<TextInput
					type="password"
					label="New Password"
					value={newPassword}
					onChange={(v) => setNewPassword(v)}
					error={newPasswordError}
				/>
				<Spacer height={6} />
				<TextInput
					type="password"
					label="Repeat New Password"
					value={repeatNewPassword}
					onChange={(v) => setRepeatNewPassword(v)}
					error={repeatNewPasswordError}
				/>
				<Spacer height={10} />
				{!!submissionError && (
					<>
						<Typography color={colors.error}>{submissionError}</Typography>
						<Spacer height={4} />
					</>
				)}
				<ButtonsRow>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => history.push('/account/profile')}>
						Cancel
					</Button>
					<Spacer width={6} />
					<LoadingButton
						variant="contained"
						onClick={handleSave}
						loading={loading}>
						Save
					</LoadingButton>
				</ButtonsRow>
			</MainColumn>
		</Screens.Container>
	);
};

const MainColumn = styled(Column)`
	width: 50%;
	min-width: 470px;
`;

const ButtonsRow = styled(Row)`
	justify-content: flex-end;
`;

export default UpdatePassword;
