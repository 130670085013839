import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogContent,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { APIProperty, ApiInspectionTemplateDigest } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { getFullTemplateName, numberAndPluralizeWord } from 'utils/helpers';

import { useCreateInspectionModalData } from 'hooks/modals';
import { useInspectionTemplates } from 'hooks/templates';

import DialogTitle from '../common/inner-dialog-title';
import innerTransition from '../common/inner-transition';
import RowButton from '../common/row-button';
import { FeatureInfo } from '../index';
import SelectFeatures from './select-features';
import { TemplateSelection } from './template-selection';

const AddFeaturesButton = ({
	template,
	selectedFeaturesInfo,
	onClick,
}: {
	template?: ApiInspectionTemplateDigest;
	selectedFeaturesInfo: FeatureInfo[];
	onClick: () => void;
}) => {
	if (!template || template.template_type === 'all-rooms') return null;

	return (
		<Button variant="text" color="primary" onClick={onClick}>
			{numberAndPluralizeWord(selectedFeaturesInfo.length, 'Feature')} Added
		</Button>
	);
};

interface Props {
	properties: APIProperty[];
	disabled: boolean;
	template?: ApiInspectionTemplateDigest;
	setTemplate: (template?: ApiInspectionTemplateDigest) => void;
	selectedFeaturesInfo: FeatureInfo[];
	setSelectFeaturesInfo: (value: FeatureInfo[]) => void;
}

type CurrentScreen = 'template' | 'features';

export default ({
	properties,
	disabled,
	template,
	setTemplate,
	selectedFeaturesInfo,
	setSelectFeaturesInfo,
}: Props) => {
	const theme = useTheme();

	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const modalData = useCreateInspectionModalData();
	const { property_type: propertyType } = modalData?.metadata ?? {};

	const [modalOpen, setModalOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [currentScreen, setCurrentScreen] = useState<CurrentScreen>('template');
	const [checkedFeaturesInfo, setCheckedFeaturesInfo] = useState<FeatureInfo[]>(
		[]
	);

	const { templates: allTemplates, loading } = useInspectionTemplates({
		propertyTypes: [propertyType],
		includeUnavailable: false,
		onlyPublished: true,
	});

	useEffect(() => {
		setSelectFeaturesInfo([]);
		setCheckedFeaturesInfo([]);
	}, [template]);

	const handleOpen = () => {
		setModalOpen(true);
	};

	const handleClose = () => {
		setModalOpen(false);
		setCurrentScreen('template');
	};

	const handleOpenFeatureSelection = () => {
		handleOpen();
		setCurrentScreen('features');
	};

	const handleInspectionTemplate = (value: ApiInspectionTemplateDigest) => {
		if (value.id !== template?.id) setTemplate(value);
		if (value.template_type === 'all-rooms') {
			handleClose();
		} else {
			setCurrentScreen('features');
		}
	};

	if (disabled) {
		return (
			<RowButton
				disabled={disabled}
				title="Select Inspection Template"
				icon={regular('file-invoice')}
			/>
		);
	}

	const buttonTitle = template
		? getFullTemplateName(template)
		: 'Select Inspection Template';

	const caption = template ? 'Inspection' : undefined;
	const title = {
		template: 'Inspection Template',
		features: 'Add Features',
	}[currentScreen];

	return (
		<>
			<RowButton
				caption={caption}
				title={buttonTitle}
				icon={regular('file-invoice')}
				hasContent={!!template}
				onClick={handleOpen}
			/>

			<AddFeaturesButton
				template={template}
				selectedFeaturesInfo={selectedFeaturesInfo}
				onClick={handleOpenFeatureSelection}
			/>

			<Dialog
				open={modalOpen}
				onClose={handleClose}
				fullScreen={mobileScreen}
				TransitionComponent={innerTransition}>
				<DialogTitle title={title} onClose={handleClose} />

				{currentScreen === 'template' && (
					<DialogContent style={{ minHeight: '65vh' }}>
						<TemplateSelection
							handleInspectionTemplate={handleInspectionTemplate}
							hasAvailableTemplates={!!allTemplates.length && !loading}
							search={search}
							setSearch={setSearch}
							template={template}
						/>
					</DialogContent>
				)}

				{currentScreen === 'features' && (
					<SelectFeatures
						properties={properties}
						templateDigest={template}
						checkedFeaturesInfo={checkedFeaturesInfo}
						setCheckedFeaturesInfo={setCheckedFeaturesInfo}
						setSelectFeaturesInfo={setSelectFeaturesInfo}
						handleClose={handleClose}
					/>
				)}
			</Dialog>
		</>
	);
};
