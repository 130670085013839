import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { incompleteInspectionStatuses } from './constants';
import { createSx } from './create-sx';

const thePast = moment('2016-01-01').startOf('day').valueOf();
const endOfYesterday = moment().endOf('day').subtract(1, 'days').valueOf();

const ChipOverdue = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });

		dispatch({
			type: 'SET_INSPECTION_DUE_DATE_RANGE_FILTER',
			dateRange: { start: thePast, end: endOfYesterday },
		});

		incompleteInspectionStatuses.forEach((element) => {
			dispatch({
				type: 'TOGGLE_INSPECTION_STATUS_FILTER',
				value: element,
			});
		});
	};

	const correctInspectionStatusSelected =
		filters.inspectionStatus.length === incompleteInspectionStatuses.length &&
		_.intersection(filters.inspectionStatus, incompleteInspectionStatuses)
			.length === incompleteInspectionStatuses.length;

	const selected =
		correctInspectionStatusSelected &&
		filters.inspectionTemplate.length === 0 &&
		filters.team.length === 0 &&
		filters.dueDateRange?.start === thePast &&
		filters.dueDateRange?.end === endOfYesterday &&
		!filters.completedDateRange &&
		!filters.archived;

	return (
		<Chip
			variant="outlined"
			icon={<FontAwesomeIcon icon={solid('exclamation-triangle')} />}
			label="Overdue"
			onClick={handleClick}
			sx={createSx(selected, '#C71D23', '#F8E8E7', '#F4D6D6')}
		/>
	);
};

export default ChipOverdue;
