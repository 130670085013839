import { Accordion, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { Spacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Permissions } from '@rentcheck/biz';
import { IntercomAPI } from 'react-intercom';
import Content from '../common/content';
import Header from '../common/header';
import ClientIdAndSecret from './client-id-secret';

const headerProps = {
	icon: integrations.rentCheck,
	title: 'RentCheck REST API',
	body: 'The RentCheck API lets developers build their own RentCheck-powered applications to enable inspection scheduling and creation and to leverage inspection data for a variety of use cases.',
	route: '/account/integrations/rentcheck-api',
};

const RentCheckAPI = () => {
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);

	const expanded = location.pathname.startsWith(headerProps.route);

	const handleChatToUpgrade = () => {
		IntercomAPI('showNewMessage');
	};

	const canAccessApi =
		Permissions.Integrations.RentCheckApi.canAccess(subscription);

	return (
		<>
			<Accordion expanded={expanded}>
				<Header {...headerProps} active={false} />
				<Content>
					{canAccessApi && <ClientIdAndSecret />}
					{!canAccessApi && (
						<Button onClick={handleChatToUpgrade}>Chat to upgrade</Button>
					)}
					<Spacer height={8} />
				</Content>
			</Accordion>
		</>
	);
};

export default RentCheckAPI;
