import { TeamInvitesApi } from '@rentcheck/api-frontend';
import * as Sentry from '@sentry/react';
import { Dispatch } from 'types';

import { UpdateTeamInviteParams } from '@rentcheck/types';
import * as ActiveProfileActions from './activeProfileActions';
import * as TeamActions from './team';
import * as TeamsActions from './teams';

export const acceptTeamInvites = () => {
	return async (dispatch: Dispatch): Promise<void> => {
		return TeamInvitesApi.acceptAllInvites()
			.then((data) => {
				if (data.length) {
					dispatch(ActiveProfileActions.refreshUser());
					dispatch(TeamsActions.getAll());
				}
			})
			.catch((e) => {
				Sentry.captureException(e);
			});
	};
};

export const deleteInvite = (teamId: string, inviteId: string) => {
	return async (dispatch: Dispatch): Promise<void> => {
		return TeamInvitesApi.deleteInvite(teamId, inviteId).then(async () => {
			await dispatch(TeamActions.getById(teamId));
		});
	};
};

export const update = (
	teamId: string,
	inviteId: string,
	payload: UpdateTeamInviteParams['data']
) => {
	return async (dispatch: Dispatch): Promise<void> => {
		return TeamInvitesApi.update(teamId, inviteId, payload).then(async () => {
			await dispatch(TeamActions.getById(teamId));
		});
	};
};
