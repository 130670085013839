import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiInspection } from '@rentcheck/types';
import { DiffResult } from 'api/Properties';
import { useEffect, useState } from 'react';

import { Rules } from '@rentcheck/biz';
import IconButton from 'components/icon-button';
import Modal from './modal';
import { inspectionPropertyDiff } from './utils';

interface Props {
	inspection: ApiInspection;
}

const QuickEditsButton = ({ inspection }: Props) => {
	const [diff, setDiff] = useState<DiffResult[]>([]);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		inspectionPropertyDiff(inspection).then((result) => setDiff(result));
	}, [inspection]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		inspectionPropertyDiff(inspection).then((result) => setDiff(result));
	};

	if (!diff.length) {
		return null;
	}

	if (!Rules.Inspections.canMakeUnitEdits(inspection)) {
		return null;
	}

	return (
		<>
			<IconButton
				icon={regular('pen-square')}
				badgeCount={diff.length}
				badgeColor="primary"
				onClick={handleOpen}
				tooltip="Unit Edits"
			/>

			<Modal
				open={open}
				inspection={inspection}
				diffResults={diff}
				handleClose={handleClose}
			/>
		</>
	);
};

export default QuickEditsButton;
