import { SubscriptionsApi } from '@rentcheck/api-frontend';
import { ApiSubscription } from '@rentcheck/types';
import { AccountSettingsApi } from '@rentcheck/api-frontend';
import { Dispatch, GetState } from 'types';

import * as SnackbarActions from './snackbar-actions';

export const waitForSubscriptionWebhook = () => async (dispatch: Dispatch) =>
	new Promise<ApiSubscription>((resolve, reject) => {
		const maxSubscriptionRetries = 15;
		let pollCount = 0;

		const intervalId = setInterval(() => {
			pollCount++;

			SubscriptionsApi.get()
				.then((subscription) => {
					if (!subscription) return;

					clearInterval(intervalId);
					dispatch({ type: 'SET_SUBSCRIPTION', subscription } as any);

					AccountSettingsApi.get().then((accountSettings) =>
						dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
					);

					resolve(subscription);
				})
				.catch(() => {});

			if (pollCount === maxSubscriptionRetries) {
				clearInterval(intervalId);
				reject();
			}
		}, 1000);
	});

export const getSubscription = () => async (dispatch: Dispatch) => {
	const sub = await SubscriptionsApi.get();

	if (!sub) {
		return sub;
	}

	dispatch({ type: 'SET_SUBSCRIPTION', subscription: sub } as any);

	AccountSettingsApi.get().then((accountSettings) =>
		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
	);

	return sub;
};

export const addAddonsToSubscription =
	(addonIds: string[]) => async (dispatch: Dispatch, getState: GetState) => {
		const subscription = getState().subscription;

		if (!subscription) {
			dispatch(
				SnackbarActions.showError("User doesn't have an active subscription")
			);
			return;
		}

		return SubscriptionsApi.editSubscription(addonIds.map((id) => ({ id })))
			.then(() => dispatch(getSubscription()))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));
	};
