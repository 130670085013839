import {
	Checkbox,
	Collapse,
	Divider,
	FormControl,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { Column, Spacer } from 'components';
import useInspectionTemplates from 'hooks/inspection-templates';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { colors } from 'theme';
import { scrollToElement } from 'utils/helpers';
import { TemplatesSkeleton } from '../skeleton';

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
	mode: 'create' | 'edit';
}

type SelectionTypeT = 'all' | 'select';

export default ({ value, setValue, mode }: Props) => {
	const { templates, loading: templatesLoading } = useInspectionTemplates(
		200,
		true
	);

	const [selectionType, setSelectionType] = useState<SelectionTypeT>('all');

	useEffect(() => {
		/**
		 * If creating default to all templates
		 */
		if (mode === 'create') {
			setSelectionType('all');
			setValue(_.map(templates, 'id'));
			return;
		}

		/**
		 * If editing set the selection mode to all or select
		 * based on what's already set on the permission group
		 */

		if (value.length === templates.length) {
			setSelectionType('all');
		} else {
			setSelectionType('select');
		}
	}, [templates]);

	const handleChangeSelectionType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const selectionType = event.target.value as SelectionTypeT;

		setSelectionType(selectionType);

		if (selectionType === 'select') {
			setValue([]);
			setTimeout(() => scrollToElement('select-table-scroll-marker'), 400);
		} else {
			setValue(templates.map((template) => template.id));
		}
	};

	const handleToggleValue = (id: string) => {
		setValue(_.xor(value, [id]));
	};

	return (
		<>
			<Typography variant="overline">View Inspections</Typography>

			<Typography variant="body2" color={colors.secondary} mt={1}>
				Control which inspections can be created and viewed.
			</Typography>

			{templatesLoading && <TemplatesSkeleton />}

			{!templatesLoading && (
				<Paper elevation={0} sx={{ border: '1px solid #0000001F', mt: 1 }}>
					<FormControl fullWidth>
						<RadioGroup
							value={selectionType}
							onChange={handleChangeSelectionType}>
							<FormControlLabel
								value="all"
								control={<Radio />}
								sx={{ padding: '8px 16px 8px 2px' }}
								labelPlacement="start"
								label={
									<Typography sx={{ flex: 1 }}>
										All Inspection Templates
									</Typography>
								}
							/>

							<Divider />
							<FormControlLabel
								value="select"
								control={<Radio />}
								sx={{ padding: '8px 16px 8px 2px' }}
								labelPlacement="start"
								label={
									<Typography sx={{ flex: 1 }}>
										Select Inspection Templates
									</Typography>
								}
							/>
							<Collapse
								id="team-list"
								in={selectionType === 'select'}
								sx={{
									display: 'flex',
									maxHeight: 250,
									overflowY: 'scroll',
									overflowX: 'clip',
								}}>
								<Column>
									{templates.map((template) => (
										<FormControlLabel
											key={template.id}
											value="select"
											control={
												<Checkbox
													checked={value.some((v) => v === template.id)}
													onChange={() => handleToggleValue(template.id)}
												/>
											}
											sx={{ padding: '8px 18px 8px 2px' }}
											labelPlacement="start"
											label={
												<Typography variant="body2" sx={{ flex: 1 }}>
													{template.internal_label}
												</Typography>
											}
										/>
									))}
								</Column>
							</Collapse>
						</RadioGroup>
					</FormControl>
				</Paper>
			)}
			<div id="select-table-scroll-marker" />
			<Spacer height={6} />
		</>
	);
};
