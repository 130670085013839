import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, SxProps, Theme, Tooltip, Typography } from '@mui/material';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import shadows from '@mui/material/styles/shadows';
import { MouseEventHandler, useMemo } from 'react';

export interface Props {
	title: string;
	label: string;
	tooltip?: string;
	icon?: IconDefinition;
	color: 'primary' | 'success' | 'warning' | 'error' | 'info';
	variant: 'outlined' | 'filled';
	matchIconColor?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
	selected?: boolean;
}

const buildSx = (
	color: Props['color'],
	variant: Props['variant'],
	matchIconColor?: boolean,
	selected?: boolean
): SxProps<Theme> => {
	const { backgroundColor, iconColor, hoverColor, textColor } = useMemo(() => {
		const textColor = '#101010';

		let accentColor = '#0000B3';
		let backgroundColor = '#E9E8FD';
		let hoverColor = '#2D3CE63D';

		switch (color) {
			case 'primary':
				accentColor =
					variant === 'outlined' ? '#0000B3' : selected ? '#3847F3' : '#0000B3';
				backgroundColor = '#E9E8FD';
				hoverColor =
					variant === 'outlined'
						? '#2D3CE63D'
						: selected
							? '#2d3ce6'
							: '#2D3CE63D';
				break;

			case 'success':
				accentColor = '#009839';
				backgroundColor = '#DFFFE9';
				hoverColor = '#36B45F3D';
				break;

			case 'warning':
				accentColor = '#DB5C09';
				backgroundColor = '#FCF4E1';
				hoverColor = '#EB830D3D';
				break;

			case 'error':
				accentColor = '#B00020';
				backgroundColor = '#FEEAEE';
				hoverColor = '#F04D4C3D';
				break;

			case 'info':
				accentColor = '#232E3A';
				backgroundColor = '#EAEEF6';
				hoverColor = '#232E3A3D';
				break;
		}

		if (variant === 'filled') {
			return {
				backgroundColor: selected ? accentColor : backgroundColor,
				textColor: selected ? '#fff' : matchIconColor ? accentColor : textColor,
				iconColor: selected ? '#fff' : accentColor,
				hoverColor: hoverColor,
			};
		}

		return {
			backgroundColor,
			textColor: matchIconColor ? accentColor : textColor,
			iconColor: accentColor,
			hoverColor: hoverColor,
		};
	}, [color, matchIconColor, selected]);

	const baseSx: SxProps<Theme> = {
		cursor: 'pointer',
		fontWeight: 400,
		backgroundColor: backgroundColor,
		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,
		border: `1px solid ${backgroundColor}`,
		color: textColor,
		boxShadow: selected ? shadows[1] : 'none',

		'.MuiChip-icon': {
			color: iconColor,
		},

		'&&:hover': {
			backgroundColor: hoverColor,
		},
	};

	if (selected && variant === 'outlined') {
		return {
			...baseSx,
			border: `1px solid ${iconColor}`,
		};
	}

	return baseSx;
};

export default ({
	title,
	label,
	tooltip,
	icon,
	color,
	variant,
	matchIconColor,
	onClick,
	selected,
}: Props) => {
	const sx = buildSx(color, variant, matchIconColor, selected);

	return (
		<Tooltip title={tooltip ?? ''}>
			<Chip
				id={`segment-chip-${title}`}
				variant="outlined"
				label={label}
				sx={sx}
				onClick={onClick}
				icon={
					icon ? (
						<FontAwesomeIcon icon={icon} />
					) : (
						<Typography fontWeight={600} fontSize={12}>
							{title}
						</Typography>
					)
				}
			/>
		</Tooltip>
	);
};
