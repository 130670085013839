import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { match as Match } from 'react-router-dom';

import { Property } from '@rentcheck/types';

import { PropertiesApi } from 'api';
import { InspectionsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import { Screens } from 'components';
import InspectionDetailHeader from '../details/header';
import MaintenanceFlagsList from './list';

interface MatchParams {
	id: string;
}

interface Props {
	match: Match<MatchParams>;
}

const FlaggedDetail = ({ match }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const inspectionId = match.params.id;
	const inspection = useTypedSelector((state) =>
		state.inspections.inspection?.id === inspectionId
			? state.inspections.inspection
			: null
	);

	const [property, setProperty] = useState<Property | undefined>();

	useEffect(() => {
		dispatch(InspectionsActions.getById(inspectionId))
			.then((data) => PropertiesApi.getById(data.property.id))
			.then((data) => setProperty(data))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));
	}, []);

	if (!inspection || !property) return null;

	return (
		<Screens.Container>
			<InspectionDetailHeader inspection={inspection} />
			<MaintenanceFlagsList inspection={inspection} property={property} />
		</Screens.Container>
	);
};

export default FlaggedDetail;
