import { ApiTeamDetails, Profile } from '@rentcheck/types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';

import authReducer from './authReducer';
import authenticatedUserReducer from './authenticatedUserReducer';
import featuresReducer, { State as FeaturesState } from './features';
import inviteReducer from './inviteReducer';

import accountSettingsReducer, {
	State as AccountSettingsState,
} from './account-settings';
import activeProfileReducer from './active-profile';
import dashboardReducer, { State as DashboardState } from './dashboard';
import fileRequestsReducer, {
	State as FileRequestsState,
} from './file-requests';
import inspectionTemplatesReducer, {
	State as InspectionTemplatesState,
} from './inspection-templates';
import inspectionsReducer, { State as InspectionsState } from './inspections';
import maintenanceReducer, { State as MaintenanceState } from './maintenance';
import modalFlowsReducer, {
	State as ModalFlowsReducerState,
} from './modal-flows';
import propertiesReducer, { State as PropertiesState } from './properties';
import sessionOptionsReducer, {
	State as SessionOptionsState,
} from './session-options';
import snackbarReducer, { State as SnackbarState } from './snackbar';
import subscriptionReducer, {
	State as SubscriptionState,
} from './subscription';
import teamReducer, { State as TeamState } from './team';
import teammatesReducer, { State as TeammatesState } from './teammates';
import teamsReducer, { State as TeamsState } from './teams';
import tenantsReducer, { State as TenantsState } from './tenants';
import userSettingsReducer, {
	State as UserSettingsState,
} from './user-settings';

export interface RootState {
	auth: { error?: string };
	activeProfile: Profile;
	authenticatedUser: Profile;
	userSettings: UserSettingsState;

	dashboard: DashboardState;

	inspections: InspectionsState;
	properties: PropertiesState;
	maintenance: MaintenanceState;

	features: FeaturesState;
	firebase: { auth: any };
	fileRequests: FileRequestsState;
	images: any;

	invites: any;
	modalFlows: ModalFlowsReducerState;
	organization?: ApiTeamDetails;
	team: TeamState;
	teams: TeamsState;
	sessionOptions: SessionOptionsState;
	snackbar: SnackbarState;
	subscription: SubscriptionState;
	accountSettings?: AccountSettingsState;
	tenants: TenantsState;
	inspectionTemplates: InspectionTemplatesState;
	teammates: TeammatesState;
}

const rootReducer = combineReducers({
	auth: authReducer,
	accountSettings: accountSettingsReducer,
	activeProfile: activeProfileReducer,
	authenticatedUser: authenticatedUserReducer,
	dashboard: dashboardReducer,
	features: featuresReducer,
	fileRequests: fileRequestsReducer,
	firebase: firebaseReducer,
	inspections: inspectionsReducer,
	inspectionTemplates: inspectionTemplatesReducer,
	properties: propertiesReducer,
	maintenance: maintenanceReducer,
	invites: inviteReducer,
	modalFlows: modalFlowsReducer,
	team: teamReducer,
	teams: teamsReducer,
	teammates: teammatesReducer,
	sessionOptions: sessionOptionsReducer,
	snackbar: snackbarReducer,
	subscription: subscriptionReducer,
	tenants: tenantsReducer,
	userSettings: userSettingsReducer,
});

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default rootReducer;
