import { firestore } from 'firebase/app';
import _ from 'lodash';

import { UnitsApi as UnitsApiAWS } from '@rentcheck/api-frontend';
import {
	CreateUnitParams,
	Inspection,
	Profile,
	Property,
} from '@rentcheck/types';
import { PropertiesApi } from 'api';
import { objectsFromQuerySnapshot } from 'api/helpers';
import { Dispatch, GetFirebase, GetState } from 'types';

type Firestore = firestore.Firestore;
type ReduxFn = (a: Dispatch, b: GetState, c: GetFirebase) => any;

export type UnitsActionTypes =
	| 'SET_UNIT'
	| 'SET_UNITS'
	| 'DELETE_UNIT'
	| 'IMPERSONATE'
	| 'SIGNOUT_SUCCESS';

export const actionTypes: { [index: string]: UnitsActionTypes } = {
	SET_UNIT: 'SET_UNIT',
	SET_UNITS: 'SET_UNITS',
	DELETE_UNIT: 'DELETE_UNIT',
	IMPERSONATE: 'IMPERSONATE',
	SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
};

export const create = (payload: CreateUnitParams) => {
	const fn: ReduxFn = async (dispatch) => {
		const newUnit = await UnitsApiAWS.create(payload);

		dispatch({ type: 'FETCHED_UNIT', property: newUnit });

		return newUnit;
	};

	return fn;
};

export const updateProperty = (id: string, payload: Partial<Property>) => {
	const fn: ReduxFn = async (dispatch, getState, { getFirestore }) => {
		const db = getFirestore();
		await db.collection('properties').doc(id).update(payload);

		const updatedUnit = (
			await db.collection('properties').doc(id).get()
		).data() as Property;

		UnitsApiAWS.getById(id).then((unit) =>
			dispatch({
				type: 'UPDATED_UNITS',
				properties: [unit as unknown as Property],
			})
		);

		if (updatedUnit) {
			dispatch({ type: 'FETCHED_PROPERTY_FB', property: updatedUnit });
			return updatedUnit;
		}
	};

	return fn;
};

export const updateOrganization = (
	propertyIds: string[],
	organizationId: string
) => {
	const fn: ReduxFn = async (dispatch) => {
		const units = await PropertiesApi.updateOrganization(
			propertyIds,
			organizationId
		);

		Promise.all(propertyIds.map((id) => UnitsApiAWS.getById(id))).then(
			(properties) =>
				dispatch({
					type: 'UPDATED_UNITS',
					properties: properties as unknown as Property[],
				})
		);

		if (units.length === 1) {
			dispatch({ type: 'FETCHED_PROPERTY_FB', property: units[0] });
		}

		if (units.length) {
			dispatch({ type: actionTypes.SET_UNITS, units });
			return units;
		}
	};

	return fn;
};

export const assignUnitsToBuilding = (
	unitIds: string[],
	buildingId: string
) => {
	const fn: ReduxFn = async (dispatch) => {
		const units = await PropertiesApi.assignUnitsToBuilding(
			unitIds,
			buildingId
		);

		if (units.length) {
			dispatch({ type: actionTypes.SET_UNITS, units });
			return units;
		}
	};

	return fn;
};

export const setProperty = (unit: Property) => {
	const fn: ReduxFn = (dispatch: Dispatch) => {
		return dispatch({ type: actionTypes.SET_UNIT, unit });
	};

	return fn;
};

export const requestProperties = () => {
	const fn: ReduxFn = async (dispatch, getState, { getFirestore }) => {
		const state = getState();
		const user: Profile = state.activeProfile;
		const db = getFirestore();
		const units: Property[] = [];

		const userId = user?.id;

		if (!userId) return dispatch({ type: actionTypes.SET_UNITS, units });

		const fetchIds = [userId];

		if (user.organizations && user.organizations.length) {
			user.organizations.forEach((o) => fetchIds.push(o.id));
		}

		const chunks = _.chunk(fetchIds, 10);

		for (const chunk of chunks) {
			const properties = await db
				.collection('properties')
				.where('authorized_user_ids', 'array-contains-any', chunk)
				.get()
				.then((snapshot) => objectsFromQuerySnapshot<Property>(snapshot))
				.catch(() => [] as Property[]);

			const filteredProperties = properties.filter(
				(p) => !['Building', 'Community'].includes(p.property_type)
			);

			filteredProperties.forEach((p) => addLegacyPropertyFields(p));

			units.push(...filteredProperties);
		}

		dispatch({ type: actionTypes.SET_UNITS, units });

		getUnlinkedProperties(user, db, dispatch);
	};

	return fn;
};

export const editProperty = (update: any, id: string) => {
	const fn: ReduxFn = async (dispatch, _getState, { getFirestore }) => {
		const db = getFirestore();

		await db.collection('properties').doc(id).update(update);

		const unit = await db
			.collection('properties')
			.doc(id)
			.get()
			.then((doc) => doc.data() as Property);

		if (!unit) return;

		dispatch({ type: actionTypes.SET_UNIT, unit });
		return dispatch({ type: actionTypes.SET_UNIT, unit });
	};

	return fn;
};

const getUnlinkedProperties = async (
	user: Profile,
	admin: Firestore,
	dispatch: Dispatch
) => {
	const fetchIds = [user.id];

	if (!user) return;

	if (user.organizations?.length)
		user.organizations.forEach((o: any) => fetchIds.push(o.id));

	const inspections: Inspection[] = [];

	const chunks = _.chunk(fetchIds, 10);

	for (const chunk of chunks) {
		await admin
			.collection('inspections')
			.where('authorized_user_ids', 'array-contains-any', chunk)
			.get()
			.then((querySnapshot: firestore.QuerySnapshot) => {
				querySnapshot.forEach((doc) =>
					inspections.push(doc.data() as Inspection)
				);
				return inspections;
			})
			.catch(() => []);
	}

	const properties: Property[] = [];

	for (const chunk of chunks) {
		await admin
			.collection('properties')
			.where('authorized_user_ids', 'array-contains-any', chunk)
			.get()
			.then((querySnapshot: firestore.QuerySnapshot) => {
				querySnapshot.forEach((doc) => properties.push(doc.data() as Property));
				return properties;
			})
			.catch(() => []);
	}

	const missingPropertyIds: string[] = [];
	inspections.forEach((i) => {
		if (!properties.find((p) => p.id === i.propertyID))
			missingPropertyIds.push(i.propertyID);
	});

	const units: Property[] = [];
	for (const id of missingPropertyIds) {
		if (!id) continue;
		await admin
			.collection('properties')
			.doc(id)
			.get()
			.then((doc) => {
				const property = doc.data() as Property;

				if (
					!property ||
					['Building', 'Community'].includes(property.property_type)
				) {
					return;
				}

				const foundUnit = units.find((u) => u.id === property.id);
				if (!foundUnit) {
					property.unowned = true;
					units.push(property);
				}
			})
			.catch(() => []);
	}

	dispatch({ type: actionTypes.SET_UNITS, units });
};

const addLegacyPropertyFields = (property: Property) => {
	const { half_bathrooms, full_bathrooms, bedrooms } = property;

	if (!half_bathrooms || !full_bathrooms || !bedrooms) {
		const updatedProperty = property;
		const HB = [];
		const FB = [];
		const BR = [];
		property.rooms.forEach((room) => {
			if (room === 'Half Bathroom') HB.push(room);
		});
		property.rooms.forEach((room) => {
			if (room === 'Full Bathroom' || room === 'Bathroom') FB.push(room);
		});
		property.rooms.forEach((room) => {
			if (room === 'Bedroom') BR.push(room);
		});
		updatedProperty.half_bathrooms = HB.length;
		updatedProperty.full_bathrooms = FB.length;
		updatedProperty.bedrooms = BR.length;
		return updatedProperty;
	}
	return property;
};
