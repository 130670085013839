import { LoadingButton } from '@mui/lab';
import {
	Card,
	CardContent,
	Divider,
	TextField,
	Typography,
} from '@mui/material';
import { Property } from '@rentcheck/types';
import { PropertiesApi } from 'api';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import styled from 'styled-components';
import { Dispatch } from 'types';

import DeleteProperty from './delete';
import RestoreProperty from './restore';
import TransferProperty from './transfer';

const PropertyTools = () => {
	const [propertyId, setPropertyId] = useState('');
	const [loading, setLoading] = useState(false);

	const [property, setProperty] = useState<Property | undefined>(undefined);

	const dispatch: Dispatch = useDispatch();

	const search = async (propertyId: string) => {
		setProperty(undefined);
		setLoading(true);

		PropertiesApi.getById(propertyId)
			.then(setProperty)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const refreshProperty = () => {
		setLoading(true);
		PropertiesApi.getById(property?.id ?? '')
			.then(setProperty)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Property tools</Typography>
				<Spacer height={2} />
				<Typography>Search by Property Id:</Typography>
				<Spacer height={4} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						search(propertyId);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="Property ID"
						onChange={(e) => setPropertyId(e.target.value)}
					/>

					<Spacer width={6} />
					<LoadingButton
						type="submit"
						variant="contained"
						onClick={() => search(propertyId)}
						loading={loading}>
						Search
					</LoadingButton>
				</FormRow>

				{!!property && (
					<SpacedRow style={{ alignItems: 'flex-start', paddingTop: 32 }}>
						<Column style={{ width: '50%' }}>
							<Card>
								<CardContent>
									<Typography variant="h6">{property.property_type}</Typography>
									<Typography>
										{property.address}, {property.city} {property.zipcode}
									</Typography>
									<Typography>
										Created {property.created_date.toDate().toDateString()}
									</Typography>
									<Divider sx={{ marginTop: 1, marginBottom: 2 }} />
									<Row>
										<Typography fontWeight={600}>
											Authorized User IDs:
										</Typography>
										<Spacer width={1} />
										<Typography>
											{property.authorized_user_ids.join(', ')}
										</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Organization ID:</Typography>
										<Spacer width={1} />
										<Typography>{property.organization_id}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Creator ID:</Typography>
										<Spacer width={1} />
										<Typography>{property.creatorID}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Landlord ID:</Typography>
										<Spacer width={1} />
										<Typography>{property.landlordID}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Property ID:</Typography>
										<Spacer width={1} />
										<Typography>{property.id}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Owner ID:</Typography>
										<Spacer width={1} />
										<Typography>{property.ownerID}</Typography>
									</Row>
								</CardContent>
							</Card>
						</Column>
						<Column style={{ width: '48%', alignItems: 'flex-start' }}>
							<RestoreProperty
								property={property}
								refreshProperty={refreshProperty}
							/>
							<TransferProperty
								property={property}
								refreshProperty={refreshProperty}
							/>
							<DeleteProperty
								property={property}
								refreshProperty={refreshProperty}
							/>
							<Spacer height={4} />
						</Column>
					</SpacedRow>
				)}
			</CardContent>
		</Card>
	);
};

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	width: 50%;
`;

export default PropertyTools;
