import { Property } from '@rentcheck/types';
import { useState } from 'react';

import { Spacer, TabBar } from 'components';

import PropertyInspectionList from '../inspections-list';
import { MaintenanceReportsList } from '../maintenance-reports';
import WorkOrdersList from '../work-orders';

export const options = [
	'Inspections',
	'Maintenance Reports',
	'Work Orders',
] as const;

export type ListType = (typeof options)[number];

interface ListsProps {
	property: Property;
	type: ListType;
}

const Lists = ({ property, type }: ListsProps) => {
	switch (type) {
		case 'Inspections':
			return <PropertyInspectionList property={property} />;
		case 'Maintenance Reports':
			return <MaintenanceReportsList property={property} />;
		case 'Work Orders':
			return <WorkOrdersList property={property} />;
	}
};

interface Props {
	property: Property;
}

const Content = ({ property }: Props) => {
	const [listType, setListType] = useState<ListType>('Inspections');

	const tabItems = [
		{
			label: 'Inspections',
			onClick: () => setListType('Inspections'),
		},
		{
			label: 'Maintenance Reports',
			onClick: () => setListType('Maintenance Reports'),
		},
		{
			label: 'Work Orders',
			onClick: () => setListType('Work Orders'),
		},
	];

	return (
		<>
			<Spacer height={4} />
			<TabBar small options={tabItems} type={listType} />
			<Lists property={property} type={listType} />
		</>
	);
};

export default Content;
