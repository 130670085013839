import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, TableBody, TableRow, TableCell } from '@mui/material';

import { WorkOrder } from '@rentcheck/api-frontend';
import { MaintenanceFlag, Property } from '@rentcheck/types';

import { Dispatch } from 'types';
import { colors } from 'theme';
import { Column } from 'components';
import { useWorkOrdersForPropertyId } from 'hooks/work-orders';
import { SnackbarActions } from 'store/actions';
import EmptyState from 'screens/maintenance/list/common/empty-state';

import DetailsTable from '../table';
import PropertyDetailsSkeleton from '../table/skeleton';
import { formatDate } from 'utils/helpers';

interface Props {
	property: Property;
}

const List = ({ property }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const [startIndex, setStartIndex] = useState(0);
	const [loading, setLoading] = useState(true);
	const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
	const [totalResults, setTotalResults] = useState<number>(0);

	useEffect(() => {
		useWorkOrdersForPropertyId(0, property.id)
			.then((result) => {
				setWorkOrders(result.data);
				setTotalResults(result.totalResults);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		if (workOrders.length >= totalResults) return;

		useWorkOrdersForPropertyId(workOrders.length, property.id)
			.then((result) => setWorkOrders([...workOrders, ...result.data]))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));
	}, [startIndex]);

	if (loading) {
		return <PropertyDetailsSkeleton />;
	}
	if (workOrders.length === 0) {
		return <EmptyState type="workOrders" />;
	}

	const workOrderSummary = (flags: MaintenanceFlag[]) => {
		const featuresList = flags.map((f) => f.feature).join(' | ');
		const totalCost = flags.reduce((acc, cur) => acc + (cur.cost ?? 0), 0);

		return (
			<Column>
				<Typography fontWeight={600}>
					{flags.length} Flag
					{flags.length === 1 ? '' : 's'}
					{totalCost === 0 ? '' : ` - $${totalCost.toFixed(2)}`}
				</Typography>
				<Typography style={{ color: colors.gray }}>{featuresList}</Typography>
			</Column>
		);
	};

	const pageSize = 5;
	const headers = ['Name', 'Report Summary', 'Date Created', 'Status'];

	return (
		<DetailsTable
			headers={headers}
			length={totalResults}
			pageSize={pageSize}
			startIndex={startIndex}
			setStartIndex={setStartIndex}>
			<TableBody>
				{workOrders
					.slice(startIndex * pageSize, startIndex * pageSize + pageSize)
					.map((wo) => {
						return (
							<TableRow
								key={wo.id}
								sx={{ cursor: 'pointer' }}
								onClick={() =>
									history.push(`/maintenance/work-orders/${wo.id}`)
								}>
								<TableCell>{wo.name}</TableCell>
								<TableCell>{workOrderSummary(wo.maintenance_flags)}</TableCell>
								<TableCell>{formatDate(wo.created_date)}</TableCell>
								<TableCell>
									<Column>
										<Typography>{wo.status}</Typography>
										<Typography color="#61768F">
											{wo.latchel?.status}
										</Typography>
									</Column>
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</DetailsTable>
	);
};

export default List;
