import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Building, Community } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, Typography } from '@mui/material';
import { TeamsApi } from '@rentcheck/api-frontend';
import { ApiTeam } from '@rentcheck/types';
import { Column, Row, ScreenTitleRow, Spacer } from 'components';
import { Modal, ModalFooter, NewInspectionButton } from 'components/Common';
import CreateInspectionComparisonModal from 'components/inspections/Comparison/Create';
import MergePropertyButton from 'components/merge-property-button';
import { Team } from 'components/properties/CreateBuilding';
import IntegrationStatus from 'components/properties/details/integration-status';
import _ from 'lodash';
import { InspectionActions } from 'store/actions';
import * as BuildingActions from 'store/actions/buildingsActions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { formattedAddress } from 'utils/helpers';
import EditCommunityModal from '../community/edit-property-modal';
import EditBuildingModal from './edit-property-modal';

interface Props {
	property: Community | Building;
}

const getAdditionalOrganizationIds = (property?: Building | Community) =>
	_.without(
		property?.authorized_user_ids ?? [],
		property?.organization_id ?? ''
	);

const PropertyDetailsHeader = ({ property }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [completedInspections, setCompletedInspections] = useState([]);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [comparisonModalVisible, setComparisonModalVisible] = useState(false);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [selectTeamModalVisible, setSelectTeamModalVisible] = useState(false);

	const [adminOrganization, setAdminOrganization] = useState<ApiTeam>();
	const [adminOrganizationId, setAdminOrganizationId] = useState('');
	const [additionalOrganizationNames, setAdditionalOrganizationNames] =
		useState('');
	const [additionalOrganizationIds, setAdditionalOrganizationIds] = useState(
		getAdditionalOrganizationIds(property)
	);

	const userIsMemberOfMultipleOrgs = profile?.organizations?.length > 0;
	const userIsMemberOfBuildingAdminOrg = !!adminOrganization;

	const fetchPropertyOrganizations = async () => {
		if (property?.organization_id) {
			TeamsApi.getById(property.organization_id)
				.then((organization) => {
					setAdminOrganization(organization);
					setAdminOrganizationId(property.organization_id ?? '');
				})
				.catch(() => {});
		}

		if ((property?.authorized_user_ids.length ?? 0) > 1) {
			Promise.all(
				getAdditionalOrganizationIds(property).map(TeamsApi.getById)
			).then((teams) => {
				const names = teams.map((o) => o.internal_label);

				if (teams.length) {
					setAdditionalOrganizationNames(names.join(', '));
					setAdditionalOrganizationIds(getAdditionalOrganizationIds(property));
				}
			});
		} else {
			setAdditionalOrganizationNames('');
			setAdditionalOrganizationIds(getAdditionalOrganizationIds(property));
		}
	};

	const fetchInspectionsForComparison = async () => {
		if (!property) return;

		const comparisonInspections =
			await InspectionActions.getInspectionsForComparison(property.id);

		if (comparisonInspections.length > 1) {
			setCompletedInspections(comparisonInspections);
		}
	};

	const resetSelectTeamModal = () => {
		setSelectTeamModalVisible(false);
		if (property?.organization_id) {
			setAdminOrganizationId(property.organization_id);
			setAdditionalOrganizationIds(getAdditionalOrganizationIds(property));
		}
	};

	const handleSaveTeam = () => {
		if (!adminOrganizationId) {
			return;
		}

		setLoading(true);

		dispatch(
			BuildingActions.updateOrganizations(
				property.id,
				adminOrganizationId,
				additionalOrganizationIds
			)
		)
			.then(() => setSelectTeamModalVisible(false))
			.catch((e) => setError(e.messsage))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchPropertyOrganizations();
		fetchInspectionsForComparison();
	}, [property]);

	const renderOrganizationName = () => {
		if (!adminOrganization) {
			return;
		}

		const organizationName = adminOrganization.internal_label + ' (Admin)';

		const organizationText = additionalOrganizationNames
			? [organizationName, additionalOrganizationNames].join(', ')
			: organizationName;

		if (!userIsMemberOfMultipleOrgs || !userIsMemberOfBuildingAdminOrg) {
			return <Typography>{organizationText}</Typography>;
		}

		return (
			<ClickableRow
				onClick={() => {
					setSelectTeamModalVisible(true);
				}}>
				<Typography>{organizationText}</Typography>
				<Spacer width={2} />
				<FontAwesomeIcon icon={solid('edit')} color={colors.gray} size="xs" />
			</ClickableRow>
		);
	};

	return (
		<ScreenTitleRow>
			<Column>
				<Typography variant="subtitle1">{property.name}</Typography>
				<Spacer height={1} />
				<ClickableRow onClick={() => setEditModalVisible(true)}>
					<Typography>{formattedAddress(property)}</Typography>
					<Spacer width={2} />
					<FontAwesomeIcon icon={solid('edit')} color={colors.gray} size="xs" />
				</ClickableRow>
				<Spacer height={1} />
				{renderOrganizationName()}
				<Spacer height={1} />
				<IntegrationStatus property={property} />
			</Column>
			<Row>
				<MergePropertyButton property={property} />
				{completedInspections.length > 0 && (
					<>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setComparisonModalVisible(true)}>
							Compare Inspections
						</Button>
						<Spacer width={3} />
					</>
				)}
				<NewInspectionButton property={property} />
			</Row>

			{comparisonModalVisible && (
				<CreateInspectionComparisonModal
					property={property}
					inspections={completedInspections}
					setModalVisible={setComparisonModalVisible}
				/>
			)}

			{editModalVisible && property.property_type === 'Building' && (
				<EditBuildingModal
					property={property as Building}
					section="Property Address"
					setHidden={() => setEditModalVisible(false)}
				/>
			)}

			{editModalVisible && property.property_type === 'Community' && (
				<EditCommunityModal
					property={property as unknown as Building}
					section="Property Address"
					setHidden={() => setEditModalVisible(false)}
				/>
			)}

			{selectTeamModalVisible && (
				<Modal setHidden={() => resetSelectTeamModal()}>
					<Team
						organizationId={adminOrganizationId}
						setOrganizationId={setAdminOrganizationId}
						additionalOrganizationIds={additionalOrganizationIds}
						setAdditionalOrganizationIds={setAdditionalOrganizationIds}
						type={property.property_type}
					/>
					<ModalFooter
						leftButtonTitle="Cancel"
						leftButtonOnClick={() => resetSelectTeamModal()}
						rightButtonTitle="Assign Team"
						rightButtonOnClick={handleSaveTeam}
						error={error}
						loading={loading}
					/>
				</Modal>
			)}
		</ScreenTitleRow>
	);
};

const ClickableRow = styled(Row)`
	cursor: pointer;
`;

export default PropertyDetailsHeader;
