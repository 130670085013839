import { RentmanagerIntegration } from '@rentcheck/types';
import { IntegrationsApi } from 'api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useRentManagerIntegrations = () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [rentManagerIntegrations, setRentManagerIntegrations] = useState<
		RentmanagerIntegration[]
	>([]);
	const [loadingRentManagerIntegration, setLoadingRentManagerIntegration] =
		useState(true);

	useEffect(() => {
		if (!subscription) {
			return;
		}
		setLoadingRentManagerIntegration(true);

		IntegrationsApi.getAllRentmanagerIntegrationsBySubscriptionId(
			subscription.id
		)
			.then((data) => setRentManagerIntegrations(data))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoadingRentManagerIntegration(false));
	}, [subscription]);

	return {
		loading: loadingRentManagerIntegration,
		rentManagerIntegrations: rentManagerIntegrations || [],
	};
};
