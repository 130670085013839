import { Button, MenuItem } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
	ApiInspectionTemplate,
	ApiInspectionTemplateDigest,
	TemplateSection,
} from '@rentcheck/types';
import assets from 'assets';
import { DialogTitle } from 'components';
import IconButton from 'components/icon-button';
import _ from 'lodash';
import { useState } from 'react';
import {
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { containsIncompleteFeatures } from '../features/helpers';

interface Props {
	template: ApiInspectionTemplate | null;
	sections: TemplateSection[];
	editing: boolean;
	setEditing: (editing: boolean) => void;
	onSave: () => Promise<boolean>;
	onClose: () => void;
	onCopy: () => void;
}

export default ({
	template,
	sections,
	editing,
	setEditing,
	onSave,
	onClose,
	onCopy,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [publishLoading, setPublishLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	const handleEdit = () => {
		setEditing(true);
	};

	const handleSave = () => {
		if (!template) return;

		setSaveLoading(true);

		return onSave()
			.then((success) => {
				if (!success) return;

				dispatch(
					SnackbarActions.showSuccess(`Success! ${template.name} saved. `)
				);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setSaveLoading(false));
	};

	if (!template) return null;

	const trackPublishingStatusChangedEvent = (
		eventName:
			| 'inspection_template_published'
			| 'inspection_template_unpublished',
		template: ApiInspectionTemplateDigest
	) =>
		Analytics.trackEvent(eventName, {
			inspection_template_id: template.id,
			property_type: template.property_type,
			org_ids: (template.is_rc_template
				? ['is rc template']
				: template.team_ids ?? []
			).join(', '),
		});

	const handlePublish = async () => {
		const handlePublishConfirmed = async () => {
			setPublishLoading(true);
			dispatch(ModalFlowActions.setConfirmationModalLoading(true));

			if (editing) {
				await handleSave();
				setEditing(false);
			}

			dispatch(InspectionTemplatesActions.setTemplatePublished(template, true))
				.then(() => {
					dispatch(ModalFlowActions.closeConfirmationModal());
					dispatch(
						SnackbarActions.showSuccess(`Success! ${template.name} published. `)
					);
					trackPublishingStatusChangedEvent(
						'inspection_template_published',
						template
					);
				})
				.catch((e) => dispatch(SnackbarActions.showError(e)))
				.finally(() => {
					setPublishLoading(false);
					dispatch(ModalFlowActions.setConfirmationModalLoading(false));
				});
		};

		if (!editing) {
			handlePublishConfirmed();
			return;
		}

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: `Ready to Publish ${template.name}?`,
				image: assets.inspectionTemplates.publish,
				body1: [
					'Confirming publish will save changes and publish the template.',
					'Once live, this template will be available for use when creating inspections.',
				],
				buttons: [
					{
						title: 'Cancel',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{ title: 'Publish', onClick: handlePublishConfirmed },
				],
			})
		);

		return;
	};

	const handleUnpublish = async () => {
		setPublishLoading(true);

		if (editing) {
			await handleSave();
			setEditing(false);
		}

		dispatch(InspectionTemplatesActions.setTemplatePublished(template, false))
			.then(() => {
				dispatch(SnackbarActions.showSuccess(`${template.name} unpublished. `));
				trackPublishingStatusChangedEvent(
					'inspection_template_unpublished',
					template
				);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setPublishLoading(false));
	};

	const handleEditTemplate = () => {
		dispatch(
			ModalFlowActions.showCreateInspectionTemplateModal({
				mode: template.property_type,
				template,
			})
		);
	};

	if (!template) return null;

	const bottomSubtitle = () => {
		const templateType = `${_.capitalize(template.property_type)} Inspection`;

		if (!template.internal_label || template.internal_label === template.name) {
			return templateType;
		}

		return `${templateType} - ${template.internal_label}`;
	};

	const disabled = containsIncompleteFeatures(sections);
	const canEditTemplate = !template.is_rc_template || profile?.template_admin;

	return (
		<DialogTitle
			title={template.name}
			titleBadge={template.is_published ? 'Live' : undefined}
			bottomSubtitle={bottomSubtitle()}
			onClose={onClose}
			mainActionButtons={
				<IconButton icon={regular('ellipsis-vertical')} disabled={disabled}>
					{!template.is_rc_template && (
						<MenuItem onClick={handleEditTemplate}>
							Edit Template Details
						</MenuItem>
					)}
					<MenuItem onClick={onCopy}>Copy Template</MenuItem>
				</IconButton>
			}
			secondaryActionButtons={
				<>
					{template.is_published && (
						<LoadingButton
							disabled={disabled}
							loading={publishLoading}
							onClick={handleUnpublish}
							startIcon={<FontAwesomeIcon icon={regular('eye-slash')} />}
							variant="outlined">
							Unpublish
						</LoadingButton>
					)}
					{!template.is_published && (
						<LoadingButton
							disabled={disabled}
							loading={publishLoading}
							onClick={handlePublish}
							startIcon={<FontAwesomeIcon icon={regular('upload')} />}
							variant="outlined">
							Publish
						</LoadingButton>
					)}
					{editing && (
						<LoadingButton
							disabled={disabled}
							onClick={handleSave}
							loading={saveLoading}
							startIcon={<FontAwesomeIcon icon={regular('save')} />}>
							Save changes
						</LoadingButton>
					)}
					{!editing && canEditTemplate && (
						<Button
							disabled={disabled}
							onClick={handleEdit}
							startIcon={<FontAwesomeIcon icon={regular('edit')} />}>
							Edit Template
						</Button>
					)}
				</>
			}
		/>
	);
};
