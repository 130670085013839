import {
	Autocomplete,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { ApiTeam, BaseIntegration } from '@rentcheck/types';
import { Column, Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import { useTeams } from 'hooks/teams';
import { ReactNodeArray } from 'react';
import { useIsMobileBreakpoint } from 'utils/hooks';
import { ExternalTeam } from '../types';

interface Props {
	vendor: string;
	extTeamName: string;
	error: boolean;
	externalTeams: ExternalTeam[];
	teamMappings?: BaseIntegration['team_mappings'];
	setTeamMappings: (value?: BaseIntegration['team_mappings']) => void;
}

interface CellProps {
	children: ReactNodeArray;
}

const ResponsiveCellGroup = ({ children }: CellProps) => {
	const isMobile = useIsMobileBreakpoint('sm');

	if (isMobile) {
		return (
			<TableCell>
				<Column>
					{children.map((c) => (
						<>
							{c}
							<Spacer height={3} />
						</>
					))}
				</Column>
			</TableCell>
		);
	}

	const width = `${100 / children.length}%`;

	return (
		<>
			{children.map((c) => (
				<TableCell width={width}>{c}</TableCell>
			))}
		</>
	);
};

export default ({
	teamMappings,
	setTeamMappings,
	externalTeams,
	error,
	vendor,
	extTeamName,
}: Props) => {
	const { teams } = useTeams();

	const mappedValueForExternalTeam = (
		externalTeam: ExternalTeam | 'default'
	) => {
		if (externalTeam === 'default') {
			return teams.find((o) => o.id === teamMappings?.default?.team_id);
		}

		return teams.find((o) => o.id === teamMappings?.[externalTeam.id]?.team_id);
	};

	const handleChangeExternalTeamMapping = (
		externalTeam: ExternalTeam | 'default',
		organization: ApiTeam | null
	) => {
		const newMappings = { ...teamMappings };

		if (!organization) {
			if (externalTeam === 'default') {
				delete newMappings.default;
			} else {
				delete newMappings[externalTeam.id];
			}

			setTeamMappings(newMappings);

			return;
		}

		if (externalTeam === 'default') {
			newMappings.default = {
				external_team_id: 'default',
				external_team_name: 'default',
				team_id: organization.id,
				team_name: organization.internal_label,
			};
		} else {
			newMappings[externalTeam.id] = {
				external_team_id: externalTeam.id,
				external_team_name: externalTeam.name,
				team_id: organization.id,
				team_name: organization.internal_label,
			};
		}

		setTeamMappings(newMappings);
	};

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<ResponsiveCellGroup>
							<>
								{vendor} {extTeamName}
							</>
							<>RentCheck Team</>
						</ResponsiveCellGroup>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow>
						<ResponsiveCellGroup>
							<>Set a default team for unmapped properties</>
							<Autocomplete
								fullWidth
								options={teams}
								getOptionLabel={(o) => o.internal_label}
								value={mappedValueForExternalTeam('default')}
								onChange={(_, v) =>
									handleChangeExternalTeamMapping('default', v)
								}
								renderInput={(params) => (
									<FormTextField
										{...params}
										placeholder="RentCheck Team"
										error={error}
										inputSx={{ mb: 0, mt: 0 }}
									/>
								)}
							/>
						</ResponsiveCellGroup>
					</TableRow>

					{externalTeams.map((p) => (
						<TableRow>
							<ResponsiveCellGroup>
								{p.name}

								<Autocomplete
									fullWidth
									options={teams}
									getOptionLabel={(o) => o.internal_label}
									value={mappedValueForExternalTeam(p)}
									onChange={(_, v) => handleChangeExternalTeamMapping(p, v)}
									renderInput={(params) => (
										<FormTextField
											{...params}
											placeholder="RentCheck Team"
											inputSx={{ mb: 0, mt: 0 }}
										/>
									)}
								/>
							</ResponsiveCellGroup>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
