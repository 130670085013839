import { DialogActions, DialogContent, Skeleton } from '@mui/material';
import { Spacer } from 'components';

export const TemplatesSkeleton = () => {
	return (
		<>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={58}
				sx={{
					borderRadius: '4px 4px 0 0',
					mt: 1,
					mb: '1px',
				}}
			/>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={174}
				sx={{
					borderRadius: '0 0 4px 4px',
					mb: '3px',
				}}
			/>
		</>
	);
};

export default () => {
	return (
		<>
			<DialogContent>
				<Skeleton variant="text" width={140} height={18} />
				<Skeleton
					variant="rectangular"
					width="100%"
					height={56}
					sx={{ borderRadius: 2 }}
				/>
				<Skeleton variant="text" width={260} height={14} sx={{ ml: 2 }} />
				<Spacer height={6} />
				<Skeleton variant="text" width={140} height={18} />
				<Skeleton
					variant="rectangular"
					width="100%"
					height={56}
					sx={{ borderRadius: 2 }}
				/>
				<Spacer height={6} />
				<Skeleton variant="text" width={140} height={18} />
				<Skeleton variant="text" width={260} height={14} />
				<TemplatesSkeleton />
			</DialogContent>
			<DialogActions>
				<Skeleton
					variant="rectangular"
					width={65}
					height={36}
					sx={{
						borderRadius: 2,
					}}
				/>
				<Skeleton
					variant="rectangular"
					width={65}
					height={36}
					sx={{
						borderRadius: 2,
					}}
				/>
			</DialogActions>
		</>
	);
};
