import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { ApiSubscription } from '@rentcheck/types';
import moment from 'moment';
import { useTypedSelector } from 'store/reducers/rootReducer';
import themeTypography from 'theme/typography';
import { formatCurrency } from 'utils/helpers';

const Header = (props: { subscription: ApiSubscription }) => {
	const { subscription } = props;

	const headerStyle = {
		...themeTypography.MuiTypography.styleOverrides.overline,
		color: 'black !important',
		backgroundColor: 'white !important',
		fontWeight: '500 !important',
	};

	const showUnitPrice =
		subscription.plan.pricing_model === 'per_unit' ||
		subscription.addons.find((addon) => addon.pricing_model === 'per_unit');

	const itemsCount =
		1 + subscription.addons.length + subscription.coupons.length;

	return (
		<TableHead>
			<TableRow>
				<TableCell sx={headerStyle} colSpan={showUnitPrice ? 1 : 3}>
					Items ({itemsCount})
				</TableCell>
				{showUnitPrice && (
					<>
						<TableCell sx={headerStyle} align="right">
							Units
						</TableCell>
						<TableCell sx={headerStyle} align="right">
							Unit Price
						</TableCell>
					</>
				)}
				<TableCell sx={headerStyle} align="right">
					Amount
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

const PlanSection = (props: { subscription: ApiSubscription }) => {
	const { subscription } = props;

	const hasAddonsOrCoupons =
		subscription.addons.length + subscription.coupons.length > 0;

	const planSx = hasAddonsOrCoupons ? undefined : { borderBottom: 'none' };
	const showUnitPrice = subscription.plan.pricing_model === 'per_unit';

	return (
		<>
			<TableRow>
				<TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
					<Typography variant="subtitle2">Plan</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell sx={planSx} colSpan={showUnitPrice ? 1 : 3}>
					{subscription.plan.plan_name}
				</TableCell>
				{showUnitPrice && (
					<>
						<TableCell align="right" sx={planSx}>
							{subscription.plan.plan_qty}
						</TableCell>
						<TableCell align="right" sx={planSx}>
							{formatCurrency(subscription.plan.plan_unit_price / 100, true)}
						</TableCell>
					</>
				)}
				<TableCell align="right" sx={planSx}>
					{formatCurrency(subscription.plan.plan_amount / 100, true)}
				</TableCell>
			</TableRow>
		</>
	);
};

const AddonsSection = (props: { subscription: ApiSubscription }) => {
	const { subscription } = props;

	if (!subscription.addons.length) {
		return null;
	}

	const hasCoupons = subscription.coupons.length > 0;

	return (
		<>
			<TableRow>
				<TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
					<Typography variant="subtitle2">Addons</Typography>
				</TableCell>
			</TableRow>

			{subscription.addons.map((addon, idx) => {
				const isLast = idx === subscription.addons.length - 1;
				const showUnitPrice = addon.pricing_model === 'per_unit';
				const sx = isLast && hasCoupons ? undefined : { borderBottom: 'none' };

				return (
					<TableRow>
						<TableCell sx={sx} colSpan={showUnitPrice ? 1 : 3}>
							{addon.name}
						</TableCell>
						{showUnitPrice && (
							<>
								<TableCell sx={sx} align="right">
									{addon.quantity}
								</TableCell>
								<TableCell sx={sx} align="right">
									{formatCurrency(addon.unit_price / 100, true)}
								</TableCell>
							</>
						)}
						<TableCell sx={sx} align="right">
							{formatCurrency(addon.amount / 100, true)}
						</TableCell>
					</TableRow>
				);
			})}
		</>
	);
};

export default () => {
	const subscription = useTypedSelector((state) => state.subscription);

	if (!subscription) {
		return null;
	}

	const needsToShowTrialInfo =
		subscription.status === 'in_trial' && subscription.plan_trial;

	return (
		<TableContainer
			component={Paper}
			elevation={0}
			sx={{ border: '1px solid #0000001F', marginTop: 3 }}>
			<Table>
				<Header subscription={subscription} />
				<TableBody>
					<PlanSection subscription={subscription} />
					<AddonsSection subscription={subscription} />

					{subscription.coupons.length > 0 && (
						<>
							<TableRow>
								<TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
									<Typography variant="subtitle2">Coupons</Typography>
								</TableCell>
							</TableRow>
							{subscription.coupons.map((coupon, idx) => (
								<TableRow>
									<TableCell
										sx={{
											borderBottom:
												needsToShowTrialInfo &&
												idx === subscription.coupons.length - 1
													? undefined
													: 'none',
										}}
										colSpan={4}>
										{coupon.name}
									</TableCell>
								</TableRow>
							))}
						</>
					)}

					{needsToShowTrialInfo && (
						<>
							<TableRow>
								<TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
									<Typography variant="subtitle2">
										Trial Period (
										{Utils.Helpers.numberAndPluralizedWord(
											subscription.plan_trial?.duration_in_days ?? 0,
											'day'
										)}
										)
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{ borderBottom: 'none' }} colSpan={4}>
									Days remaining in trial:{' '}
									{moment(subscription.plan_trial?.end).diff(moment(), 'days') +
										1}
								</TableCell>
							</TableRow>
							{!!subscription.payment_method && (
								<TableRow>
									<TableCell sx={{ borderBottom: 'none' }} colSpan={4}>
										The payment method on file will be charged automatically at
										the end of the trial period.
									</TableCell>
								</TableRow>
							)}
						</>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
