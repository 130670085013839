import {
	LoadingButton as MUILoadingButton,
	LoadingButtonProps,
} from '@mui/lab';
import { Button as MUIButton, ButtonProps, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends ButtonProps {
	tooltip?: ReactNode;
}

interface LoadingProps extends LoadingButtonProps {
	tooltip?: ReactNode;
}

export const Button = ({ tooltip, ...props }: Props) => {
	return (
		<Tooltip title={tooltip ?? ''}>
			<div>
				<MUIButton {...props} />
			</div>
		</Tooltip>
	);
};

export const LoadingButton = ({ tooltip, ...props }: LoadingProps) => {
	return (
		<Tooltip title={tooltip ?? ''}>
			<div>
				<MUILoadingButton {...props} />
			</div>
		</Tooltip>
	);
};
