import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InspectionTemplatesApi } from '@rentcheck/api-frontend';
import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

interface Props {
	template: ApiInspectionTemplateDigest;
}

export default ({ template }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [deleteLoading, setDeleteLoading] = useState(false);
	const [publishLoading, setPublishLoading] = useState(false);
	const [cloneLoading, setCloneLoading] = useState(false);

	const trackPublishingStatusChangedEvent = (
		eventName:
			| 'inspection_template_published'
			| 'inspection_template_unpublished',
		template: Props['template']
	) =>
		Analytics.trackEvent(eventName, {
			inspection_template_id: template.id,
			property_type: template.property_type,
			org_ids: (template.is_rc_template
				? ['is rc template']
				: template.team_ids ?? []
			).join(', '),
		});

	const handleDelete = (e?: React.MouseEvent) => {
		e?.stopPropagation();
		e?.preventDefault();

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: `Delete ${template.name}?`,
				body1: [
					'Are you sure you want to delete this inspection template?',
					'This action is irreversible.',
				],
				icon: solid('trash'),
				buttons: [
					{
						title: 'Cancel',
						color: 'secondary',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Delete',
						color: 'error',
						onClick: () => {
							setDeleteLoading(true);
							dispatch(ModalFlowActions.setConfirmationModalLoading(true));

							dispatch(InspectionTemplatesActions.deleteTemplate(template))
								.then(() => dispatch(ModalFlowActions.closeConfirmationModal()))
								.catch((e) => dispatch(SnackbarActions.showError(e)))
								.finally(() => {
									setDeleteLoading(false);
									dispatch(ModalFlowActions.setConfirmationModalLoading(false));
								});
						},
					},
				],
			})
		);
	};

	const handleClone = (e?: React.MouseEvent) => {
		e?.stopPropagation();
		e?.preventDefault();

		setCloneLoading(true);

		InspectionTemplatesApi.get(template.id)
			.then((template) => {
				dispatch(
					ModalFlowActions.showCreateInspectionTemplateModal({
						mode: template.property_type,
						baseTemplate: template,
					})
				);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => {
				setCloneLoading(false);
			});
	};

	const handlePublish = (e?: React.MouseEvent) => {
		e?.stopPropagation();
		e?.preventDefault();

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: `Publish ${template.name}?`,
				body1: [
					'Are you sure you want to publish this template?',
					'Once live, this template will be available for use when creating inspections.',
				],
				icon: solid('eye'),
				buttons: [
					{
						title: 'Cancel',
						color: 'secondary',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Publish',
						onClick: () => {
							setPublishLoading(true);
							dispatch(ModalFlowActions.setConfirmationModalLoading(true));
							dispatch(
								InspectionTemplatesActions.setTemplatePublished(template, true)
							)
								.then(() => dispatch(ModalFlowActions.closeConfirmationModal()))
								.then(() => {
									dispatch(
										SnackbarActions.showSuccess(
											`Success! ${template.name} published.`
										)
									);
									trackPublishingStatusChangedEvent(
										'inspection_template_published',
										template
									);
								})
								.catch((e) => dispatch(SnackbarActions.showError(e)))
								.finally(() => {
									setPublishLoading(false);
									dispatch(ModalFlowActions.setConfirmationModalLoading(false));
								});
						},
					},
				],
			})
		);
	};

	const handleUnpublish = (e?: React.MouseEvent) => {
		e?.stopPropagation();
		e?.preventDefault();

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: `Unpublish ${template.name}?`,
				body1: [
					'Are you sure you want to unpublish this template?',
					"If unpublished, it won't be accessible when creating inspections.",
				],
				icon: solid('eye-slash'),
				buttons: [
					{
						title: 'Cancel',
						color: 'secondary',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Unpublish',
						onClick: () => {
							setPublishLoading(true);
							dispatch(ModalFlowActions.setConfirmationModalLoading(true));

							dispatch(
								InspectionTemplatesActions.setTemplatePublished(template, false)
							)
								.then(() => dispatch(ModalFlowActions.closeConfirmationModal()))
								.then(() => {
									dispatch(
										SnackbarActions.showSuccess(`${template.name} unpublished.`)
									);
									trackPublishingStatusChangedEvent(
										'inspection_template_unpublished',
										template
									);
								})
								.catch((e) => dispatch(SnackbarActions.showError(e)))
								.finally(() => {
									setPublishLoading(false);
									dispatch(ModalFlowActions.setConfirmationModalLoading(false));
								});
						},
					},
				],
			})
		);
	};

	return (
		<>
			{!template.is_rc_template && (
				<IconButton
					loading={deleteLoading}
					icon={solid('trash')}
					tooltip="Delete"
					onClick={handleDelete}
				/>
			)}
			<IconButton
				icon={solid('clone')}
				tooltip="Copy"
				onClick={handleClone}
				loading={cloneLoading}
			/>
			{template.is_published && (
				<IconButton
					loading={publishLoading}
					icon={solid('eye')}
					tooltip="Published"
					onClick={handleUnpublish}
				/>
			)}
			{!template.is_published && (
				<IconButton
					loading={publishLoading}
					icon={solid('eye-slash')}
					tooltip="Unpublished"
					onClick={handlePublish}
				/>
			)}
		</>
	);
};
