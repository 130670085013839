"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCancelled = exports.hasPremiumVideoAddons = exports.hasPropertySyncIntegrationAddons = exports.isOnProPlan = exports.isOnEssentialsPlan = void 0;
const integrations_1 = require("../../permissions/integrations");
const isOnEssentialsPlan = (subscription) => {
    if (!(subscription === null || subscription === void 0 ? void 0 : subscription.plan)) {
        return false;
    }
    return subscription.plan.plan_id.startsWith('essential-plan');
};
exports.isOnEssentialsPlan = isOnEssentialsPlan;
const isOnProPlan = (subscription) => {
    if (!subscription) {
        return false;
    }
    let planId;
    if ('plan' in subscription) {
        // We have an ApiSubscription
        if (!(subscription === null || subscription === void 0 ? void 0 : subscription.plan)) {
            return false;
        }
        planId = subscription.plan.plan_id;
    }
    else {
        // We have a ChargebeeEventSubscription
        planId = subscription.plan_id;
    }
    return planId.startsWith('professional-plan');
};
exports.isOnProPlan = isOnProPlan;
const hasPropertySyncIntegrationAddons = (subscription) => {
    return (integrations_1.AppFolio.canInstall(subscription) ||
        integrations_1.RentManager.canInstall(subscription));
};
exports.hasPropertySyncIntegrationAddons = hasPropertySyncIntegrationAddons;
const hasPremiumVideoAddons = (subscription) => {
    if (!subscription) {
        return false;
    }
    return subscription.addons.some((a) => a.id.startsWith('premium-video'));
};
exports.hasPremiumVideoAddons = hasPremiumVideoAddons;
const isCancelled = (subscription) => {
    if (!subscription)
        return false;
    return subscription.status === 'cancelled';
};
exports.isCancelled = isCancelled;
