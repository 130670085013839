import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PropertiesApi } from 'api';
import FormTextField from 'components/form-text-field';
import { useFlagFeatureModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';

interface Props {
	value: string;
	setValue: (value: string) => void;
	error: string;
}

const Notes = ({ value, setValue, error }: Props) => {
	const modalData = useFlagFeatureModalData();
	const { inspection } = modalData.metadata;

	const [caption, setCaption] = useState('');

	useEffect(() => {
		PropertiesApi.getById(inspection.property.id)
			.then((data) => {
				if (data?.jenark_sync) {
					return setCaption(
						"Provide a description of the issue. This field maps to the work order field 'Details' in Jenark."
					);
				}

				if (data?.yardi_sync) {
					return setCaption(
						"Provide a description of the issue. This is the work order field 'Problem Description' in Yardi."
					);
				}

				if (data?.appfolio_sync) {
					return setCaption(
						"Provide a description of the issue. This is the work order field 'JobDescription' in AppFolio."
					);
				}

				if (data?.rentmanager_sync) {
					return setCaption(
						"Provide a description of the issue. This is the Service Issue Details field 'Description' in Rent Manager"
					);
				}

				setCaption('');
			})
			.catch(() => {});
	}, [inspection]);

	return (
		<FormTextField
			title="Notes"
			helperText="Required"
			caption={caption}
			icon={regular('message-lines')}
			multiline
			error={!!error}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
};

export default Notes;
