import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Community, Property } from '@rentcheck/types';
import _ from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import Modal from 'components/Common/Modal';

import { Dialog, DialogContent } from '@mui/material';
import firebase from 'firebase/app';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import * as CommunitiesActions from 'store/actions/communitiesActions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { DialogTitle } from '../..';
import { Address, Team } from '../CreateBuilding/Components';
import { Confirmation, Footer } from '../CreateUnit/Components';

interface Props {
	setModalVisible: (visible: boolean) => void;
	creationCallback: (property: Property) => void;
}

export type CreateCommunityStep = 'Community Address' | 'Select Team';

const CreateCommunityModal = ({ setModalVisible, creationCallback }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [organizationId, setOrganizationId] = useState('');
	const [additionalOrganizationIds, setAdditionalOrganizationIds] = useState<
		string[]
	>([]);

	const [loading, setLoading] = useState(false);

	const [exitModalVisible, setExitModalVisible] = useState(false);

	const [property, setProperty] = useState<Community | undefined>(undefined);
	const [stepHistory, setStepHistory] = useState<CreateCommunityStep[]>([
		'Community Address',
	]);

	const pushStep = (newStep: CreateCommunityStep) => {
		setStepHistory([...stepHistory, newStep]);
	};

	const backStep = () => {
		if (stepHistory.length <= 1) return;

		setStepHistory(stepHistory.slice(0, stepHistory.length - 1));
	};

	const handleCreateCommunity = () => {
		setLoading(true);

		const payload: Omit<Community, 'id'> = {
			name: name,
			address: address,
			address_lowercase: address.toLowerCase(),
			authorized_user_ids: [organizationId, ...additionalOrganizationIds],
			city: city,
			region: '',
			zipcode: zipcode,
			created_date: firebase.firestore.Timestamp.fromDate(new Date()),
			creatorID: profile.id,
			landlordID: profile.id,
			ownerID: profile.id,
			property_type: 'Community',
			room_names: [],
			rooms: [],
			organization_id: organizationId,
			bedrooms: 0,
			full_bathrooms: 0,
			half_bathrooms: 0,
		};

		dispatch(CommunitiesActions.create(payload))
			.then((createdCommunity: Community) => {
				Analytics.trackEvent('created community', {
					address,
					city,
					zipcode,
					propertyID: createdCommunity.id,
				});

				setProperty(createdCommunity);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const renderContent = () => {
		const currentRenderStep = _.last(stepHistory);

		switch (currentRenderStep) {
			case 'Community Address':
				return (
					<>
						<Address
							name={name}
							address={address}
							city={city}
							zipcode={zipcode}
							setName={setName}
							setAddress={setAddress}
							setCity={setCity}
							setZipcode={setZipcode}
							title="Where is the Community located?"
							firstLabel="Community Name"
							secondLabel="Community Address"
						/>
						<Footer
							leftClick={() => setExitModalVisible(true)}
							rightClick={() => pushStep('Select Team')}
							leftLabel={'Cancel'}
							rightLabel={'Next: Select Team'}
							disabled={!name || !address || !city || !zipcode}
						/>
					</>
				);
			case 'Select Team':
				return (
					<>
						<Team
							type="Community"
							organizationId={organizationId}
							setOrganizationId={setOrganizationId}
							additionalOrganizationIds={additionalOrganizationIds}
							setAdditionalOrganizationIds={setAdditionalOrganizationIds}
						/>
						<Footer
							leftClick={backStep}
							rightClick={handleCreateCommunity}
							rightClickDisabled={() =>
								dispatch(
									SnackbarActions.showError(
										'At least one team must be selected.'
									)
								)
							}
							leftLabel={'Back'}
							rightLabel={'Complete Community'}
							disabled={!organizationId}
							loading={loading}
						/>
					</>
				);
		}
	};

	const currentStep = _.last(stepHistory);

	if (property) {
		const extraText = `${property.name} was successfully created.`;

		return (
			<Modal>
				<Confirmation
					address={address}
					title="Community Created!"
					extraText={extraText}
					dismissModal={() => {
						setModalVisible(false);
						history.push('/properties/communities');
					}}
					showInspectionModal={() => creationCallback(property)}
				/>
			</Modal>
		);
	}

	return (
		<Dialog open onClose={() => setExitModalVisible(true)}>
			{!property && (
				<DialogTitle
					title="Add Community"
					icon={solid('buildings')}
					onClose={() => setExitModalVisible(true)}
					bottomSubtitle={
						currentStep !== 'Community Address'
							? `${name} - ${address}, ${city} ${zipcode}`
							: 'Fill out the details below to add a community.'
					}
				/>
			)}

			<DialogContent>{renderContent()}</DialogContent>

			{exitModalVisible && (
				<ConfirmationModal
					title="Are you sure?"
					body="Do you want to exit and lose all your progress in creating this new community?"
					cancelTitle="Go Back"
					confirmTitle="Exit & Close"
					destructive
					cancelPressed={() => setExitModalVisible(false)}
					confirmPressed={() => setModalVisible(false)}
				/>
			)}
		</Dialog>
	);
};

export { Address, Team };
export default CreateCommunityModal;
