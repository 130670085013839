import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	Divider,
	InputAdornment,
} from '@mui/material';
import { InspectionTemplatesApi } from '@rentcheck/api-frontend';
import {
	AccountSettings,
	ApiInspectionTemplateDigest,
	ApiTeam,
	InspectionTemplate,
} from '@rentcheck/types';

import { DialogTitle } from 'components';
import FormTextField from 'components/form-text-field';
import { useCreateInspectionTemplateModalData } from 'hooks/modals';
import { useTeams } from 'hooks/teams';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import AssignPermissionGroup from 'screens/modal-flows-controller/edit-users-permission-groups/assign-permission-group';
import MoreOptions from './more-options';
import TeamAccess, { TeamAccessT } from './team-access';
import TemplateType from './template-type';
import { isEmailValid } from 'utils/emails';

const getAccessTypeFromTemplate = (
	teams: ApiTeam[],
	template?: ApiInspectionTemplateDigest,
	baseTemplate?: ApiInspectionTemplateDigest
): TeamAccessT => {
	if (!template && !baseTemplate) {
		return 'all-teams';
	}

	if (template && template.team_ids.length === teams.length) {
		return 'all-teams';
	}

	if (baseTemplate && baseTemplate.team_ids.length === teams.length) {
		return 'all-teams';
	}

	return 'select-teams';
};

const getSelectedTeamsFromTemplate = (
	teams: ApiTeam[],
	template?: ApiInspectionTemplateDigest,
	baseTemplate?: ApiInspectionTemplateDigest
): ApiTeam[] => {
	if (template) {
		return teams.filter((t) => template.team_ids.includes(t.id));
	}

	if (baseTemplate) {
		return teams.filter((t) => baseTemplate.team_ids.includes(t.id));
	}

	return [];
};

const getContactInfo = (
	accountSettings?: AccountSettings,
	template?: ApiInspectionTemplateDigest,
	baseTemplate?: ApiInspectionTemplateDigest
): InspectionTemplate['contact_info'] => {
	if (template) {
		return {
			email:
				template?.contact_info.email ||
				accountSettings?.inspection_contact_info.email,
			phone_number:
				template?.contact_info.phone_number ||
				accountSettings?.inspection_contact_info.phone_number,
		};
	}

	if (baseTemplate && !baseTemplate.is_rc_template) {
		return {
			email:
				baseTemplate?.contact_info.email ||
				accountSettings?.inspection_contact_info.email,
			phone_number:
				baseTemplate?.contact_info.phone_number ||
				accountSettings?.inspection_contact_info.phone_number,
		};
	}

	return {
		email: accountSettings?.inspection_contact_info.email,
		phone_number: accountSettings?.inspection_contact_info.phone_number,
	};
};

interface Props {
	onBack: () => void;
	baseTemplate?: ApiInspectionTemplateDigest;
}

const initializeProperty = <
	T extends ApiInspectionTemplateDigest,
	K extends keyof T,
>(
	key: K,
	defaultValue?: T[K],
	templateToEdit?: T,
	baseTemplate?: T
) => {
	if (key === 'name' || key === 'internal_label') {
		if (templateToEdit) {
			return templateToEdit[key];
		}

		if (baseTemplate) {
			return `COPY - ${baseTemplate[key]}`;
		}

		return '';
	}

	if (key === 'permission_groups') {
		return (
			(templateToEdit ?? baseTemplate)?.permission_groups?.map((pg) => pg.id) ??
			defaultValue
		);
	}

	return _.get(templateToEdit, key, _.get(baseTemplate, key, defaultValue));
};

const trackCreationEvent = (
	newTemplate: ApiInspectionTemplateDigest,
	baseTemplate?: ApiInspectionTemplateDigest
) => {
	if (!baseTemplate) {
		Analytics.trackEvent('inspection_template_created', {
			inspection_template_id: newTemplate.id,
			inspection_template_name: newTemplate.name,
			inspection_template_internal_label: newTemplate.internal_label,
			template_type: newTemplate.template_type,
		});
		return;
	}

	Analytics.trackEvent('Inspection_template_copied', {
		copied_template_id: baseTemplate.id,
		is_rc_template: String(baseTemplate.is_rc_template),
		inspection_template_id: newTemplate.id,
		inspection_template_name: newTemplate.name,
		inspection_template_internal_label: newTemplate.internal_label,
		template_type: newTemplate.template_type,
	});
};

export default ({ onBack, baseTemplate }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const { teams } = useTeams();
	const templates = useTypedSelector(
		(state) => state.inspectionTemplates.templates
	);

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const modalData = useCreateInspectionTemplateModalData();
	const template = modalData?.metadata?.template;
	const mode = modalData?.metadata?.mode;

	const [loading, setLoading] = useState(false);
	const [permissionGroupIds, setPermissionGroupIds] = useState<string[]>(
		initializeProperty(
			'permission_groups',
			[],
			template,
			baseTemplate
		) as string[]
	);

	const [internalName, setInternalName] = useState(
		initializeProperty('internal_label', '', template, baseTemplate) as string
	);
	const [externalName, setExternalName] = useState(
		initializeProperty('name', '', template, baseTemplate) as string
	);
	const [description, setDescription] = useState(
		initializeProperty('description', '', template, baseTemplate) as string
	);

	const [templateType, setTemplateType] = useState(
		initializeProperty('template_type', undefined, template, baseTemplate) as
			| InspectionTemplate['template_type']
			| undefined
	);

	const [propertyType, setPropertyType] = useState(
		initializeProperty(
			'property_type',
			mode,
			template,
			baseTemplate
		) as InspectionTemplate['property_type']
	);

	const [isFastTrackEnabled, setIsFastTrackEnabled] = useState(
		initializeProperty(
			'is_fast_track_enabled',
			false,
			template,
			baseTemplate
		) as boolean
	);

	const [accessType, setAccessType] = useState<TeamAccessT>(
		getAccessTypeFromTemplate(teams, template, baseTemplate)
	);
	const [selectedTeams, setSelectedTeams] = useState<ApiTeam[]>(
		getSelectedTeamsFromTemplate(teams, template, baseTemplate)
	);

	const contactInfo = getContactInfo(accountSettings, template, baseTemplate);

	const [email, setEmail] = useState<string>(contactInfo?.email || '');
	const [phone, setPhone] = useState<string>(contactInfo?.phone_number || '');

	const createDisabed = (() => {
		if (!internalName.trim() || !externalName.trim() || !description.trim()) {
			return true;
		}

		if (!templateType) {
			return true;
		}

		if (accessType === 'select-teams' && selectedTeams.length === 0) {
			return true;
		}

		if (permissionGroupIds.length === 0) {
			return true;
		}

		if (email?.trim() && !isEmailValid(email)) {
			return true;
		}

		return false;
	})();

	const internalLabelAlreadyExists = (label: string) => {
		return templates.some(
			(t) =>
				t.id !== template?.id &&
				t.internal_label?.trim().toLowerCase() === label.trim().toLowerCase()
		);
	};

	const handleConfirm = () => {
		if (internalLabelAlreadyExists(internalName)) {
			return dispatch(
				SnackbarActions.showError(
					'A template with this internal name already exists!'
				)
			);
		}

		if (template) {
			return handleSaveEdits();
		}

		return handleCreate();
	};

	const handleSaveEdits = () => {
		if (!template) {
			return;
		}

		setLoading(true);

		dispatch(
			InspectionTemplatesActions.updateDetails(template.id, {
				name: externalName,
				internal_label: internalName,
				description,
				is_fast_track_enabled: isFastTrackEnabled,
				team_ids: _.map(
					accessType === 'all-teams' ? teams : selectedTeams,
					'id'
				),
				contact_info: {
					email: email,
					phone_number: phone,
				},
			})
		)
			.then(() =>
				dispatch(
					InspectionTemplatesActions.editPermissionGroups(template.id, {
						permission_group_ids: permissionGroupIds,
					})
				)
			)
			.then(() => {
				dispatch(SnackbarActions.showSuccess('Template Details Saved!'));
				dispatch(ModalFlowActions.closeCurrentModal());
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleCreate = async () => {
		if (!templateType) {
			return;
		}

		setLoading(true);

		const fullBaseTemplate = baseTemplate
			? await InspectionTemplatesApi.get(baseTemplate.id)
			: undefined;

		return dispatch(
			InspectionTemplatesActions.create({
				name: externalName,
				internal_label: internalName,
				description,
				property_type: propertyType,
				is_fast_track_enabled: isFastTrackEnabled,
				template_type: templateType,
				sections: fullBaseTemplate?.sections || [],
				team_ids: _.map(
					accessType === 'all-teams' ? teams : selectedTeams,
					'id'
				),
				permission_group_ids: permissionGroupIds,
				contact_info: {
					email,
					phone_number: phone,
				},
			})
		)
			.then((data: ApiInspectionTemplateDigest) => {
				// we only track events for templates created from scratch
				trackCreationEvent(data, baseTemplate);

				dispatch(SnackbarActions.showSuccess('Template Created!'));
				dispatch(ModalFlowActions.closeCurrentModal());
				dispatch(
					ModalFlowActions.showInspectionTemplateModal({
						template: data,
						editMode: true,
					})
				);
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!template) return;
	});

	const externalNameCaption =
		'Add a descriptive name that will be visible to residents and others you invite to complete inspections. ' +
		`Recipients will receive a request to complete a ${externalName || '[external inspection template name]'} inspection.`;

	return (
		<>
			<DialogTitle
				title={
					template ? 'Edit Template Details' : 'Create Inspection Template'
				}
				icon={solid('folder-plus')}
				onClose={handleClose}
			/>
			<DialogContent>
				<FormTextField
					required
					title="External Inspection Template Name"
					caption={externalNameCaption}
					helperText="0-50 Characters"
					placeholder="Add an external name"
					inputProps={{ maxLength: 50 }}
					value={externalName}
					onChange={(e) => setExternalName(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">Inspection</InputAdornment>
						),
					}}
				/>
				<FormTextField
					required
					title="Internal Inspection Template Name"
					caption="Add a label for this template for you and team members."
					helperText="0-50 Characters"
					placeholder="Add an internal name"
					inputProps={{ maxLength: 50 }}
					value={internalName}
					onChange={(e) => setInternalName(e.target.value)}
					error={internalLabelAlreadyExists(internalName)}
				/>
				<FormTextField
					required
					multiline
					title="Inspection Template Description"
					helperText="0-500 Characters"
					placeholder="Add a template description"
					inputProps={{ maxLength: 500 }}
					value={description}
					onChange={(e) => setDescription(e.target.value)}
				/>

				<Divider sx={{ mb: 3 }} />

				<TemplateType
					baseTemplate={baseTemplate}
					templateType={templateType}
					setTemplateType={setTemplateType}
					propertyType={propertyType}
					setPropertyType={setPropertyType}
					isFastTrackEnabled={isFastTrackEnabled}
					setIsFastTrackEnabled={setIsFastTrackEnabled}
				/>

				<Divider sx={{ mb: 3 }} />

				<TeamAccess
					accessType={accessType}
					setAccessType={setAccessType}
					selectedTeams={selectedTeams}
					setSelectedTeams={setSelectedTeams}
				/>
				<Divider sx={{ mb: 3 }} />

				<AssignPermissionGroup
					required
					setValue={setPermissionGroupIds}
					value={permissionGroupIds}
					caption="Select the permission groups that you want to have access to this inspection template."
				/>
				<Divider />
				<MoreOptions
					email={email}
					setEmail={setEmail}
					phone={phone}
					setPhone={setPhone}
				/>
			</DialogContent>

			<DialogActions>
				<Button variant="text" color="secondary" onClick={onBack}>
					Back
				</Button>
				<LoadingButton
					disabled={createDisabed}
					onClick={handleConfirm}
					loading={loading}>
					{template ? 'Save' : 'Create'}
				</LoadingButton>
			</DialogActions>
		</>
	);
};
