import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TenantsApi } from '@rentcheck/api-frontend';
import { GetAllTenantsV2Params, ListOptions } from '@rentcheck/types';
import _ from 'lodash';
import { SnackbarActions, TenantsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { State as FiltersState } from 'store/reducers/tenants/filters';
import { Dispatch } from 'types';
import useApiModel from './common/api-hook';

export const useSortedFilteredTenants = (overwriteFilters?: FiltersState) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const tenantsState = useTypedSelector((state) => state.tenants.tenants);
	const filters = useTypedSelector((state) => state.tenants.filters);

	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);

	const fetchResidents = (filters: FiltersState) => {
		setLoading(true);

		dispatch(TenantsActions.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
				setInitialLoading(false);
			});
	};

	const fetchMore = () => {};

	const effectiveFilters = overwriteFilters || filters;

	useEffect(() => {
		if (!profile) return;

		fetchResidents(filters);
	}, [
		profile,
		effectiveFilters.active_only,
		effectiveFilters.search,
		effectiveFilters.propertyId,
		effectiveFilters.email,
		effectiveFilters.sortBy,
		effectiveFilters.sortType,
	]);

	return {
		initialLoading,
		loading,
		fetchMore,
		filters,
		tenants: tenantsState,
	};
};

export const useTenants = (
	pageSize: number = 20,
	filters?: GetAllTenantsV2Params['filters']
) => {
	const defaultSort: ListOptions['sort'] = {
		sort_by: 'first_name',
		sort_type: 'ASC',
	};

	const hookData = useApiModel(
		TenantsApi,
		filters ?? {},
		defaultSort,
		pageSize
	);

	return {
		tenants: hookData.data,
		..._.omit(hookData, 'data'),
	};
};
