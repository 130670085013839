import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Snackbar, SnackbarContent, Typography } from '@mui/material';

import { Utils } from '@rentcheck/biz';

import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const history = useHistory();
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!Utils.Subscriptions.isCancelled(subscription)) return;
		setOpen(true);

		if (location.pathname === '/account/billing') return;
		history.push('/account/billing');
	}, [subscription, location]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			sx={{ marginTop: 6 }}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<SnackbarContent
				style={{ backgroundColor: 'black' }}
				message={
					<>
						<Typography
							style={{ color: 'white', maxWidth: 400, fontWeight: 'bold' }}>
							Your access is currently limited because your RentCheck
							subscription is no longer active. Upgrade now to regain access to
							features available on a RentCheck subscription.
						</Typography>
					</>
				}
				action={
					<Button
						variant="text"
						onClick={handleClose}
						style={{ color: '#ACFFC6' }}>
						Ok
					</Button>
				}
			/>
		</Snackbar>
	);
};
