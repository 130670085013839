"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFromCameraRoll = exports.isRegularPhoto = exports.is360Photo = exports.isVideo = void 0;
/**
 * Returns true if the media type is video
 * @param imageUri the GCP uri for the video
 */
const isVideo = (imageUri) => {
    return imageUri.endsWith('.mp4') || imageUri.includes('.mov');
};
exports.isVideo = isVideo;
/**
 * Returns true if the image was taken by a 360 camera
 * @param imageUri the GCP uri for the image
 */
const is360Photo = (imageUri) => {
    return imageUri.includes('360-image');
};
exports.is360Photo = is360Photo;
/**
 * Returns true if the image is neither a 360 image or a video
 * @param imageUri the GCP uri for the image
 */
const isRegularPhoto = (imageUri) => {
    return !(0, exports.is360Photo)(imageUri) && !(0, exports.isVideo)(imageUri);
};
exports.isRegularPhoto = isRegularPhoto;
/**
 * Returns true if the image was picked from the camera roll
 * @param imageUri the GCP uri for the image
 */
const isFromCameraRoll = (imageUri) => {
    return imageUri.includes('camera-roll');
};
exports.isFromCameraRoll = isFromCameraRoll;
