import {
	ChargebeeAddon,
	ChargebeePlan,
	ChargebeeSubscription,
	Profile,
} from '@rentcheck/types';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import firebase from 'firebase/app';
import { splitUserName } from 'utils/helpers';

const apiUrl = () => {
	return firebase.remoteConfig().getValue('chargebee_url').asString();
};

const demoFollowUpPlanIds = () => {
	return JSON.parse(
		firebase
			.remoteConfig()
			.getValue('demo_follow_up_chargebee_plan_ids')
			.asString()
	) as string[];
};

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

export const getSubscriptionById = async (planId: string) => {
	const resource = '/api/v2/subscriptions/';

	return axios({
		method: 'get',
		url: `${apiUrl()}${resource}${planId}`,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}).then((response) => response.data);
};

export const getPlans = async (): Promise<ChargebeePlan[]> => {
	return firebase
		.app()
		.functions()
		.httpsCallable('chargebeeGetPlans')()
		.then(async (response) => response.data);
};

export const getRemoteConfiguredPlans = async (): Promise<ChargebeePlan[]> => {
	return getPlans().then((data) => {
		const planIds = demoFollowUpPlanIds();
		return data.filter((plan: ChargebeePlan) => planIds.includes(plan.id));
	});
};

export const getAddons = async (): Promise<ChargebeeAddon[]> => {
	return firebase
		.app()
		.functions()
		.httpsCallable('chargebeeGetAddons')()
		.then(async (response) => response.data);
};

export const getSubscription = async (
	subId: string
): Promise<ChargebeeSubscription> => {
	return firebase
		.app()
		.functions()
		.httpsCallable('chargebeeGetSubscription')({ subscription_id: subId })
		.then(async (response) => response.data)
		.catch((e) => {
			Sentry.captureException(e);
			throw e;
		});
};

export const initChargebeeModal = (
	profile: Profile,
	onClose: () => void,
	onSuccess: () => void
) => {
	const cbScript = document.getElementById('cb-script');
	cbScript?.remove();

	const el = document.createElement('script');

	el.onload = () => {
		// cast to any, tsc doesn't like Chargebee as a prop in window
		const chargebeeWindow = window as any;
		const chargebee = chargebeeWindow.Chargebee;
		const chargebeeSite = firebase
			.remoteConfig()
			.getValue('chargebee_site')
			.asString();

		chargebee.init({ site: chargebeeSite });
		chargebee.registerAgain();

		chargebee.getInstance().setCheckoutCallbacks(() => ({
			close: onClose,
			success: onSuccess,
		}));

		const { firstName, lastName } = splitUserName(profile.user_name);

		chargebee
			.getInstance()
			.getCart()
			.setCustomer({
				first_name: firstName,
				last_name: lastName,
				cf_custom_field1: profile.id,
				email: encodeURIComponent(profile.email),
			});
	};

	el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
	el.setAttribute('id', 'cb-script');

	document.body.appendChild(el);
};
