import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CardContent, Paper, Typography } from '@mui/material';
import { Permissions } from '@rentcheck/biz';
import { SpacedRow, Spacer } from 'components';
import { ReactNode, ReactNodeArray } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	title: string;
	children: ReactNode | ReactNodeArray;
	onAdd?: () => void;
}

export default ({ title, children, onAdd }: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	const canEdit =
		Permissions.AccountSettings.PropertyConfiguration.canEdit(subscription);

	return (
		<Paper elevation={0} sx={{ backgroundColor: '#0000000A', marginBottom: 2 }}>
			<CardContent sx={{ padding: 2 }}>
				<SpacedRow>
					<Typography variant="overline">{title}</Typography>
					{!!onAdd && (
						<Button
							onClick={onAdd}
							disabled={!canEdit}
							startIcon={<FontAwesomeIcon icon={solid('plus')} />}>
							Add
						</Button>
					)}
				</SpacedRow>
				<Spacer height={4} />

				{children}
			</CardContent>
		</Paper>
	);
};
