"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canUpload = void 0;
const canUpload = (susbscription) => {
    if (!susbscription) {
        return false;
    }
    if (susbscription.status !== 'active') {
        return false;
    }
    return true;
};
exports.canUpload = canUpload;
