import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import {
	CenteredRow,
	Column,
	PopoverMenu,
	Row,
	SpacedRow,
	Spacer,
	TabBar,
} from 'components';
import { ConfirmationModal } from 'components/Common';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';

import { useTeam } from 'hooks/teams';
import { ModalFlowActions, TeamActions } from 'store/actions';
import EditLogoModal from './edit-logo-modal';
import EditNameModal from './edit-name-modal';
import Invites from './invites-table';
import Teammates from './teammates-table';

const TeamDetails = () => {
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();

	const teamId = location.pathname.split('/')[3];

	const { team } = useTeam(teamId);

	const [loading, setLoading] = useState(false);
	const [editNameModalVisible, setEditNameModalVisible] = useState(false);
	const [editLogoModalVisible, setEditLogoModalVisible] = useState(false);
	const [confirmRemoveLogoVisible, setConfirmRemoveLogoVisible] =
		useState(false);

	const userCanEdit = team?.user_role === 'admin' ?? false;

	if (!teamId) {
		return null;
	}

	if (!team) {
		return (
			<Column
				style={{ alignSelf: 'flex-start', marginLeft: 16, width: '100%' }}>
				<Spacer height={50} />
				<CenteredRow>
					<CircularProgress />
				</CenteredRow>
			</Column>
		);
	}

	const editNameOrInternalLabel = () => {
		setEditNameModalVisible(true);
	};

	const popoverItems = [
		{
			title: 'Edit Company Logo',
			onClick: () => setEditLogoModalVisible(true),
		},
		{
			title: 'Remove',
			danger: true,
			selected: true,
			onClick: () => setConfirmRemoveLogoVisible(true),
			isLast: true,
		},
	];

	const tabItems = [
		{
			label: 'Members',
			url: `/account/teams/${team.id}`,
			isDefault: true,
		},
		{ label: 'Invites', url: `/account/teams/${team.id}/invites` },
	];

	if (!userCanEdit) tabItems.pop();

	const handleRemoveLogo = async () => {
		setLoading(true);

		dispatch(
			TeamActions.update(team.id, {
				logo: null,
			})
		)
			.then(() => setConfirmRemoveLogoVisible(false))
			.catch(Sentry.captureException)
			.finally(() => setLoading(false));
	};

	const teammatesActive = !_.endsWith(location.pathname, 'invites');
	const invitesActive = _.endsWith(location.pathname, 'invites');

	return (
		<Container>
			<TitleRow>
				<Row>
					<Column>
						<Row>
							<Typography variant="subtitle2" color="#4d4d4d">
								Name
							</Typography>
							<Spacer width={2} />
							{userCanEdit && (
								<EditIcon
									icon={solid('edit')}
									onClick={editNameOrInternalLabel}
									color={colors.gray}
								/>
							)}
						</Row>
						<Spacer height={1} />
						<Typography variant="h5">{team.name}</Typography>
					</Column>
					<Spacer width={20} />
					<Column>
						<Row>
							<Typography variant="subtitle2" color="#4d4d4d">
								Internal Label
							</Typography>
							<Spacer width={2} />
							{userCanEdit && (
								<EditIcon
									icon={solid('edit')}
									onClick={editNameOrInternalLabel}
									color={colors.gray}
								/>
							)}
						</Row>
						<Spacer height={1} />
						<Typography variant="h5">{team.internal_label}</Typography>
					</Column>
				</Row>
				{!!team.logo && (
					<Row>
						<LogoImg src={team.logo} />
						{userCanEdit && (
							<PopoverMenu
								anchorPosition="top-right"
								topSpace={2}
								noBorder
								triggerNode={
									<LogoEditIconContainer>
										<FontAwesomeIcon
											icon={solid('edit')}
											color={colors.black}
										/>
									</LogoEditIconContainer>
								}
								options={popoverItems}
							/>
						)}
					</Row>
				)}
				{!team.logo && userCanEdit && (
					<Button
						color="secondary"
						onClick={() => setEditLogoModalVisible(true)}>
						Add a Company Logo
					</Button>
				)}
			</TitleRow>
			<Spacer height={15} />
			<TitleRow>
				<TabBar
					small
					options={tabItems}
					baseRoute={`/account/teams/${team.id}`}
				/>
				{userCanEdit && (
					<Button
						onClick={() =>
							dispatch(ModalFlowActions.showInviteUsers({ teams: [team] }))
						}>
						Invite Teammates
					</Button>
				)}
			</TitleRow>
			<Spacer height={3} />
			{teammatesActive && <Teammates team={team} />}
			{invitesActive && <Invites team={team} />}
			{editNameModalVisible && (
				<EditNameModal team={team} setModalVisible={setEditNameModalVisible} />
			)}
			{editLogoModalVisible && (
				<EditLogoModal team={team} setModalVisible={setEditLogoModalVisible} />
			)}
			{confirmRemoveLogoVisible && (
				<ConfirmationModal
					destructive
					loading={loading}
					title="Are you sure?"
					body="Are you sure you want to remove your current company logo?"
					cancelTitle="Cancel"
					cancelPressed={() => setConfirmRemoveLogoVisible(false)}
					confirmTitle="Yes"
					confirmPressed={handleRemoveLogo}
				/>
			)}
		</Container>
	);
};

const Container = styled(Column)`
	align-items: flex-start;
	align-self: flex-start;
	margin-left: 16px;
	margin-top: 16px;
	width: 100%;
`;

const EditIcon = styled(FontAwesomeIcon)`
	font-size: ${spacingPx(3)};
	cursor: pointer;
`;

const LogoEditIconContainer = styled(Row)`
	height: ${spacingPx(5)};
	width: ${spacingPx(5)};
	position: relative;
	right: ${spacingPx(1)};
	top: -${spacingPx(4)};
	margin-left: -${spacingPx(5)};
	background-color: ${colors.white};
	cursor: pointer;
	justify-content: center;
	border-radius: ${spacingPx(5)};
	font-size: ${spacingPx(3)};
	padding-left: ${spacingPx(0.5)};
`;

const LogoImg = styled.img`
	min-width: ${spacingPx(15)};
	height: ${spacingPx(15)};
	border-radius: ${spacingPx(1)};
`;

const TitleRow = styled(SpacedRow)`
	width: 100%;
	height: ${spacingPx(10)};
`;

export default TeamDetails;
