import { DatePicker } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DialogTitle } from 'components';
import ResponsiveForm from 'components/responsive-form';
import { useEditDueDateModalData } from 'hooks/modals';
import moment from 'moment-timezone';
import {
	InspectionActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';
import { formattedInspectionAddress } from 'utils/helpers';

interface Props {
	open: boolean;
}

const EditDueDate = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useEditDueDateModalData();
	const inspections = modalData?.metadata?.inspections ?? [];
	const onSuccess = modalData?.metadata?.onSuccess;

	const [subtitle, setSubtitle] = useState('');
	const [dueDate, setDueDate] = useState<Date | null>(null);
	const [inviteDate, setInviteDate] = useState<Date | null>(null);
	const [triedToSubmit, setTriedToSubmit] = useState(false);

	const canEditInviteDates = !!inspections.find(
		(i) => i.inspection_status === 'Scheduled'
	);

	useEffect(() => {
		if (!inspections.length) {
			return;
		}

		setDueDate(null);
		setInviteDate(null);
		setTriedToSubmit(false);

		// Setting the subtitle in here so it doesn't get cleared out when the modal is dismissed.
		// A new subtitle will be calculated next time the modal opens
		if (inspections.length === 1) {
			setSubtitle(
				`${
					inspections[0].inspection_template.name
				} Inspection • ${formattedInspectionAddress(inspections[0])}`
			);
		} else {
			setSubtitle(`${inspections.length} inspections`);
		}
	}, [open]);

	useEffect(() => {
		/**
		 * If there's no due date no need to calculate
		 * anything right now
		 */
		if (!dueDate) {
			return setInviteDate(null);
		}

		/**
		 * In any other case we need to select a new invitation date
		 * and for this we go with the default
		 */
		const now = moment();
		const defaultValue = moment(dueDate).subtract(14, 'd');

		if (defaultValue.isBefore(now, 'd')) {
			return setInviteDate(now.toDate());
		}

		return setInviteDate(defaultValue.toDate());
	}, [dueDate]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleUpdate = async () => {
		setTriedToSubmit(true);

		const dueDateMoment = moment(dueDate).startOf('d');
		const inviteDateMoment = moment(inviteDate).startOf('d');
		const today = moment().startOf('d');

		if (!dueDate || !dueDateMoment.isValid() || dueDateMoment.isBefore(today)) {
			return;
		}

		if (canEditInviteDates) {
			if (
				!inviteDate ||
				!inviteDateMoment.isValid() ||
				inviteDateMoment.isBefore(today) ||
				inviteDateMoment.isAfter(dueDateMoment)
			) {
				return;
			}
		}

		handleClose();

		const progress = await dispatch(
			SnackbarActions.showProgress('Updating inspections')
		);

		dispatch(
			InspectionActions.updateInspectionsDueDate(
				inspections,
				dueDate,
				canEditInviteDates && inviteDate ? inviteDate : undefined
			)
		)
			.then(() => {
				dispatch(
					SnackbarActions.showSuccess(
						!inviteDate ? 'New dates saved' : 'New due date saved'
					)
				);

				onSuccess?.();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setDueDate(null);
				dispatch(SnackbarActions.hideSnackbar(progress.id));
			});
	};

	const title = canEditInviteDates ? 'Edit Dates' : 'Edit Due Date';

	const inviteTime = moment().tz('America/Chicago').set('h', 11);
	const userTimezone = moment.tz.guess() || 'America/Chicago';
	const formattedUserTimezone = moment.tz(userTimezone).format('z');
	const userTimezoneHasName = !/^[+-]\d+$/.test(formattedUserTimezone);
	const inviteIsToday = moment(inviteDate).isSame(moment(), 'd');

	const inviteTimeMessage = inviteIsToday
		? 'Invitation will be sent immediately upon inspection creation.'
		: `Invitation will be sent at ` +
			`${inviteTime.tz('UTC').format('ha')} ` +
			`UTC (${inviteTime.tz(userTimezone).format('ha')} ` +
			`${
				userTimezoneHasName ? formattedUserTimezone : `user's timezone`
			}) on the selected day.`;

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title={title}
				bottomSubtitle={subtitle}
				icon={solid('calendar-day')}
				onClose={handleClose}
			/>

			<DialogContent>
				<ResponsiveForm onSubmit={handleUpdate} columns={1} fullWidth={false}>
					<DatePicker
						inputFormat="MM/dd/yy"
						mask="__/__/__"
						disablePast={true}
						value={dueDate}
						renderInput={(props) => (
							<TextField
								{...props}
								required
								fullWidth
								id="input-due-date"
								variant="filled"
								label="Due date"
								error={props.error || (triedToSubmit && !dueDate)}
							/>
						)}
						onChange={(date) => {
							setDueDate(date);
						}}
					/>

					{canEditInviteDates && (
						<DatePicker
							inputFormat="MM/dd/yy"
							mask="__/__/__"
							disablePast={true}
							maxDate={dueDate}
							value={inviteDate}
							renderInput={(props) => (
								<TextField
									{...props}
									required
									fullWidth
									id="input-invite-date"
									variant="filled"
									label="Invitation date"
									helperText={inviteTimeMessage}
									error={props.error || (triedToSubmit && !inviteDate)}
								/>
							)}
							onChange={(date) => {
								setInviteDate(date);
							}}
						/>
					)}
				</ResponsiveForm>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<Button id="button-confirm" variant="contained" onClick={handleUpdate}>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditDueDate;
