import { Tooltip, Typography } from '@mui/material';

import { colors } from '@rentcheck/theme';
import { Property } from '@rentcheck/types';

import { Utils } from '@rentcheck/biz';
import { Column } from 'components';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import { formatDateAndTime, simplePropertyType } from 'utils/helpers';

interface Props {
	property: Property;
}

const Yardi = ({ property }: Props) => {
	if (!property.yardi_sync) {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.yardi_sync.last_sync
			)}`}>
			<Typography>Synced with Yardi</Typography>
		</Tooltip>
	);
};

const AppFolio = ({ property }: Props) => {
	if (!property.appfolio_sync) {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.appfolio_sync.last_sync
			)}`}>
			<Typography>Synced with AppFolio</Typography>
		</Tooltip>
	);
};

const RentManager = ({ property }: Props) => {
	if (!property.rentmanager_sync) {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.rentmanager_sync.last_sync
			)}`}>
			<Typography>Synced with Rent Manager</Typography>
		</Tooltip>
	);
};

interface NotSyncedProps {
	property: Property;
	vendor: string;
}

const NotSynced = ({ property, vendor }: NotSyncedProps) => {
	const properyType = simplePropertyType(property);

	return (
		<Tooltip
			title={`This may be a duplicate property. Merge this ${properyType} to sync property and inspection data`}>
			<Typography
				color={colors.error.default}>{`Not Synced with ${vendor}`}</Typography>
		</Tooltip>
	);
};

const IntegrationStatus = ({ property }: Props) => {
	const { appFolioIntegration } = useAppFolioIntegration();
	const { rentManagerIntegrations } = useRentManagerIntegrations();

	const integration =
		appFolioIntegration || rentManagerIntegrations?.find((rmi) => rmi.active);
	const activeIntegration = !!integration;

	if (!activeIntegration) return null;

	const vendor = () => {
		if (appFolioIntegration) return 'AppFolio';
		if (rentManagerIntegrations) return 'Rent Manager';
		return '';
	};

	if (property.property_type === 'Community') return null;

	if (!Utils.Properties.propertyIsStillInSync(property)) {
		return <NotSynced property={property} vendor={vendor()} />;
	}

	return (
		<Column>
			<Yardi property={property} />
			<AppFolio property={property} />
			<RentManager property={property} />
		</Column>
	);
};

export default IntegrationStatus;
