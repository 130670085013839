import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { ApiSubscription } from '@rentcheck/types';
import assets from 'assets';
import { DialogTitle, SpacedRow } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	ModalFlowActions,
	SnackbarActions,
	SubscriptionActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import {
	inspectionTemplateFreeTrialAddonId,
	inspectionTemplatePerUnitAddonId,
} from 'utils/integrations';
import Item from './item';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);

	const [loading, setLoading] = useState(false);

	const hasUsedFreeTrial = subscription?.addon_free_trials?.some(
		(aft) => aft.id === inspectionTemplateFreeTrialAddonId
	);

	const trackFreeTrialStartedEvent = async () => {
		// get the updated subscription with the latest addons
		const subscription: ApiSubscription = await dispatch(
			SubscriptionActions.getSubscription()
		);

		const freeTrialAddon = subscription?.addon_free_trials?.find(
			(aft) => aft?.id === inspectionTemplateFreeTrialAddonId
		);

		if (!freeTrialAddon) return;

		return Analytics.trackEvent('customization_free_trial_started', {
			subscription_id: subscription?.id,
			free_trial_start_date: freeTrialAddon.trial_start,
			free_trial_end_date: freeTrialAddon.trial_end,
		});
	};

	const handleTryForFree = () => {
		if (!subscription) {
			dispatch(
				SnackbarActions.showError("User doesn't have an active subscription")
			);
			return;
		}

		setLoading(true);

		const successMessage = hasUsedFreeTrial
			? 'Great news! Your subscription has been updated. Feel free to chat with us if you have any questions.'
			: 'Great news! Your Inspection Templates free trial has begun. Feel free to chat with us if you have any questions.';

		dispatch(
			SubscriptionActions.addAddonsToSubscription([
				hasUsedFreeTrial
					? inspectionTemplatePerUnitAddonId
					: inspectionTemplateFreeTrialAddonId,
			])
		)
			.then(() => dispatch(SnackbarActions.showSuccess(successMessage)))
			.then(() => {
				if (!hasUsedFreeTrial) {
					return trackFreeTrialStartedEvent();
				}
			})

			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<>
			<DialogTitle
				title="Inspection Templates"
				icon={solid('hand-sparkles')}
				onClose={handleClose}
			/>
			<DialogContent>
				<SpacedRow>
					<Typography variant="h5" mr={7}>
						Fully Customized Inspections at Your Fingertips
					</Typography>
					<img
						src={assets.inspectionTemplates.dogPainting}
						alt="Empty State"
						style={{ width: 142 }}
					/>
				</SpacedRow>
				<Item
					title="Unlimited Inspection Templates"
					icon={regular('arrow-up-right-dots')}
					description="Create an unlimited number of custom inspection templates tailored to every aspect of your business."
				/>
				<Item
					title="In-Depth Customization"
					icon={regular('gears')}
					description="Tailor inspections with granular details - set photo requirements, condition ratings, and choose from four question types: Yes/No, Multiple Choice, Open Text, and Asset Capture."
				/>
				<Item
					title="Gather Valuable Data"
					icon={regular('database')}
					description="Utilize asset capture questions to easily obtain model and serial numbers for appliances and other products."
				/>
				{!hasUsedFreeTrial && (
					<Typography color="#010198" mt={2}>
						Try for free for 30 days. Cancel anytime with no penalties or fees.
					</Typography>
				)}
			</DialogContent>
			<DialogActions>
				<div />
				<LoadingButton onClick={handleTryForFree} loading={loading}>
					{hasUsedFreeTrial ? 'Upgrade' : 'Try for free'}
				</LoadingButton>
			</DialogActions>
		</>
	);
};
