import { Community, Profile } from '@rentcheck/types';
import firebase from 'config/fbConfig';
import _ from 'lodash';

import {
	objectFromDocumentSnapshot,
	objectsFromQuerySnapshot,
} from './helpers';

const COLLECTION = 'properties';

export const create = async (payload: Omit<Community, 'id'>) => {
	const ref = firebase.firestore().collection(COLLECTION).doc();
	return ref.set({ ...payload, id: ref.id }).then(() => getById(ref.id));
};

export const hasCommunities = async (profile: Profile): Promise<boolean> => {
	const fetchIds = [profile.id];

	if (profile.organizations && profile.organizations.length) {
		profile.organizations.forEach((o) => fetchIds.push(o.id));
	}

	const chunks = _.chunk(fetchIds, 10);

	return Promise.all(
		chunks.map((chunk) =>
			firebase
				.firestore()
				.collection('properties')
				.where('authorized_user_ids', 'array-contains-any', chunk)
				.where('property_type', '==', 'Community')
				.limit(1)
				.get()
				.then((snapshot) => objectsFromQuerySnapshot<Community>(snapshot))
				.catch(() => [] as Community[])
		)
	).then((results) => _.flatten(results).length > 0);
};

export const getAll = async (profile: Profile): Promise<Community[]> => {
	const fetchIds = [profile.id];

	if (profile.organizations && profile.organizations.length) {
		profile.organizations.forEach((o) => fetchIds.push(o.id));
	}

	const chunks = _.chunk(fetchIds, 10);

	return Promise.all(
		chunks.map((chunk) =>
			firebase
				.firestore()
				.collection('properties')
				.where('authorized_user_ids', 'array-contains-any', chunk)
				.where('property_type', '==', 'Community')
				.get()
				.then((snapshot) => objectsFromQuerySnapshot<Community>(snapshot))
				.catch(() => [] as Community[])
		)
	).then((results) => _.flatten(results));
};

export const getById = async (id: string) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.doc(id)
		.get()
		.then((doc) => objectFromDocumentSnapshot<Community>(doc))
		.catch(() => undefined);
};

export const getByOrganizationIds = async (orgIds: string[]) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.where('organization_id', 'in', orgIds)
		.where('property_type', '==', 'Community')
		.get()
		.then((querySnapshot) => objectsFromQuerySnapshot<Community>(querySnapshot))
		.catch(() => []);
};

export const getByCreatorId = async (creatorId: string) => {
	return firebase
		.firestore()
		.collection(COLLECTION)
		.where('creatorID', '==', creatorId)
		.where('property_type', '==', 'Community')
		.get()
		.then((querySnapshot) => objectsFromQuerySnapshot<Community>(querySnapshot))
		.catch(() => []);
};

export const getByCreatorIdAndOrganizationIds = async (
	creatorId: string,
	organizationIds: string[]
) => {
	const results: Community[] = [];

	const organizationCommunities: Community[] = [];
	const chunks = _.chunk(organizationIds, 10);

	for (const ids_chunk of chunks) {
		const retreivedCommunities = await getByOrganizationIds(ids_chunk);
		organizationCommunities.push(...retreivedCommunities);
	}

	const personalCommunities = await getByCreatorId(creatorId);

	const personalCommunitiesIds = personalCommunities.map((b) => b.id);

	results.push(...personalCommunities);
	results.push(
		...organizationCommunities.filter(
			(b) => !personalCommunitiesIds.includes(b.id)
		)
	);

	return results;
};
