import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import Spacer from 'components/Spacer';
import IconButton from 'components/icon-button';
import { CenteredRow } from 'components/layout/Row';

interface Props {
	value: string;
	onChange: (value: string) => void;
}

export default ({ value, onChange }: Props) => {
	const textFieldRef = useRef<HTMLInputElement>(null);

	const [text, setText] = useState(value);

	useEffect(() => {
		if (value !== text) {
			setText(value);
		}
	}, [value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		onChange(e.target.value);
	};

	const handleClick = () => {
		textFieldRef.current?.focus();
	};

	return (
		<CenteredRow>
			<TextField
				id="table-input-search"
				inputRef={textFieldRef}
				value={value}
				onChange={handleChange}
				color="secondary"
				placeholder="Search"
				sx={{
					marginRight: '-42px',
					opacity: value ? 1 : 0,
					transition: 'opacity .3s',
					':focus-within': { opacity: 1 },
					input: {
						maxHeight: 10,
						width: value ? 150 : 0,
						transition: 'width .3s',
						':focus': { width: 150 },
					},
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Spacer width={3} />
						</InputAdornment>
					),
				}}
			/>
			<IconButton
				tooltip="Search"
				onClick={handleClick}
				icon={regular('search')}
			/>
		</CenteredRow>
	);
};
