import { PermissionGroupsApi } from '@rentcheck/api-frontend';
import { GetAllPermissionGroupsV2Params } from '@rentcheck/types';
import _ from 'lodash';
import useApiModel from './common/api-hook';

type FiltersT = GetAllPermissionGroupsV2Params['filters'];
type SortT = GetAllPermissionGroupsV2Params['options']['sort'];

const initialFilters: FiltersT = {
	search: '',
};

const defaultSort: SortT = {
	sort_by: 'name',
	sort_type: 'ASC',
};

const usePermissionGroups = (pageSize: number = 20) => {
	const hookData = useApiModel(
		PermissionGroupsApi,
		initialFilters,
		defaultSort,
		pageSize
	);

	return {
		permissionGroups: hookData.data,
		..._.omit(hookData, 'data'),
	};
};

export default usePermissionGroups;
