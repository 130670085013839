import { Button as MuiButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Utils } from '@rentcheck/biz';

import { useTypedSelector } from 'store/reducers/rootReducer';
import { isRenter } from 'utils/helpers';

interface NavItemProps {
	route: string;
	title: string;
}

const NavItem = ({ route, title }: NavItemProps) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(route);
	};

	return (
		<Button
			variant="text"
			color="secondary"
			onClick={handleClick}
			active={history.location.pathname.startsWith(route)}
			sx={{
				color: '#000',
				marginLeft: 4,
			}}>
			{title}
		</Button>
	);
};
export default () => {
	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	if (!profile || Utils.Subscriptions.isCancelled(subscription)) return null;

	const navItems: (NavItemProps | null)[] = [
		{ route: '/properties', title: 'Properties' },
		{ route: '/inspections', title: 'Inspections' },
		{ route: '/maintenance', title: 'Maintenance' },
		!isRenter(profile) ? { route: '/residents', title: 'Residents' } : null,
	];

	return <>{navItems.map((item) => (item ? <NavItem {...item} /> : null))}</>;
};

const Button = styled(MuiButton)<{ active?: boolean }>`
	${({ active }) =>
		active
			? `&:after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 2px;
		background: #2d3ce6;
		position: absolute;
		bottom: 0;
		left: 0;
	}`
			: ``}
`;
