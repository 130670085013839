import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppBar, Box, Toolbar } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { landing } from 'assets';
import CancelledSubscription from 'components/cancelled-subscription';
import IconButton from 'components/icon-button';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { isEmbeddedInMobileApp } from 'utils/helpers';

import { SubscriptionActions } from 'store/actions';
import DeeplinkUtils from '../../utils/deeplinkNavigation';
import Drawer from './drawer';
import NavItems from './nav-items';
import ProfileButton from './profile-button';

const hideForLocations = ['/apmhelp', '/appfolio', '/essential'];
const Navbar = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();

	const auth = useTypedSelector((state) => state.firebase.auth);
	const profile = useTypedSelector((state) => state.authenticatedUser);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const URLParameters = queryString.parse(location.search);

	useEffect(() => {
		profile && dispatch(SubscriptionActions.getSubscription());
	}, [profile?.id]);

	if (hideForLocations.includes(location.pathname)) {
		return null;
	}

	if (typeof URLParameters.$deeplink_path === 'string' && auth.uid) {
		const handledLink = DeeplinkUtils.handleDeeplink(
			URLParameters.$deeplink_path,
			dispatch,
			profile
		);

		if (handledLink?.redirect) return <Redirect to={handledLink.redirect} />;
	}

	if (!auth.uid) {
		return (
			<AppBar
				component="nav"
				sx={{ height: 56, backgroundColor: '#E6E7F3' }}
				elevation={0}
			/>
		);
	}

	if (isEmbeddedInMobileApp()) {
		return null;
	}

	const handleDrawerToggle = (e?: React.MouseEvent<HTMLButtonElement>) => {
		!!e && setAnchorEl(e.currentTarget);
	};

	const hideOnLargeScreens = { display: { md: 'none', xs: 'flex' } };
	const hideOnSmallScreens = { display: { md: 'flex', xs: 'none' } };

	return (
		<AppBar component="nav">
			<CancelledSubscription />

			<Toolbar style={{ minHeight: 56 }}>
				{/**
				 *	Small screen components:
				 *	• Hamburger button
				 *	• Drawer
				 *	• RentCheck logo (no text)
				 */}
				<Box
					sx={{
						cursor: 'pointer',
						mr: 2,
						...hideOnLargeScreens,
					}}
					onClick={() => history.push('/')}>
					<img src={landing.rentCheckLogoNotext} style={{ height: 21 }} />
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						justifyContent: 'flex-end',
						...hideOnLargeScreens,
					}}>
					<IconButton
						onClick={handleDrawerToggle}
						icon={regular('bars')}
						buttonSx={{ color: '#000' }}
					/>
				</Box>
				<Drawer anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

				{/**
				 *	Large screen components:
				 *	• RentCheck logo button
				 *	• NavItems
				 *	• Prorfile button
				 */}
				<Box
					sx={{
						flexGrow: 1,
						justifyContent: 'flex-start',
						cursor: 'pointer',
						...hideOnSmallScreens,
					}}
					onClick={() => history.push('/')}>
					<img src={landing.rentCheckLogo} style={{ height: 28 }} />
				</Box>
				<Box sx={{ ...hideOnSmallScreens }}>
					<NavItems />
					<ProfileButton />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
