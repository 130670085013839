import { Route, Switch } from 'react-router-dom';

import Account from 'screens/account';
import AdminPanel from 'screens/account/admin';
import Billing from 'screens/account/billing';
import InspectionReportSettings from 'screens/account/inspection-report-settings';
import Integrations from 'screens/account/integrations';
import inspectionMessaging from 'screens/account/inspection-messaging';
import maintenanceSettings from 'screens/account/maintenance-settings';
import Profile from 'screens/account/profile';
import SalesPanel from 'screens/account/sales';
import Teammates from 'screens/account/teammates';
import Teams from 'screens/account/teams';

import PropertyDetails from 'components/properties/details';
import Properties from 'screens/properties/list';

import Dashboard from 'screens/dashboard';

import InspectionsComparison from 'components/inspections/Comparison';
import ImageViewer from 'screens/image-viewer';
import Inspections from 'screens/inspections';
import InspectionsCreate from 'screens/inspections/create';
import InspectionDetail from 'screens/inspections/details';
import FlaggedDetail from 'screens/inspections/flagged';
import InspectionShare from 'screens/inspections/share';

import Maintenance from 'screens/maintenance/list';
import WorkOrderDetails from 'screens/maintenance/list/work-orders/details';
import MaintenanceReportDetails from 'screens/maintenance/report-details';

import ApmLanding from 'screens/landing/apm';
import AppFolioLanding from 'screens/landing/appfolio';
import EssentialPlanLanding from 'screens/landing/essential-plan';

import Residents from 'screens/residents';

import CreateWorkOrder from 'screens/create-work-order';

import Requests from 'components/Requests';

import inspectionTemplates from 'screens/account/inspection-templates';
import propertyConfiguration from 'screens/account/property-configuration';
import SignIn from 'screens/authentication/sign-in';
import SignUp from 'screens/authentication/sign-up';
import NotFound from 'screens/not-found';

const Routes = () => {
	return (
		<Switch>
			{/* Dashboard */}
			<Route exact path="/" component={Dashboard} />
			<Route exact path="/dashboard" component={Dashboard} />
			<Route exact path="/dashboard/:modal" component={Dashboard} />

			{/* Account */}
			<Route exact path="/account" component={Account} />
			<Route path="/account/profile" component={Profile} />
			<Route path="/account/teams" component={Teams} />
			<Route path="/account/teammates" component={Teammates} />
			<Route path="/account/billing" component={Billing} />
			<Route path="/account/integrations" component={Integrations} />
			<Route path="/account/admin" component={AdminPanel} />
			<Route path="/account/sales" component={SalesPanel} />
			<Route
				path="/account/inspection-report-settings"
				component={InspectionReportSettings}
			/>
			<Route
				path="/account/inspection-messaging"
				component={inspectionMessaging}
			/>
			<Route
				path="/account/maintenance-settings"
				component={maintenanceSettings}
			/>
			<Route
				path="/account/property-configuration"
				component={propertyConfiguration}
			/>
			<Route
				path="/account/inspection-templates"
				component={inspectionTemplates}
			/>

			{/* Landing pages */}
			<Route path="/apmhelp" component={ApmLanding} />
			<Route path="/appfolio" component={AppFolioLanding} />
			<Route path="/essential" component={EssentialPlanLanding} />

			{/* Properties */}
			<Route exact path="/properties" component={Properties} />
			<Route exact path="/properties/buildings" component={Properties} />
			<Route exact path="/properties/communities" component={Properties} />
			<Route exact path="/properties/:id" component={PropertyDetails} />

			{/* Inspections */}
			<Route exact path="/inspections" component={Inspections} />
			<Route path="/inspections/create" component={InspectionsCreate} />
			<Route path="/inspections/share" component={InspectionShare} />
			<Route exact path="/inspections/scheduled" component={Inspections} />
			<Route exact path="/inspections/:id" component={InspectionDetail} />
			<Route
				exact
				path="/inspections/comparison/:query"
				component={InspectionsComparison}
			/>
			<Route
				exact
				path="/inspections/:id/flagged-items"
				component={FlaggedDetail}
			/>

			{/* Maintenance */}
			<Route exact path="/maintenance" component={Maintenance} />
			<Route exact path="/maintenance/reports" component={Maintenance} />
			<Route exact path="/maintenance/work-orders" component={Maintenance} />
			<Route
				exact
				path="/maintenance/reports/:id"
				component={MaintenanceReportDetails}
			/>
			<Route
				exact
				path="/maintenance/work-orders/:id"
				component={WorkOrderDetails}
			/>

			{/* Residents */}
			<Route exact path="/residents" component={Residents} />
			<Route exact path="/residents/:id" component={Residents} />

			{/* Others */}
			<Route exact path="/requests/:id" component={Requests} />
			<Route path="/image-viewer" component={ImageViewer} />

			<Route path="/create-work-order" component={CreateWorkOrder} />

			<Route path="/signin" component={SignIn} />
			<Route path="/signup" component={SignUp} />

			<Route path="/404" component={NotFound} />
		</Switch>
	);
};

export default Routes;
