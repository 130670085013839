"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canViewInspectionMessaging = void 0;
const utils_1 = require("../../utils");
const canViewInspectionMessaging = (subscription, profile) => {
    if (!subscription)
        return false;
    return !!profile && !utils_1.Users.isRenter(profile);
};
exports.canViewInspectionMessaging = canViewInspectionMessaging;
