const authErrorReducer = (state = null, action) => {
	switch (action.type) {
		case 'LOGIN_ERROR':
			return 'login failed';
		case 'LOGIN_SUCCESS':
			return null;
		case 'SIGNOUT_SUCCESS':
			return null;
		case 'SIGNUP_SUCCESS':
			return null;
		case 'SIGNUP_ERROR':
			return action.err.message;
		case 'PASSWORD_RESET_SENT':
			return 'Reset email sent. Please check your email.';
		case 'PASSWORD_RESET_FAILED':
			return 'Invalid email address. Please enter account email and try again';
		case 'EMAIL_UPDATED':
			return 'Email Reset. Please check your email for verification';
		case 'EMAIL_UPDATE_FAILED':
			return 'Email Update failed. Please enter a valid email and try again';
		case 'REAUTH_FAILURE':
			return action.error.message;
		default:
			return state;
	}
};

export default authErrorReducer;
