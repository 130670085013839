import { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import {
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Paper, SxProps, Theme, Typography } from '@mui/material';

import { TemplateFeature } from '@rentcheck/types';

import { Column, SpacedRow } from 'components';
import { numberAndPluralizeWord } from 'utils/helpers';

import FeatureLogicModal from './feature-logic/modal';

interface Props {
	feature: TemplateFeature;
	index: number;
	editing: boolean;
	selected?: boolean;
	onSelect: (feature: TemplateFeature) => void;
	features: TemplateFeature[];
	handleUpdateSingleFeature: (feature: TemplateFeature) => void;
}

const getItemStyle = (
	selected?: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle
): SxProps<Theme> => ({
	mb: 1,
	minHeight: 54,
	paddingLeft: 4,
	paddingRight: 2,
	paddingTop: 1,
	paddingBottom: 1,
	backgroundColor: '#f6f5ff',
	border: selected ? '1px solid #2D3CE6' : '1px solid #0000001F',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	transition: 'elevation 0.2s ease-in-out',
	cursor: 'pointer',

	// styles we need to apply on draggables
	...(draggableStyle as any),
});

export default ({
	feature,
	index,
	editing,
	selected,
	onSelect,
	features,
	handleUpdateSingleFeature,
}: Props) => {
	const [featureLogicModalOpen, setFeatureLogicModalOpen] = useState(false);

	return (
		<>
			<Draggable
				key={feature.id}
				draggableId={feature.id}
				isDragDisabled={!editing}
				index={index}>
				{(provided, snapshot) => (
					<Paper
						ref={provided.innerRef}
						onClick={() => onSelect(feature)}
						elevation={snapshot.isDragging ? 1 : 0}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						sx={getItemStyle(selected, provided.draggableProps.style)}>
						<SpacedRow style={{ width: '100%' }}>
							<Column>
								<Typography
									variant="subtitle2"
									sx={{ wordBreak: 'break-word' }}>
									{feature.name || 'New Feature'}
								</Typography>
								<Typography variant="caption">
									{numberAndPluralizeWord(feature.questions.length, 'Question')}
								</Typography>
							</Column>
						</SpacedRow>
					</Paper>
				)}
			</Draggable>
			<FeatureLogicModal
				feature={feature}
				features={features}
				onUpdate={handleUpdateSingleFeature}
				open={featureLogicModalOpen}
				onClose={() => setFeatureLogicModalOpen(false)}
			/>
		</>
	);
};
