import { LoadingButton } from '@mui/lab';
import {
	Button,
	Card,
	CardContent,
	TextField,
	Typography,
} from '@mui/material';
import { Profile } from '@rentcheck/types';
import { UsersApi } from 'api';
import { Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { setImpersonatedUser } from 'store/actions/activeProfileActions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';

export default function AdminPanel() {
	const dispatch = useDispatch();

	const activeProfile = useTypedSelector((state) => state.activeProfile);

	const [user, setUser] = useState<Profile | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState<string>('');

	const search = async (searchValue: string) => {
		setLoading(true);

		const userByEmail = await UsersApi.getByEmail(searchValue).catch((e) => {
			dispatch(SnackbarActions.showError(e));
			return undefined;
		});

		const userById = await UsersApi.getById(searchValue).catch((e) => {
			dispatch(SnackbarActions.showError(e));
			return undefined;
		});

		const user = userByEmail || userById;

		setLoading(false);

		if (!user) {
			dispatch(SnackbarActions.showError('User not found'));
			return;
		}

		setUser(user);
	};

	const impersonate = () => {
		if (!user || !activeProfile) {
			return;
		}

		dispatch(setImpersonatedUser(activeProfile, user));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Impersonate User</Typography>
				<Spacer height={2} />
				<Typography>Search user by email or Id:</Typography>
				<Spacer height={2} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						search(input);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="email"
						placeholder="Email"
						onChange={(e) => setInput(e.target.value)}
					/>
					<Spacer width={6} />
					<LoadingButton
						type="submit"
						variant="contained"
						onClick={() => search(input)}
						loading={loading}>
						Search
					</LoadingButton>
				</FormRow>

				{user && (
					<>
						<Spacer height={6} />
						<Typography>Name: </Typography>
						<Typography>{user.user_name}</Typography>
						<Spacer height={1} />
						<Typography>Email: </Typography>
						<Typography>{user.email}</Typography>
						<Spacer height={1} />
						<Typography>User Type: </Typography>
						<Typography>{user.user_type}</Typography>
						<Spacer height={1} />
						{user.organizations?.length && (
							<>
								<Typography>Org IDs: </Typography>
								<Typography>
									{user.organizations.map((o) => o.id).join(', ')}
								</Typography>
							</>
						)}
						<Spacer height={6} />
						<Button onClick={() => impersonate()}>Impersonate User</Button>
					</>
				)}
			</CardContent>
		</Card>
	);
}

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	width: 50%;
	justify-content: space-between;
	text-align: center;
`;
