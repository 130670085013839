import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip, Typography } from '@mui/material';

import { Property } from '@rentcheck/types';
import { Utils } from '@rentcheck/biz';

import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';
import {
	ModalFlowActions,
	PropertiesActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import EditPropertyModal, { EditPropertySection } from './EditPropertyModal';
import { Column, Row, ScreenContentSideBar, SpacedRow, Spacer } from '../..';
import { RoomNames } from '../../../constants';

interface Props {
	property: Property;
}

const EditButton = () => (
	<FontAwesomeIcon icon={solid('edit')} color={colors.gray} size="xs" />
);

interface DeleteButtonProps {
	disabled: boolean;
	onClick: () => void;
}

const DeleteButton = ({ disabled, onClick }: DeleteButtonProps) => {
	if (disabled)
		return (
			<Tooltip title="Cannot delete property when integration sync is active">
				<Column>
					<Button disabled variant="outlined" color="secondary">
						Delete Unit
					</Button>
				</Column>
			</Tooltip>
		);

	return (
		<Button variant="outlined" color="secondary" onClick={onClick}>
			Delete Unit
		</Button>
	);
};

const Checkmark = () => <FontAwesomeIcon icon={solid('check')} size="xs" />;

const PropertyInformation = ({ property }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [editModalVisible, setEditModalVisible] = useState(false);
	const [editModalSection, setEditModalSection] =
		useState<EditPropertySection>('Property Type');

	const renderRooms = (title: string, rooms: string[], onClick: () => void) => {
		return (
			<Column>
				<EditableRow onClick={onClick}>
					<Typography variant="subtitle2">{title}</Typography>
					<Spacer width={1} />
					<EditButton />
				</EditableRow>
				<Spacer height={1} />
				<InnerContentDetails>
					{rooms.length === 0 && <Typography color="#4D4D4D">None</Typography>}
					{rooms.length > 0 &&
						rooms.map((r) => (
							<RoomRow id={`${r}-row`} key={r}>
								<Typography color="#4D4D4D">{r}</Typography>
								<Checkmark />
							</RoomRow>
						))}
				</InnerContentDetails>
				<Spacer height={1} />
			</Column>
		);
	};

	const renderBedrooms = () => {
		const rooms = property.room_names.filter((r) =>
			r.toLowerCase().includes('bedroom')
		);
		return renderRooms('Bedrooms', rooms, () => {
			setEditModalSection('Bedrooms & Bathrooms');
			setEditModalVisible(true);
		});
	};

	const renderFullBathrooms = () => {
		const rooms = property.room_names.filter(
			(r) =>
				r.toLowerCase().includes('bathroom') &&
				!r.toLowerCase().includes('half')
		);
		return renderRooms('Full Bathrooms', rooms, () => {
			setEditModalSection('Bedrooms & Bathrooms');
			setEditModalVisible(true);
		});
	};

	const getUncategorizedCustomRooms = () => {
		const allCategorizedRooms = [
			...RoomNames.main,
			...RoomNames.common,
			...RoomNames.outside,
			...(accountSettings?.rooms.unit.map((r) => r.name) ?? []),
		];

		return _.difference(property.room_names, allCategorizedRooms).filter(
			(r) =>
				!r.includes('Bedroom') &&
				!r.includes('Bathroom') &&
				r !== 'Front Door' &&
				r !== 'Back Door'
		);
	};

	const renderHalfBathrooms = () => {
		const rooms = property.room_names.filter((r) =>
			r.toLowerCase().includes('half bathroom')
		);
		return renderRooms('Half Bathrooms', rooms, () => {
			setEditModalSection('Bedrooms & Bathrooms');
			setEditModalVisible(true);
		});
	};

	const renderMainRooms = () => {
		const mainRooms = RoomNames.main.map((r) => r.toLowerCase());

		if (accountSettings) {
			mainRooms.push(
				...accountSettings.rooms.unit.map((r) => r.name.toLowerCase())
			);
		}

		mainRooms.push(
			...getUncategorizedCustomRooms().map((r) => r.toLowerCase())
		);

		const rooms = property.room_names.filter((r) =>
			mainRooms.includes(r.toLowerCase())
		);

		return renderRooms('Additional Rooms', rooms, () => {
			setEditModalSection('Additional Rooms');
			setEditModalVisible(true);
		});
	};

	const renderCommonRooms = () => {
		const commonRooms = RoomNames.common.map((r) => r.toLowerCase());
		const rooms = property.room_names.filter((r) =>
			commonRooms.includes(r.toLowerCase())
		);

		return renderRooms('Common Areas', rooms, () => {
			setEditModalSection('Common Areas');
			setEditModalVisible(true);
		});
	};

	const renderOutside = () => {
		const outside = RoomNames.outside.map((r) => r.toLowerCase());
		const rooms = property.room_names.filter((r) =>
			outside.includes(r.toLowerCase())
		);

		return renderRooms('Outside', rooms, () => {
			setEditModalSection('Outside Rooms');
			setEditModalVisible(true);
		});
	};

	const handleDeleteProperty = () => {
		history.push('/properties');

		dispatch(PropertiesActions.units.deleteObjects([property.id])).catch(() =>
			dispatch(SnackbarActions.showError())
		);
	};

	const handleConfirmationModal = () => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					"Are you sure you want to delete this unit? This action can't be undone.",
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: handleDeleteProperty,
					},
				],
			})
		);
	};

	const stillInSync = Utils.Properties.propertyIsStillInSync(property);

	return (
		<ScreenContentSideBar hasTitleRow>
			<InnerContent>
				<Typography variant="subtitle1">Unit Information</Typography>
				<InnerContentDetails>
					{renderBedrooms()}
					{renderFullBathrooms()}
					{renderHalfBathrooms()}
					{renderMainRooms()}
					{renderCommonRooms()}
					{renderOutside()}
				</InnerContentDetails>
			</InnerContent>
			<HorizontalDivider />
			<InnerContent>
				<Typography variant="h6">Unit Type</Typography>
				<InnerContentDetails>
					<EditableRow
						onClick={() => {
							setEditModalSection('Property Type');
							setEditModalVisible(true);
						}}>
						<Typography>{property.property_type}</Typography>
						<Spacer width={1} />
						<EditButton />
					</EditableRow>
				</InnerContentDetails>
			</InnerContent>
			<HorizontalDivider />
			<InnerContent>
				<DeleteButton
					disabled={stillInSync}
					onClick={handleConfirmationModal}
				/>
			</InnerContent>

			{editModalVisible && (
				<EditPropertyModal
					section={editModalSection}
					property={property}
					setHidden={() => setEditModalVisible(false)}
				/>
			)}
		</ScreenContentSideBar>
	);
};

const HorizontalDivider = styled.div`
	background-color: #bfbfbf;
	flex-shrink: 0;
	height: 1px;
	width: auto;
`;

const InnerContent = styled(Column)`
	padding: ${spacingPx(8)};
`;

const InnerContentDetails = styled(Column)`
	padding: ${spacingPx(2)};
`;

const EditableRow = styled(Row)`
	cursor: pointer;
`;

const RoomRow = styled(SpacedRow)`
	margin-bottom: ${spacingPx(2)};
`;

export default PropertyInformation;
