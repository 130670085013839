"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.canInstall = void 0;
const __1 = require("../..");
const Utils = __importStar(require("../../utils"));
const canInstall = (subscription) => {
    if (!subscription) {
        return false;
    }
    /**
     * User must be on a plan that is not the essentials plan
     */
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    /**
     * If user is part of the Professional plan then they can install any addon
     */
    if (Utils.Subscriptions.isOnProPlan(subscription)) {
        return true;
    }
    /**
     * If user in on a legacy plan but has the AppFolio addon then they can install the
     * integration
     */
    if (subscription.addons.some((addon) => __1.Constants.Addons.appFolioAddonIds.includes(addon.id))) {
        return true;
    }
    return false;
};
exports.canInstall = canInstall;
