"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.canAccess = exports.areFlagDefaultPhotosEnabled = exports.canRenterReportMaintenance = exports.canTake360Photos = exports.canRecordVideo = void 0;
const Utils = __importStar(require("../utils"));
const canRecordVideo = (subscription) => {
    if (!subscription) {
        return false;
    }
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    return true;
};
exports.canRecordVideo = canRecordVideo;
const canTake360Photos = (subscription) => {
    if (!subscription) {
        return false;
    }
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    return true;
};
exports.canTake360Photos = canTake360Photos;
const canRenterReportMaintenance = (accountSettings, subscription) => {
    if (!subscription) {
        return false;
    }
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    return accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.is_resident_reported_maintenance_enabled;
};
exports.canRenterReportMaintenance = canRenterReportMaintenance;
const areFlagDefaultPhotosEnabled = (accountSettings) => {
    if (!accountSettings)
        return false;
    return accountSettings.is_maintenance_flag_default_photos_enabled;
};
exports.areFlagDefaultPhotosEnabled = areFlagDefaultPhotosEnabled;
const canAccess = ({ user, inspection, permissionGroups, }) => {
    var _a, _b;
    const userIdIsInAuthorizedUserIds = inspection.authorized_user_ids.includes(user.id);
    if (userIdIsInAuthorizedUserIds)
        return true;
    const userOrgIds = user.organizations.map((org) => org.id);
    const userOrOrgsHaveAccessToInspection = userOrgIds.some((orgId) => inspection.authorized_user_ids.includes(orgId));
    const validInspectionTemplatesForUser = (_b = (_a = permissionGroups === null || permissionGroups === void 0 ? void 0 : permissionGroups.map((permissionGroup) => permissionGroup.inspection_templates)) === null || _a === void 0 ? void 0 : _a.flat()) !== null && _b !== void 0 ? _b : [];
    const userPermissionGroupsHaveAccessToInspectionTemplate = validInspectionTemplatesForUser === null || validInspectionTemplatesForUser === void 0 ? void 0 : validInspectionTemplatesForUser.includes(inspection.inspection_template.id);
    if (userOrOrgsHaveAccessToInspection &&
        userPermissionGroupsHaveAccessToInspectionTemplate) {
        return true;
    }
    return false;
};
exports.canAccess = canAccess;
