import { FeatureRating } from '@rentcheck/types';

import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'Feature Rating';

const FilterRatingTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const ratingsFilter = useTypedSelector(
		(state) => state.inspections.filters.ratings
	);

	return (
		<FilterTitle
			label={title}
			isActive={ratingsFilter.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const FilterRatingDetails = ({ selectedFilter }: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const ratingsFilter = useTypedSelector(
		(state) => state.inspections.filters.ratings
	);

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (feature: FeatureRating) => {
		dispatch({
			type: 'TOGGLE_INSPECTION_RATINGS_FILTER',
			value: feature,
		});
	};

	return (
		<>
			<FilterTitleOption
				label={'Poor'}
				isSelected={ratingsFilter.includes('Poor')}
				onClick={() => handleClick('Poor')}
			/>
			<FilterTitleOption
				label={'Fair'}
				isSelected={ratingsFilter.includes('Fair')}
				onClick={() => handleClick('Fair')}
			/>
			<FilterTitleOption
				label={'Good'}
				isSelected={ratingsFilter.includes('Good')}
				onClick={() => handleClick('Good')}
			/>
		</>
	);
};

export { FilterRatingDetails, FilterRatingTitle };
