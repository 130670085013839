import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Feature } from '@rentcheck/types';

import { InspectionsApi } from 'api';
import { Column, Row, Spacer } from 'components';
import { useEditFeatureModalData } from 'hooks/modals';
import {
	FeatureActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { calculateFeatureChangeset } from 'utils/helpers';

import EditImages from './edit-images';
import Header from './header';
import Images from './images';
import InstructionsModal from './instructions';
import Sidebar from './sidebar';
import Tools from './tools';

interface Props {
	open: boolean;
}

const EditFeature = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up(599));

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useEditFeatureModalData();
	const { features, inspection } = modalData?.metadata ?? {};

	const [currentFeature, setCurrentFeature] = useState<Feature | null>(null);

	const [showInstructions, setShowInstructions] = useState(false);
	const [featurePayload, setFeaturePayload] = useState<Partial<Feature>>({});
	const [saveLoading, setSaveLoading] = useState(false);
	const [editImagesOpen, setEditImagesOpen] = useState(false);

	const setToCurrentFeature = () => {
		if (!currentFeature) return;

		setFeaturePayload({
			rating: currentFeature.rating,
			responses: currentFeature.responses,
			notes: currentFeature.notes ?? '',
			not_applicable: currentFeature.not_applicable,
			images: currentFeature.images,
		});
	};

	useEffect(() => {
		if (!open || !profile) {
			return;
		}

		setShowInstructions(!profile.feature_editing_dismissed);
		setEditImagesOpen(false);
	}, [open, profile]);

	useEffect(() => {
		if (!modalData?.metadata?.initialFeature) {
			return;
		}

		setCurrentFeature(modalData.metadata.initialFeature);
	}, [modalData]);

	useEffect(() => {
		if (!features || !currentFeature) {
			return;
		}

		const updatedCurrentFeature = features.find(
			(f) => f.id === currentFeature.id
		);

		if (!updatedCurrentFeature) {
			return;
		}

		setCurrentFeature(updatedCurrentFeature);
	}, [features]);

	useEffect(() => {
		setToCurrentFeature();
	}, [currentFeature]);

	const handleClose = () => {
		setToCurrentFeature();
		setCurrentFeature(null);
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSave = async () => {
		if (!currentFeature) {
			return;
		}

		const changeset = calculateFeatureChangeset(
			currentFeature,
			featurePayload,
			profile
		);

		const emptyResponse =
			changeset.questions.find((q) => q.updated.length === 0)?.updated === '' ||
			changeset.questions.find((q) => !q.updated.trim().length);

		if (emptyResponse) {
			return dispatch(
				SnackbarActions.showError(
					'Responses to text questions are required to edit this feature.'
				)
			);
		}

		if (
			!changeset.note &&
			!changeset.rating &&
			!changeset.images.addded.length &&
			!changeset.images.removed.length &&
			!changeset.questions.length
		) {
			return handleClose();
		}

		setSaveLoading(true);

		if (featurePayload.rating === undefined) {
			delete featurePayload.rating;
		}

		await dispatch(
			FeatureActions.updateFeature(currentFeature.id, featurePayload)
		)
			.then(() => InspectionsApi.edited(inspection.id, profile, changeset))
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setSaveLoading(false));
	};

	const handleEditImages = () => {
		setEditImagesOpen(true);
	};

	if (!currentFeature) {
		return null;
	}

	if (showInstructions) {
		return (
			<InstructionsModal
				open={showInstructions}
				setOpen={setShowInstructions}
				userId={profile.id}
				handleClose={handleClose}
			/>
		);
	}

	return (
		<>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				transitionDuration={0}>
				<DialogTitle sx={{ paddingLeft: 30, paddingRight: 30 }}>
					<Header feature={currentFeature} onClose={handleClose} />
					<Spacer height={4} />
					<Divider />
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: 30,
						paddingRight: 30,
						overflowY: md ? 'hidden' : 'scroll',
					}}>
					{md && (
						<Row style={{ alignItems: 'flex-start', height: '100%' }}>
							<Sidebar
								feature={currentFeature}
								featurePayload={featurePayload}
								setFeaturePayload={setFeaturePayload}
							/>
							<Spacer width={4} />
							<Images feature={{ ...currentFeature, ...featurePayload }} />
						</Row>
					)}
					{!md && (
						<Column style={{ alignItems: 'flex-start' }}>
							<Sidebar
								feature={currentFeature}
								featurePayload={featurePayload}
								setFeaturePayload={setFeaturePayload}
								fullWidth
							/>
							<Spacer height={4} />
							<Images feature={{ ...currentFeature, ...featurePayload }} />
						</Column>
					)}
				</DialogContent>
			</Dialog>

			<EditImages
				open={editImagesOpen}
				handleClose={() => setEditImagesOpen(false)}
				feature={currentFeature}
				featurePayload={featurePayload}
				setFeaturePayload={setFeaturePayload}
			/>

			<Tools
				onClose={handleClose}
				onSave={handleSave}
				onEditImages={handleEditImages}
				saveLoading={saveLoading}
			/>
		</>
	);
};

export default EditFeature;
