import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import { testId } from 'utils/helpers';

interface Props {
	roomType: string;
	sections?: ApiInspectionWithTemplate['sections_progress'];
}

const AreaGroup = ({ roomType, sections = [] }: Props) => {
	if (!sections.length) {
		return null;
	}

	return (
		<Column style={{ marginBottom: 20 }}>
			<Typography variant="overline">{roomType}</Typography>
			{sections.map((r) => {
				return (
					<SpacedRow
						key={r.section.name_with_ordinal}
						style={{ marginTop: 12 }}>
						<Typography variant="body2" color="#4D4D4D">
							{r.section.name_with_ordinal}
						</Typography>
						{r.progress === 100 && (
							<FontAwesomeIcon
								id={`check-icon-${testId(r.section.name_with_ordinal)}`}
								icon={regular('check')}
								color="#51C978"
							/>
						)}
					</SpacedRow>
				);
			})}
		</Column>
	);
};

export default AreaGroup;
