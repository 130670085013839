import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
	ApiMaintenanceReport,
	GetAllMaintenanceReportsParams,
	Property,
} from '@rentcheck/types';

import { Column } from 'components';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { shortDisplayDate } from 'utils/helpers';

import { MaintenanceReportsApi } from '@rentcheck/api-frontend';
import EmptyState from 'screens/maintenance/list/common/empty-state';
import { SnackbarActions } from 'store/actions';
import DetailsTable from '../table';
import PropertyDetailsSkeleton from '../table/skeleton';

interface Props {
	property: Property;
}

interface RowProps {
	property: Property;
	reports: ApiMaintenanceReport[];
}

const ReportRows = ({ reports }: RowProps) => {
	const history = useHistory();

	const [startIndex, setStartIndex] = useState(0);

	const reportSummary = (
		flags: ApiMaintenanceReport['maintenance_flags'][0][]
	) => {
		const featuresList = flags.map((f) => f.feature).join(' | ');

		let sum = 0;

		flags.forEach((f) => {
			if (f.cost) sum = sum + f.cost;
		});

		return (
			<Column>
				<Typography fontWeight={600}>
					{flags.length} Flag
					{flags.length === 1 ? '' : 's'}
					{sum === 0 ? '' : ` - $${sum.toFixed(2)}`}
				</Typography>
				<Typography style={{ color: colors.gray }}>{featuresList}</Typography>
			</Column>
		);
	};

	const pageSize = 5;
	const headers = ['Report Name', 'Report Summary', 'Created On'];

	return (
		<DetailsTable
			headers={headers}
			length={reports.length}
			pageSize={pageSize}
			startIndex={startIndex}
			setStartIndex={setStartIndex}>
			<TableBody>
				{reports
					.slice(startIndex * pageSize, startIndex * pageSize + pageSize)
					.map((r) => {
						return (
							<TableRow
								key={r.id}
								sx={{ cursor: 'pointer' }}
								onClick={() => history.push(`/maintenance/reports/${r.id}`)}>
								<TableCell>{r.name}</TableCell>
								<TableCell>{reportSummary(r.maintenance_flags)}</TableCell>
								<TableCell>{shortDisplayDate(r.created_date)}</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</DetailsTable>
	);
};

const MaintenanceReportsList = ({ property }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [reports, setReports] = useState<ApiMaintenanceReport[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const filters: GetAllMaintenanceReportsParams['filters'] = {
			property_id: property.id,
		};

		setLoading(true);

		MaintenanceReportsApi.getAll(0, 100, filters)
			.then((res) => setReports(res.data))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <PropertyDetailsSkeleton />;
	}

	if (!reports.length) {
		return <EmptyState type="reports" />;
	}

	return <ReportRows reports={reports} property={property} />;
};

export default MaintenanceReportsList;
