import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Checkbox,
	Chip,
	IconButton,
	SxProps,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { Inspection } from '@rentcheck/api-frontend';
import _ from 'lodash';
import moment from 'moment';

import { colors as colorsTheme } from '@rentcheck/theme';
import { FeatureRating } from '@rentcheck/types';
import { Column, Row, Spacer } from 'components';
import { useHistory } from 'react-router-dom';
import { colors } from 'theme';
import {
	formatDate,
	formattedInspectionAddress,
	inspectionIsInProgress,
} from 'utils/helpers';
import ActionsMenu from './actions-menu';

interface Props {
	inspection: Inspection;
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectId: (id: string) => void;
	index: number;
}

interface CellProps {
	inspection: Inspection;
}

const InspectionStatus = ({ inspection }: CellProps) => {
	const createSx = (status: string): SxProps => {
		const needsAttention = ['Awaiting Review', 'Revision Review'].includes(
			status
		);
		const isComplete = ['Completed', 'Approved'].includes(status);

		const baseSx = {
			width: 200,
			fontWeight: 500,
			backgroundColor: '#eaeaea',
			color: '#353535',
		};

		if (needsAttention) {
			return {
				...baseSx,
				backgroundColor: '#101010',
				color: '#ffffff',
			};
		}

		if (isComplete) {
			return {
				...baseSx,
				border: '2px solid #8d8d8d',
				backgroundColor: '#ffffff',
				color: '#535353',
			};
		}

		return baseSx;
	};

	let label: string = inspection.inspection_status;

	if (inspection.inspection_status === 'Started') {
		label = `${label} ${inspection.progress_pct}%`;
	}

	return <Chip sx={createSx(inspection.inspection_status)} label={label} />;
};

const RatingsBar = ({ inspection }: CellProps) => {
	if (!inspection.features_ratings) return <></>;

	const { fair_quantity, good_quantity, poor_quantity } =
		inspection.features_ratings;
	const total = _.max([good_quantity, fair_quantity, poor_quantity]) || 1;
	const goodPercentage = (good_quantity / total) * 100;
	const fairPercentage = (fair_quantity / total) * 100;
	const poorPercentage = (poor_quantity / total) * 100;

	if (inspectionIsInProgress(inspection)) return <></>;

	const buildColor = (rating: FeatureRating, size: number) => {
		const colorMap = {
			Poor: colorsTheme.error['800'],
			Fair: colorsTheme.caution['600'],
			Good: colorsTheme.success['700'],
			'': colors.error,
		};

		return (
			<div
				style={{
					width: `${size > 10 ? size : 10}%`,
					height: 6,
					marginTop: 1,
					marginBottom: 1,
					backgroundColor:
						size > 0 ? colorMap[rating] : colorsTheme.secondary['100'],
					borderRadius: 4,
					overflow: 'hidden',
				}}
			/>
		);
	};

	return (
		<Tooltip
			placement="left"
			title={
				<>
					Good: {good_quantity} <br />
					Fair: {fair_quantity} <br />
					Poor: {poor_quantity} <br />
				</>
			}>
			<Column style={{ width: 30, height: 24 }}>
				{buildColor('Good', goodPercentage)}
				{buildColor('Fair', fairPercentage)}
				{buildColor('Poor', poorPercentage)}
			</Column>
		</Tooltip>
	);
};

const DueDate = ({ inspection }: CellProps) => {
	const dueDate = moment(inspection.due_date).startOf('day');
	const daysAway = moment.duration(moment().diff(dueDate)).asDays();

	const inspectionIsComplete = [
		'Awaiting Review',
		'Approved',
		'Completed',
	].includes(inspection.inspection_status);

	// The inspection is at least almost due
	if (daysAway >= -3 && !inspectionIsComplete) {
		let title = 'This inspection is almost due';
		let color = colors.warning;

		if (daysAway >= 1) {
			title = 'This inspection is past due';
			color = colors.error;
		}

		return (
			<Tooltip title={title} placement="top-end">
				<Row>
					<Typography>{formatDate(inspection.due_date)}</Typography>
					<Spacer width={2} />
					<FontAwesomeIcon icon={solid('exclamation-triangle')} color={color} />
				</Row>
			</Tooltip>
		);
	}

	return <Typography>{formatDate(inspection.due_date)}</Typography>;
};

const InspectionTableRow = ({
	inspection,
	selectedIds,
	setSelectedIds,
	selectId,
	index,
}: Props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/inspections/${inspection.id}`);
	};

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					size="small"
					id={`inspection-row-checkbox-${index}`}
					checked={selectedIds.includes(inspection.id)}
					onChange={() => selectId(inspection.id)}
				/>
			</TableCell>
			<TableCell onClick={handleClick}>
				{formattedInspectionAddress(inspection)}
			</TableCell>
			<TableCell onClick={handleClick}>
				<Row>
					<Column>
						<Typography>
							{inspection?.inspection_template.name}
							{inspection.fast_track && (
								<FontAwesomeIcon
									icon={solid('bolt')}
									color={colors.primary}
									style={{ marginLeft: 10 }}
								/>
							)}
						</Typography>
						<Typography color="#61768F">
							{inspection.inspection_label}
						</Typography>
					</Column>
				</Row>
			</TableCell>
			<TableCell onClick={handleClick}>
				<DueDate inspection={inspection} />
			</TableCell>
			<TableCell onClick={handleClick}>
				{formatDate(inspection.completed_date)}
			</TableCell>
			<TableCell onClick={handleClick}>
				<InspectionStatus inspection={inspection} />
			</TableCell>
			<TableCell onClick={handleClick}>
				<RatingsBar inspection={inspection} />
			</TableCell>
			<TableCell align="center">
				<ActionsMenu
					inspectionIds={[inspection.id]}
					setSelectedIds={setSelectedIds}
					archived={inspection.archived}>
					<IconButton size="small" sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon
							id={`inspection-row-menu-icon-${index}`}
							icon={solid('ellipsis-v')}
							color="gray"
						/>
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</TableRow>
	);
};

export default InspectionTableRow;
