"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userIsInvitedTeammate = void 0;
const userIsInvitedTeammate = (inspection) => {
    const validRoles = ['invitee-write', 'invitee-readonly'];
    const validAssigneeTypes = ['teammates'];
    if (validRoles.includes(inspection.role) &&
        validAssigneeTypes.includes(inspection.assigned_recipients.type)) {
        return true;
    }
    return false;
};
exports.userIsInvitedTeammate = userIsInvitedTeammate;
